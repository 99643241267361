import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0004 0C4.48566 0 0 4.48419 0 10.0004C0 15.5166 4.48419 20.0008 10.0004 20.0008C15.5166 20.0008 20.0008 15.5158 20.0008 10.0004C20.0008 4.48494 15.5158 0 10.0004 0ZM10.0004 18.6639C9.10569 18.6639 8.24678 18.5281 7.43403 18.2774C7.78503 17.7038 8.31653 16.7578 8.51178 16.0075C8.61662 15.6011 9.05031 13.9486 9.05031 13.9486C9.33156 14.4872 10.1606 14.9448 11.0342 14.9448C13.6478 14.9448 15.5302 12.5428 15.5302 9.55378C15.5302 6.68994 13.1942 4.55028 10.1905 4.55028C6.4515 4.55028 4.46759 7.06125 4.46759 9.79297C4.46759 11.063 5.14378 12.6442 6.22525 13.1477C6.38912 13.2267 6.47512 13.1902 6.51388 13.0303C6.54119 12.9089 6.68994 12.3224 6.756 12.0467C6.77594 11.9599 6.76744 11.8824 6.69769 11.7968C6.33894 11.3627 6.0525 10.5618 6.0525 9.82028C6.0525 7.91019 7.49791 6.05916 9.96275 6.05916C12.0913 6.05916 13.5799 7.50822 13.5799 9.58331C13.5799 11.9271 12.3962 13.5522 10.8574 13.5522C10.0059 13.5522 9.36887 12.8483 9.57628 11.9861C9.82212 10.9549 10.2946 9.845 10.2946 9.10384C10.2946 8.43984 9.93987 7.88472 9.19722 7.88472C8.32616 7.88472 7.63116 8.78347 7.63116 9.99003C7.63116 10.7548 7.88841 11.2749 7.88841 11.2749C7.88841 11.2749 7.02878 14.9038 6.87225 15.5801C6.69619 16.3301 6.76741 17.3813 6.84125 18.0648C3.61866 16.8073 1.33356 13.6752 1.33356 10.0022C1.33356 5.21725 5.21206 1.33875 9.99706 1.33875C14.7821 1.33875 18.6606 5.21725 18.6606 10.0022C18.6606 14.7872 14.7821 18.6661 9.99706 18.6661L10.0004 18.6639V18.6639Z"
      fill="#222222"
    />
  </svg>
);

export default Icon;
