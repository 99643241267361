import React from 'react';
import { useRouter } from 'next/router';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { desktop } from 'helpers/utils/screensizes';
export interface GuestCheckoutProps {
  handleGuestCheckout?: boolean;
}

const GuestCheckout = ({ handleGuestCheckout }) => {
  //next/router
  const [isDesktop] = useMediaQuery(desktop);
  const router = useRouter();

  return (
    <>
      <section className=" w-full sm:w-[373px]">
        <h1 className="mb-5 text-center text-[28px] font-semibold leading-[34px] text-[#1B3144] lg:mb-14 lg:text-center">
          {mapLocaleToMeaningfulFormat(router.locale).checkoutAsGuest}
        </h1>
        <section className="mt-4">
          <p className="text-sm">
            {isDesktop
              ? mapLocaleToMeaningfulFormat(router.locale).continueToCheckoutGuest
              : mapLocaleToMeaningfulFormat(router.locale).continueToCheckoutGuestMobile}{' '}
          </p>
          <button
            type="submit"
            id="guestCheckoutButton"
            className="btn-primary-large mt-7 w-full md:w-[208px]"
            onClick={handleGuestCheckout}
          >
            {mapLocaleToMeaningfulFormat(router.locale).checkoutAsGuest}
          </button>
        </section>
      </section>
    </>
  );
};

export default GuestCheckout;
