import React, { useEffect, useState, useCallback } from 'react';
import Link from 'next/link';
import router from 'next/router';
import algoliasearch from 'algoliasearch/lite';
import _ from 'lodash';
import CloseIcon from 'components/icons/sol/close';
import SearchIcon from 'components/icons/sol/search';
import Categories from 'components/sol-ui/categories';
import { ArrayHelpers } from 'helpers/arrayHelpers';
import { debounce } from 'helpers/debounce';
import { StringHelpers } from 'helpers/stringHelpers';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { useConfig } from 'frontastic';
import SearchContent from './search-content-product';
import SearchData from './search-data.json';
import SearchRelatedProduct from './search-related-product';
import SearchSuggestedProduct from './search-suggested-product';

export const SOLHeaderMobileSearch = ({
  categories,
  setIsOpenSearch,
  searchRelatedProdutsCount,
  searchSuggestedSearchesCount,
  searchContentCount,
}) => {
  const [isExpand, setIsExpand] = useState(false);
  const [word, setWord] = useState('');

  useEffect(() => {
    word.trim() !== '' && word.trim().length >= 3 ? setIsExpand(true) : setIsExpand(false);
  }, [word]);

  const { data: config } = useConfig();
  const [searchClient, setSearchClient] = useState(null);
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [categoriesArray, setCategoriesArray] = useState([]);
  const [searchResults, setSearchResults] = useState({
    products: [],
    blogs: [],
    categories: [],
  });
  const [productRedirectionLink, setProductRedirectionLink] = useState('');

  useEffect(() => {
    if (config?.algolia && !searchClient) {
      const client = algoliasearch(config?.algolia?.appId, config?.algolia?.appSearchKey);
      setSearchClient(client);
    }
  }, [config?.algolia, searchClient]);

  const handleSearchChange = debounce(async (e) => {
    const searchQuery = e.target.value;
    if (searchQuery.startsWith(' ')) {
      e.target.value = searchQuery.trimStart();
    }

    setWord(e.target.value.trimStart());

    if (_.isEmpty(searchQuery)) {
      setSearchInput('');
      setShowSearchPopup(false);
      setSearchResults({
        products: [],
        blogs: [],
        categories: [],
      });
      return;
    }

    if (searchQuery.length >= 3) {
      handleSearchAutoComplete(searchQuery);
      setShowSearchPopup(true);
    }
    setSearchInput(searchQuery);
  }, 500);

  const handleSearchAutoComplete = debounce(async (searchQuery) => {
    const queries = [
      {
        indexName: config?.algolia?.indexNameTopSellers,
        query: searchQuery,
        params: {
          hitsPerPage: searchRelatedProdutsCount,
        },
      },
      {
        indexName: config?.algolia?.indexNameBlogs,
        query: searchQuery,
        params: {
          hitsPerPage: searchContentCount,
        },
      },
      {
        indexName: config?.algolia?.indexNameCategories,
        query: searchQuery,
        params: {
          hitsPerPage: searchSuggestedSearchesCount,
        },
      },
    ];

    const responses = await searchClient?.search(queries);

    setSearchResults({
      products: responses?.results[0]?.hits,
      blogs: responses?.results[1]?.hits,
      categories: responses?.results[2]?.hits,
    });
  }, 500);

  const handleKeyPress = debounce(async (event) => {
    const inputValue = word.trim();
    if (event.key === 'Enter' && inputValue !== '') {
      setIsOpenSearch(false);
      if (productRedirectionLink !== '') {
        router.push(productRedirectionLink);
      } else {
        setIsOpenSearch(false);
        router.push(`${routerURL.search}${inputValue}`);
      }
    }
  }, 500);

  const handleOnClick = (e, link) => {
    e.preventDefault();
    window.location.href = link;
    setIsOpenSearch(false);
  };

  useEffect(() => {
    const temp_Array = [];
    categories?.map((val, idx) => {
      temp_Array.push(StringHelpers.titleCaseFormatter(val?.name).join('').trim().toLowerCase());
      val?.children?.map((subValue) => {
        temp_Array.push(StringHelpers.titleCaseFormatter(subValue?.name).join('').trim().toLowerCase());
      });
    });
    setCategoriesArray([].concat(temp_Array));
  }, [categories]);

  useEffect(() => {
    if (searchResults?.products?.length === 1) {
      const currentResult: any = searchResults?.products[0];

      const exactVariant = ArrayHelpers?.findObjectMatchesSku(
        currentResult?.all_variants,
        StringHelpers?.removeWhiteSpace(searchInput)?.toLowerCase(),
      );
      const selectedVariantColor = exactVariant?.all_Attributes?.actualColor;
      setProductRedirectionLink(`/products/${searchResults?.products[0]?.slug['en-US']}?color=${selectedVariantColor}`);
    }
  }, [searchResults]);
  return (
    <section className="flex w-screen flex-col bg-white">
      <section className="shadow-[0px_2px_4px_rgba(177, 154, 106, 0.3)]">
        <section className="relative w-full">
          <button type="submit" id="mobSearchBtn" aria-label="search keyword" className="absolute top-[12px] left-6">
            <SearchIcon className="h-4 w-4" aria-label="search icon" />
          </button>
          <input
            id="mobHeaderSearch"
            autoComplete="off"
            type="text"
            className="input-text-primary !mb-0 !w-full !border-x-0 !border-t-0 pl-[50px] pr-9"
            onChange={(e) => handleSearchChange(e)}
            onKeyDown={handleKeyPress}
            aria-label="search submit"
          />
        </section>
        <section onClick={() => setIsOpenSearch(false)} className="absolute top-[15px] right-4 cursor-pointer">
          <CloseIcon className="h-[10px] w-[10px] fill-sol-300" aria-label="search clear icon" />
        </section>
      </section>
      {isExpand && (
        <section className="h-[calc(100vh_-_127px)] bg-white">
          <section className="flex h-[calc(90vh_-_127px)] w-screen flex-col gap-3 overflow-y-auto p-4 text-sm font-bold">
            <p className="mt-3 text-sm font-semibold">{mapLocaleToMeaningfulFormat(router.locale).suggestedSearches}</p>
            {/* TODO: The URL and data will change on later */}
            {searchResults?.categories?.length === 0 ? (
              <p className="text-sm font-normal text-sol-300">{mapLocaleToMeaningfulFormat(router.locale).noResult}</p>
            ) : (
              <ul>
                {searchResults?.categories?.map((data, i) => (
                  <li
                    key={i}
                    className="leading-9"
                    onClick={(e) =>
                      handleOnClick(
                        e,
                        categoriesArray?.includes(data?.name['en-US'].toLowerCase())
                          ? `/pages/${data?.slug['en-US']}`
                          : `/categories/${data?.slug['en-US']}`,
                      )
                    }
                  >
                    <SearchSuggestedProduct
                      suggestedSearchData={data?.name['en-US']}
                      dataLink={
                        categoriesArray?.includes(data?.name['en-US'].toLowerCase())
                          ? `/pages/${data?.slug['en-US']}`
                          : `/categories/${data?.slug['en-US']}`
                      }
                    />
                  </li>
                ))}
              </ul>
            )}
            <section className="border-b-[1px] border-sol-600"></section>
            <p className="my-2 text-sm font-semibold">{mapLocaleToMeaningfulFormat(router.locale).relatedProduct}</p>
            {/* TODO: The URL and data will change on later */}
            {searchResults?.products.length === 0 ? (
              <p className="text-sm font-normal text-sol-300">{mapLocaleToMeaningfulFormat(router.locale).noResult}</p>
            ) : (
              <ul className="grid grid-cols-2 gap-5">
                {searchResults?.products?.map((relatedData, i) => {
                  const exactVariant = ArrayHelpers?.findObjectMatchesSku(
                    relatedData?.all_variants,
                    StringHelpers?.removeWhiteSpace(searchInput)?.toLowerCase(),
                  );
                  let masterVariant;
                  if (!exactVariant) {
                    masterVariant = ArrayHelpers?.findObjectWithKey(
                      relatedData?.all_variants,
                      mapLocaleToMeaningfulFormat(router.locale)?.masterVariantKey,
                    );
                  }
                  return (
                    <li
                      key={i}
                      className="flex flex-col gap-4"
                      onClick={(e) => {
                        handleOnClick(
                          e,
                          `/products/${relatedData?.slug['en-US']}?color=${exactVariant?.all_Attributes?.actualColor}`,
                        );
                      }}
                    >
                      <SearchRelatedProduct
                        productName={relatedData?.name['en-US']}
                        imageSrc={
                          exactVariant
                            ? exactVariant?.images[0]?.url
                            : masterVariant
                            ? masterVariant?.images[0]?.url
                            : ''
                        }
                        dataLink={`/products/${relatedData?.slug['en-US']}`}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
            {/* TODO: The URL will change on later */}
            {searchResults?.products?.length === 0 ? null : (
              <Link href={`${routerURL?.search}${word}`}>
                <p
                  className="mt-10 cursor-pointer text-center text-sm font-normal text-sol-300 underline underline-offset-2"
                  onClick={() => setIsOpenSearch(false)}
                >
                  {mapLocaleToMeaningfulFormat(router.locale).viewMoreResult}
                </p>
              </Link>
            )}
            <section className="border-b-[1px] border-sol-600 pb-5"></section>
            <p className="mt-1 text-sm font-semibold">{mapLocaleToMeaningfulFormat(router.locale).content}</p>
            {/* TODO: The URL and data will change on later */}
            {searchResults?.blogs?.length === 0 ? (
              <p className="text-sm font-normal text-sol-300">{mapLocaleToMeaningfulFormat(router.locale).noResult}</p>
            ) : (
              <ul>
                {searchResults?.blogs?.map((contentData, i) => (
                  <li key={i} className="leading-9" onClick={(e) => handleOnClick(e, '/')}>
                    <SearchContent contentData={contentData?.title} link={contentData?.ctaLink} />
                  </li>
                ))}
              </ul>
            )}
          </section>
        </section>
      )}
    </section>
  );
};
