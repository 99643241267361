// Doc Examples
import SimpleButton from './doc-examples/simple-button';
import HelloWorld from './doc-examples/hello-world';
import StarWarsOpeningCrawl from './doc-examples/star-wars/movies';
import StarWarsCharacterSearch from './doc-examples/star-wars/character-search';
import StarWarsCharacterFilter from './doc-examples/star-wars/character-filter';
//import ContentfulBlog from './doc-examples/contentful/blog';

import AccountDetails from './account/details';
import AccountLogin from './account/login';
import AccountOrdersHistory from './account/orders';
import AccountRegister from './account/register';
import ResetPassword from './account/reset-password';
import AccountTest from './account/test';
import CartTastic from './sol/cart';
import Checkout from './checkout';
import ThankYou from './checkout/thank-you';
import Blog from './content/blog';
import Markdown from './content/markdown';
import Spacer from './content/spacer';
import Tile from './content/tile';
import Footer from './footer';
import Header from './header';
import Hero from './hero';
import Newsletter from './newsletter';
import NotFound from './not-found';
import ProductDetails from './products/details';
import ProductList from './products/product-list';
import ProductSlider from './products/slider';
import SimilarProducts from './products/similar-products';
import Showcase from './showcase';
import Wishlist from './wishlist';
import CategoryTeasers from './content/category-teasers';
import NewsCardTastic from './news-card';
import SOLCategoriesTastic from './sol/categories';
import SampleTastic from './sample';
import SOLHeaderTastic from './sol/header';
import SOLFooterTastic from './sol/footer';
import HeroCarouselContentBlockTastic from './sol/cms/hero-carousel-content-block';
import TrendingNowTastic from './sol/cms/trending-now';
import TwoColumnContentBlockTastic from './sol/cms/two-column-content-block';
import ThreeColumnContentBlockTastic from './sol/cms/three-column-content-block';
import AccountLoginTastic from './sol/account/login';
import ForgotPasswordTastic from './sol/account/forgot-password';
import ResetPasswordTastic from './sol/account/reset-password';
import MyListTastic from './sol/account/myList';
import OrderHistoryTastic from 'components/sol-ui/orderHistory';
import AddressTastic from 'components/sol-ui/addressBook';
import OrderDetailsTastic from './sol/account/orderDetails';
import CreditCardTastic from './sol/account/credit-card';
import AccountDetailsTastic from './account/details';
import CheckoutTastic from './sol/checkout';
import CheckoutHeaderTastic from './sol/layout/checkout-header';
import CheckoutFooterTastic from './sol/layout/checkout-footer';
import ProductListTastic from './sol/products/product-list';
import ProductSearchListTastic from './sol/products/product-search-list';
import ProductDetailsTastic from './sol/products/details';
import CommunicationPreferenceTastic from './sol/account/communication-preferences';
import CatalogRequestFormTastic from './sol/catalog-request-form';
import ThankYouTastic from './sol/checkout/thank-you';
import { Error404PageTastic } from './sol/404';
import { PopularCategoryBlockTastic } from './sol/404/popular-category';
import BlogDetailTastic from './sol/cms/blog-detail';
import BlogCategoryPageTastic from './sol/cms/blog-category';
import BlogCategoryTastic from './sol/cms/blogCategory';
import { PromotionalBannerTastic } from './sol/promotional-banner';
import BlogBannerTastic from './sol/cms/blogBanner';
import TradeAccountLandingPageTastic from './sol/pages/trade-account';
import FeaturedBlogsTastic from './sol/cms/featuredBlogs';
import BlogPopularTagsTastic from './sol/cms/blogPopularTags';
import BannerCatalogRequestSubmitTastic from './sol/pages/banner-catalog-request-submit';
import TwoCatalogRequestSubmitTastic from './sol/pages/two-column-catalog-request-submit';
import SixCatalogRequestSubmitTastic from './sol/pages/six-column-catalog-request-submit';
import DesignCatalogRequestSubmitTastic from './sol/pages/design-catalog-request-submit';
import CyberSaleBannerTastic from './sol/cms/cyber-sale-banner';
import SaleBannerTastic from './sol/cms/sale-banner';
import YearEndSaleBannerTastic from './sol/cms/year-end-sale-banner';

import CheckoutAddressesTastic from './sol/checkout/addresses';
import CheckoutPaymentTastic from './sol/checkout/payment';
import CheckoutReviewTastic from './sol/checkout/review';
import CcpaFormTastic from './sol/cms/ccpa-form';
import CatalogLandingPageTastic from './sol/pages/catalog';
import PlpContentTastic from './sol/products/plp-content';
import ShareMyListTastic from './sol/share/my-list';
import CheckoutManaulOrderTastic from './sol/checkout/manual-order';
import ImpersonationTastic from './sol/account/impersonation';
import GiftRegistryTastic from './sol/pages/gift-registry';
import FeaturedBlogsAndPopularTagsTastic from './sol/cms/blogsAndTags';
import DownrodsTastic from './sol/downrods';
import SOLSitemap from 'sitemap';
import CatalogUnsubscribe from './sol/account/catalog-unsubscribe';
import ShopCollectionTastic from './shop-collection';
import ProductRecommendationsTastic from './product-recommendations';
import RecentlyViewedTastic from './recently-viewed';
import YouMayAlsoNeedTastic from './sol/products/details/you-may-also-need';
export const tastics = {
  // Doc Examples
  'example/simple-button': SimpleButton,
  'example/hello-world': HelloWorld,
  'example/star-wars/movie': StarWarsOpeningCrawl,
  'example/star-wars/character-search': StarWarsCharacterSearch,
  'example/star-wars/character-filter': StarWarsCharacterFilter,
  //TODO we will remove it later because it was originally comming from  commercetool
  //'contentful/blog': ContentfulBlog,

  'commercetools/ui/checkout': Checkout,
  'commercetools/ui/thank-you': ThankYou,
  'commercetools/ui/footer': Footer,
  'commercetools/ui/header': Header,
  'commercetools/ui/content/blog': Blog,
  'commercetools/ui/content/tile': Tile,
  'commercetools/ui/content/spacer': Spacer,
  'commercetools/ui/content/showcase': Showcase,
  'commercetools/ui/content/markdown': Markdown,
  'commercetools/ui/content/newsletter': Newsletter,
  'commercetools/ui/products/details': ProductDetails,
  'commercetools/ui/products/product-list': ProductList,
  'commercetools/ui/products/slider': ProductSlider,
  'commercetools/ui/products/similar-products': SimilarProducts,
  'commercetools/ui/wishlist': Wishlist,
  'commercetools/ui/account/test': AccountTest,
  'commercetools/ui/account/details': AccountDetails,
  //TODO we will remove it later because it was originally comming from  commercetool
  // 'commercetools/ui/account/login': AccountLogin,
  'commercetools/ui/account/register': AccountRegister,
  'commercetools/ui/account/orders': AccountOrdersHistory,
  //TODO we will remove it later because it was originally comming from  commercetool
  // 'commercetools/ui/account/reset-password': ResetPassword,
  'commercetools/ui/content/category-teasers': CategoryTeasers,
  'commercetools/ui/content/news-card': NewsCardTastic,
  'commercetools/ui/hero': Hero,
  'commercetools/ui/content/sample': SampleTastic,

  'sol/ui/header': SOLHeaderTastic,
  'sol/ui/footer': SOLFooterTastic,
  'sol/ui/account/login': AccountLoginTastic,
  'sol/ui/account/reset-password': ResetPasswordTastic,
  'sol/ui/account/forgot-password': ForgotPasswordTastic,
  'sol/ui/account/details': AccountDetailsTastic,

  'sol/cms/hero-carousel-content-block': HeroCarouselContentBlockTastic,
  'sol/cms/two-column-content-block': TwoColumnContentBlockTastic,
  'sol/cms/three-column-content-block': ThreeColumnContentBlockTastic,
  'sol/cms/trending-now': TrendingNowTastic,
  'sol/ui/account/myList': MyListTastic,
  'sol/ui/orderHistory': OrderHistoryTastic,
  'sol/ui/address': AddressTastic,
  'sol/ui/orderDetails': OrderDetailsTastic,
  'sol/ui/account/credit-card': CreditCardTastic,
  'sol/ui/cart': CartTastic,
  'sol/ui/checkout': CheckoutTastic,
  'sol/ui/checkout-header': CheckoutHeaderTastic,
  'sol/ui/checkout-footer': CheckoutFooterTastic,
  'sol/ui/product-list': ProductListTastic,
  'sol/ui/product-search-list': ProductSearchListTastic,
  'sol/ui/products/details': ProductDetailsTastic,
  'sol/ui/catalog-request-form': CatalogRequestFormTastic,
  'sol/ui/communication-preference': CommunicationPreferenceTastic,
  'sol/ui/categories/category-list': SOLCategoriesTastic,
  'sol/ui/checkout/thank-you': ThankYouTastic,
  'sol/cms/404': Error404PageTastic,
  'sol/cms/popular-category': PopularCategoryBlockTastic,
  'sol/cms/blog-detail': BlogDetailTastic,
  'sol/cms/blog-banner': BlogBannerTastic,
  'sol/cms/blog-category-page': BlogCategoryPageTastic,
  'sol/cms/blog-category': BlogCategoryTastic,
  'sol/cms/featured-blogs': FeaturedBlogsTastic,
  'sol/cms/blog-popular-tags': BlogPopularTagsTastic,
  'sol/cms/trade-account': TradeAccountLandingPageTastic,
  'sol/promotional-banner': PromotionalBannerTastic,
  'sol/ui/checkout/address': CheckoutAddressesTastic,
  'sol/ui/checkout/payment': CheckoutPaymentTastic,
  'sol/ui/checkout/review': CheckoutReviewTastic,
  'sol/ui/checkout/manualOrder': CheckoutManaulOrderTastic,
  'sol/cms/ccpa-form': CcpaFormTastic,
  'sol/ui/catalog-iframe': CatalogLandingPageTastic,
  'sol/ui/banner-catalog-request-submit-confirmation': BannerCatalogRequestSubmitTastic,
  'sol/ui/two-catalog-request-submit-confirmation': TwoCatalogRequestSubmitTastic,
  'sol/ui/six-catalog-request-submit-confirmation': SixCatalogRequestSubmitTastic,
  'sol/ui/design-catalog-request-submit-confirmation': DesignCatalogRequestSubmitTastic,
  'sol/ui/plp-content': PlpContentTastic,
  'sol/ui/share-my-list': ShareMyListTastic,
  'sol/imp-login': ImpersonationTastic,
  'sol/ui/gift-registry': GiftRegistryTastic,
  'sol/cms/blogs-and-tags': FeaturedBlogsAndPopularTagsTastic,
  'sol/cms/downrods': DownrodsTastic,
  'sol/ui/sitemap': SOLSitemap,
  'sol/ui/pages/catalog-unsubscribe': CatalogUnsubscribe,
  'sol/cms/cyber-sale-banner': CyberSaleBannerTastic,
  'sol/cms/sale-banner': SaleBannerTastic,
  'sol/cms/shop-collection': ShopCollectionTastic,
  'sol/cms/product-recommendations': ProductRecommendationsTastic,
  'sol/product/product-you-may-also-need': YouMayAlsoNeedTastic,
  'sol/cms/year-end-sale-banner': YearEndSaleBannerTastic,
  'sol/ui/products/recently-viewed': RecentlyViewedTastic,
  default: NotFound,
};
