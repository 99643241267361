import CheckoutReview from 'components/sol-ui/checkout/checkout-review';
import React, { useEffect } from 'react';
import { DataSourceExtractorHelpers } from 'helpers/utils/dataSourceExtractor';
import { DATA_SOURCE_LINKS } from 'helpers/utils/constant';
import CheckoutManualOrder from 'components/sol-ui/checkout/checkout-manual-order';
import useLocalStorage from 'helpers/hooks/useLocalStorage';

const CheckoutManaulOrderTastic = (data) => {
  const { setAsGuest, removeAsGuest } = useLocalStorage();
  const content = data?.data?.content?.dataSource?.content?.content;
  const orderConfiguration = DataSourceExtractorHelpers.getPreloadedValue(
    DATA_SOURCE_LINKS.EMPTY_DATASOURCE,
    data?.pageFolder?.dataSourceConfigurations,
  );

  useEffect(() => {
    setAsGuest();

    return () => {
      removeAsGuest();
    };
  }, []);
  return <CheckoutManualOrder data={data.data} content={content} order={orderConfiguration.order} />;
};

export default CheckoutManaulOrderTastic;
