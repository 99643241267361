import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { createInMemoryCache } from '@algolia/cache-in-memory';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import useAlgoliaSearchClient from 'helpers/hooks/useAlgoliaSearchClient';
import QuickViewModal from '../../product-list/quick-view-modal';
import ProductListItemBlock from './ProductListItemBlock';

const YouMayLike = ({ variantName, searchTerm, algoliaConfig, setIsData }) => {
  // will make dynamic in next pr

  const [filterQuery, setFilterQuery] = useState(null);
  //next/router
  const router = useRouter();
  //create delete modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openDeleteModal = (e) => {
    e.preventDefault();
    setDeleteModalOpen(true);
  };

  const StopPropagation = (e) => {
    e.preventDefault();
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    if (searchTerm != '' && searchTerm) {
      setFilterQuery(`categories.en-US.slug:${searchTerm} AND shownOnSite:true`);
    }
  }, [searchTerm]);

  useEffect(() => {}, [filterQuery]);

  const searchClient = useAlgoliaSearchClient(algoliaConfig);

  return (
    <>
      <InstantSearch
        key={'algolia-client'}
        insights={true}
        searchClient={searchClient}
        indexName={algoliaConfig.indexNameProducts}
      >
        <section className="flex h-full w-full">
          <ProductListItemBlock setIsData={setIsData} variantName={variantName} />
        </section>

        <QuickViewModal modalOpen={deleteModalOpen} onClose={closeDeleteModal} />
        <Configure filters={filterQuery} distinct hitsPerPage={5} />
      </InstantSearch>
    </>
  );
};

export default YouMayLike;
