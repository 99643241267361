import React, { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import EyeIcon from 'components/icons/sol/eye-icon';
export default function Password({ onBlur, className, id, name }) {
  const [showPassword, setShowPassword] = useState(false);
  const { values } = useFormikContext();
  return (
    <div className="relative">
      <Field
        type={showPassword ? 'text' : 'password'}
        name={name}
        id={id}
        onBlur={onBlur}
        className={`input-text-primary mt-3 !w-full pr-10 ${className}`}
      />
      {values[name] && (
        <button
          type="button"
          className="absolute top-1/2 right-3 -translate-y-1/2"
          onClick={() => setShowPassword(!showPassword)}
        >
          <EyeIcon className={`${showPassword ? 'fill-gray-600' : 'fill-gray-400'} cursor-pointer text-lg`} />
        </button>
      )}
    </div>
  );
}
