/**
 * Google Tag Manager Ecommerce Events
 * TODO this needs to be refactored to use proper ecommerce
 * object structure and account for discounts currectly in the items[] array
 */

import { Cart, ProductList, Product } from '@Types/gtm-events/Ecommerce';

import { gtmTrackEvent, gtmTrackEventError } from 'helpers/utils/gtm-events/gtmTrackEvent';
import { hydrateUserObj } from 'helpers/utils/gtm-events/gtmHelpers';
import { StringHelpers } from 'helpers/stringHelpers';
import { removeNonAlphanumericAndSpaces } from 'helpers/skuHelpers';

// these are used for purchase event hook
import { Discount } from '@Types/cart/Discount';
import { LineItem } from '@Types/cart/LineItem';
import { Order } from '@Types/cart/Order';
import { Money } from '@Types/product/Money';
import Cookies from 'js-cookie';

const isValidProduct = (product: Product): boolean => {
  return product.item_id && product.item_name && product.price > 0;
};

// TODO apply this to the ecommerce objects as possible
const calculatePrice = (price: any): number => {
  return price?.centAmount / 10 ** price?.fractionDigits;
};

const mapLineItemsToProducts = (lineItems: LineItem[]): Product[] => {
  return lineItems.map((lineItem, i) => ({
    item_id: lineItem.variant.sku,
    item_name: lineItem.name,
    index: i,
    item_variant: lineItem.variant?.attributes?.collection,
    price: calculatePrice(lineItem),
    quantity: lineItem.count,
  }));
};

export const gtmEventAddToCart = (product: any, variant: any, quantity: any, account: any) => {
  const eventKey = 'add_to_cart';
  try {
    const eventObject = {
      ecommerce: {
        currency: variant?.price?.currencyCode,
        value:
          (variant?.discountedPrice?.centAmount / 10 ** variant?.price?.fractionDigits) * quantity ||
          (variant?.price?.centAmount / 10 ** variant?.price?.fractionDigits) * quantity,
        items: <ProductList>[
          <Product>{
            item_id: variant?.sku,
            item_name: product?.name,
            item_variant: variant?.attributes?.collection,
            // price: calculatePrice(variant?.discountedPrice || variant?.price),
            price:
              variant?.discountedPrice?.centAmount / 10 ** variant?.price?.fractionDigits ||
              variant?.price?.centAmount / 10 ** variant?.price?.fractionDigits,
            quantity: quantity,
          },
        ],
      },
    };

    // if we know the user email let's track them to the event
    const userObject = hydrateUserObj(account);
    if (userObject) {
      Object.assign(eventObject, { user: userObject });
    }

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }

    return;
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmRemoveFromCart = (cart: any, lineItem: any, account: any) => {
  const eventKey = 'remove_from_cart';
  const variant = cart.lineItems.find((item: any) => {
    if (item === lineItem) {
      return item;
    }
  });

  try {
    const eventObject = {
      ecommerce: {
        currency: variant?.totalPrice?.currencyCode,
        value: variant?.totalPrice?.centAmount / 10 ** variant?.totalPrice?.fractionDigits,
        items: <ProductList>[
          <Product>{
            item_id: variant?.variant?.sku,
            item_name: variant?.name,
            item_variant: variant?.variant?.attributes?.collection,
            // price: calculatePrice(variant?.discountedPrice || variant?.price),
            price:
              variant?.variant?.discountedPrice?.centAmount / 10 ** variant?.variant?.price?.fractionDigits ||
              variant?.variant?.price?.centAmount / 10 ** variant?.variant?.price?.fractionDigits,
            quantity: variant?.count,
          },
        ],
      },
    };

    // if we know the user email let's track them to the event
    const userObject = hydrateUserObj(account);
    if (userObject) {
      Object.assign(eventObject, { user: userObject });
    }

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmViewCart = (cart: any, account?: any) => {
  const eventKey = 'view_cart';
  try {
    const eventObject = {
      ecommerce: {
        currency: cart?.total?.currencyCode,
        value: cart.cartTotal.centAmount / 10 ** cart.cartTotal.fractionDigits,
        items: <ProductList>cart?.lineItems?.map((lineItem, i) => {
            return <Product>{
              item_id: lineItem.variant.sku,
              item_name: lineItem.name,
              index: i,
              item_variant: lineItem.variant?.attributes?.collection,
              // price: calculatePrice(variant?.discountedPrice || variant?.price),
              price:
                lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
                lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
              quantity: lineItem.count,
            };
          }) ?? [],
      },
    };

    // TODO refactor opportunity - this cjEvent should be pushed into GTM app to manage as a dataLayer variable
    const cjEventObject = {
      products: cart.lineItems.map((lineItem) => {
        return {
          productTitle: lineItem?.name,
          productType: lineItem?.productType,
          productSku: removeNonAlphanumericAndSpaces(lineItem?.variant?.sku),
          productPrice: lineItem?.price?.centAmount / 10 ** lineItem?.price?.fractionDigits,
          productQuantity: lineItem?.count,
          productDiscount:
            lineItem?.appliedDiscountPrice?.centAmount / 10 ** lineItem?.appliedDiscountPrice?.fractionDigits,
        };
      }),
    };

    // TODO this cjEvent should be pushed into GTM app to manage as a dataLayer variable
    Object.assign(eventObject, { cjEvent: cjEventObject });

    // if we know the user email let's track them to the event
    const userObject = hydrateUserObj(account);
    if (userObject) {
      Object.assign(eventObject, { user: userObject });
    }

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmBeginCheckout = (cart: any) => {
  const eventKey = 'begin_checkout';
  try {
    const eventObject = {
      ecommerce: {
        currency: cart?.total?.currencyCode,
        value: cart.cartTotal.centAmount / 10 ** cart.cartTotal.fractionDigits,
        items: <ProductList>cart?.lineItems?.map((lineItem, i) => {
            return <Product>{
              item_id: lineItem.variant.sku,
              item_name: lineItem.name,
              index: i,
              item_variant: lineItem.variant?.attributes?.collection,
              // price: calculatePrice(variant?.discountedPrice || variant?.price),
              price:
                lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
                lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
              quantity: lineItem.count,
            };
          }) ?? [],
      },
    };

    // if we know the user email let's track them to the event
    if (cart?.email) {
      const userObject = {
        user: {
          email: StringHelpers?.convertToSHA256Hash(cart?.email),
        },
      };
      Object.assign(eventObject, userObject);
    }

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmAddPaymentInfo = (cart: any) => {
  const eventKey = 'add_payment_info';
  try {
    const eventObject = {
      ecommerce: {
        currency: cart?.total?.currencyCode,
        value: cart?.total?.centAmount / 10 ** cart?.total?.fractionDigits,
        coupon: cart?.discountCodes[0]?.code || null,
        payment_type: 'Credit Card',
        items: <ProductList>cart?.lineItems?.map((lineItem, i) => {
            return <Product>{
              item_id: lineItem.variant.sku,
              item_name: lineItem.name,
              index: i,
              item_variant: lineItem.variant?.attributes?.collection,
              // price: calculatePrice(variant?.discountedPrice || variant?.price),
              price:
                lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
                lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
              quantity: lineItem.count,
            };
          }) ?? [],
      },
    };

    // if we know the user email let's track them to the event
    if (cart?.email) {
      const userObject = {
        user: {
          email: StringHelpers?.convertToSHA256Hash(cart?.email),
        },
      };
      Object.assign(eventObject, userObject);
    }

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmAddShippingInfo = (cart: any) => {
  const eventKey = 'add_shipping_info';
  try {
    const eventObject = {
      ecommerce: {
        currency: cart?.total?.currencyCode,
        value: cart?.total?.centAmount / 10 ** cart?.total?.fractionDigits,
        coupon: cart?.discountCodes[0]?.code || null,
        shipping_tier: 'Ground',
        items: <ProductList>cart?.lineItems?.map((lineItem, i) => {
            return <Product>{
              item_id: lineItem.variant.sku,
              item_name: lineItem.name,
              index: i,
              item_variant: lineItem.variant?.attributes?.collection,
              // price: calculatePrice(variant?.discountedPrice || variant?.price),
              price:
                lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
                lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
              quantity: lineItem.count,
            };
          }) ?? [],
      },
    };

    // if we know the user email let's track them to the event
    if (cart?.email) {
      const userObject = {
        user: {
          email: StringHelpers?.convertToSHA256Hash(cart?.email),
        },
      };
      Object.assign(eventObject, userObject);
    }

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmPurchase = (order: Order, account: any) => {
  // TODO fix in GTM since CCX used `ga4_purchase` we are migrating to `purchase`
  const eventKey = 'purchase';
  const cjeCookie = Cookies?.get('cje');
  const accumalateDiscountCodes = (discountCodes: Discount[]) => {
    let discountCode = '';
    if (discountCodes?.length > 0) {
      discountCodes?.map((discountCodeItem: Discount) => {
        discountCode = discountCode + ' ' + discountCodeItem?.code;
        return discountCode;
      });
    }
    return discountCode;
  };
  const formatPriceToDecimal = (price: Money) => {
    const numericStringValue = price ? parseFloat((price?.centAmount / 100).toString()) : 0.0;
    return parseFloat(numericStringValue.toFixed(2));
  };
  const calculateProductPrice = (lineItem: LineItem) => {
    if (account?.isB2BApproved) {
      return formatPriceToDecimal(lineItem?.discountedPrice ? lineItem?.discountedPrice : lineItem?.price);
    } else {
      return formatPriceToDecimal(lineItem?.price);
    }
  };
  const discountCoupon = accumalateDiscountCodes(order?.discountCodes);
  const discount = formatPriceToDecimal(order?.discountTotal);

  try {
    const eventObject = {
      ecommerce: {
        currency: order?.subTotal?.currencyCode,
        transaction_id: order?.orderNumber,
        value: order?.subTotal?.centAmount / 10 ** order?.subTotal?.fractionDigits,
        shipping: order?.shippingAmount?.centAmount / 10 ** order?.shippingAmount?.fractionDigits,
        tax: order?.totalTaxedPrice?.centAmount / 10 ** order?.totalTaxedPrice?.fractionDigits,
        coupon: order?.discountCodes?.[0]?.code || null,
        items: <ProductList>order?.lineItems?.map((lineItem, i) => {
            return <Product>{
              item_id: lineItem.variant.sku,
              item_name: lineItem.name,
              index: i,
              item_variant: lineItem.variant?.attributes?.collection,
              // price: calculatePrice(lineItem.variant?.discountedPrice || lineItem.variant?.price),
              price:
                lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
                lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
              quantity: lineItem.count,
            };
          }) ?? [],
      },
    };
    const cjEventObj = {
      subTotal: formatPriceToDecimal(order?.subTotal),
      coupon: discountCoupon,
      discount: account?.isB2BApproved ? 0 : discount,
      orderId: order?.orderNumber,
      FIRECJ: cjeCookie || '',
      products:
        order.lineItems.map((lineItem) => {
          return {
            productTitle: lineItem?.name,
            productType: lineItem?.productType,
            productSku: removeNonAlphanumericAndSpaces(lineItem?.variant?.sku),
            productPrice: calculateProductPrice(lineItem),
            productQuantity: lineItem?.count,
          };
        }) ?? [],
    };

    // TODO this cjEvent should be pushed into GTM app to manage as a dataLayer variable
    Object.assign(eventObject, { cjEvent: cjEventObj });

    // if we know the user email let's track them to the event
    const userObject = hydrateUserObj(account);
    if (userObject) {
      Object.assign(eventObject, { user: userObject });
    }

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

// TODO this needs to be refactored for proper `add_to_wishlist` event
export const gtmSelectItem = (wishlist: any, lineItem: any) => {
  const eventKey = 'select_item';
  try {
    const eventObject = {
      ecommerce: {
        item_list_name: wishlist?.wishlistId,
        item_list_id: wishlist?.name,
        items: <ProductList>[
          <Product>{
            item_id: lineItem?.variant?.sku,
            item_name: lineItem?.name,
            item_variant: lineItem?.variant?.attributes?.collection,
            // price: calculatePrice(lineItem.variant?.discountedPrice || lineItem.variant?.price),
            price:
              lineItem?.variant?.discountedPrice?.centAmount / 10 ** lineItem?.variant?.price?.fractionDigits ||
              lineItem?.variant?.price?.centAmount / 10 ** lineItem?.variant?.price?.fractionDigits,
            quantity: lineItem?.count,
          },
        ],
      },
    };

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmViewItem = (productName: string, variant: any, email: string) => {
  const eventKey = 'view_item';
  try {
    const eventObject = {
      ecommerce: {
        currency: variant?.totalPrice?.currencyCode,
        value: variant?.price?.centAmount / 100,
        items: <ProductList>[
          <Product>{
            item_id: variant?.sku,
            item_name: productName,
            item_variant: variant?.attributes?.categories[0],
            // price: calculatePrice(variant?.discountedPrice || variant?.price),
            price: variant?.price?.centAmount / 100,
            quantity: 1,
          },
        ],
      },
    };

    // if we know the user email let's track them to the event
    if (email) {
      const userObject = {
        user: {
          email: StringHelpers?.convertToSHA256Hash(email),
        },
      };
      Object.assign(eventObject, userObject);
    }

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

// TODO we need to track currency code
export const gtmViewItemList = (itemList: any) => {
  const eventKey = 'view_item_list';
  try {
    const eventObject = {
      ecommerce: {
        item_list_id: itemList?.wishlistId, // TODO research why is this using wishlist property naming
        item_list_name: itemList?.name,
        items: <ProductList>itemList?.lineItems?.map((lineItem, i) => {
            return <Product>{
              item_id: lineItem.variant.sku,
              item_name: lineItem.name,
              index: i,
              item_variant: lineItem.variant?.attributes?.collection,
              // price: calculatePrice(lineItem.variant?.discountedPrice || lineItem.variant?.price),
              price:
                lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
                lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
              quantity: lineItem.count,
            };
          }) ?? [],
      },
    };

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmViewPromotion = (promo_name: string, product_id: any, itemList: any) => {
  const eventKey = 'view_promotion';
  try {
    const eventObject = {
      ecommerce: {
        promotion_id: 'P_' + product_id + '_related',
        promotion_name: promo_name,
        items: <ProductList>itemList?.lineItems?.map((lineItem, i) => {
            return <Product>{
              item_id: lineItem.variant.sku,
              item_name: lineItem.name,
              index: i,
              item_variant: lineItem.variant?.attributes?.collection,
              // price: calculatePrice(lineItem.variant?.discountedPrice || lineItem.variant?.price),
              price:
                lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
                lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
              quantity: lineItem.count,
            };
          }) ?? [],
      },
    };

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmSelectPromotion = (promo_name: string, product_id: any, itemList: any) => {
  const eventKey = 'select_promotion';
  try {
    const eventObject = {
      ecommerce: {
        promotion_id: 'P_' + product_id + '_related',
        promotion_name: promo_name,
        items: <ProductList>itemList?.lineItems?.map((lineItem, i) => {
            return <Product>{
              item_id: lineItem.variant.sku,
              item_name: lineItem.name,
              index: i,
              item_variant: lineItem.variant?.attributes?.collection,
              // price: calculatePrice(lineItem.variant?.discountedPrice || lineItem.variant?.price),
              price:
                lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
                lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
              quantity: lineItem.count,
            };
          }) ?? [],
      },
    };

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};
