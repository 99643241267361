import React, { useState, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Variant } from '@Types/product/Variant';
import DownIcon from 'components/icons/sol/down-icon';
import SuccessIcon from 'components/icons/sol/right';
import UpIcon from 'components/icons/sol/up-icon';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import AccessoriesModal from './optional-accessories-modal';
import UpdateQuantity from './quantity-increment-decrement';

type AccordionProps = {
  title: string;
  content?: any;
  index?: any;
  isClearItems?: boolean;
  accessoryCategoryGrouping?: any;
  checkedOptionAccessories?: (sku: any) => boolean;
  handleAccessoryChange?: (
    sku: any,
    variant: Variant,
    e?: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLElement>,
  ) => void;
  handleCategoryChange?: () => void;
  optionalAccessory?: any;
  currentIndex?: number;
  setCurrentIndex?: React.Dispatch<React.SetStateAction<number>>;
  downrodsIncluded?: string;
  selectedVarient?: Variant;
  selectedAccessories?: Array<{ variant: Variant; quantity: number }>;
  setSelectedAccessories?: React.Dispatch<React.SetStateAction<Array<{ variant: Variant; quantity: number }>>>;
};
const Accordion = ({
  title,
  selectedVarient,
  setCurrentIndex,
  currentIndex,
  index,
  downrodsIncluded,
  accessoryCategoryGrouping,
  checkedOptionAccessories,
  handleAccessoryChange,
  handleCategoryChange,
  optionalAccessory,
  selectedAccessories,
  setSelectedAccessories,
}: AccordionProps) => {
  const [open, setOpened] = useState<boolean>(index === currentIndex);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [accessoriesModalOpen, setAccessoriesModalOpen] = useState(false);
  //next/router
  const router = useRouter();
  useEffect(() => {
    if (index === currentIndex) {
      setOpened(true);
    } else {
      setOpened(false);
    }
  });
  useEffect(() => {
    setCurrentIndex(null);
  }, [selectedVarient]);
  const openAccessorieswModal = (e) => {
    e.preventDefault();
    setAccessoriesModalOpen(true);
  };

  const closeAccessorieswModal = () => {
    setAccessoriesModalOpen(false);
  };

  const filterAccordionOpening = (e) => {
    e.preventDefault();
    if (!open) {
      setCurrentIndex(index);
    } else {
      setCurrentIndex(null);
      setOpened(false);
    }
  };
  const handleAccordion = (e) => {
    e.preventDefault();
    handleCategoryChange();
    setSelectedCategory(title);
    if (!open) {
      setCurrentIndex(index);
    } else {
      setCurrentIndex(null);
      setOpened(false);
    }
  };
  useEffect(() => {
    setSelectedCategory(title);
  }, [title]);
  const accessoryCategory = accessoryCategoryGrouping[selectedCategory]?.sort(
    (priceA, priceB) =>
      priceA?.obj?.masterData?.current?.masterVariant?.prices[0]?.value?.centAmount -
      priceB?.obj?.masterData?.current?.masterVariant?.prices[0]?.value?.centAmount,
  );

  return (
    <>
      <section>
        <section
          onClick={(e) => filterAccordionOpening(e)}
          className={'text-black flex cursor-pointer items-center justify-between bg-sol-100 p-3'}
        >
          <button
            type="button"
            className="text-sm font-semibold uppercase leading-[17px] text-[#222222]"
            onClick={(e) => handleAccordion(e)}
          >
            {title}
          </button>
          <section className="cursor-pointer pt-1">
            <button type="button">
              {open ? <UpIcon className="fill-sol-300" /> : <DownIcon className="fill-sol-300" />}
            </button>
          </section>
        </section>
        <section className={`overflow-hidden transition-all duration-200 ${!open ? 'hidden' : ''}`}>
          <section>
            {selectedCategory && (
              <div id="ui-id-2" aria-labelledby="ui-id-1" role="tabpanel">
                {optionalAccessory && optionalAccessory?.length !== 0 && (
                  <>
                    {selectedCategory == 'Downrods' ? (
                      <div className="mt-4 text-center">
                        <a
                          onClick={openAccessorieswModal}
                          href={routerURL?.downrods}
                          className="text-center text-xs font-semibold leading-5 text-sol-300 hover:underline"
                        >
                          {mapLocaleToMeaningfulFormat(router.locale).viewDownrodSelectionGuide}
                        </a>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                {selectedCategory == 'Downrods' ? (
                  <section>
                    <p className="mb-2 mt-3 text-center text-xs font-semibold leading-5 text-red-600">
                      {mapLocaleToMeaningfulFormat(router.locale).safetyWarningDownrods}
                    </p>
                    {downrodsIncluded && (
                      <p className="mb-2 mt-3 flex text-sm leading-5">
                        <SuccessIcon className="ml-1 mr-2 h-5 w-3 gap-4" color="#19c06a" />
                        {mapLocaleToMeaningfulFormat(router.locale).downrodsIncluded} {downrodsIncluded}
                      </p>
                    )}
                  </section>
                ) : (
                  <></>
                )}
                {accessoryCategory?.map((accessory, index) => (
                  <div key={index} className="flex justify-between py-2">
                    <div className="flex items-center">
                      <label className="flex cursor-pointer gap-2">
                        <section className="relative">
                          <input
                            className="filterNavCheckbox h-4 w-4 cursor-pointer appearance-none rounded-[2px] border !border-black-900 text-white shadow-none focus:ring-0"
                            type="checkbox"
                            name={`accessories[${accessory?.obj?.masterData?.current?.masterVariant?.sku}]`}
                            id={`accessories_${accessory?.obj?.masterData?.current?.masterVariant?.sku}`}
                            value={accessory?.obj?.masterData?.current?.masterVariant?.sku}
                            checked={checkedOptionAccessories(accessory?.obj?.masterData?.current?.masterVariant?.sku)}
                            onClick={(e) =>
                              handleAccessoryChange(
                                accessory?.obj?.masterData?.current?.masterVariant?.sku,
                                accessory?.obj?.masterData?.current?.masterVariant,
                                e,
                              )
                            }
                            onKeyDown={(e) =>
                              handleAccessoryChange(
                                accessory?.obj?.masterData?.current?.masterVariant?.sku,
                                accessory?.obj?.masterData?.current?.masterVariant,
                                e,
                              )
                            }
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="7"
                            viewBox="0 0 8 7"
                            fill="none"
                            className={`checked-icon absolute top-[9px] left-1/2 hidden h-2 w-2 -translate-x-1/2`}
                          >
                            <path
                              d="M2.134 5.95163C2.2236 6.04791 2.3302 6.12432 2.44766 6.17647C2.56511 6.22862 2.69108 6.25547 2.81832 6.25547C2.94556 6.25547 3.07154 6.22862 3.18899 6.17647C3.30644 6.12432 3.41304 6.04791 3.50264 5.95163L7.43506 1.7607C7.61655 1.56727 7.71851 1.30493 7.71851 1.03139C7.71851 0.757849 7.61655 0.495511 7.43506 0.302087C7.25356 0.108664 7.00741 0 6.75074 0C6.49407 0 6.24791 0.108664 6.06642 0.302087L2.81832 3.77399L1.65209 2.52082C1.4706 2.32739 1.22444 2.21873 0.967771 2.21873C0.711102 2.21873 0.464946 2.32739 0.283454 2.52082C0.101961 2.71424 0 2.97658 0 3.25012C0 3.52366 0.101961 3.786 0.283454 3.97943L2.134 5.95163Z"
                              fill="#222222"
                            ></path>
                          </svg>
                        </section>
                        <div className="px-2">
                          <p
                            className={`text-sm leading-5 ${
                              checkedOptionAccessories(accessory?.obj?.masterData?.current?.masterVariant?.sku)
                                ? 'font-bold text-sol-300'
                                : 'text-sol-300'
                            }`}
                          >
                            {accessory?.obj?.masterData?.current?.name['en-US']}
                          </p>
                          <p
                            className={`text-xs leading-5 ${
                              checkedOptionAccessories(accessory?.obj?.masterData?.current?.masterVariant?.sku)
                                ? 'font-bold text-sol-300'
                                : 'text-sol-300'
                            }`}
                          >
                            {mapLocaleToMeaningfulFormat(router.locale).sku.slice(0, 3)}{' '}
                            {accessory?.obj?.masterData?.current?.masterVariant?.sku}
                          </p>
                        </div>
                      </label>
                    </div>
                    <UpdateQuantity
                      accessory={accessory}
                      checkedOptionAccessories={checkedOptionAccessories}
                      setSelectedAccessories={setSelectedAccessories}
                      selectedAccessories={selectedAccessories}
                    />
                  </div>
                ))}
              </div>
            )}
          </section>
        </section>
      </section>
      <AccessoriesModal
        open={accessoriesModalOpen}
        onClose={closeAccessorieswModal}
        optionalAccessory={optionalAccessory}
      />
    </>
  );
};

export default Accordion;
