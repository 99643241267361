import * as Yup from 'yup';

const validationSchema = Yup.object({
  email: Yup.string().email('Email must be valid').required('Please enter a valid email address.'),
  firstName: Yup.string()
    .matches(/^[A-Za-z-]+$/, 'Please enter only alphabetic characters.')
    .required('Please enter first name.'),
  lastName: Yup.string()
    .matches(/^[A-Za-z-]+$/, 'Please enter only alphabetic characters.')
    .required('Please enter last name.'),
  oldPassword: Yup.string().oneOf(
    [Yup.ref('oldPassword')],
    'Please update the password and password confirmation fields to match.',
  ),

  newPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null])
    .min(8, 'Minimum length would be 8 chars')
    .max(40, 'Maximum length would be 40 chars')
    .notOneOf([Yup.ref('email')], 'Must be different from username')
    .oneOf([Yup.ref('newPassword'), null])
    .matches(/^\S*$/, 'Must not contain spaces')
    .oneOf([Yup.ref('newPassword'), null])
    .matches(/[a-z]+/, 'Must contain 1 lowercase character (a-z)')
    .oneOf([Yup.ref('newPassword'), null])
    .matches(/[A-Z]+/, 'Must contain 1 uppercase character (A-Z)')
    .oneOf([Yup.ref('newPassword'), null])
    .matches(/\d+/, 'Must contain 1 digit from 0-9')
    .oneOf([Yup.ref('newPassword'), null])
    .matches(/[@#$%^&*()_+=\[\]{}|:;"'<>,.?\/\\]+/, 'Must contain 1 special character "^[]’@#$%&*()_+=|}{;:<,.>/?'),

  confirmPassword: Yup.string().oneOf(
    [Yup.ref('newPassword')],
    'Please update the new password and confirm password confirmation fields to match.',
  ),
});
export default validationSchema;
