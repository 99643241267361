import { Fragment, useState } from 'react';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { useDarkMode } from 'frontastic';
import CloseIcon from '../../../icons/sol/close';
type actions = 'setAsDefault' | 'delete' | 'removeLineItem';

export interface QuickViewModalProps {
  open?: boolean;
  onClose?: () => void;
  optionalAccessory?: any;
}

const AccessoriesModal: React.FC<QuickViewModalProps> = ({ open, onClose, optionalAccessory }) => {
  //next/router
  const router = useRouter();
  //Darkmode
  const { mode, theme } = useDarkMode();
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <>
      {optionalAccessory && optionalAccessory?.length !== 0 ? (
        <Transition.Root show={open} as={Fragment}>
          <Dialog className={`${mode} ${theme} default fixed inset-0 z-[100] overflow-y-auto`} onClose={onClose}>
            <>
              <section className="flex items-end justify-center px-4 pt-4 pb-20 text-left sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <section className="absolute inset-0" onClick={onClose}>
                    {/* eslint-disable */}
                    <section
                      className="absolute top-1/2 left-1/2 w-[90%] -translate-x-1/2 -translate-y-1/2 overflow-auto bg-white py-6 px-6 dark:bg-primary-200  md:w-[712px]"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <section className="mb-3 flex justify-end">
                        <section>
                          <button type="button" onClick={onClose} id="modalClose">
                            <CloseIcon className="fill-sol-300 " />
                          </button>
                        </section>
                      </section>
                      <section className="grid grid-cols-1 gap-6 text-xs text-sol-300 md:grid-cols-2">
                        {optionalAccessory &&
                          optionalAccessory?.data &&
                          optionalAccessory?.data?.map((data, index) => (
                            <section key={index}>
                              <table className="w-full">
                                <thead className="text-xs">
                                  <tr className="bg-sol-100">
                                    <th colSpan={2} className="px-2 py-2 text-center font-normal">
                                      {data?.heading}
                                    </th>
                                  </tr>
                                  <tr className="bg-white">
                                    <th className="px-2 py-2 text-center font-semibold">{data?.head?.column1}</th>
                                    <th className="px-2 py-2 text-center font-semibold">{data?.head?.column2}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data?.rows?.map((row, index) => (
                                    <tr key={index} className="bg-white odd:bg-sol-100">
                                      <td className="px-2 py-2 text-center ">{row?.row?.column1}</td>
                                      <td className="px-2 py-2  text-center">{row?.row?.column2}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </section>
                          ))}
                      </section>
                      {optionalAccessory && (
                        <section className="mt-5 text-center text-xs">
                          <p className="mb-3">{optionalAccessory?.tip}</p>
                          <a
                            className="text-xs font-[500] leading-5 hover:underline"
                            target="_blank"
                            href={optionalAccessory?.ctaLink}
                          >
                            {optionalAccessory?.ctaText}
                          </a>
                        </section>
                      )}
                    </section>
                  </section>
                </Transition.Child>
              </section>
            </>
          </Dialog>
        </Transition.Root>
      ) : (
        <></>
      )}
    </>
  );
};

export default AccessoriesModal;
