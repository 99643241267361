import React from 'react';
import { useRouter } from 'next/router';
import { titleCase } from 'converting-case';
import Breadcrumb from 'components/sol-ui/cms/Breadcrumb';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import PopularSearchCategories from './popular-search-categories';
import SearchTips from './search-tips';
import YouMayLikeCard from './you-may-like-section/you-may-like-card';

const NoSearchResults = (data) => {
  const router = useRouter();
  const { algolia: algoliaConfig } = data || data.data;

  return (
    <>
      <section>
        <Breadcrumb breadcrumLevelLink={true} activePage={mapLocaleToMeaningfulFormat(router.locale).searchResults} />
        <section className="mx-auto my-10 max-w-[1440px] px-5 md:px-2 lg:mb-12">
          <h1 className="mt-6 text-center text-[1.688rem] font-semibold after:mx-auto after:mt-4 after:block after:h-[0.063rem] after:w-[9.375rem] after:bg-[#B19A6A] after:content-[''] lg:mt-2">
            {mapLocaleToMeaningfulFormat(router.locale).noSearchResultTitle}{' '}
            {`" ${titleCase(router.query.q ? router.query.q : '')} "`}
          </h1>
          {data?.isFiltersApplied && (
            <button
              style={{ textDecoration: 'underline', marginTop: '30px', fontSize: '18px' }}
              onClick={() => {
                data?.setCanResetFilters(true);
                data?.setShowNoSearchFound(false);
              }}
            >
              {mapLocaleToMeaningfulFormat(router.locale).resetFilters}
            </button>
          )}
          <SearchTips solInfo={data?.solInfo} />
          {data?.isYMALEnabled && <YouMayLikeCard algoliaConfig={algoliaConfig} />}
        </section>
      </section>
    </>
  );
};

export default NoSearchResults;
