import React from 'react';
import NextLink from 'next/link';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { titleCase } from 'converting-case';
import { routerURL } from 'helpers/utils/routerUrl';
import Imagesf from '../../../icons/sol/light.png';

interface SuggestedItemProps {
  suggestedSearchData?: string;
  dataLink?: string;
}
function SuggestedProduct({ dataLink, suggestedSearchData }: SuggestedItemProps) {
  const router = useRouter();
  return (
    <Link href={dataLink}>
      <a tabIndex={0} className="inline-block text-sm font-normal leading-5 text-sol-300">
        {suggestedSearchData ? titleCase(suggestedSearchData) : ''}
      </a>
    </Link>
  );
}

export default SuggestedProduct;
