import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Tab } from '@headlessui/react';
import { useHits, useInfiniteHits } from 'react-instantsearch';
import { Hits, connectHits, connectInfiniteHits } from 'react-instantsearch-dom';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ProductSearchContent = ({ indexType, data }) => {
  const router = useRouter();
  const selectedLocale = (router.query.locale ? router.query.locale.toString() : 'en-US').replaceAll('_', '-');

  const getImageUrl = (hit) => {
    return indexType == 'Product' ? hit?.images[0]?.url : hit?.image;
  };

  const CustomHits = React.memo(function CustomHits({ hits, isLastPage, showMore }: any) {
    // const { hits, isLastPage, showMore } = useInfiniteHits();

    return (
      <>
        {hits.map((hit, index) => (
          <li key={index}>
            <Link href={hit?.ctaLink}>
              <a>
                <section className="border-[rgb(0,0,0,)]/opacity-10 flex gap-4 rounded-[6px] border p-4">
                  <section className="h-[75px] shrink-0 pt-1">
                    <Image
                      loader={(options: { src: any }) => options?.src}
                      width={75}
                      height={75}
                      src={getImageUrl(hit)}
                      className="rounded-[6px]"
                    />
                  </section>
                  <section>
                    <section className="mb-2 flex flex-wrap items-center gap-[10px]">
                      <h4 className="text-base font-semibold leading-5 text-sol-300">{hit.title}</h4>
                      <p className="min-h-[24px] rounded-[6px] bg-[#d9d9d9] px-2 py-[2px] text-xs font-semibold leading-[21px] text-sol-300">
                        {hit?.type == 'blog' ? 'Blog' : 'Product Category Page'}
                      </p>
                    </section>
                    <p className="text-sm font-normal leading-[17px] text-sol-300">
                      {hit.shortDescription ? hit.shortDescription : ''}
                    </p>
                  </section>
                </section>
              </a>
            </Link>
          </li>
        ))}

        <div className="text-center">
          {!isLastPage ? (
            <button
              className="mt-4 h-10 rounded bg-[#404040] px-5 text-sm font-bold leading-[17px] text-white"
              onClick={showMore}
              disabled={isLastPage}
            >
              {mapLocaleToMeaningfulFormat(router.locale).loadMore}
            </button>
          ) : null}
        </div>
      </>
    );
  });

  return (
    <>
      <section className="mx-auto max-w-[902px]">
        <ul className="productSearchContent grid gap-y-[14px]">
          <CustomHits {...data} />
        </ul>
      </section>
    </>
  );
};

export default React.memo(ProductSearchContent);
