import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Account } from '@Types/account/Account';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { desktop } from 'helpers/utils/screensizes';
import Modal from './edit-profile-modal';
interface AccountProps {
  account: Account;
}

function Profile({ account }: AccountProps) {
  const [isDesktop] = useMediaQuery(desktop);
  const router = useRouter();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const openCreateModal = () => {
    setCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalOpen(false);
  };

  return (
    <section className="mt-4 rounded border border-slate-300 p-4 lg:mt-0">
      <section className="mb-4 md:mb-6">
        <section className="flex justify-between">
          <h3 className="text-sm font-semibold text-sol-300">{mapLocaleToMeaningfulFormat(router.locale).profile}</h3>
          {isDesktop && (
            <button type="button" id="updateProfile" className="btn-primary-small lg:px-4" onClick={openCreateModal}>
              {mapLocaleToMeaningfulFormat(router.locale).update}
            </button>
          )}
        </section>
        {/* TODO:To be replaced by data from API */}

        <section className="mb-4 mt-3 grid grid-cols-4 gap-2 text-sm lg:mt-0">
          <section className="col-span-4 grid grid-cols-4 gap-4">
            <em className="col-span-1 text-sm font-semibold not-italic text-sand-100">
              {mapLocaleToMeaningfulFormat(router.locale).email}:
            </em>
            <p className="col-span-3 w-full truncate text-sm font-normal text-sol-300">{account?.email || ''}</p>
          </section>
          <section className="col-span-4 grid grid-cols-4 gap-4">
            <em className="col-span-1 mt-2 font-semibold not-italic text-sand-100">
              {mapLocaleToMeaningfulFormat(router.locale).name}:
            </em>
            <p className="col-span-3 mt-2 text-sm font-normal text-sol-300">{`${account?.firstName || ''} ${
              account?.lastName || ''
            }`}</p>
          </section>
          <section className="col-span-4 grid grid-cols-4 gap-4">
            <em className="col-span-1 mt-2 font-semibold not-italic text-sand-100">
              {mapLocaleToMeaningfulFormat(router.locale).password}:
            </em>
            <p className="col-span-3 mt-2 text-sm font-normal text-sol-300">***********</p>
          </section>
        </section>
      </section>
      {!isDesktop && (
        <section className="shrink-0">
          <button
            type="button"
            id="submitUpdateProfile"
            className="btn-primary-small mt-2 w-full py-2"
            onClick={openCreateModal}
          >
            {mapLocaleToMeaningfulFormat(router.locale).update}
          </button>
        </section>
      )}
      <Modal
        modalHeading={'Edit Profile'}
        modelOpen={createModalOpen}
        onClose={closeCreateModal}
        primaryBtnContent={'Update'}
        account={account}
      />
    </section>
  );
}

export default Profile;
