import React, { useState, useReducer } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import Redirect from 'helpers/redirect';
import { gtmViewItemList } from 'helpers/utils/gtm-events/gtmEcommerce';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { desktop } from 'helpers/utils/screensizes';
import { useAccount, useWishlist } from 'frontastic';
import ContinueShoppingButton from '../continueShoppingButton';

function AccountNav() {
  const router = useRouter();
  const [isDesktop] = useMediaQuery(desktop);
  function AccountHome(state, action) {
    switch (action.type) {
      case 'UPDATE_ACTIVE_ID':
        return {
          ...state,
          activeId: action.payload,
        };
      default:
        return state;
    }
  }
  const [state, dispatch] = useReducer(AccountHome, { activeId: undefined });
  const [subMenuArrowDown, setSubMenuArrowDown] = useState(false);
  function handleDropDown() {
    setSubMenuArrowDown(!subMenuArrowDown);
    dispatch({ type: 'UPDATE_ACTIVE_ID', payload: 'account-home' });
  }
  const { data: wishlist } = useWishlist();
  const { loggedIn } = useAccount();
  const navList = [
    {
      id: 1,
      activeId: undefined,
      name: 'Account Home',
      url: routerURL?.account,
      title: 'Account | Home',
    },
    {
      id: 2,
      activeId: 'order-history',
      name: 'Order History',
      url: routerURL?.orderHistory,
      title: 'Account | Order History',
    },
    {
      id: 3,
      activeId: 'my-list',
      name: 'My List',
      url: routerURL?.myList,
      title: 'Account | My List',
    },
    {
      id: 4,
      activeId: 'address',
      name: 'Address Book',
      url: routerURL?.address,
      title: 'Account | Address',
    },
    {
      id: 5,
      activeId: 'credit-card',
      name: 'Credit Cards',
      url: routerURL?.creditCard,
      title: 'Account | Credit Card',
    },
  ];
  const routerPath = router.asPath.split('/')[2];
  const activeId = routerPath === 'order-details' ? 'order-history' : routerPath;

  const handleClick = (e) => {
    if (routerURL.myList != router.asPath && e.target.innerText === 'My List' && loggedIn) {
      gtmViewItemList(wishlist);
    }
  };

  return (
    <>
      {isDesktop ? (
        <div>
          <nav className="shrink-0 lg:w-[211px]">
            <ul className="block rounded border border-slate-300 py-2">
              {navList.map((nav) => (
                <li
                  key={nav.id}
                  onClick={(e) => handleClick(e)}
                  className={`${
                    activeId === nav?.activeId
                      ? 'bg-sol-100 pl-2 pt-1 text-left text-sm font-semibold leading-[1.469rem] text-sol-300'
                      : ''
                  } my-2 pl-2 text-left text-sm leading-[1.469rem] text-sol-300 hover:bg-sol-100 hover:font-semibold`}
                >
                  <Link href={nav?.url}>
                    <a>{nav?.name}</a>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <ContinueShoppingButton />
        </div>
      ) : (
        <>
          <section className="mt-10 rounded border lg:mt-8">
            <section className="flex cursor-pointer justify-between bg-sol-900" onClick={handleDropDown}>
              {navList
                .filter((nav) => activeId === nav?.activeId)
                .map((nav) => (
                  <h3 key={nav.id} className="p-3 text-sm text-sol-300">
                    {nav.name}
                  </h3>
                ))}

              {!subMenuArrowDown ? (
                <section className="my-auto mr-3">
                  <MdOutlineKeyboardArrowDown onClick={handleDropDown} className="text-2xl text-sol-300" />
                </section>
              ) : (
                <section className="my-auto mr-3">
                  <MdOutlineKeyboardArrowUp onClick={handleDropDown} className="text-2xl text-sol-300" />
                </section>
              )}
            </section>
            {subMenuArrowDown && (
              <ul className="pl-3 text-left">
                {navList
                  .filter((nav) => activeId !== nav?.activeId)
                  .map((nav) => (
                    <li key={nav?.id} onClick={(e) => handleClick(e)} className="mr-1 text-sm leading-[2.5rem]">
                      <Link href={nav?.url}>
                        <a>{nav?.name}</a>
                      </Link>
                      {activeId === nav?.activeId && (
                        <Head>
                          <title>{nav?.title}</title>
                        </Head>
                      )}
                    </li>
                  ))}
              </ul>
            )}
          </section>
        </>
      )}
    </>
  );
}

export default AccountNav;
