import React, { useState } from 'react';
import { useRouter } from 'next/router';
import type { Address as AddressType } from '@Types/account/Address';
import { ADDRESS_DELETED, ADDRESS_UPDATED, SERVER_ERROR, ADDRESS_NOT_VALID } from 'helpers/constants/messages';
import { useNotification } from 'helpers/notification/notificationContext';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { useAccount, useCart } from 'frontastic';
import { CartDetails } from 'frontastic/actions/cart';
import { AddressReturnProps } from '../../addressBook';
import DeleteModal from '../modal/delete-modal';
import SetAsDefaultCreditCard from '../modal/delete-modal';
import AddNewAddressModal from '../modal/edit-profile';

export interface EditProfileProps {
  open?: boolean;
  onClose?: () => void;
}

interface LinksModalProps {
  defaultName?: string;
  setAsDefault?: string;
  address?: AddressType;
}

function LinksModal({ address, defaultName, setAsDefault }: LinksModalProps) {
  const router = useRouter();

  //account data
  const {
    account,
    setDefaultBillingAddress,
    setDefaultShippingAddress,
    removeAddress,
    addAddress,
    updateAddress,
    validateAddress,
  } = useAccount();
  //cart data
  const { data: cart, updateCart } = useCart();

  //create delete modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  //global notification
  const { showNotification } = useNotification();

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  //create default address modal
  const [defaultModalOpen, setDefaultModalOpen] = useState(false);

  const openDefaultModal = () => {
    setDefaultModalOpen(true);
  };

  const closeDefaultModal = () => {
    setDefaultModalOpen(false);
  };

  //Edit address modal
  const [editModalOpen, setEditModalOpen] = useState(false);

  const openEditModal = () => {
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const handleSetAsDefault = async ({ addressId }: AddressReturnProps) => {
    setLoading(true);
    await setDefaultBillingAddress(addressId);
    await setDefaultShippingAddress(addressId);
    showNotification(ADDRESS_UPDATED, 'success');
    closeDefaultModal();
    setLoading(false);
  };

  const handleDelete = async ({ addressId }: AddressReturnProps) => {
    try {
      await removeAddress(addressId);
      closeDeleteModal();
      showNotification(ADDRESS_DELETED, 'success');
    } catch (error) {
      showNotification(SERVER_ERROR, 'error');
    }
  };

  const handleEditAddress = async (address: AddressType, isDefault: boolean) => {
    try {
      setLoading(true);
      const newAddress: AddressType = address;
      newAddress.firstName = newAddress.firstName.trim();
      newAddress.lastName = newAddress.lastName.trim();
      const validate = await validateAddress(newAddress);
      if (!validate.isValid) {
        showNotification(ADDRESS_NOT_VALID, 'error');
        return;
      } else {
        if (isDefault) {
          await setDefaultBillingAddress(newAddress?.addressId);
          await setDefaultShippingAddress(newAddress?.addressId);
        }
        await updateAddress(newAddress);

        const updateCartData: CartDetails = {
          account: {
            email: cart?.email ?? account?.email,
          },
          billing: undefined,
          shipping: undefined,
        };
        if (cart?.billingAddress?.addressId === newAddress?.addressId) {
          updateCartData.billing = newAddress ?? undefined;
        }
        if (cart?.shippingAddress?.addressId === newAddress?.addressId) {
          updateCartData.shipping = newAddress ?? undefined;
        }
        if (updateCartData?.shipping || updateCartData?.billing) {
          await updateCart(updateCartData);
        }
        setLoading(false);
        showNotification(ADDRESS_UPDATED, 'success');
        closeEditModal();
      }
    } catch (error) {
      showNotification(SERVER_ERROR, 'error');
    }
  };

  return (
    <>
      {address?.isDefaultBillingAddress === true ? (
        <p className="font-semibold text-sol-300">{defaultName}</p>
      ) : (
        <section className="flex text-sm underline underline-offset-2">
          <button type="button" className="truncate" onClick={openDefaultModal}>
            {setAsDefault}
            <span className="px-3">|</span>
          </button>
          <button type="button" onClick={openEditModal}>
            {mapLocaleToMeaningfulFormat(router.locale).edit}
            <span className="px-3">|</span>
          </button>
          <button type="button" onClick={openDeleteModal}>
            {mapLocaleToMeaningfulFormat(router.locale).delete}
          </button>
        </section>
      )}
      <SetAsDefaultCreditCard
        open={defaultModalOpen}
        onClose={closeDefaultModal}
        modalHeading={`${mapLocaleToMeaningfulFormat(router.locale).setAsDefaultAddress}`}
        modalContent={`${mapLocaleToMeaningfulFormat(router.locale).sureWantSetAddress}`}
        primaryBtnContent={`${mapLocaleToMeaningfulFormat(router.locale).setAsDefaultAddress}`}
        addressId={address?.addressId}
        handleSetAsDefault={handleSetAsDefault}
        handleDelete={handleDelete}
        action="setAsDefault"
        loading={loading}
      />
      <AddNewAddressModal
        open={editModalOpen}
        onClose={closeEditModal}
        modalHeading={`${mapLocaleToMeaningfulFormat(router.locale).editAddress}`}
        primaryBtnContent={`${mapLocaleToMeaningfulFormat(router.locale).updateAddress}`}
        address={address}
        onClickEdit={true}
        handleEditAddress={handleEditAddress}
        loading={loading}
        isBillingAddress={true}
      />
      <DeleteModal
        open={deleteModalOpen}
        onClose={closeDeleteModal}
        modalHeading={`${mapLocaleToMeaningfulFormat(router.locale).deleteConfirmation}`}
        modalContent={`${mapLocaleToMeaningfulFormat(router.locale).sureWantDelete}`}
        primaryBtnContent={`${mapLocaleToMeaningfulFormat(router.locale).delete}`}
        handleSetAsDefault={handleSetAsDefault}
        handleDelete={handleDelete}
        addressId={address?.addressId}
        action="delete"
      />
    </>
  );
}

export default LinksModal;
