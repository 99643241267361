import * as Yup from 'yup';

const validationSchema = Yup.object({
  email: Yup.string().email('Email must be valid').required('Please enter a valid email address.'),
  password: Yup.string()
    .oneOf([Yup.ref('password'), null])
    .min(8, 'Minimum length would be 8 chars')
    .max(40, 'Maximum length would be 40 chars')
    .notOneOf([Yup.ref('email')], 'Must be different from username')
    .oneOf([Yup.ref('password'), null])
    .matches(/^\S*$/, 'Must not contain spaces')
    .oneOf([Yup.ref('password'), null])
    .matches(/[a-z]+/, 'Must contain 1 lowercase character (a-z)')
    .oneOf([Yup.ref('password'), null])
    .matches(/[A-Z]+/, 'Must contain 1 uppercase character (A-Z)')
    .oneOf([Yup.ref('password'), null])
    .matches(/\d+/, 'Must contain 1 digit from 0-9')
    .oneOf([Yup.ref('password'), null])
    .matches(/[@#$%^&*()_+=\[\]{}|:;"'<>,.?\/\\-]+/, 'Must contain 1 special character "^[]’@#$%&*()_+=|}{;:<,.>/?-')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Password and Confirm Password fields do not match.')
    .required('Confirm Password is required'),
});
export default validationSchema;
