import { Field, Form, Formik } from 'formik';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { DEFAULT_COUNTRY, DEFAULT_STATE } from 'helpers/utils/constant';
import React, { useEffect, useState } from 'react';
import { Country, State } from '@Types/account/Country';
import { useAccount, useFreshdesk } from 'frontastic/provider';
import { useNotification } from 'helpers/notification/notificationContext';
import {
  catalogValidationSchemaGuestUser,
  catalogValidationSchemaLoginUser,
} from 'helpers/utils/validationSchema/communicationPreference';
import { useRouter } from 'next/router';
import ReCaptcha from 'components/sol-ui/recaptcha';
import Spinner from 'components/sol-ui/notification/spinner';
import Breadcrumb from 'components/sol-ui/cms/Breadcrumb';
import { catalogUnsubscribeFreshDesk } from 'frontastic/actions/account';
const CatalogUnsubscribe = () => {
  const { shippingLocations, loggedIn, catalogUnsubscribe, account } = useAccount();
  const router = useRouter();
  const countries = Object.freeze(shippingLocations?.data) as Country[];
  const [states, setStates] = useState<State[]>();
  const [isUnsubscribeCatalog, setIsUnsubscribeCatalog] = useState(true);
  const { createTicket } = useFreshdesk();
  const { showNotification } = useNotification();
  const [isReCaptchaVerified, setIsReCaptchaVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reCaptchaError, setReCaptchaError] = useState(false);

  const resetValues = {
    email: loggedIn && account?.email ? account.email : '',
    confirmEmail: '',
    firstName: '',
    lastName: '',
    streetName: '',
    streetNumber: '',
    city: '',
    postalCode: '',
    state: DEFAULT_STATE,
    country: DEFAULT_COUNTRY,
  };
  const handleCountrySelect = (countryCode: string) => {
    if (countryCode) {
      return countries?.find((country) => country?.countryCode === countryCode)?.states;
    }
    return [];
  };

  const handleDefaultState = (countryCode) => {
    const states = countries?.find((country) => country?.countryCode === countryCode)?.states;
    if (states) {
      return states[0]?.stateCode;
    }
    return '';
  };
  const emailPreferenceInitialValues = resetValues;

  useEffect(() => {
    if (countries) {
      const selectedCountry = emailPreferenceInitialValues?.country ?? undefined;
      const countryCode = selectedCountry !== '' ? selectedCountry : DEFAULT_COUNTRY;
      if (countryCode) {
        const states = handleCountrySelect(countryCode) as State[];
        setStates(states);
      }
    }
  }, [countries]);
  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    setReCaptchaError(false);
    if (isReCaptchaVerified) {
      await catalogUnsubscribe(values?.email, values);
      await catalogUnsubscribeFreshDesk(values?.email, values);
    } else {
      setReCaptchaError(true);
    }
    setLoading(false);
    resetForm();
    setIsUnsubscribeCatalog(false);
  };
  const handleUnSubscribe = () => {
    setIsUnsubscribeCatalog(true);
  };
  return (
    <div>
      {isUnsubscribeCatalog ? (
        <section>
          <Breadcrumb
            breadcrumLevelLink={loggedIn ? false : true}
            activePage={mapLocaleToMeaningfulFormat(router.locale).catalogUnsubscribe}
          />
          <h1 className="mt-7 text-center text-[1.688rem] font-semibold after:mx-auto after:mt-4 after:block after:h-[0.063rem] after:w-[9.375rem] after:bg-[#B19A6A] after:content-[''] lg:mt-4">
            {mapLocaleToMeaningfulFormat(router.locale).catalogUnsubscribe}
          </h1>
          <Formik
            initialValues={emailPreferenceInitialValues}
            validationSchema={!loggedIn ? catalogValidationSchemaGuestUser : catalogValidationSchemaLoginUser}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, { resetForm });
            }}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form>
                <section className="mx-auto mt-10 w-full md:w-[41.875rem]">
                  {!loggedIn && loggedIn != undefined && (
                    <section className="grid  lg:grid-cols-2">
                      <section className="w-full px-4 pt-2">
                        <label
                          htmlFor="emailAddress"
                          className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                        >
                          {mapLocaleToMeaningfulFormat(router.locale).emailAddress}
                        </label>
                        <Field
                          className={`input-text-primary mt-3 !w-full ${
                            formik?.errors?.email && formik?.touched?.email ? '!border-red-600' : ''
                          }`}
                          id="emailAddress"
                          name="email"
                          type="text"
                          onBlur={formik?.handleBlur}
                        />
                        {formik?.errors?.email && formik?.touched?.email && (
                          <p className="pt-2 text-sm font-normal leading-[17px] text-[#DA0F0F]">
                            {formik?.errors?.email}
                          </p>
                        )}
                      </section>

                      <section className="w-full px-4 pt-2">
                        <label
                          htmlFor="confirmEmailAddress"
                          className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                        >
                          {mapLocaleToMeaningfulFormat(router.locale).confirmEmailAddress}
                        </label>
                        <Field
                          className={`input-text-primary mt-3 !w-full ${
                            formik?.errors?.confirmEmail && formik?.touched?.confirmEmail ? '!border-red-600' : ''
                          }
                                                `}
                          id="confirmEmailAddress"
                          name="confirmEmail"
                          type="text"
                          onBlur={formik?.handleBlur}
                        />
                        {formik?.errors?.confirmEmail && formik?.touched?.confirmEmail && (
                          <p className="w-[20.3rem] pt-2 text-sm font-normal leading-[17px] text-[#DA0F0F]">
                            {formik.errors.confirmEmail}
                          </p>
                        )}
                      </section>
                    </section>
                  )}
                  <section className="grid  lg:grid-cols-2">
                    <section className="w-full px-4 pt-2">
                      <label
                        htmlFor="firstName"
                        className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).firstName}
                      </label>

                      <Field
                        type="text"
                        name="firstName"
                        id="firstName"
                        onBlur={formik?.handleBlur}
                        className={`input-text-primary mt-3 !w-full ${
                          formik?.errors?.firstName && formik?.touched?.firstName ? '!border-red-600' : ''
                        }`}
                      />
                      {formik?.errors?.firstName && formik?.touched?.firstName && (
                        <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{formik?.errors?.firstName}</p>
                      )}
                    </section>
                    <section className="w-full px-4 pt-2">
                      <label
                        htmlFor="lastName"
                        className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).lastName}
                      </label>
                      <Field
                        type="text"
                        name="lastName"
                        id="lastName"
                        onBlur={formik?.handleBlur}
                        className={`input-text-primary mt-3 !w-full ${
                          formik?.errors?.lastName && formik?.touched?.lastName ? '!border-red-600' : ''
                        }`}
                      />
                      {formik?.errors?.lastName && formik?.touched?.lastName && (
                        <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{formik?.errors?.lastName}</p>
                      )}
                    </section>
                  </section>
                  <section className="w-full px-4 pt-6">
                    <section className="flex items-center justify-between">
                      <label
                        htmlFor="streetName"
                        className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).streetName}
                      </label>
                    </section>
                    <Field
                      type="text"
                      name="streetName"
                      id="streetName"
                      onBlur={formik?.handleBlur}
                      className={`input-text-primary mt-3 !w-full ${
                        formik?.errors?.streetName && formik?.touched?.streetName ? '!border-red-600' : ''
                      }`}
                    />
                    {formik?.errors?.streetName && formik?.touched?.streetName && (
                      <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{formik?.errors?.streetName}</p>
                    )}
                  </section>
                  <section className="w-full px-4 pt-6">
                    <label htmlFor="streetNumber" className="text-sm font-semibold leading-[17px] text-sol-300">
                      {mapLocaleToMeaningfulFormat(router.locale).streetNumber}
                    </label>
                    <Field
                      type="text"
                      name="streetNumber"
                      id="streetNumber"
                      className={`input-text-primary mt-3 !w-full`}
                    />
                  </section>
                  <section className="grid  lg:grid-cols-2">
                    <section className="w-full px-4 pt-6">
                      <label htmlFor="city" className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300">
                        {mapLocaleToMeaningfulFormat(router.locale).city}
                      </label>
                      <Field
                        type="text"
                        name="city"
                        id="city"
                        className={`input-text-primary mt-3 !w-full ${
                          formik?.errors?.city && formik?.touched?.city ? '!border-red-600' : ''
                        }`}
                      />
                      {formik?.errors?.city && formik?.touched?.city && (
                        <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{formik?.errors?.city}</p>
                      )}
                    </section>
                    <section className="w-full px-4 pt-6">
                      <label
                        htmlFor="postalCode"
                        className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).postalCode}
                      </label>
                      <Field
                        type="text"
                        name="postalCode"
                        id="postalCode"
                        className={`input-text-primary mt-3 !w-full ${
                          formik?.errors?.postalCode && formik?.touched?.postalCode ? '!border-red-600' : ''
                        }`}
                      />
                      {formik?.errors?.postalCode && formik?.touched?.postalCode && (
                        <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                          {formik?.errors?.postalCode}
                        </p>
                      )}
                    </section>
                  </section>
                  <section className="grid pb-5 lg:grid-cols-2">
                    <section className="w-full px-4 pt-6">
                      <label
                        htmlFor="state"
                        className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).stateRegion}
                      </label>
                      <Field
                        value={formik?.values?.state ?? emailPreferenceInitialValues?.state}
                        id="state"
                        name="state"
                        as="select"
                        onChange={(e) => {
                          const { value } = e.target;
                          formik?.setFieldValue('state', value);
                          formik?.handleChange(e);
                        }}
                        className="input-text-primary mt-3 !w-full"
                      >
                        {states &&
                          states.map((state) => (
                            <option key={state?.stateCode} value={state?.stateCode}>
                              {state?.state}
                            </option>
                          ))}
                      </Field>
                    </section>
                    <section className="w-full px-4 pt-8">
                      <label htmlFor="country" className="block text-sm font-semibold leading-[17px] text-sol-300">
                        {' '}
                        {mapLocaleToMeaningfulFormat(router.locale).country}
                      </label>
                      <Field
                        id="country"
                        name={`.country`}
                        as="select"
                        className="input-text-primary mt-3 !w-full"
                        value={formik?.values?.country ?? emailPreferenceInitialValues?.country}
                        onChange={async (e) => {
                          const { value } = e.target;
                          const _states = handleCountrySelect(value);
                          if (_states) {
                            formik?.setFieldValue(`country`, value);
                            formik?.setFieldValue(`state`, handleDefaultState(value));
                            setStates(_states);
                          }
                        }}
                      >
                        {countries &&
                          countries?.map((country, index) => (
                            <option key={index} value={country?.countryCode}>
                              {country?.country}
                            </option>
                          ))}
                      </Field>
                    </section>
                  </section>
                  <section className="mx-auto mt-4 w-full px-4 md:w-[41.875rem]">
                    <div>
                      <ReCaptcha
                        setIsReCaptchaVerified={setIsReCaptchaVerified}
                        setReCaptchaError={setReCaptchaError}
                      />
                      {reCaptchaError && (
                        <span className="text-xs font-semibold text-red-500">
                          {mapLocaleToMeaningfulFormat(router.locale).reCaptchaError}
                        </span>
                      )}
                    </div>
                  </section>
                  <section className="mx-auto mt-4 mb-8 w-full px-4 md:w-[41.875rem]">
                    <button type="submit" className="btn-primary-large mt-6 w-full md:w-[294px]">
                      {loading ? (
                        <section className="flex justify-center gap-3">
                          {' '}
                          <Spinner />
                          <p>{mapLocaleToMeaningfulFormat(router.locale).processing}</p>
                        </section>
                      ) : (
                        mapLocaleToMeaningfulFormat(router.locale).unsubscribe
                      )}
                    </button>
                  </section>
                </section>
              </Form>
            )}
          </Formik>
        </section>
      ) : (
        <section className="mt-6 text-center">
          <div className="inline-block text-left">
            <p className="mb-2 text-2xl font-bold">{mapLocaleToMeaningfulFormat(router.locale).unSubscribed}</p>
            <p className="mb-1 text-sm">{mapLocaleToMeaningfulFormat(router.locale).sorryToSeeYouGo}</p>
            <p className="mb-1 text-sm">{mapLocaleToMeaningfulFormat(router.locale).signInAgain}</p>
            <button
              type="button"
              id="back"
              className="mb-1 flex h-[44px] w-[80px] items-center justify-center rounded-md bg-[#404040] px-6 text-sm font-bold leading-[17px] text-white focus:outline-offset-2 "
              onClick={handleUnSubscribe}
            >
              {mapLocaleToMeaningfulFormat(router.locale).back}
            </button>
          </div>
        </section>
      )}
    </div>
  );
};

export default CatalogUnsubscribe;
