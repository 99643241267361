import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg
    width="1em"
    height="1em"
    className={className}
    viewBox="0 0 11 8"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.84534 7.61142C2.96481 7.73455 3.10694 7.83228 3.26354 7.89897C3.42014 7.96566 3.58811 8 3.75776 8C3.92741 8 4.09538 7.96566 4.25198 7.89897C4.40858 7.83228 4.55072 7.73455 4.67019 7.61142L9.91341 2.25172C10.1554 2.00436 10.2913 1.66885 10.2913 1.31903C10.2913 0.969199 10.1554 0.633699 9.91341 0.386334C9.67142 0.138968 9.34321 0 9.00099 0C8.65876 0 8.33055 0.138968 8.08856 0.386334L3.75776 4.82648L2.20279 3.22382C1.9608 2.97646 1.63259 2.83749 1.29036 2.83749C0.948137 2.83749 0.619928 2.97646 0.377938 3.22382C0.135949 3.47119 0 3.80669 0 4.15652C0 4.50634 0.135949 4.84185 0.377938 5.08921L2.84534 7.61142Z" />
  </svg>
);

export default Icon;
