import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { sentenceCase } from 'converting-case';
import ForwardIcon from 'components/icons/sol/forward';
import Warning from 'components/icons/sol/warning-icon';
import TrustBox from 'components/sol-ui/Trustpilot';
import { convertTitleFromCamelCase } from 'helpers/convertToCamelCase';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import CheckedIcon from '../../../icons/sol/checked-icon';
import DownIcon from '../../../icons/sol/down-icon';
import UpIcon from '../../../icons/sol/up-icon';
import Accordion from './pdp-accordion';
import ReviewAccordion from './pdp-review-accordion';

type AccordionProps = {
  isClearItems?: boolean;
  productData?: any;
  variant: any;
  dataSource?: any;
  trustPilotSkuId?: string;
};

const ProductAttributesList = ({ productData, dataSource }) => {
  const router = useRouter();
  const [openAcordian, setOpenAcordian] = useState(false);
  if (!productData) {
    return <></>;
  }

  const attributesToMap = dataSource?.data?.attributes?.map((item) => item['attributeKey']) || [];

  const attributeEntries = attributesToMap.reduce((acc, key) => {
    const value = productData[key];

    if (value !== undefined) {
      let displayValue;

      if (Array.isArray(value)) {
        if (typeof value[0] === 'object') {
          displayValue = value.map((v) => v.label).join(', ');
        } else if (typeof value[0] === 'string') {
          displayValue = value.join(', ');
        }
      } else if (typeof value === 'object') {
        displayValue = value.label;
      } else {
        displayValue = value;
      }
      acc.push({
        label: convertTitleFromCamelCase(
          key === mapLocaleToMeaningfulFormat(router.locale).actualColor
            ? mapLocaleToMeaningfulFormat(router.locale).color
            : key,
        ),
        key,
        value: displayValue,
      });
    }

    return acc;
  }, []);

  const Prop65_Ca_Residents = attributeEntries.find((attributeEntrie) => attributeEntrie.key === 'prop65CaResidents');
  return (
    <section>
      <ul className="grid grid-flow-row grid-cols-1 gap-5 md:grid-flow-col md:grid-cols-2 md:grid-rows-12 lg:grid-cols-3 lg:grid-rows-8">
        {attributeEntries.map(
          ({ key, value, label }, index) =>
            !(key === 'prop65CaResidents') && (
              <li key={index}>
                <section>
                  <section className="flex gap-[18px]">
                    <strong className="inline-block shrink-0 text-sm font-semibold leading-5 text-sol-300">
                      {label}:
                    </strong>
                    {typeof value != 'boolean' ? (
                      <p className="text-sm font-normal leading-5 text-sol-300">{value}</p>
                    ) : (
                      <p className="text-sm font-normal leading-5 text-sol-300">
                        {value
                          ? mapLocaleToMeaningfulFormat(router.locale).yes
                          : mapLocaleToMeaningfulFormat(router.locale).no}
                      </p>
                    )}
                  </section>
                </section>
              </li>
            ),
        )}
      </ul>
      {Prop65_Ca_Residents && (
        <section className="mt-7">
          <button type="button">
            <strong
              className="flex min-w-[83px] gap-3 text-sm font-semibold leading-5 text-sol-300"
              onClick={() => setOpenAcordian(!openAcordian)}
            >
              <p>{mapLocaleToMeaningfulFormat(router.locale).californiaResidentsWARNINGS}</p>
              <section className={openAcordian ? 'ml-2 -rotate-90' : 'rotate-90'}>
                <ForwardIcon />
              </section>
            </strong>
          </button>
          {openAcordian && (
            <section className="fotn-normal text-sm leading-6 text-sol-300">
              <p className="mt-3 text-sm font-normal leading-5 text-sol-300">
                <span className="uppercase">
                  <Warning className="inline h-[18px] w-5 fill-[#d5a806]" />{' '}
                  {mapLocaleToMeaningfulFormat(router.locale).warning}:
                </span>
                <span>{Prop65_Ca_Residents.value}</span>
                <span>{mapLocaleToMeaningfulFormat(router.locale).forMoreInformation}</span>
              </p>
              {/* static data will come from Amplience */}
              <p className="mt-5">
                The warning listed on our website is in accordance with a law implemented by the state of California
                called Proposition-65. Under this regulation, California law requires businesses to provide warnings for
                products containing chemicals that the state of California has identified as carcinogens or reproductive
                toxins. Recent amendments to this law require any retailers selling to California residents to list this
                warning on their website.
              </p>
              {/* static data will come from Amplience */}
              <p className="mt-5">
                There are currently almost 1,000 substances on the Proposition-65 list, ranging from cigarette smoke and
                asbestos to aspirin and wood dust. One of the materials listed is lead, and because most light fixtures
                contain brass in the sockets or in the frames of the shades, we are required to put this warning label
                on our light fixtures. Brass is a metal alloy consisting of copper, zinc and small amounts of lead,
                which is what triggers the “lead” warning under California Proposition-65 since lead has been shown to
                cause cancer when it is ingested.
              </p>
              {/* static data will come from Amplience */}
              <p className="mt-5">
                We hope that this explains things a little more clearly. If you have any additional questions, we would
                suggest visiting the website mentioned above for more information and the complete list of substances
                that require warnings under Proposition-65.
              </p>
            </section>
          )}
        </section>
      )}
    </section>
  );
};

const ProductDescription = ({ isClearItems, productData, variant, dataSource, trustPilotSkuId }: AccordionProps) => {
  //next/router

  const router = useRouter();

  return (
    <>
      <section className="mt-8">
        <section className="border-t border-sol-600">
          <Accordion
            title={`${mapLocaleToMeaningfulFormat(router.locale).description}`}
            defaultOpen={true}
            content={
              <section className="mt-6">
                <h2
                  className="text-sm font-normal leading-5 text-sol-300"
                  dangerouslySetInnerHTML={{ __html: productData?.description ?? '' }}
                ></h2>
              </section>
            }
            isClearItems={false}
          />
        </section>
        <section className="border-t border-sol-600">
          <Accordion
            title={`${mapLocaleToMeaningfulFormat(router.locale).specifications}`}
            defaultOpen={true}
            content={
              <section className="mt-6">
                <ProductAttributesList productData={variant?.attributes} dataSource={dataSource} />
              </section>
            }
            isClearItems={false}
          />
        </section>
        {trustPilotSkuId && (
          <section className="border-t border-sol-600">
            <ReviewAccordion
              defaultOpen={false}
              title={`${mapLocaleToMeaningfulFormat(router.locale).reviews}`}
              content={<section className="mt-6">{<TrustBox id={trustPilotSkuId} />}</section>}
              isClearItems={false}
            />
          </section>
        )}
      </section>
    </>
  );
};

export default ProductDescription;
