import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { createInMemoryCache } from '@algolia/cache-in-memory';
import { Variant } from '@Types/product/Variant';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure, Hits } from 'react-instantsearch-dom';
import useAlgoliaSearchClient from 'helpers/hooks/useAlgoliaSearchClient';
import ProductListItemBlock from '../../no-search-results/you-may-like-section/ProductListItemBlock';
import ProductList from '../../product-list';
import ProductListCard from '../../product-list/product-list-card';
import QuickViewModal from '../../product-list/quick-view-modal';

type Props = {
  selectedVariant: Variant;
  algoliaConfig: any;
  setIsData: any;
  setCollection: any;
  setIsShowViewAll: any;
  variantName: any;
};
const ShopCollection = ({
  selectedVariant,
  algoliaConfig,
  setIsData,
  setCollection,
  setIsShowViewAll,
  variantName,
}: Props) => {
  const collection = selectedVariant?.attributes?.collection ?? '';
  useEffect(() => {
    if (collection !== '') {
      setCollection(collection);
    }
  }, [collection, setCollection]);
  //next/router
  const router = useRouter();
  //create delete modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openDeleteModal = (e) => {
    e.preventDefault();
    setDeleteModalOpen(true);
  };

  const StopPropagation = (e) => {
    e.preventDefault();
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const searchClient = useAlgoliaSearchClient(algoliaConfig);

  return (
    <>
      {' '}
      {collection && (
        <InstantSearch
          key={'algolia-client'}
          insights={true}
          searchClient={searchClient}
          indexName={algoliaConfig?.indexNameProducts}
        >
          <section className="flex h-full w-full">
            <ProductListItemBlock setIsData={setIsData} setIsShowViewAll={setIsShowViewAll} variantName={variantName} />
          </section>
          <QuickViewModal modalOpen={deleteModalOpen} onClose={closeDeleteModal} />
          <Configure query={collection} distinct hitsPerPage={4} />
        </InstantSearch>
      )}
    </>
  );
};
export default ShopCollection;
