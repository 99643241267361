import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';

const YearEndSaleBanner = ({ data }) => {
  const content = data?.data?.dataSource?.content;
  const isSaleCategory = data?.data?.dataSource?.isSaleCategory;
  const isNewCategory = data?.data?.dataSource?.isNewCategory;
  const isClpPage = data?.data?.dataSource?.isClpPage;
  const [isDesktopSize] = useMediaQuery(desktop);
  const classNamesSize = 'mx-auto my-6 grid  max-w-screen-8xl justify-center px-4 lg:mt-10 lg:mb-8';
  const desktopWidth = 1440;
  const desktopHeight = 197;
  const mobileWidth = 1916;
  const mobileHeight = 454;
  const mobileWidthFmt = 1024;
  return isNewCategory ? (
    <section className={classNamesSize}>
      <Link href={content?.newSalePromoBanner?.imageUrl || '#'}>
        <a className="flex">
          {isDesktopSize ? (
            <Image
              className="mx-auto"
              src={`${content?.newSalePromoBanner?.desktopImageBanner?.url}?fmt=auto&w=${desktopWidth}`}
              loader={({ src }) => src}
              alt={content?.altImage}
              width={desktopWidth}
              height={desktopHeight}
            />
          ) : (
            <Image
              className="mx-auto"
              src={`${content?.newSalePromoBanner?.mobileImageBanner?.url}?fmt=auto&w=${mobileWidthFmt}`}
              alt={content?.altImage}
              width={mobileWidth}
              height={mobileHeight}
              loader={({ src }) => src}
            />
          )}
        </a>
      </Link>
    </section>
  ) : isSaleCategory ? (
    <section className={classNamesSize}>
      <Link href={content?.newCategoryPromoBanner?.imageUrl || '#'}>
        <a className="flex">
          {isDesktopSize ? (
            <Image
              className="mx-auto"
              src={`${content?.newCategoryPromoBanner?.desktopImageBanner?.url}?fmt=auto&w=${desktopWidth}`}
              loader={({ src }) => src}
              alt={content?.altImage}
              width={desktopWidth}
              height={desktopHeight}
            />
          ) : (
            <Image
              className="mx-auto"
              src={`${content?.newCategoryPromoBanner?.mobileImageBanner?.url}?fmt=auto&w=${mobileWidthFmt}`}
              alt={content?.altImage}
              width={mobileWidth}
              height={mobileHeight}
              loader={({ src }) => src}
            />
          )}
        </a>
      </Link>
    </section>
  ) : (
    <section className={classNamesSize}>
      {!isClpPage && (
        <Link href={content?.sitewidePromoBanner?.imageUrl || '#'}>
          <a className="flex">
            {isDesktopSize ? (
              <Image
                className="mx-auto"
                src={`${content?.sitewidePromoBanner?.desktopImageBanner?.url}?fmt=auto&w=${desktopWidth}`}
                alt={content?.altImage}
                width={desktopWidth}
                height={desktopHeight}
                loader={({ src }) => src}
              />
            ) : (
              <Image
                className="mx-auto"
                src={`${content?.sitewidePromoBanner?.mobileImageBanner?.url}?fmt=auto&w=${mobileWidthFmt}`}
                alt={content?.altImage}
                width={mobileWidth}
                height={mobileHeight}
                loader={({ src }) => src}
              />
            )}
          </a>
        </Link>
      )}
    </section>
  );
};

export default YearEndSaleBanner;
