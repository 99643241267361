import * as Yup from 'yup';

const validationSchema = Yup.object({
  creditCardNumber: Yup.string()
    .test('no-spaces', 'Spaces are not allowed inside the Credit card Number.', (value) => {
      return value === undefined || !/\s/.test(value);
    })
    .required('Please enter a valid Credit card Number.')
    .min(11, 'Must be exactly between 11-19 digits')
    .max(19, 'Must be exactly between 11-19 digits'),
  nameOnCard: Yup.string().required('Please enter valid Name.'),
  expirationDate: Yup.string()
    .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, 'Invalid expiration date')
    .required('Please enter correct Card Expiration Date.'),
});
export default validationSchema;
