import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import algoliasearch from 'algoliasearch/lite';
// import isEmpty from 'lodash/isEmpty';
import { createInfiniteHitsSessionStorageCache } from 'instantsearch.js/es/lib/infiniteHitsCache';
import { history } from 'instantsearch.js/es/lib/routers';
import { simple } from 'instantsearch.js/es/lib/stateMappings';
import isEmpty from 'lodash/isEmpty';
import {
  InstantSearch,
  Pagination,
  Configure,
  useStats,
  InstantSearchProps,
  useCurrentRefinements,
  useInfiniteHits,
} from 'react-instantsearch';
import { connectStats } from 'react-instantsearch-dom';
import Breadcrumb from 'components/sol-ui/cms/Breadcrumb';
import Breadcrumbs from 'components/sol-ui/cms/Breadcrumbs';
import { SEO_Variant } from 'helpers/constants/localStorage';
import { convertTitleFromCamelCase } from 'helpers/convertToCamelCase';
import useAlgoliaSearchClient from 'helpers/hooks/useAlgoliaSearchClient';
import { RouteHelpers } from 'helpers/routeHelpers';
import { scrollToTop } from 'helpers/scrollToTop';
import { CLEARANCE_CATEGORY_SLUG_PREFIX, CYBER_SALE_CATEGORY_SLUG_PREFIX } from 'helpers/utils/constant';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { buildPLPFacets } from 'helpers/utils/productUrlBuilder';
import { useConfig } from 'frontastic';
import ProductList from '../product-list';

export interface AlgoliaConfig {
  appAdminKey: string;
  appId: string;
  appSearchKey: string;
  appWriteKey: string;
  indexNameBlogs: string;
  indexNameBlogsNewest: string;
  indexNameCategories: string;
  indexNameProducts: string;
  indexNameMain?: string;
  indexNameTopSellers: string;
  indexNameProductsMain?: string;
  sortAttributes: {
    indexPrefix: string;
  };
}

const RenderStats = () => {
  const router = useRouter();
  const { nbHits } = useStats();

  return (
    <>
      <span>{nbHits}</span> <span className="capitalize">{mapLocaleToMeaningfulFormat(router.locale).items}</span>
    </>
  );
};
const RenderTitleForPage = ({ setSeoVariant }) => {
  const { items } = useCurrentRefinements();
  if (items.length > 1) {
    setSeoVariant('');
  }
  if (items.length == 1) {
    if (items[0].refinements.length > 1) {
      setSeoVariant('');
    } else {
      setSeoVariant(items[0].refinements[0].value);
    }
  }

  return <></>;
};

const Plp = ({
  rawFacetsData,
  data,
  facetsDisplay,
  setShowNoSearchFound,
  hitsPerPage,
  sortBy,
  setIsFiltersApplied,
  canResetFilters,
  setCanResetFilters,
  CustomSortBy,
  isSearchComponent = false,
}) => {
  const router = useRouter();

  const algoliaConfig: AlgoliaConfig = data?.algolia;
  const urlCategory = data?.pathSlug?.replace('/categories/', '');
  const catagories = urlCategory.split('/');
  const isClearanceCategory =
    (router?.query?.path?.includes(CLEARANCE_CATEGORY_SLUG_PREFIX) &&
      !router?.query?.path?.includes(CYBER_SALE_CATEGORY_SLUG_PREFIX)) ||
    false ||
    (router?.asPath?.includes(CLEARANCE_CATEGORY_SLUG_PREFIX) &&
      !router?.asPath?.includes(CYBER_SALE_CATEGORY_SLUG_PREFIX)) ||
    false;

  const [facetRouterQuery, setFacetRouterQuery] = useState('');
  const [currentCat, setCurrentCat] = useState(null);
  const [styleCategoryName, setStyleCategoryName] = useState('');
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const facets = buildPLPFacets(data?.route, urlCategory, isClearanceCategory);
  const [seoVariant, setSeoVariant] = useState('');
  const searchClient = useAlgoliaSearchClient(algoliaConfig);
  const currentPath = router.asPath.split('?').shift();

  const title = `${convertTitleFromCamelCase(catagories.length > 0 ? catagories[catagories.length - 1] : '')}`;

  const tmpSortByOptions = sortBy?.map((item) => {
    return {
      label: item?.optionName,
      value: item?.optionIndex.replace('${env}', algoliaConfig?.sortAttributes?.indexPrefix),
      paramName: item?.paramName,
    };
  });

  useEffect(() => {
    if (urlCategory) {
      localStorage.setItem('currentNav', urlCategory);
    }

    const currentPageNo = localStorage.getItem('pageNo');
    if (currentPageNo) {
      setCurrentPageNo(parseInt(currentPageNo));
    } else {
      localStorage?.setItem('pageNo', '1');
    }
  }, []);

  useEffect(() => {
    if (data) {
      setCurrentCat(data?.categoryData?.breadCrumb[data?.categoryData?.breadCrumb.length - 1]);
    }
  }, [data]);

  const routing = {
    router: history({
      cleanUrlOnDispose: false,
      writeDelay: 400,
      createURL({ qsModule, routeState, location }: any) {
        const routeValues = routeState[algoliaConfig.indexNameProductsMain];
        let queryParameters: any = {};
        if (routeValues && routeValues.refinementList) {
          for (const key in routeValues.refinementList) {
            if (Object.prototype.hasOwnProperty.call(routeValues.refinementList, key)) {
              const refinement = routeValues.refinementList[key];
              const facet = rawFacetsData?.facets?.find((facet) => facet.facetKey === key);
              if (facet) {
                if (facet.paramName) {
                  queryParameters[facet.paramName] = refinement;
                } else {
                  queryParameters[key] = refinement;
                }
              }
            }
          }
        } else {
          queryParameters = {};
        }

        // if (routeValues && routeValues?.page) {
        //   queryParameters['page'] = routeValues?.page;
        // }

        if (routeValues && routeValues.sortBy) {
          const sortByOption = routeValues.sortBy;
          const selectedSortByOptions = tmpSortByOptions?.find((option) => option.value === sortByOption);
          queryParameters['sort'] = selectedSortByOptions?.paramName;
          if (selectedSortByOptions?.paramName) {
            queryParameters['sort'] = selectedSortByOptions.paramName;
          } else {
            queryParameters['sort'] = routeValues.sortBy;
          }
        }

        const queryString = qsModule.stringify(queryParameters, {
          addQueryPrefix: true,
          arrayFormat: 'brackets',
        });

        return window.location.href.includes('categories') ? currentPath + queryString : '';
      },

      parseURL({ qsModule, location }) {
        const queryParams = qsModule.parse(location.search, {
          ignoreQueryPrefix: true,
        });
        const uiState = {};

        // Convert queryParams back into the UI state expected by React InstantSearch
        for (const [key, value] of Object.entries(queryParams)) {
          // Example: Convert back based on your paramName mapping in rawFacetsData
          const facet = rawFacetsData?.facets?.find((facet) => facet.paramName === key || facet.facetKey === key);
          if (facet) {
            const facetKey = facet.facetKey || key;
            if (!uiState[algoliaConfig.indexNameProductsMain]) {
              uiState[algoliaConfig.indexNameProductsMain] = { refinementList: {} };
            }
            uiState[algoliaConfig.indexNameProductsMain].refinementList[facetKey] = Array.isArray(value)
              ? value
              : [value];
          }
        }

        // Handle special parameters like page and sort
        // if (queryParams?.page) {
        //   uiState[algoliaConfig.indexNameProductsMain] = {
        //     ...uiState[algoliaConfig.indexNameProductsMain],
        //     page: queryParams?.page,
        //   };
        // }
        if (queryParams.sort) {
          const sortByOption = queryParams.sort;
          const selectedSortByOptions = tmpSortByOptions?.find((option) => option.paramName === sortByOption);
          uiState[algoliaConfig.indexNameProductsMain] = {
            ...uiState[algoliaConfig.indexNameProductsMain],
            sortBy: selectedSortByOptions?.value,
          };
        }

        return uiState;
      },
    }),
    stateMapping: simple(),
  };

  useEffect(() => {
    const handleRouteChange = (event) => {
      // Prevent default browser behavior
      event.preventDefault();
      if (window.location.href.includes('categories')) window.location.reload();
    };
    window.addEventListener('popstate', handleRouteChange);
  }, []);

  useEffect(() => {
    localStorage?.setItem('plpUrl', router?.asPath);
  }, [router?.asPath]);

  useEffect(() => {
    if (facetRouterQuery) {
      if (localStorage.getItem('pdpRoutePath') !== null) {
        router.replace(localStorage.getItem('plpUrl'), null, { shallow: true });
      } else {
        router.replace(facetRouterQuery);
      }
    }
  }, [facetRouterQuery]);

  useEffect(() => {
    const appliedFiltersCount = RouteHelpers.getFilterCount(router?.asPath, router?.query?.sort, router?.query?.fbclid);
    if (
      appliedFiltersCount === 1 &&
      localStorage.getItem(SEO_Variant) !== 'true' &&
      localStorage.getItem(SEO_Variant) !== 'false'
    ) {
      setSeoVariant(localStorage.getItem(SEO_Variant));
    } else {
      setSeoVariant('');
    }
  }, [router.query, router.asPath]);

  return (
    <>
      <section className="min-h-[100vh]">
        <section className="mx-auto max-w-[1440px]">
          <Breadcrumbs
            createBreadCrumbFromUrlFlag={false}
            breadCrumbsData={data?.categoryData?.breadCrumb}
            isPDP={false}
            productName={null}
          />
        </section>
        <InstantSearch
          insights={true}
          routing={routing}
          searchClient={searchClient}
          indexName={algoliaConfig.indexNameProductsMain}
        >
          <section className="mx-auto my-10 max-w-[1440px] px-5 md:px-10">
            <section className="mb-9">
              <h1 className="mx-auto mb-5 text-center text-2xl font-bold leading-[29px] text-sol-400 after:mx-auto after:mt-6 after:block after:h-[1px] after:w-[150px] after:bg-[#B19A6A] after:content-['']">
                {styleCategoryName ? styleCategoryName : `${seoVariant + ' ' + data?.categoryData?.name}`}
              </h1>
              <p className="text-center text-sm text-sol-300">
                <RenderStats />
                <RenderTitleForPage setSeoVariant={setSeoVariant} />
              </p>
            </section>
            <ProductList
              data={data}
              downrodsContent={data?.downrodsContent}
              facetsDisplay={facetsDisplay}
              algoliaData={algoliaConfig}
              currentCat={currentCat}
              sortByData={sortBy}
              setSeoVariant={setSeoVariant}
              setIsFiltersApplied={setIsFiltersApplied}
              canResetFilters={canResetFilters}
              setCanResetFilters={setCanResetFilters}
              isClearanceCategory={isClearanceCategory}
              CustomSortBy={CustomSortBy}
              setShowNoSearchFound={setShowNoSearchFound}
              isSearchComponent={isSearchComponent}
              setCurrentPageNo={setCurrentPageNo}
            />
          </section>

          <Configure
            facets={['*']}
            filters={facets}
            facetingAfterDistinct={true}
            hitsPerPage={hitsPerPage * currentPageNo}
          />
        </InstantSearch>
      </section>
    </>
  );
};

export default Plp;
