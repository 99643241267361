import { Fragment } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { useDarkMode } from 'frontastic';
import CloseIcon from '../../../icons/sol/close';
import PDP from '../details';
import ImageGalleryCarousel from '../details/image-gallery-carousel';
type actions = 'setAsDefault' | 'delete' | 'removeLineItem';

export interface DeleteModalProps {
  modalOpen?: boolean;
  downrodsContent?: any;
  onClose?: () => void;
  product?: any;
  selectedVariant?: any;
  pdpNavigationLink?: string;
  setNavigationLink?: any;
}

const QuickViewModal: React.FC<DeleteModalProps> = ({
  modalOpen,
  pdpNavigationLink,
  downrodsContent,
  setNavigationLink,
  onClose,
  product,
  selectedVariant,
}) => {
  //next/router
  const router = useRouter();
  //Darkmode
  const { mode, theme } = useDarkMode();

  const selectedLocale = (router.query.locale ? router.query.locale.toString() : 'en-US').replaceAll('_', '-');

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog className={`${mode} ${theme} fixed inset-0 z-[99] overflow-y-auto`} onClose={onClose}>
        <>
          <section className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-left sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <section className="fixed inset-0">
                {/* eslint-disable */}
                <section
                  className="absolute top-1/2 left-1/2 w-[90%] -translate-x-1/2 -translate-y-1/2 bg-white py-6 px-4 dark:bg-primary-200 sm:px-6 md:w-[989px] lg:px-8"
                  onClick={(e) => e.stopPropagation()}
                >
                  <section className="mb-3 flex justify-end">
                    <section className="pt-2">
                      <button type="button" onClick={onClose}>
                        <CloseIcon className="fill-sol-300 " />
                      </button>
                    </section>
                  </section>
                  <section className="customtablescrollbar max-h-[70vh] overflow-y-auto">
                    <PDP
                      isQuickView={true}
                      optionalAccessory={downrodsContent}
                      productId={product?.key ? product?.key : product?.productId}
                      pdpSelectedVariant={selectedVariant}
                      setNavigationLink={setNavigationLink}
                      closePlpQuickViewModal={onClose}
                      pdpNavigationLink={pdpNavigationLink}
                      isYMALEnabled={false}
                      isShopCollectionEnabled={false}
                    />
                    <section className="flex justify-center">
                      <Link href={pdpNavigationLink}>
                        <a className="cursor-pointer text-sm leading-10 text-sol-300 underline">
                          {mapLocaleToMeaningfulFormat(router.locale).viewFullDetails}
                        </a>
                      </Link>
                    </section>
                  </section>
                </section>
              </section>
            </Transition.Child>
          </section>
        </>
      </Dialog>
    </Transition.Root>
  );
};

export default QuickViewModal;
