import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { AMPLIENCE_IMAGE_URL_FORMAT } from 'helpers/utils/constant';
import format from 'helpers/utils/format';
import calculateDynamicImageWidth from 'helpers/utils/getImageSize';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { desktop } from 'helpers/utils/screensizes';

const BlogCategoryComponent = ({ category, blogs, tags }) => {
  const [isDesktop] = useMediaQuery(desktop);
  const router = useRouter();
  const [currentTag, setCurrentTag] = useState(null);
  const [filteredBlogs, setFilteredBlogs] = useState(blogs || []);
  const dynamicWidth = calculateDynamicImageWidth(300, 400, 500);
  useEffect(() => {
    setFilteredBlogs(blogs);
    const path = router?.query?.slug;
    if (path?.length === 4) {
      const result = blogs?.filter((blog) => blog?.tag?.title == path[3]);
      setFilteredBlogs(result);
      setCurrentTag(path[3]);
    }
  }, [router?.query]);

  const queryTag = (tagName) => {
    if (tagName == currentTag) {
      setCurrentTag(null);
      setFilteredBlogs(blogs);
    } else {
      router.push(`blogs/${category?.slug?.toLowerCase().replaceAll(' ', '-')}/tagged/${tagName}`);
    }
  };

  return (
    <section className="mx-auto grid max-w-5xl grid-cols-1 gap-7 px-4 md:grid-cols-4">
      <section className="col-span-3 mx-auto max-w-5xl">
        <section className="mx-auto mb-12 flex">
          <div className="lg:px-auto flex flex-col leading-snug md:flex-row">
            <p className="px-4 pb-8 text-left text-sm font-normal leading-5 text-sol-300">{category?.description}</p>
          </div>
        </section>

        <section className="mx-auto mb-12 flex">
          <div className="lg:px-auto flex flex-col leading-snug md:flex-row">
            <div className="wrapper mb-4 leading-snug">
              <ul className="grid gap-7 divide-y divide-sol-600">
                {filteredBlogs?.map((blog, index) => (
                  <>
                    {blog?.ctaLink ? (
                      <li key={index} className="pt-7 first:pt-0">
                        <Link key={index} href={blog?.ctaLink}>
                          <a>
                            <section className="flex">
                              <section className="mr-5 w-28 shrink-0 md:w-52">
                                <Image
                                  loader={(options) => options.src}
                                  src={format(AMPLIENCE_IMAGE_URL_FORMAT, [blog?.image?.url, dynamicWidth])}
                                  alt={blog?.alt}
                                  width={210}
                                  height={159}
                                  sizes="100vw"
                                  className="rounded-md"
                                />
                              </section>
                              <section>
                                <h4 className="mb-3 text-base font-semibold text-sol-300 ">{blog?.title}</h4>
                                <p className="text-sm font-normal leading-5 text-sol-300">{blog?.shortDescription}</p>
                                <div className="mt-3 flex items-center">
                                  <div className="text-sm">
                                    <p className="z-50 text-sm font-normal leading-5 text-sol-300">
                                      {mapLocaleToMeaningfulFormat(router.locale).tags}{' '}
                                      <Link
                                        href={`blogs/${category?.slug?.toLowerCase().replaceAll(' ', '-')}/tagged/${
                                          blog?.tag?.title
                                        }`}
                                      >
                                        <span className="underline">{blog?.tag?.title}</span>
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </section>
                            </section>
                          </a>
                        </Link>
                      </li>
                    ) : (
                      <li key={index} className="flex">
                        <section className="mr-5 w-28 shrink-0 md:w-52">
                          <Image
                            loader={(options) => options.src}
                            src={format(AMPLIENCE_IMAGE_URL_FORMAT, [blog?.image?.url, dynamicWidth])}
                            alt={blog?.alt}
                            width={210}
                            height={159}
                            sizes="100vw"
                            className="rounded-md"
                          />
                        </section>
                        <section>
                          <h4 className="mb-3 text-base font-semibold text-sol-300 ">{blog?.title}</h4>
                          <p className="text-sm font-normal leading-5 text-sol-300">{blog?.shortDescription}</p>
                          <div className="mt-3 flex items-center">
                            <div className="text-sm">
                              <p className="text-sm font-normal leading-5 text-sol-300">
                                {mapLocaleToMeaningfulFormat(router.locale).tags}{' '}
                                <span className="underline">{blog?.tag?.title}</span>
                              </p>
                            </div>
                          </div>
                        </section>
                      </li>
                    )}
                  </>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </section>

      <section className="mt-5">
        <section className="mx-auto max-w-screen-8xl border-t-[1px] border-sol-600 pt-10 lg:border-none">
          <h3 className="text-3xl font-semibold text-gray-700 md:text-2xl lg:text-3xl">
            {mapLocaleToMeaningfulFormat(router.locale).popularTags}
          </h3>
          <section className="my-8 flex">
            <ul>
              {Object.keys(tags)?.map((tag, index) => (
                <li
                  onClick={() => queryTag(tag)}
                  key={index}
                  className={`cursor-pointer p-2 leading-5 ${currentTag == tag && 'bg-gray-200'}`}
                >
                  {tag} ({tags[tag]})
                </li>
              ))}
            </ul>
          </section>
        </section>
      </section>
    </section>
  );
};

export default BlogCategoryComponent;
