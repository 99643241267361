import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Address as AddressType } from '@Types/account/Address';
import { Country, State } from '@Types/account/Country';
import { Field, Form } from 'formik';
import { get } from 'lodash-es';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import { DEFAULT_COUNTRY, DEFAULT_STATE, EXTENSIONMASK, PHONEMASK } from 'helpers/utils/constant';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { useAccount } from 'frontastic';

interface AddressFormProps {
  type: string;
  formik: any;
  addressInitialValues: AddressType;
  countries: Country[];
}

const Input = styled(InputMask)``;

const AddressForm: React.FC<AddressFormProps> = ({ type, formik, countries, addressInitialValues }) => {
  const router = useRouter();

  useEffect(() => {
    if (countries) {
      const selectedCountry = formik.values[type].country ?? undefined;
      const selectedState = formik.values[type].state;
      const countryCode = selectedCountry !== '' ? selectedCountry : DEFAULT_COUNTRY;
      if (countryCode) {
        const states = handleCountrySelect(countryCode) as State[];
        formik.setFieldValue('states', states);
        if (selectedState === '' && states) {
          formik.setFieldValue(`${type}.state`, DEFAULT_STATE);
        }
        if (!selectedCountry) {
          formik.setFieldValue(`${type}.country`, DEFAULT_COUNTRY);
        }
      }
    }
  }, [countries]);

  const handleCountrySelect = (countryCode: string) => {
    if (countryCode) {
      return countries?.find((country) => country.countryCode === countryCode)?.states;
    }
    return [];
  };
  const handleDefaultState = (countryCode) => {
    const states = countries?.find((country) => country.countryCode === countryCode)?.states;
    if (states) {
      return states[0].stateCode;
    }
    return '';
  };

  return (
    <section className="flex flex-col gap-5">
      <section className="grid gap-y-5 gap-x-10 lg:grid-cols-2">
        <section className="w-full">
          <label
            htmlFor={`${type}.firstName`}
            className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
          >
            {mapLocaleToMeaningfulFormat(router.locale).firstName}
          </label>
          <Field
            type="text"
            name={`${type}.firstName`}
            onBlur={formik?.handleBlur}
            className={`input-text-primary mt-3 !w-full 
            ${
              formik?.errors?.[`${type}`]?.firstName && formik?.touched?.[`${type}`]?.firstName ? '!border-red-600' : ''
            }`}
          />
          {formik?.errors?.[`${type}`]?.firstName && formik?.touched?.[`${type}`]?.firstName && (
            <p
              className="text-sm font-normal leading-[17px] 
          text-[#DA0F0F]"
            >
              {formik?.errors?.[`${type}`]?.firstName}
            </p>
          )}
        </section>
        <section className="w-full">
          <label
            htmlFor={`${type}.lastName`}
            className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
          >
            {mapLocaleToMeaningfulFormat(router.locale).lastName}
          </label>
          <Field
            type="text"
            name={`${type}.lastName`}
            onBlur={formik?.handleBlur}
            className={`input-text-primary mt-3 !w-full ${
              formik?.errors?.[`${type}`]?.lastName && formik?.touched?.[`${type}`]?.lastName ? '!border-red-600' : ''
            }`}
          />
          {formik?.errors?.[`${type}`]?.lastName && formik?.touched?.[`${type}`]?.lastName && (
            <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{formik?.errors?.[`${type}`]?.lastName}</p>
          )}
        </section>
      </section>
      <section className="w-full">
        <label htmlFor={`${type}.company`} className="text-sm font-semibold leading-[17px] text-sol-300">
          {mapLocaleToMeaningfulFormat(router.locale).company}
        </label>
        <Field
          type="text"
          name={`${type}.company`}
          onBlur={formik?.handleBlur}
          className={`input-text-primary mt-3 block !w-full`}
        />
      </section>
      <section className="w-full">
        <section className="flex  flex-wrap-reverse items-center justify-between">
          <label
            htmlFor={`${type}.streetName`}
            className="requiredLabel whitespace-nowrap text-sm font-semibold leading-[17px] text-sol-300"
          >
            {mapLocaleToMeaningfulFormat(router.locale).streetName}
          </label>
          <p className="mb-1 whitespace-nowrap text-sm font-normal leading-[17px] text-sol-300">
            {' '}
            {mapLocaleToMeaningfulFormat(router.locale).weCannotShip}
          </p>
        </section>
        <Field
          type="text"
          name={`${type}.streetName`}
          onBlur={formik?.handleBlur}
          className={`input-text-primary mt-3 !w-full ${
            formik?.errors?.[`${type}`]?.streetName && formik?.touched?.[`${type}`]?.streetName ? '!border-red-600' : ''
          }`}
        />
        {formik?.errors?.[`${type}`]?.streetName && formik?.touched?.[`${type}`]?.streetName && (
          <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{formik?.errors?.[`${type}`]?.streetName}</p>
        )}
      </section>
      <section className="w-full">
        <label htmlFor={`${type}.streetNumber`} className="text-sm font-semibold leading-[17px] text-sol-300">
          {mapLocaleToMeaningfulFormat(router.locale).streetNumber}
        </label>
        <Field type="text" name={`${type}.streetNumber`} className={`input-text-primary mt-3 !w-full`} />
      </section>

      <section className="grid gap-5 lg:grid-cols-2 lg:gap-10">
        <section>
          <label htmlFor={`${type}.city`} className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300">
            {mapLocaleToMeaningfulFormat(router.locale).city}
          </label>
          <Field
            type="text"
            name={`${type}.city`}
            onBlur={formik?.handleBlur}
            className={`input-text-primary mt-3 !w-full ${
              formik?.errors?.[`${type}`]?.city && formik?.touched?.[`${type}`]?.city ? '!border-red-600' : ''
            }`}
          />
          {formik?.errors?.[`${type}`]?.city && formik?.touched?.[`${type}`]?.city && (
            <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{formik?.errors?.[`${type}`]?.city}</p>
          )}
        </section>
        <section className="w-full">
          <label htmlFor={`${type}.state`} className="text-sm font-semibold leading-[17px] ">
            {mapLocaleToMeaningfulFormat(router.locale).stateRegion}
          </label>
          <Field
            value={formik?.values?.[`${type}`]?.state ?? addressInitialValues?.state}
            id="state"
            name={`${type}.state`}
            as="select"
            onChange={formik.handleChange}
            className="input-text-primary mt-3 !w-full"
          >
            {formik?.values?.states &&
              formik?.values?.states.map((state) => (
                <option key={state?.stateCode} value={state?.stateCode}>
                  {state?.state}
                </option>
              ))}
          </Field>
        </section>
      </section>
      <section className="grid gap-5 lg:grid-cols-2 lg:gap-10">
        <section className="w-full">
          <label
            htmlFor={`${type}.postalCode`}
            className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
          >
            {mapLocaleToMeaningfulFormat(router.locale).postalCode}
          </label>
          <Field
            type="text"
            name={`${type}.postalCode`}
            onBlur={formik?.handleBlur}
            className={`input-text-primary mt-3 !w-full ${
              formik?.errors?.[`${type}`]?.postalCode && formik?.touched?.[`${type}`]?.postalCode
                ? '!border-red-600'
                : ''
            }`}
          />
          {formik?.errors?.[`${type}`]?.postalCode && formik?.touched?.[`${type}`]?.postalCode && (
            <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
              {formik?.errors?.[`${type}`]?.postalCode}
            </p>
          )}
        </section>
        <section>
          <label
            htmlFor={`${type}.country`}
            className="block h-5 text-sm font-semibold leading-[17px] text-sol-300 lg:h-6"
          >
            {' '}
            {mapLocaleToMeaningfulFormat(router.locale).country}
          </label>

          <Field
            id="country"
            name={`${type}.country`}
            as="select"
            className="input-text-primary mt-3 !w-full"
            value={formik?.values?.[`${type}`]?.country ?? addressInitialValues?.country}
            onChange={async (e) => {
              const { value } = e.target;
              const _states = handleCountrySelect(value);
              if (_states) {
                formik.setFieldValue(`${type}.country`, value);
                formik.setFieldValue(`${type}.state`, handleDefaultState(value));
                formik.setFieldValue('states', _states);
              }
            }}
          >
            {countries &&
              countries
                ?.filter((country) => (type === 'shippingAddress' ? country?.countryCode !== 'CA' : true))
                ?.map((country, index) => (
                  <option key={index} value={country?.countryCode}>
                    {country?.country}
                  </option>
                ))}
          </Field>
        </section>
      </section>
      <section className="flex gap-x-4 lg:grid lg:grid-cols-2 lg:gap-x-10">
        <section className="w-full">
          <label htmlFor={`${type}.phone`} className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300">
            {mapLocaleToMeaningfulFormat(router.locale).phoneNumber}
          </label>
          <Field
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            value={formik?.values?.[`${type}`]?.phone ?? addressInitialValues?.phone}
          >
            {({ form: { errors, touched } }) => (
              <Input
                type="text"
                name={`${type}.phone`}
                mask={PHONEMASK}
                maskChar={null}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                value={formik?.values?.[`${type}`]?.phone ?? addressInitialValues?.phone}
                className={`input-text-primary mt-3 !w-full ${
                  errors?.[`${type}`]?.phone && touched?.[`${type}`]?.phone ? '!border-red-600' : ''
                }`}
              />
            )}
          </Field>
          {formik?.errors?.[`${type}`]?.phone && formik?.touched?.[`${type}`]?.phone && (
            <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{formik?.errors?.[`${type}`]?.phone}</p>
          )}
        </section>
        <section className="w-[115px]">
          <label htmlFor={`${type}.extension`} className="text-sm font-semibold leading-[17px] text-sol-300">
            {mapLocaleToMeaningfulFormat(router.locale).extension}
          </label>
          <Field>
            {({ form: { errors, touched } }) => (
              <Input
                type="text"
                mask={EXTENSIONMASK}
                maskChar={null}
                name={`${type}.extension`}
                onBlur={formik?.handleBlur}
                onChange={formik?.handleChange}
                value={formik?.values?.[`${type}`]?.extension ?? addressInitialValues?.extension}
                className={`input-text-primary mt-3 !w-full ${
                  errors?.[`${type}`]?.extension && touched?.[`${type}`]?.extension ? '!border-red-600' : ''
                }`}
              />
            )}
          </Field>
        </section>
      </section>
    </section>
  );
};

export default AddressForm;
