import { Address } from '@Types/account/Address';
import { StringHelpers } from 'helpers/stringHelpers';

export class AddressHelpers {
  static formatAddress = (address: Address, isCheckout: boolean) => {
    if (address === undefined) {
      return '';
    }
    if (isCheckout) {
      const fullName = `${address?.firstName} ${address?.lastName} </br>`;
      const company = address?.company ? `${address?.company}, ` : '';
      const streetNumber = `${address?.streetNumber}`;
      const streetName = `${address?.streetName}`;
      const addressPhone =
        address?.phone?.length > 10 && !address?.phone?.includes(' ')
          ? `${address?.phone?.slice(0, -10)} ${address?.phone?.slice(-10)}`
          : address?.phone;
      const addressLines =
        streetName === '' || streetName === undefined ? `${streetNumber}` : `${streetName} ${streetNumber}`;
      const phone = `${
        StringHelpers.phoneDisplayFormat(
          address?.extension ? StringHelpers.phoneExtensionMerger(address)?.phone : addressPhone,
        ) ?? ''
      } </br>`;
      const formatAddress = [
        `${fullName} ${company} ${company && '</br>'} ${addressLines} </br> ${address?.city} ${address?.state}  ${
          address?.postalCode
        } </br> ${address?.country} </br> ${StringHelpers.phoneDisplayFormat(
          address?.extension ? StringHelpers.phoneExtensionMerger(address)?.phone : addressPhone,
        )}`,
      ];
      return formatAddress;
    } else {
      const fullName = `${address?.firstName} ${address?.lastName}, `;
      const company = address?.company ? `${address?.company}, ` : '';
      const addressPhone =
        address?.phone?.length > 10 && !address?.phone?.includes(' ')
          ? `${address?.phone?.slice(0, -10)} ${address?.phone?.slice(-10)}`
          : address?.phone;
      const streetNumber = address?.streetNumber ? `${address?.streetNumber}  ,` : '';
      const streetName = address?.streetName ? `${address?.streetName}` : '';
      const addressLines =
        streetName === '' || streetName === undefined ? `${streetNumber}` : ` ${streetName} ${streetNumber}`;
      const formatAddress = [
        `${fullName} ${company} ${addressLines} ${address?.city} ${address?.state}  ${address?.postalCode} , ${
          address?.country
        } , ${
          StringHelpers.phoneDisplayFormat(
            address?.extension ? StringHelpers.phoneExtensionMerger(address)?.phone : addressPhone,
          ) || ''
        }`,
      ];
      return formatAddress;
    }
  };
}
