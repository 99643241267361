import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import algoliasearch from 'algoliasearch/lite';
import _ from 'lodash';
import CloseIcon from 'components/icons/sol/close';
import SearchIcon from 'components/icons/sol/search';
import { debounce } from 'helpers/debounce';
import { setAlgoliaQueryID } from 'helpers/hooks/algoliaEvents/algoliaUtils';
import useOnClickOutside from 'helpers/hooks/useOnClickOutside';
import { StringHelpers } from 'helpers/stringHelpers';
import { routerURL } from 'helpers/utils/routerUrl';
import { useConfig } from 'frontastic';
import SOLHeaderDesktopSearch from './header-desktop-search';

const SearchBarComponent = ({
  categories,
  searchRelatedProdutsCount,
  searchSuggestedSearchesCount,
  searchContentCount,
}) => {
  const router = useRouter();
  const { data: config } = useConfig();
  const [searchClient, setSearchClient] = useState(null);
  const searchInputRef = useRef(null);
  const SOLHeaderDesktopSearchRef = useRef(null);
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState({
    products: [],
    blogs: [],
    categories: [],
  });
  const [productRedirectionLink, setProductRedirectionLink] = useState('');

  useOnClickOutside(SOLHeaderDesktopSearchRef, () => setShowSearchPopup(false), searchInputRef);

  useEffect(() => {
    if (config?.algolia && !searchClient) {
      const client = algoliasearch(config?.algolia?.appId, config?.algolia?.appSearchKey);
      setSearchClient(client);
    }
  }, [config?.algolia, searchClient]);

  useEffect(() => {
    if (!showSearchPopup) searchInputRef.current.value = '';
  }, [showSearchPopup]);

  const handleSearchChange = debounce(async (e) => {
    const searchQuery = e.target.value;

    if (searchQuery.startsWith(' ')) {
      e.target.value = searchQuery.trimStart();
    }
    setSearchInput(e.target.value);

    if (_.isEmpty(searchQuery)) {
      setSearchInput('');
      setShowSearchPopup(false);
      setSearchResults({
        products: [],
        blogs: [],
        categories: [],
      });
      return;
    }
    if (searchQuery.length > 0) {
      if (searchQuery.length >= 3) {
        handleSearchAutoComplete(searchQuery);
        setShowSearchPopup(true);
      }
      setSearchInput(searchQuery);
    } else {
      setShowSearchPopup(false);
      setSearchResults({
        products: [],
        blogs: [],
        categories: [],
      });
    }
  }, 500);

  const handleRouteChange = () => {
    localStorage.setItem('pageNo', '1');
    localStorage.setItem('scrollPosition', '0');
  };

  const handleSearchPopupClose = () => {
    setShowSearchPopup(false);
  };

  const getAutoCompleteResults = useCallback(
    async (key, indexValue, hitCounts = 5) => {
      const index = searchClient?.initIndex(indexValue);
      const { hits } = await index?.search(key, { hitsPerPage: hitCounts });
      return hits;
    },
    [searchClient],
  );

  const handleSearchAutoComplete = debounce(async (searchQuery) => {
    const queries = [
      {
        indexName: config?.algolia?.indexNameTopSellers,
        query: searchQuery,
        params: {
          hitsPerPage: searchRelatedProdutsCount,
          clickAnalytics: true,
        },
      },
      {
        indexName: config?.algolia?.indexNameBlogs,
        query: searchQuery,
        params: {
          hitsPerPage: searchContentCount,
        },
      },
      {
        indexName: config?.algolia?.indexNameCategories,
        query: searchQuery,
        params: {
          hitsPerPage: searchSuggestedSearchesCount,
        },
      },
    ];

    const responses = await searchClient?.multipleQueries(queries);
    setAlgoliaQueryID(responses?.results?.[0]?.queryID);

    setSearchResults({
      products: responses?.results[0]?.hits,
      blogs: responses?.results[1]?.hits,
      categories: responses?.results[2]?.hits,
    });
  }, 500);

  const handleKeyPress = debounce(async (event) => {
    const inputValue = searchInput.trim();
    if ((event?.keyCode === 13 || event?.type === 'click') && inputValue !== '') {
      if (productRedirectionLink !== '') {
        router.push(productRedirectionLink);
      } else {
        handleRouteChange();
        handleSearchPopupClose();
        // router.push(`${routerURL.search}${inputValue}`);
        window.location.href = `${routerURL.search}${inputValue}`;
      }
    }
  }, 500);

  return (
    <section className="relative top-[6px] flex items-center">
      <button
        type="button"
        onClick={handleKeyPress}
        id="searchBtn"
        aria-label="search keyword"
        className="absolute top-[12px] left-6"
      >
        <SearchIcon className="h-4 w-4" aria-label="search icon" />
      </button>
      <label htmlFor="headerSearch">
        <input
          id="headerSearch"
          ref={searchInputRef}
          type="text"
          autoComplete="off"
          onChange={handleSearchChange}
          onKeyDown={handleKeyPress}
          className="ml-2 h-10 w-[267px] rounded-md border-0 border-transparent bg-transparent py-2 pl-[50px] pr-9 placeholder:text-gray-400 focus:border-none focus:outline-none focus:ring-0 sm:bg-white"
          placeholder="Search"
          aria-label="search submit"
        />
      </label>
      {showSearchPopup && (
        <button
          type="button"
          id="clearSearchInput"
          onClick={handleSearchPopupClose}
          className="absolute top-[15px] right-4 cursor-pointer"
        >
          <CloseIcon className="h-[10px] w-[10px] fill-sol-300" aria-label="search clear icon" />
        </button>
      )}
      {showSearchPopup && (
        <section ref={SOLHeaderDesktopSearchRef} className="absolute top-[40px] left-[7px] z-20">
          <SOLHeaderDesktopSearch
            productsAutoComplete={searchResults.products}
            blogsAutoComplete={searchResults.blogs}
            categoriesAutoComplete={searchResults.categories}
            handleSearchPopupClose={() => handleSearchPopupClose()}
            data={searchInput}
            categories={categories}
            setProductRedirectionLink={setProductRedirectionLink}
          />
        </section>
      )}
    </section>
  );
};

export default SearchBarComponent;
