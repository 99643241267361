import React from 'react';

const Downrods = ({ optionalAccessory }) => {
  return (
    <section className="mx-auto mt-10 max-w-screen-8xl px-5">
      <section className="flex flex-col  items-center justify-center px-4 pt-4 pb-20 text-left sm:block sm:p-0">
        <section className="flex flex-wrap items-start justify-center gap-6 text-xs text-sol-300 md:grid-cols-2">
          {optionalAccessory &&
            optionalAccessory?.data &&
            optionalAccessory?.data?.map((data, index) => (
              <section key={index}>
                <table className="w-full">
                  <thead className="text-xs">
                    <tr className="bg-sol-100">
                      <th colSpan={2} className="p-2 text-center font-normal">
                        {data?.heading}
                      </th>
                    </tr>
                    <tr className="bg-white">
                      <th className="p-2 text-center font-semibold">{data?.head?.column1}</th>
                      <th className="p-2 text-center font-semibold">{data?.head?.column2}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.rows?.map((row, index) => (
                      <tr key={index} className="bg-white odd:bg-sol-100">
                        <td className="p-2 text-center">{row?.row?.column1}</td>
                        <td className="p-2 text-center">{row?.row?.column2}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>
            ))}
        </section>
        {optionalAccessory && (
          <section className="mt-5 text-center text-xs">
            <p className="mb-3">{optionalAccessory?.tip}</p>
            <a
              className="text-xs font-[500] leading-5 hover:underline"
              target="_blank"
              href={optionalAccessory?.ctaLink}
              rel="noreferrer"
            >
              {optionalAccessory?.ctaText}
            </a>
          </section>
        )}
      </section>
    </section>
  );
};

export default Downrods;
