import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { getAlignment, isImage, isVideo } from 'helpers/utils/banner';
import { desktop } from 'helpers/utils/screensizes';

export const ClpBanner = ({ content }) => {
  const [isDesktop] = useMediaQuery(desktop);

  const formatPosterUrl = (media) => {
    return `https://${media?.defaultHost}/v/${media?.endpoint}/${media?.name}`;
  };

  const formatVideoUrl = (media) => {
    return `https://${media?.defaultHost}/v/${media?.endpoint}/${media?.name}/mp4_720p`;
  };

  return (
    <section className="relative mx-auto mb-8 mt-6 flex items-center justify-center lg:mt-0">
      <ul className="h-[450px] w-full">
        <Link href={content?.clpCtaLink}>
          <li className="h-[450px] w-full">
            {isImage(content?.clpBannerDesktopMedia) && (
              <Image
                loader={({ src }) => src}
                src={isDesktop ? content?.clpBannerDesktopMedia?.url : content?.clpBannerMobileMedia?.url}
                width="0"
                height="0"
                layout="fill"
                className="rounded-md object-cover"
                alt={content?.clpAlt}
              />
            )}
            {isVideo(content?.clpBannerDesktopMedia) && (
              <video
                className="h-[450px] w-full content-center object-cover"
                autoPlay
                loop
                poster={
                  isDesktop
                    ? formatPosterUrl(content?.clpBannerDesktopMedia)
                    : formatPosterUrl(content?.clpBannerMobileMedia)
                }
                src={
                  isDesktop
                    ? formatVideoUrl(content?.clpBannerDesktopMedia)
                    : formatVideoUrl(content?.clpBannerMobileMedia)
                }
              ></video>
            )}
          </li>
        </Link>
      </ul>
      {content?.bannerHeading && (
        <div
          className={`absolute flex h-[450px] w-full ${
            isDesktop ? getAlignment(content?.bannerAlignment) : 'items-center justify-center'
          } `}
        >
          <div className="m-12 flex flex-col items-center justify-center gap-3 bg-[rgb(0,0,0,0.4)] p-10 text-white">
            <p className="font-bold">{content?.bannerSubHeading}</p>
            <p className="text-3xl">{content?.bannerHeading}</p>
            <Link href={content?.bannerCtaLink}>
              <p className="cursor-pointer bg-[rgb(60,60,60)] p-4 hover:bg-black-100">{content?.bannerCtaLinkText}</p>
            </Link>
          </div>
        </div>
      )}
    </section>
  );
};
