import { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Card } from '@Types/account/AuthorizeNetAccount';
import { Order } from '@Types/cart/Order';
import BackIcon from 'components/icons/sol/backIcon';
import Breadcrumb from 'components/sol-ui/cms/Breadcrumb';
import CartSummary from 'components/sol-ui/order-summary';
import { DateHelpers } from 'helpers/dateHelpers';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { StringHelpers } from 'helpers/stringHelpers';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { desktop } from 'helpers/utils/screensizes';
import { useAuthorizeNet } from 'frontastic';
import AccountNav from '../account-nav';
import CardIcon from '../credit-card/card-icon';
import OrderDetailsList from './orderDetailsList';
const OrderDetails = ({ data }) => {
  const order = data?.data?.dataSource?.order as Order;
  const [creditCard, setCreditCard] = useState<Card>(undefined);
  const { getTransactionDetails } = useAuthorizeNet();
  //next/router
  const router = useRouter();
  const [isDesktop] = useMediaQuery(desktop);
  useEffect(() => {
    if (!creditCard) {
      getTransactionDetails({ transactionId: order?.paymentMethod?.transactionId }).then((response) => {
        setCreditCard(response?.payment?.creditCard ?? undefined);
      });
    }
  }, []);
  const shippingPhone =
    order?.shippingAddress?.phone?.length > 10 && !order?.shippingAddress?.phone?.includes(' ')
      ? `${order?.shippingAddress?.phone?.slice(0, -10)} ${order?.shippingAddress?.phone?.slice(-10)}`
      : order?.shippingAddress?.phone;

  const billingPhone =
    order?.billingAddress?.phone?.length > 10 && !order?.billingAddress?.phone?.includes(' ')
      ? `${order?.billingAddress?.phone?.slice(0, -10)} ${order?.billingAddress?.phone?.slice(-10)}`
      : order?.billingAddress?.phone;
  return (
    <>
      <section className="mx-auto max-w-screen-8xl">
        <Breadcrumb breadcrumLevelLink={false} activePage={mapLocaleToMeaningfulFormat(router.locale).orderDetails} />
      </section>
      {!isDesktop && (
        <section className="p-4 lg:mt-[30px] xl:mt-[30px]">
          {/* backOrderHistory  */}
          {/*<section className="mb-4 flex items-center gap-1 p-2">
            <BackIcon className="fill-textsol-300" />
            <Link href={routerURL?.orderHistory}>
              <a className="text-sm font-medium leading-[17px] text-sol-300">
                {mapLocaleToMeaningfulFormat(router.locale).backOrderHistory}
              </a>
            </Link>
          </section> */}
          <section>
            <AccountNav />
          </section>
        </section>
      )}
      {order && (
        <section className="mt-10 mb-[74px]">
          <section className="mx-auto max-w-screen-8xl">
            <h1 className="mx-auto mb-4 w-max text-center text-[28px] font-semibold leading-[34px] text-[#1B3144] after:mx-auto after:mt-6 after:block after:h-[1px] after:w-[150px] after:bg-[#B19A6A] after:content-['']">
              {mapLocaleToMeaningfulFormat(router.locale).orderDetails}
            </h1>
            <ul className="mt-4 mb-6 flex flex-col flex-wrap items-center gap-5 px-5 lg:mb-0 lg:flex-row lg:justify-center lg:gap-[30px]">
              <li>
                <p className="flex gap-1 text-sm leading-5 text-sol-300">
                  {mapLocaleToMeaningfulFormat(router.locale).orderNumber}:
                  <p className="text-sm uppercase leading-5 text-sol-300">{order?.orderNumber}</p>
                </p>
              </li>
              <li className="text-center">
                <p className="text-sm leading-5 text-sol-300">
                  {mapLocaleToMeaningfulFormat(router.locale).orderDate}:{' '}
                  {DateHelpers.solFormatDate(order?.createdAt, router)}
                </p>
              </li>
              <li className="text-center">
                <p className="text-sm leading-5 text-sol-300">
                  {mapLocaleToMeaningfulFormat(router.locale).status}: {order?.orderState}
                </p>
              </li>
            </ul>
          </section>
          <section className="mx-auto max-w-[1440px] px-5 lg:flex lg:gap-10 xl:gap-2">
            {isDesktop && (
              <section className="lg:mt-[30px] xl:mt-[30px]">
                <section className="mb-4 flex items-center gap-1">
                  <BackIcon className="fill-textsol-300" />
                  <Link href={routerURL?.orderHistory}>
                    <a className="text-sm font-medium leading-[17px] text-sol-300">
                      {mapLocaleToMeaningfulFormat(router.locale).backOrderHistory}
                    </a>
                  </Link>
                </section>
                <section>
                  <AccountNav />
                </section>
              </section>
            )}
            <section className="mx-auto w-full xl:w-[1132px]">
              <section className="mt-16 flex flex-col gap-6 xl:flex-row xl:gap-10">
                <section className="w-full">
                  <ul className="grid gap-6">
                    <OrderDetailsList order={order} />
                  </ul>
                  <section className="mt-6 rounded-[6px] border border-slate-300 p-5">
                    <h4 className="text-[18px] font-semibold leading-[22px] text-sol-300">
                      {mapLocaleToMeaningfulFormat(router.locale).addresses}
                    </h4>
                    <section className="mt-7 grid gap-5 md:grid-cols-5">
                      <section className="md:col-span-2">
                        <h4 className="mb-2 text-sm font-semibold leading-[21px] text-sol-300">
                          {mapLocaleToMeaningfulFormat(router.locale).shippingAddress}
                        </h4>
                        <p className="mb-2 text-sm leading-[21px] text-sol-300">
                          {order?.shippingAddress?.firstName} {order?.shippingAddress?.lastName}
                        </p>
                        <p className="mb-2 text-sm leading-[21px] text-sol-300">
                          {order?.shippingAddress?.company} {order?.shippingAddress?.streetNumber}{' '}
                          {order?.shippingAddress?.streetName}
                        </p>
                        <p className="mb-2 text-sm leading-[21px] text-sol-300">
                          {order?.shippingAddress?.city}, {order?.shippingAddress?.state}{' '}
                          {order?.shippingAddress?.postalCode}
                        </p>
                        <p className="mb-2 text-sm leading-[21px] text-sol-300">{order?.shippingAddress?.country}</p>
                        <p className="mb-2 text-sm leading-[21px] text-sol-300">
                          {StringHelpers.phoneDisplayFormat(
                            order?.shippingAddress?.extension
                              ? StringHelpers.phoneExtensionMerger(order?.shippingAddress)?.phone
                              : shippingPhone,
                          )}
                        </p>
                        <h4 className="mt-7 text-sm font-semibold leading-[21px] text-sol-300">
                          {mapLocaleToMeaningfulFormat(router.locale).email}
                        </h4>
                        <p className="mb-2 text-sm leading-[21px] text-sol-300">{order?.email}</p>
                      </section>
                      <section className="md:col-span-3">
                        <h4 className="mb-2 text-sm font-semibold leading-[21px] text-sol-300">
                          {mapLocaleToMeaningfulFormat(router.locale).billingAddress}
                        </h4>
                        <p className="mb-2 text-sm leading-[21px] text-sol-300">
                          {order?.billingAddress?.firstName} {order?.billingAddress?.lastName}
                        </p>
                        <p className="mb-2 text-sm leading-[21px] text-sol-300">
                          {order?.billingAddress?.company} {order?.billingAddress?.streetNumber}{' '}
                          {order?.billingAddress?.streetName}
                        </p>
                        <p className="mb-2 text-sm leading-[21px] text-sol-300">
                          {order?.billingAddress?.city}, {order?.billingAddress?.state}{' '}
                          {order?.billingAddress?.postalCode}
                        </p>
                        <p className="mb-2 text-sm leading-[21px] text-sol-300">{order?.billingAddress?.country}</p>
                        <p className="mb-2 text-sm leading-[21px] text-sol-300">
                          {StringHelpers.phoneDisplayFormat(
                            order?.billingAddress?.extension
                              ? StringHelpers.phoneExtensionMerger(order?.billingAddress)?.phone
                              : billingPhone,
                          )}
                        </p>
                      </section>
                    </section>
                  </section>
                  <section className="mt-6 rounded-[6px] border border-slate-300 p-5">
                    <h4 className="mb-4 text-[18px] font-semibold leading-[22px] text-sol-300">
                      {mapLocaleToMeaningfulFormat(router.locale).paymentMethod}
                    </h4>
                    <h4 className="text-sm font-semibold leading-[21px] text-sol-300">
                      {mapLocaleToMeaningfulFormat(router.locale).creditCard}
                    </h4>
                    <section className="mt-4 flex items-center gap-4">
                      <CardIcon cardType={creditCard?.cardType || 'default'} />
                      <p className="text-sm leading-[21px] text-sol-300">
                        {StringHelpers.creditCardNumberFormatter(creditCard?.cardNumber, router)}
                      </p>
                    </section>
                  </section>
                </section>
                <section className="shrink-0">
                  <CartSummary
                    checkoutHeading={mapLocaleToMeaningfulFormat(router.locale).orderSummary}
                    showCalculateEstimatedTax={true}
                    showPromoCode={false}
                    showCheckoutButton={false}
                    showHelpLinks={false}
                    cart={order}
                    showShippingPrice={true}
                    showPromoRemoveButton={false}
                    isCheckout={true}
                    content={data?.showContent ? data?.content?.dataSource?.content?.cartSummaryContent : null}
                    isCartValid={true}
                    isCheckoutAddress={false}
                  />
                </section>
              </section>
            </section>
          </section>
        </section>
      )}
    </>
  );
};

export default OrderDetails;
