import React from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { cartQuantityTotal } from 'helpers/cartQuantityTotal';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { AMPLIENCE_IMAGE_URL_FORMAT } from 'helpers/utils/constant';
import format from 'helpers/utils/format';
import calculateDynamicImageWidth from 'helpers/utils/getImageSize';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { itemCount } from 'helpers/utils/itemCount';
import omitFileExtension from 'helpers/utils/omitFileExtension';
import { routerURL } from 'helpers/utils/routerUrl';
import { desktop } from 'helpers/utils/screensizes';
import NoImageAvailable from 'public/images/No-Image-Available.jpg';
import { useWishlist } from 'frontastic';

interface ListProps {
  images: string[];
  count: number;
}

function List({ images, count }: ListProps) {
  const [isDesktop] = useMediaQuery(desktop);
  const { data: wishlist } = useWishlist();
  const dynamicWidth = calculateDynamicImageWidth(100, 100, 100);
  const router = useRouter();
  return (
    <section className="rounded-md border border-slate-300 p-4">
      <section className="mb-1 flex justify-between">
        <h3 className="text-sm font-semibold text-sol-300">{mapLocaleToMeaningfulFormat(router.locale).myList}</h3>
        {isDesktop && (
          <Link href={routerURL?.myList}>
            <a className="btn-primary-small cursor-pointer lg:px-4">
              {mapLocaleToMeaningfulFormat(router.locale).manageList}
            </a>
          </Link>
        )}
      </section>
      {count !== 0 ? (
        <ul className="mt-8 flex flex-wrap gap-2">
          {images?.slice(0, isDesktop ? 4 : 3)?.map((image, i) => (
            <li key={i} className="w-[70px] shrink-0 sm:w-[75px] md:w-[90px]">
              <Link href={routerURL?.myList}>
                <a>
                  {' '}
                  <img
                    src={
                      format(AMPLIENCE_IMAGE_URL_FORMAT, [
                        omitFileExtension(image) || NoImageAvailable?.src,
                        dynamicWidth,
                      ]) || NoImageAvailable?.src
                    }
                    alt="My List Image"
                    width={90}
                    height={90}
                    sizes="100vw"
                    className="rounded-md"
                  />
                </a>
              </Link>
            </li>
          ))}
          {count > 4 && (
            <li className="h-[70px] w-[70px] shrink-0 rounded-md bg-gray-200 sm:h-[75px] sm:w-[75px] md:h-[90px] md:w-[90px]">
              <Link href={routerURL?.myList}>
                <a className="flex h-full w-full cursor-pointer items-center justify-center font-normal">
                  {itemCount(isDesktop, count)} {mapLocaleToMeaningfulFormat(router.locale).more}
                </a>
              </Link>
            </li>
          )}
        </ul>
      ) : (
        <>
          <p className="text-sm text-sol-300">{mapLocaleToMeaningfulFormat(router.locale).myListEmptyMessage}</p>
        </>
      )}
      {!isDesktop && (
        <section className="mt-5 shrink-0 lg:mt-0">
          <Link href={routerURL?.myList}>
            <a className="btn-primary-small cursor-pointer text-center lg:px-4">
              {mapLocaleToMeaningfulFormat(router.locale).manageList}
            </a>
          </Link>
        </section>
      )}
    </section>
  );
}

export default List;
