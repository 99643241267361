export class DataSourceExtractorHelpers {
  static getPreloadedValue(name, dataSourceConfigurations) {
    // Iterate over the array

    if (dataSourceConfigurations) {
      for (let i = 0; i < dataSourceConfigurations.length; i++) {
        // Check if the current item's name matches the provided name
        if (dataSourceConfigurations[i].name === name) {
          //console.log('preLoadedValue', dataSourceConfigurations[i].preloadedValue);
          // If the item has a preLoadedValue, return it
          if (dataSourceConfigurations[i].preloadedValue) {
            return dataSourceConfigurations[i].preloadedValue;
          }
          // If the item doesn't have a preLoadedValue, return null
          else {
            return null;
          }
        }
      }
      // If no items with the provided name were found, return null
      return null;
    }
    return null;
  }
}
