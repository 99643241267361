import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Country, State } from '@Types/account/Country';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import CustomCheckBox from 'components/icons/sol/custom-checkbox';
import DownArrowIcon from 'components/icons/sol/down-icon';
import Breadcrumb from 'components/sol-ui/cms/Breadcrumb';
import { DEFAULT_COUNTRY, DEFAULT_STATE, PHONEMASK, DEFAULT_REFERRAL } from 'helpers/utils/constant';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import validationSchema from 'helpers/utils/validationSchema/catalog-request-form';
import { useFreshdesk, useAccount } from 'frontastic';
import ReCaptcha from '../recaptcha';

const CatalogRequestForm = ({ data }) => {
  const router = useRouter();
  const catalogRequestFormInitialValues = {
    email: '',
    firstName: '',
    lastName: '',
    streetName: '',
    streetNumber: '',
    city: '',
    postalCode: '',
    state: DEFAULT_STATE,
    country: DEFAULT_COUNTRY,
    phone: '',
    referal: '',
    viewAndShopLatestCatalogOnline: true,
    subscribeCatalogByMail: true,
    subscribeNewsletter: true,
  };
  const { createTicket } = useFreshdesk();
  const [isRecaptchaVerified, setIsReCaptchaVerified] = useState(false);
  const [reCaptchaError, setReCaptchaError] = useState(false);
  const { shippingLocations, catalogRequest } = useAccount();
  const countries = Object.freeze(shippingLocations?.data) as Country[];
  const [states, setStates] = useState<State[]>();
  const [sendCatalog, setSendCatalog] = useState(true);

  const Input = styled(InputMask)``;
  const handleSubmit = async (values, { resetForm }) => {
    setReCaptchaError(false);

    if (isRecaptchaVerified) {
      const { email } = values;
      await catalogRequest(email, values);
      resetForm({ values: '' });
      router.push('/pages/catalog-request-submit-confirmation');
    } else {
      setReCaptchaError(true);
    }
  };
  useEffect(() => {
    if (countries) {
      const selectedCountry = catalogRequestFormInitialValues.country ?? undefined;
      const countryCode = selectedCountry !== '' ? selectedCountry : DEFAULT_COUNTRY;
      if (countryCode) {
        const states = handleCountrySelect(countryCode) as State[];
        setStates(states);
      }
    }
  }, [countries]);

  const handleCountrySelect = (countryCode: string) => {
    if (countryCode) {
      return countries?.find((country) => country.countryCode === countryCode)?.states;
    }
    return [];
  };
  const handleDefaultState = (countryCode) => {
    const states = countries?.find((country) => country.countryCode === countryCode)?.states;
    if (states) {
      return states[0].stateCode;
    }
    return '';
  };
  const { referrals } = data;

  return (
    <>
      {!data?.removeBreadcrumbs && (
        <Breadcrumb
          breadcrumLevelLink={true}
          activePage={mapLocaleToMeaningfulFormat(router.locale).catalogRequestForm}
        />
      )}
      <section className="mx-auto max-w-screen-8xl px-4 pb-10 lg:pb-[20rem]">
        <h1 className="mt-6 text-center text-[1.688rem] font-semibold after:mx-auto after:mt-4 after:block after:h-[0.063rem] after:w-[9.375rem] after:bg-[#B19A6A] after:content-[''] lg:mt-4">
          {mapLocaleToMeaningfulFormat(router.locale).catalogRequestForm}
        </h1>
        <p className="mx-auto mt-4 text-center text-sm md:w-[51rem]">
          {mapLocaleToMeaningfulFormat(router.locale).catalogRequestFormDescription}

          <Link href={routerURL?.catalog}>
            <a className="px-1 underline underline-offset-4">
              {mapLocaleToMeaningfulFormat(router.locale).recentCatalogs}
            </a>
          </Link>
          {mapLocaleToMeaningfulFormat(router.locale).nextCatalogMail}
        </p>
        <Formik
          initialValues={catalogRequestFormInitialValues}
          validationSchema={validationSchema(sendCatalog)}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, { resetForm });
          }}
        >
          {(formik) => (
            <Form>
              <section className="mx-auto mt-6 w-full md:w-[44rem]">
                <>
                  <section className="grid lg:grid-cols-2">
                    <section className="w-full px-3 pt-2">
                      <label
                        htmlFor="firstName"
                        className="requiredLabel pb-1 text-sm font-semibold leading-[17px] text-sol-300"
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).firstName}
                      </label>

                      <Field
                        type="text"
                        name="firstName"
                        id="firstName"
                        onBlur={formik?.handleBlur}
                        className={`input-text-primary !w-full ${
                          formik?.errors?.firstName && formik?.touched?.firstName ? '!border-red-600' : ''
                        }`}
                      />
                      {formik?.errors?.firstName && formik?.touched?.firstName && (
                        <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{formik?.errors?.firstName}</p>
                      )}
                    </section>
                    <section className="w-full px-3 pt-2">
                      <label
                        htmlFor="lastName"
                        className="requiredLabel pb-1 text-sm font-semibold leading-[17px] text-sol-300"
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).lastName}
                      </label>
                      <Field
                        type="text"
                        name="lastName"
                        id="lastName"
                        onBlur={formik?.handleBlur}
                        className={`input-text-primary !w-full ${
                          formik?.errors?.lastName && formik?.touched?.lastName ? '!border-red-600' : ''
                        }`}
                      />
                      {formik?.errors?.lastName && formik?.touched?.lastName && (
                        <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{formik?.errors?.lastName}</p>
                      )}
                    </section>
                  </section>

                  <section className="w-full px-3 pt-4">
                    <section className="flex items-center justify-between">
                      <label
                        htmlFor="streetName"
                        className={`pb-1 text-sm font-semibold leading-[17px] text-sol-300 ${
                          sendCatalog ? 'requiredLabel' : ''
                        }`}
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).streetName}
                      </label>
                    </section>
                    <Field
                      type="text"
                      name="streetName"
                      id="streetName"
                      onBlur={formik?.handleBlur}
                      className={`input-text-primary !w-full ${
                        formik?.errors?.streetName && formik?.touched?.streetName ? '!border-red-600' : ''
                      }`}
                    />
                    {formik?.errors?.streetName && formik?.touched?.streetName && (
                      <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{formik?.errors?.streetName}</p>
                    )}
                  </section>
                  <section className="w-full px-3 pt-4">
                    <label htmlFor="streetNumber" className="pb-1 text-sm font-semibold leading-[17px] text-sol-300">
                      {mapLocaleToMeaningfulFormat(router.locale).streetNumber}
                    </label>
                    <Field type="text" name="streetNumber" id="streetNumber" className={`input-text-primary !w-full`} />
                  </section>
                  <section className="grid  lg:grid-cols-2">
                    <section className="w-full px-3 pt-4">
                      <label
                        htmlFor="city"
                        className={`pb-1 text-sm font-semibold leading-[17px] text-sol-300 ${
                          sendCatalog ? 'requiredLabel' : ''
                        }`}
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).city}
                      </label>
                      <Field
                        type="text"
                        name="city"
                        id="city"
                        className={`input-text-primary !w-full ${
                          formik?.errors?.city && formik?.touched?.city ? '!border-red-600' : ''
                        }`}
                      />
                      {formik?.errors?.city && formik?.touched?.city && (
                        <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{formik?.errors?.city}</p>
                      )}
                    </section>
                    <section className="w-full px-3 pt-4">
                      <label
                        htmlFor="postalCode"
                        className={`pb-1 text-sm font-semibold leading-[17px] text-sol-300 ${
                          sendCatalog ? 'requiredLabel' : ''
                        }`}
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).postalCode}
                      </label>
                      <Field
                        type="text"
                        name="postalCode"
                        id="postalCode"
                        className={`input-text-primary !w-full ${
                          formik?.errors?.postalCode && formik?.touched?.postalCode ? '!border-red-600' : ''
                        }`}
                      />
                      {formik?.errors?.postalCode && formik?.touched?.postalCode && (
                        <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                          {formik?.errors?.postalCode}
                        </p>
                      )}
                    </section>
                  </section>
                  <section className="grid pb-5 lg:grid-cols-2">
                    <section className="mt-4 w-full px-3 pt-4">
                      <label
                        htmlFor="state"
                        className={`pb-1 text-sm font-semibold leading-[17px] text-sol-300 ${
                          sendCatalog ? 'requiredLabel' : ''
                        }`}
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).stateRegion}
                      </label>
                      <section className="sortByBtn relative">
                        <Field
                          value={formik?.values?.state ?? catalogRequestFormInitialValues?.state}
                          id="state"
                          name="state"
                          as="select"
                          onChange={(e) => {
                            const { value } = e.target;
                            formik.setFieldValue('state', value);
                            formik.handleChange(e);
                          }}
                          className="input-text-primary mt-3 !w-full"
                        >
                          {states &&
                            states.map((state) => (
                              <option key={state?.stateCode} value={state?.stateCode}>
                                {state?.state}
                              </option>
                            ))}
                        </Field>
                        <DownArrowIcon className="absolute top-[16px] right-[15px] z-0 mt-3 h-[12px] w-[13px] fill-sand-700" />
                        {formik?.errors?.state && formik?.touched?.state && (
                          <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{formik?.errors?.state}</p>
                        )}
                      </section>
                    </section>
                    <section className="mt-4 w-full px-3 pt-4">
                      <label
                        htmlFor="country"
                        className="block text-sm font-semibold leading-[17px] text-sol-300 lg:h-[22px]"
                      >
                        {' '}
                        {mapLocaleToMeaningfulFormat(router.locale).country}
                      </label>
                      <section className="sortByBtn relative pt-[2px]">
                        <Field
                          id="country"
                          name={`.country`}
                          as="select"
                          className="mt-3"
                          value={formik?.values?.country ?? catalogRequestFormInitialValues?.country}
                          onChange={async (e) => {
                            const { value } = e.target;
                            const _states = handleCountrySelect(value);
                            if (_states) {
                              formik.setFieldValue(`country`, value);
                              formik.setFieldValue(`state`, handleDefaultState(value));
                              setStates(_states);
                            }
                          }}
                        >
                          {countries &&
                            countries?.map((country, index) => (
                              <option key={index} value={country?.countryCode}>
                                {country?.country}
                              </option>
                            ))}
                        </Field>
                        <DownArrowIcon className="absolute top-[16px] right-[15px] z-0 mt-3 h-[12px] w-[13px] fill-sand-700" />
                      </section>
                    </section>
                  </section>
                  <section className="grid pb-5 lg:grid-cols-2">
                    <section className="w-full px-3 pt-4">
                      <label
                        htmlFor="email"
                        className="requiredLabel pb-1 text-sm font-semibold leading-[17px] text-sol-300"
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).email}
                      </label>
                      <Field
                        type="text"
                        name="email"
                        id="email"
                        className={`input-text-primary !w-full ${
                          formik?.errors?.email && formik?.touched?.email ? '!border-red-600' : ''
                        }`}
                      />
                      {formik?.errors?.email && formik?.touched?.email && (
                        <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{formik?.errors?.email}</p>
                      )}
                    </section>
                    <section className="w-full px-3 pt-4">
                      <label
                        htmlFor="phone"
                        className={`pb-1 text-sm font-semibold leading-[17px] text-sol-300 ${
                          sendCatalog ? 'requiredLabel' : ''
                        }`}
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).phoneNumber}
                      </label>
                      <Field
                        name="phone"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        value={formik?.values?.phone ?? catalogRequestFormInitialValues?.phone}
                      >
                        {({ form: { errors, touched } }) => (
                          <Input
                            id="phone"
                            type="text"
                            mask={PHONEMASK}
                            maskChar={null}
                            onChange={formik?.handleChange}
                            onBlur={formik?.handleBlur}
                            value={formik?.values?.phone ?? catalogRequestFormInitialValues?.phone}
                            className={`input-text-primary !w-full ${
                              errors?.phone && touched?.phone ? '!border-red-600' : ''
                            }`}
                          />
                        )}
                      </Field>
                      {formik?.errors?.phone && formik?.touched?.phone && (
                        <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{formik?.errors?.phone}</p>
                      )}
                    </section>
                  </section>
                  <section className="grid pb-4 lg:grid-cols-2">
                    <section className="w-full px-3">
                      <label htmlFor="referal" className="pb-1 text-sm font-semibold leading-[17px] text-sol-300">
                        {mapLocaleToMeaningfulFormat(router.locale).referral}
                      </label>
                      <section className="sortByBtn relative">
                        <Field
                          component="select"
                          name="referal"
                          id="referal"
                          className={`input-text-primary w-full rounded-[3px] border-[rgb(0,0,0)]/20 focus:border-[rgb(0,0,0)]/20 focus:ring-0`}
                        >
                          {referrals.map((referral, index) => (
                            <option key={index} value={referral?.name} selected={referral?.code === DEFAULT_REFERRAL}>
                              {referral?.name}
                            </option>
                          ))}
                        </Field>
                        <DownArrowIcon className="absolute top-[16px] right-[15px] z-0 h-[12px] w-[13px] fill-sand-700" />
                      </section>
                    </section>
                  </section>
                  <label
                    htmlFor="viewAndShopLatestCatalogOnline"
                    className="flex cursor-pointer items-center gap-3 px-4 pt-2"
                  >
                    <section className="relative">
                      <Field
                        type="checkbox"
                        name="viewAndShopLatestCatalogOnline"
                        id="viewAndShopLatestCatalogOnline"
                        className="filterNavCheckbox h-4 w-4 cursor-pointer appearance-none rounded-[2px] border !border-black-900 text-white shadow-none focus:ring-0"
                      />
                      <CustomCheckBox className={'absolute top-[10px] left-1/2 hidden -translate-x-1/2 shadow-xl'} />
                    </section>
                    <p className="mt-[2px] text-sm font-normal leading-[17px] text-sol-300">
                      {mapLocaleToMeaningfulFormat(router.locale).viewLatestCatalog}
                    </p>
                  </label>
                  <label htmlFor="subscribeCatalogByMail" className="flex cursor-pointer items-center gap-3 px-4 pt-4">
                    <section className="relative">
                      <Field
                        type="checkbox"
                        name="subscribeCatalogByMail"
                        id="subscribeCatalogByMail"
                        className="filterNavCheckbox h-4 w-4 cursor-pointer appearance-none rounded-[2px] border !border-black-900 text-white shadow-none focus:ring-0"
                        onClick={() => setSendCatalog(!sendCatalog)}
                      />
                      <CustomCheckBox
                        className={'absolute top-[10px] left-1/2 hidden h-2 w-2 -translate-x-1/2 shadow-xl'}
                      />
                    </section>
                    <p className="mt-[2px] text-sm font-normal leading-[17px] text-sol-300">
                      {mapLocaleToMeaningfulFormat(router.locale).sendCatalogByMail}
                    </p>
                  </label>
                  <label htmlFor="subscribeNewsletter" className="flex cursor-pointer items-center gap-3 px-4 pt-4">
                    <section className="relative">
                      <Field
                        type="checkbox"
                        name="subscribeNewsletter"
                        id="subscribeNewsletter"
                        className="filterNavCheckbox h-4 w-4 cursor-pointer appearance-none rounded-[2px] border !border-black-900 text-white shadow-none focus:ring-0"
                      />
                      <CustomCheckBox
                        className={'absolute top-[10px] left-1/2 hidden h-[6px] w-[7.72px] -translate-x-1/2 shadow-xl'}
                      />
                    </section>
                    <p className="mt-[2px] text-sm font-normal leading-[17px] text-sol-300">
                      {mapLocaleToMeaningfulFormat(router.locale).subscribeNewsletter}
                    </p>
                  </label>
                  <section className="px-4">
                    <ReCaptcha setIsReCaptchaVerified={setIsReCaptchaVerified} setReCaptchaError={setReCaptchaError} />
                    {reCaptchaError && (
                      <span className="text-xs font-semibold text-red-500">
                        {mapLocaleToMeaningfulFormat(router.locale).reCaptchaError}
                      </span>
                    )}
                  </section>
                  <button
                    type="submit"
                    className="btn-primary-small mt-4 w-full py-2 text-center md:ml-4 lg:h-11 lg:w-32"
                  >
                    {mapLocaleToMeaningfulFormat(router.locale).submit}
                  </button>
                </>
              </section>
            </Form>
          )}
        </Formik>
      </section>
    </>
  );
};

export default CatalogRequestForm;
