import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import BackIcon from 'components/icons/sol/backIcon';
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import { gtmViewCart } from 'helpers/utils/gtm-events/gtmEcommerce';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { isCartIsValid } from 'helpers/utils/validateCart';
import { useCart, useAccount } from 'frontastic';
import CheckoutSummary from '../../order-summary';
import AddressSummary from '../address-summary';
import ProgressIndicator from '../progress-indicator';
import CheckoutAuthPayment from './auth-payment';
import CheckoutGuestPayment from './guest-payment';
function CheckoutPayment({ data }) {
  const { isGuestUser } = useLocalStorage();
  const { loggedIn, account } = useAccount();
  const router = useRouter();
  const [activeStep, setActiveStep] = useState(2);
  const { data: cart, redeemDiscountCode, removeDiscountCode } = useCart();
  const isCartValid = isCartIsValid(cart);
  useEffect(() => {
    if (!isGuestUser && !loggedIn) {
      router.push(routerURL.checkout);
    }
    if (
      (!cart?.shippingAddress && Object.keys(cart?.shippingAddress).length === 0) ||
      (!cart?.billingAddress && Object.keys(cart?.billingAddress).length === 0)
    ) {
      router.push(routerURL.checkoutAddress);
    }
  }, [cart]);

  return (
    <>
      <section>
        <>
          <section className="mx-auto my-10 max-w-[1180px] px-5">
            <section className="mb-4 flex items-center justify-center gap-1">
              <BackIcon className="fill-textsol-300" />
              <Link href={routerURL?.cart}>
                <a
                  className="text-sm font-medium leading-[17px] text-sol-300 underline underline-offset-2"
                  onClick={() => {
                    gtmViewCart(cart, account);
                  }}
                >
                  {mapLocaleToMeaningfulFormat(router.locale).returnToCart}
                </a>
              </Link>
            </section>
            <h1 className="mt-6 text-center text-[1.688rem] font-semibold after:mx-auto after:mt-4 after:block after:h-[0.063rem] after:w-[9.375rem] after:bg-[#B19A6A] after:content-[''] lg:mt-2">
              {mapLocaleToMeaningfulFormat(router.locale).checkout}
            </h1>
            <section className="mt-4">
              <ProgressIndicator activeStep={activeStep} setActiveStep={setActiveStep} />
            </section>
            <section className="mt-6 flex flex-col justify-between gap-5 md:grid-cols-2 lg:mt-20 lg:flex-row lg:gap-10 ">
              <section className="flex w-full flex-col">
                <section>
                  <AddressSummary cart={cart} showEditButton={true} />
                </section>
                {isGuestUser ? <CheckoutGuestPayment /> : <CheckoutAuthPayment />}
              </section>
              <section>
                <CheckoutSummary
                  checkoutHeading={mapLocaleToMeaningfulFormat(router.locale).orderSummary}
                  showCalculateEstimatedTax={true}
                  showPromoCode={true}
                  showCheckoutButton={false}
                  showHelpLinks={true}
                  cart={cart}
                  redeemDiscountCode={redeemDiscountCode}
                  removeDiscountCode={removeDiscountCode}
                  showPromoRemoveButton={true}
                  showShippingPrice={true}
                  content={data?.showContent ? data?.content?.dataSource?.content?.cartSummaryContent : null}
                  isCheckout={true}
                  isCartValid={isCartValid}
                  isCheckoutAddress={false}
                />
              </section>
            </section>
          </section>
        </>
      </section>
    </>
  );
}
export default CheckoutPayment;
