import React from 'react';
import router from 'next/router';
import type { Address as AddressType } from '@Types/account/Address';
import { StringHelpers } from 'helpers/stringHelpers';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';

export interface AddressProps {
  address?: AddressType;
}

const Address: React.FC<AddressProps> = ({ address }) => {
  return (
    <>
      <section className="mt-3 mb-2 text-sm font-normal leading-6 text-sol-300 lg:mt-0">
        <p>
          {address?.firstName} {address?.lastName}
        </p>
        <p> {address?.company}</p>
        <p>
          {' '}
          {address?.streetName} {address?.streetNumber}
        </p>
        <p>
          {' '}
          {address?.city} {address?.state} {address?.postalCode}
        </p>
        <p> {address?.country}</p>
        <section className="flex">
          <p className="mt-3 text-sm font-normal text-sol-300">
            {StringHelpers.phoneDisplayFormat(
              address?.extension ? StringHelpers.phoneExtensionMerger(address)?.phone : address?.phone,
            )}
          </p>

          {/* {address?.salutation && (
            <p className="mt-3 pl-4 text-sm font-normal text-sol-300">{`${
              mapLocaleToMeaningfulFormat(router.locale).addressExtension
            } ${address?.salutation}`}</p>
          )} */}
        </section>
      </section>
    </>
  );
};

export default Address;
