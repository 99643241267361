import { useState, Fragment, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import { Country, State } from '@Types/account/Country';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { MdCheck, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import Spinner from 'components/sol-ui/notification/spinner';
import ReCaptcha from 'components/sol-ui/recaptcha';
import { ADDRESS_NOT_VALID, BU_SUCCESS } from 'helpers/constants/messages';
import { useNotification } from 'helpers/notification/notificationContext';
import { DEFAULT_COUNTRY, DEFAULT_STATE, PHONEMASK } from 'helpers/utils/constant';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import validationSchema from 'helpers/utils/validationSchema/modal/edit-profil';
import { useDarkMode, useBusinessUnit, useAccount } from 'frontastic';
import CloseIcon from '../../../icons/sol/close';

export interface EditProfileProps {
  modalOpen?: boolean;
  modalClose?: () => void;
  addressId?: string;
  modalHeading?: string;
  primaryBtnContent?: string;
}
const tradeAccountInitialValues = {
  additionalAddressInfo: '',
  additionalStreetInfo: '',
  city: '',
  country: DEFAULT_COUNTRY,
  firstName: '',
  isDefaultBillingAddress: undefined,
  isDefaultShippingAddress: undefined,
  lastName: '',
  phone: '',
  extension: '',
  postalCode: '',
  salutation: '',
  streetName: '',
  streetNumber: '',
  state: DEFAULT_STATE,
  company: '',
};

const EditProfile: React.FC<EditProfileProps> = ({ modalOpen, modalClose, modalHeading, primaryBtnContent }) => {
  //next/router
  const router = useRouter();
  const { showNotification } = useNotification();
  const hearAboutUsData = [
    { dropDownData: 'Not Specified' },
    { dropDownData: 'Catalog' },
    { dropDownData: 'Search Engine' },
    { dropDownData: 'Facebook' },
    { dropDownData: 'Pinterest' },
    { dropDownData: 'Houzz' },
    { dropDownData: 'Instagram' },
    { dropDownData: 'Another Website' },
    { dropDownData: 'Professional Reference' },
    { dropDownData: 'Other' },
  ];

  const [listAboutUsSelected, setListAboutUsSelected] = useState('Not Specified');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [primaryList, setprimaryList] = useState('Not Specified');
  const [isPrimaryDataOpen, setIsPrimaryDataOpen] = useState(false);
  const primaryDataList = [
    { value: 'Not Specified' },
    { value: 'Residential-Design' },
    { value: 'Hospitality and Commercial Design' },
  ];
  const handleOptionChange = (name) => {
    setListAboutUsSelected(name);
    setIsDropdownOpen(false);
  };
  const handlePrimaryListChange = (value) => {
    setprimaryList(value);
    setIsPrimaryDataOpen(false);
  };
  //Darkmode
  const { mode, theme } = useDarkMode();
  const { registerBusinessUnit } = useBusinessUnit();
  const { account, validateAddress } = useAccount();
  const renderError = (message: any) => <p className="mt-2 text-sm leading-[17px] text-sol-700">{message}</p>;
  const [loading, setLoading] = useState(false);
  const handleEditProfile = async (values: any) => {
    setLoading(true);
    const validateShipping = await validateAddress(values);
    if (validateShipping?.isValid) {
      const businessUnit = await registerBusinessUnit({
        email: account?.email,
        password: values?.userPassword,
        name: values?.companyName,
        phone: values?.number,
        firstName: values?.firstName,
        lastName: values?.lastName,
      });

      if (businessUnit) {
        setLoading(false);
        showNotification(BU_SUCCESS, 'success');
        modalClose();
        return;
      }
    } else {
      showNotification(ADDRESS_NOT_VALID, 'error');
    }
    setLoading(false);
  };
  const [isRecaptchaVerified, setIsReCaptchaVerified] = useState(false);
  const [reCaptchaError, setReCaptchaError] = useState(false);
  const { shippingLocations } = useAccount();
  const countries = Object.freeze(shippingLocations?.data) as Country[];
  const [states, setStates] = useState<State[]>();
  const Input = styled(InputMask)``;

  useEffect(() => {
    if (countries) {
      const selectedCountry = tradeAccountInitialValues.country ?? undefined;
      const countryCode = selectedCountry !== '' ? selectedCountry : DEFAULT_COUNTRY;
      if (countryCode) {
        const states = handleCountrySelect(countryCode) as State[];
        setStates(states);
      }
    }
  }, [countries]);

  const handleCountrySelect = (countryCode: string) => {
    if (countryCode) {
      return countries?.find((country) => country?.countryCode === countryCode)?.states;
    }
    return [];
  };
  const handleDefaultState = (countryCode) => {
    const states = countries?.find((country) => country?.countryCode === countryCode)?.states;
    if (states) {
      return states[0].stateCode;
    }
    return '';
  };
  useEffect(() => {
    if (account) {
      tradeAccountInitialValues.firstName = account.firstName ? account.firstName : '';
      tradeAccountInitialValues.lastName = account.lastName ? account.lastName : '';
    }
  }, [account]);
  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog className={`${mode} ${theme} default fixed inset-0 z-10 overflow-y-auto`} onClose={modalClose}>
        <>
          <section className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-left sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <section className="absolute inset-0" onClick={modalClose}>
                {/* eslint-disable */}
                <section
                  className="absolute top-1/2 left-1/2 h-[90vh] w-[90%] max-w-[600px] -translate-x-1/2 -translate-y-1/2 overflow-auto bg-white py-8 px-4 dark:bg-primary-200 sm:px-6  lg:px-8"
                  onClick={(e) => e.stopPropagation()}
                >
                  <section className="flex justify-between">
                    <h1 className="mx-auto mb-9 w-max text-center text-2xl font-bold leading-[29px] text-sol-400 after:mx-auto after:mt-4 after:block after:h-[1px] after:w-[150px] after:bg-[#B19A6A] after:content-['']">
                      {modalHeading}
                    </h1>
                    <section className="pt-2">
                      <button type="button" onClick={modalClose}>
                        <CloseIcon className="fill-sol-300 " />
                      </button>
                    </section>
                  </section>
                  <Formik
                    initialValues={tradeAccountInitialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleEditProfile(values)}
                    validateOnBlur={false}
                    validateOnChange={false}
                  >
                    {(formik) => (
                      <Form>
                        <section className="grid gap-y-5">
                          <section className="grid gap-y-5 gap-x-10 lg:grid-cols-2">
                            <section className="w-full">
                              <label
                                htmlFor="firstName"
                                className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).firstName}
                              </label>
                              <Field
                                type="text"
                                name="firstName"
                                id="firstName"
                                onBlur={formik?.handleBlur}
                                className={`input-text-primary mt-3 !w-full ${
                                  formik?.errors?.firstName && formik?.touched?.firstName ? '!border-red-600' : ''
                                }`}
                              />
                              {formik?.errors?.firstName && formik?.touched?.firstName && (
                                <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                                  {formik?.errors?.firstName}
                                </p>
                              )}
                            </section>
                            <section className="w-full">
                              <label
                                htmlFor="lastName"
                                className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).lastName}
                              </label>
                              <Field
                                type="text"
                                name="lastName"
                                id="lastName"
                                onBlur={formik?.handleBlur}
                                className={`input-text-primary mt-3 !w-full ${
                                  formik?.errors?.lastName && formik?.touched?.lastName ? '!border-red-600' : ''
                                }`}
                              />
                              {formik?.errors?.lastName && formik?.touched?.lastName && (
                                <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                                  {formik?.errors?.lastName}
                                </p>
                              )}
                            </section>
                          </section>
                          <section className="w-full">
                            <label htmlFor="companyName" className="text-sm font-semibold leading-[17px] text-sol-300">
                              {mapLocaleToMeaningfulFormat(router.locale).company}
                            </label>
                            <Field
                              type="text"
                              name="companyName"
                              id="companyName"
                              onBlur={formik?.handleBlur}
                              className={`input-text-primary mt-3 !w-full ${
                                formik?.errors?.company && formik?.touched?.company ? '!border-red-600' : ''
                              }`}
                            />
                            {formik?.errors?.company && formik?.touched?.company && (
                              <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                                {formik?.errors?.company}
                              </p>
                            )}
                          </section>
                          <section className="w-full">
                            <section className="flex items-center justify-between">
                              <label
                                htmlFor="addressLineOne"
                                className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).streetName}
                              </label>
                              <p className="mb-1 text-sm font-normal leading-[17px] text-sol-300">
                                {' '}
                                {mapLocaleToMeaningfulFormat(router.locale).weCannotShip}
                              </p>
                            </section>
                            <Field
                              type="text"
                              name="streetName"
                              id="streetName"
                              onBlur={formik?.handleBlur}
                              className={`input-text-primary mt-3 !w-full ${
                                formik?.errors?.streetName && formik?.touched?.streetName ? '!border-red-600' : ''
                              }`}
                            />
                            {formik?.errors?.streetName && formik?.touched?.streetName && (
                              <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                                {formik?.errors?.streetName}
                              </p>
                            )}
                          </section>
                          <section className="w-full">
                            <label
                              htmlFor="addressLineTwo"
                              className="text-sm font-semibold leading-[17px] text-sol-300"
                            >
                              {mapLocaleToMeaningfulFormat(router.locale).streetNumber}
                            </label>
                            <Field
                              type="text"
                              name="streetNumber"
                              id="streetNumber"
                              className={`input-text-primary mt-3 !w-full`}
                            />
                          </section>
                          {/* TODO STATIC - this will be replaced with dynamic on later */}

                          <section className="grid gap-x-10 lg:grid-cols-2">
                            <section className="w-full">
                              <label
                                htmlFor="city"
                                className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).city}
                              </label>
                              <Field
                                type="text"
                                name="city"
                                id="city"
                                onBlur={formik?.handleBlur}
                                className={`input-text-primary mt-3 !w-full ${
                                  formik?.errors?.city && formik?.touched?.city ? '!border-red-600' : ''
                                }`}
                              />
                              {formik?.errors?.city && formik?.touched?.city && (
                                <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                                  {formik?.errors?.city}
                                </p>
                              )}
                            </section>
                            <section>
                              <label
                                htmlFor="state"
                                className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).stateRegion}
                              </label>
                              <Field
                                value={formik?.values?.state ?? tradeAccountInitialValues?.state}
                                id="state"
                                name="state"
                                as="select"
                                onChange={(e) => {
                                  const { value } = e.target;
                                  formik.setFieldValue('state', value);
                                  formik.handleChange(e);
                                }}
                                className="input-text-primary mt-3 !w-full"
                              >
                                {states &&
                                  states.map((state) => (
                                    <option key={state?.stateCode} value={state?.stateCode}>
                                      {state?.state}
                                    </option>
                                  ))}
                              </Field>
                            </section>
                          </section>
                          <section className="grid gap-x-10 lg:grid-cols-2">
                            <section className="w-full">
                              <label
                                htmlFor="zipCode"
                                className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).postalCode}
                              </label>
                              <Field
                                type="text"
                                name="postalCode"
                                id="postalCode"
                                onBlur={formik?.handleBlur}
                                className={`input-text-primary mt-3 !w-full ${
                                  formik?.errors?.postalCode && formik?.touched?.postalCode ? '!border-red-600' : ''
                                }`}
                              />
                              {formik?.errors?.postalCode && formik?.touched?.postalCode && (
                                <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                                  {formik?.errors?.postalCode}
                                </p>
                              )}
                            </section>
                            <section className="w-full">
                              <label
                                htmlFor="country"
                                className="requiredLabel block h-6 text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {' '}
                                {mapLocaleToMeaningfulFormat(router.locale).country}
                              </label>
                              <Field
                                id="country"
                                name={`.country`}
                                as="select"
                                className="input-text-primary mt-3 !w-full"
                                value={formik?.values?.country ?? tradeAccountInitialValues?.country}
                                onChange={async (e) => {
                                  const { value } = e.target;
                                  const _states = handleCountrySelect(value);
                                  if (_states) {
                                    formik.setFieldValue(`country`, value);
                                    formik.setFieldValue(`state`, handleDefaultState(value));
                                    setStates(_states);
                                  }
                                }}
                              >
                                {countries &&
                                  countries?.map((country, index) => (
                                    <option key={index} value={country?.countryCode}>
                                      {country?.country}
                                    </option>
                                  ))}
                              </Field>
                            </section>
                          </section>
                          <section className="flex gap-x-4 lg:grid lg:grid-cols-2 lg:gap-x-10">
                            <section className="w-full">
                              <label
                                htmlFor="number"
                                className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                              >
                                {mapLocaleToMeaningfulFormat(router.locale).phoneNumber}
                              </label>
                              <Field
                                name="phone"
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                                value={formik?.values?.phone ?? tradeAccountInitialValues?.phone}
                              >
                                {({ form: { errors, touched } }) => (
                                  <Input
                                    id="phone"
                                    type="text"
                                    mask={PHONEMASK}
                                    maskChar={null}
                                    onChange={formik?.handleChange}
                                    onBlur={formik?.handleBlur}
                                    value={formik?.values?.phone ?? tradeAccountInitialValues?.phone}
                                    className={`input-text-primary mt-3 !w-full ${
                                      errors?.phone && touched?.phone ? '!border-red-600' : ''
                                    }`}
                                  />
                                )}
                              </Field>
                              {formik?.errors?.phone && formik?.touched?.phone && (
                                <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                                  {formik?.errors?.phone}
                                </p>
                              )}
                            </section>
                            <section className="w-[115px]">
                              <label htmlFor="extension" className="text-sm font-semibold leading-[17px] text-sol-300">
                                {mapLocaleToMeaningfulFormat(router.locale).extension}
                              </label>
                              <Field
                                type="text"
                                name="extension"
                                id="extension"
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                                value={formik?.values?.extension ?? tradeAccountInitialValues?.extension}
                                className={`input-text-primary mt-3 !w-full`}
                              />
                            </section>
                          </section>
                          {/* TODO STATIC - this will be replaced with dynamic on later */}
                          <section className="relative mt-2 w-full">
                            <label className="block text-sm font-semibold leading-[17px] text-sol-300">
                              {mapLocaleToMeaningfulFormat(router.locale).yourPrimaryFocus}
                            </label>
                            <section className="relative">
                              <button
                                type="button"
                                className="mt-1 flex w-full items-start justify-start rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-sol-300 focus:outline-none focus:ring-sol-300 sm:text-sm"
                                onClick={() => setIsPrimaryDataOpen(!isPrimaryDataOpen)}
                              >
                                {primaryList}
                                <section className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <MdOutlineKeyboardArrowDown
                                    className="h-[22px] w-[22px] text-gray-500"
                                    aria-hidden="true"
                                  />
                                </section>
                              </button>
                              {isPrimaryDataOpen && (
                                <section className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg">
                                  {primaryDataList?.map((primary, i) => (
                                    <button
                                      key={primary?.value}
                                      type="button"
                                      className={`flex w-full justify-between px-4 py-2 text-left ${
                                        primaryList === primary?.value ? 'bg-sol-100' : 'hover:bg-sol-100'
                                      }`}
                                      onClick={() => handlePrimaryListChange(primary?.value)}
                                    >
                                      <span className="mr-2">{primary?.value}</span>
                                      {primaryList === primary?.value && (
                                        <MdCheck className="h-5 w-5 text-black-500" aria-hidden="true" />
                                      )}
                                    </button>
                                  ))}
                                </section>
                              )}
                            </section>
                          </section>

                          <section className="relative mt-2 w-full">
                            <label className="block text-sm font-semibold leading-[17px] text-sol-300">
                              {mapLocaleToMeaningfulFormat(router.locale).hearAboutUs}
                            </label>
                            <section className="relative">
                              <button
                                type="button"
                                className="mt-1 flex w-full items-start justify-start rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-sol-300 focus:outline-none focus:ring-sol-300 sm:text-sm"
                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                              >
                                {listAboutUsSelected}
                                <section className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <MdOutlineKeyboardArrowDown
                                    className="h-[22px] w-[22px] text-gray-500"
                                    aria-hidden="true"
                                  />
                                </section>
                              </button>
                              {isDropdownOpen && (
                                <section className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg">
                                  {hearAboutUsData.map((option) => (
                                    <button
                                      key={option?.dropDownData}
                                      type="button"
                                      className={`flex w-full justify-between px-4 py-2 text-left ${
                                        listAboutUsSelected === option?.dropDownData ? 'bg-sol-100' : 'hover:bg-sol-100'
                                      }`}
                                      onClick={() => handleOptionChange(option?.dropDownData)}
                                    >
                                      <span className="mr-2">{option?.dropDownData}</span>
                                      {listAboutUsSelected === option?.dropDownData && (
                                        <MdCheck className="h-5 w-5 text-black-500" aria-hidden="true" />
                                      )}
                                    </button>
                                  ))}
                                </section>
                              )}
                            </section>
                          </section>
                          <section className="mt-4 flex items-center gap-4">
                            <Field
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              className="h-4 w-4 appearance-none rounded-[2px] border-none border-black-300 text-white ring-2 ring-sol-300 focus:ring-sol-300"
                            />
                            <label htmlFor="checkbox" className="text-sm leading-[17px] text-sol-300">
                              {mapLocaleToMeaningfulFormat(router.locale).sendCatalog}
                            </label>
                          </section>

                          <ReCaptcha
                            setIsReCaptchaVerified={setIsReCaptchaVerified}
                            setReCaptchaError={setReCaptchaError}
                          />
                          {reCaptchaError && (
                            <span className="text-xs font-semibold text-red-500">
                              {mapLocaleToMeaningfulFormat(router.locale).reCaptchaError}
                            </span>
                          )}

                          <section className="flex flex-col items-center gap-0 md:flex-row md:gap-6">
                            <button
                              type="submit"
                              id="requestTradeAccount"
                              className={`btn-primary-small flex h-[44px] w-full items-center justify-center px-8 md:w-auto ${
                                Object.keys(formik?.errors).length === 0 ? '' : 'cursor-not-allowed'
                              }`}
                            >
                              {' '}
                              {loading ? (
                                <section className="flex items-center justify-center gap-3">
                                  {' '}
                                  <Spinner />
                                  <p>{mapLocaleToMeaningfulFormat(router.locale).processing}</p>
                                </section>
                              ) : (
                                primaryBtnContent
                              )}
                            </button>
                            <button
                              type="button"
                              id="cancelRequestTradeAccount"
                              onClick={modalClose}
                              className={`btn-cancel underline} flex h-[44px] w-full items-center justify-center md:w-auto`}
                            >
                              {mapLocaleToMeaningfulFormat(router.locale).cancel}
                            </button>
                          </section>
                        </section>
                      </Form>
                    )}
                  </Formik>
                </section>
              </section>
            </Transition.Child>
          </section>
        </>
      </Dialog>
    </Transition.Root>
  );
};

export default EditProfile;
