import TradeAccountLandingPage from 'components/sol-ui/pages/trade-account';
import React from 'react';

const TradeAccountLandingPageTastic = ({ data }) => {
  const amplienceContent = data?.data?.dataSource?.content || {};

  return <TradeAccountLandingPage content={amplienceContent} />;
};

export default TradeAccountLandingPageTastic;
