import NodeCache from 'node-cache';
import { useCategory } from 'frontastic';
interface getCacheCategory {
  cacheKey: string;
  cacheCheckPeriodTime: number;
  cacheStdTTLTime: number;
}
export default async function getCacheCategoriesData(payload: getCacheCategory) {
  const { cacheKey, cacheCheckPeriodTime, cacheStdTTLTime } = payload;
  const cache = new NodeCache({ stdTTL: cacheStdTTLTime, checkperiod: cacheCheckPeriodTime });
  const { getCategories } = useCategory();

  // check cache for existing result
  const cachedResult = cache.get(cacheKey);
  if (cachedResult) {
    return cachedResult;
  }

  // fetch data from data source
  const data = await getCategories();

  // store result in cache
  cache.set(cacheKey, data);

  return data;
}
