import React from 'react';
import { useRouter } from 'next/router';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { desktop } from 'helpers/utils/screensizes';

function CheckoutFooter({ content }) {
  const { integrityGroups, copyrightText, year } = content;
  const router = useRouter();
  const [isDesktop] = useMediaQuery(desktop);

  return (
    <section className="w-full bg-sand-600 py-5 text-xs font-normal text-sol-300">
      <section className="mx-10 flex flex-wrap items-center justify-center text-xs leading-[15px] text-[#222222] md:justify-between">
        <ul className="flex flex-wrap items-center justify-center gap-2">
          {integrityGroups?.data?.map((content, key) => (
            <li key={key} className="shrink-0 hover:underline">
              {key !== 0 ? <span className="pr-2">|</span> : <></>}
              {content.link === routerURL?.hash ? (
                <button
                  className="hover:underline"
                  type="button"
                  onClick={() =>
                    window !== undefined && window['Osano']?.cm?.showDrawer('osano-cm-dom-info-dialog-open')
                  }
                >{`${content?.title || ''} `}</button>
              ) : (
                <a href={content?.link || '/'}>{`${content?.title || ''} `}</a>
              )}
            </li>
          ))}
        </ul>
        {isDesktop && (
          <p>{`${mapLocaleToMeaningfulFormat(router.locale).copyrightSymbol} ${year || ''} ${copyrightText || ''}`}</p>
        )}
      </section>
    </section>
  );
}

export default CheckoutFooter;
