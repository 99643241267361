// hooks/useAlgoliaSearchClient.js
import { useMemo } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { createInMemoryCache } from '@algolia/cache-in-memory';

const useAlgoliaSearchClient = (algoliaConfig) => {
  return useMemo(() => {
    return algoliasearch(algoliaConfig?.appId, algoliaConfig?.appAdminKey, {
      responsesCache: createInMemoryCache(),
    });
  }, [algoliaConfig?.appId, algoliaConfig?.appAdminKey]);
};

export default useAlgoliaSearchClient;
