import React, { useState } from 'react';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { SOLHeaderDesktop } from './header-desktop';
import { SOLHeaderMobile } from './header-mobile';

const SOLHeader = ({ data }) => {
  const [isDesktop] = useMediaQuery(1024);

  return isDesktop ? (
    <SOLHeaderDesktop data={data} categories={data?.categoryList?.dataSource?.categories} />
  ) : (
    <section className="lg:hidden">
      <SOLHeaderMobile data={data} categories={data?.categoryList?.dataSource?.categories} />
    </section>
  );
};

export default SOLHeader;
