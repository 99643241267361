import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import data from './six-column-catalog-request-submit.json';

const SixCatalogRequestSubmit = () => {
  const [isDesktop] = useMediaQuery(desktop);
  return (
    <>
      {/* TODO: Static the comes from amplience on later  */}
      <section className="mx-auto mb-8 max-w-[1440px] px-5 md:px-10 lg:mb-12">
        <h1 className="relative mb-8 text-center before:absolute before:top-[54%] before:block before:h-[1px] before:w-full before:bg-sol-600 before:content-[''] lg:mb-10">
          <em className="relative bg-white text-[32px] font-semibold uppercase not-italic leading-[39px] md:px-10">
            DESIGN INSPIRATION & IDEAS
          </em>
        </h1>
        <ul className="grid grid-cols-1 gap-8 lg:grid-cols-3 lg:gap-5">
          {data.map((item) => (
            <li key={item.id}>
              <Link href="">
                <a className="flex h-full w-full">
                  <section className="h-full w-full rounded-t-[6px] bg-sol-100">
                    <section>
                      <Image
                        loader={(options) => options.src}
                        width={441}
                        height={309}
                        src={item?.imageUrl}
                        alt={item?.title}
                        className="rounded-t-md"
                      />
                    </section>
                    <section className="flex justify-between p-4">
                      <section>
                        <h1 className="text-1xl mb-2 font-semibold leading-[29px] text-sol-300">{item.title}</h1>
                        <p className="text-sm leading-[17px] text-sol-300">{item.description}</p>
                      </section>
                      <section className="ml-4 mt-1 shrink-0">
                        <button
                          type="button"
                          id={'sixColCatalogBtn' + item.title?.trim()?.split(' ')?.join('')}
                          className="btn-primary-small inline-block px-4 py-2 text-sm !font-normal"
                        >
                          {item.button}
                        </button>
                      </section>
                    </section>
                  </section>
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default SixCatalogRequestSubmit;
