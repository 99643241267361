import React from 'react';
import Link from 'next/link';
import { routerURL } from 'helpers/utils/routerUrl';
interface ContentProps {
  contentData?: string;
  link?: string;
}

function SuggestedItem({ contentData, link }: ContentProps) {
  return (
    <Link href={link}>
      <a tabIndex={0} className="inline-block text-sm font-normal leading-5 text-sol-300">
        {contentData}
      </a>
    </Link>
  );
}

export default SuggestedItem;
