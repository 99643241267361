import Image from 'next/image';
import router, { useRouter } from 'next/router';
import DiscoverIcon from 'components/icons/sol/info-icon';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { desktop } from 'helpers/utils/screensizes';
import { AccountSubMenuDesktop, AccountSubMenuMobile } from '../sections/accountListing';
import OrderDetailsListItem from './orderDetailsListItem';

const OrderDetailsList = ({ order }) => {
  //next/router
  const router = useRouter();
  const [isDesktop] = useMediaQuery(desktop);
  /*To Do Dynamic*/
  const trackingNumber = '394594459756'; //No data in backend for trackingNumber

  return (
    <>
      {/* //TODO: static comment */}
      <li className="rounded-[6px] border border-slate-300 p-5">
        <h2 className="mb-2 text-2xl font-semibold leading-[29px] text-sol-300">Shipment 1</h2>
        <section className="mb-9 flex flex-wrap gap-5">
          <p className="text-sm leading-[17px] text-sol-300">
            {`${mapLocaleToMeaningfulFormat(router.locale).status} ${mapLocaleToMeaningfulFormat(router.locale).colon}`}
            <span className="pl-1">{order?.orderState}</span>
          </p>
        </section>
        <ul className="grid gap-14">
          {order?.lineItems?.map((lineItem, index) => (
            <OrderDetailsListItem key={index} lineItem={lineItem} index={index} />
          ))}
        </ul>
      </li>
    </>
  );
};

export default OrderDetailsList;
