import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { SOLFooterDesktop } from './footer-desktop';
import { SOLFooterMobile } from './footer-mobile';

const SOLFooter = ({ data }) => {
  const [isDesktop] = useMediaQuery(1024);

  const { content, desktop, mobile, tablet, _type, ...navs } = data;
  const amplienceContent = content?.dataSource?.content || {};
  return isDesktop ? (
    <SOLFooterDesktop content={amplienceContent} navs={navs} />
  ) : (
    <SOLFooterMobile content={amplienceContent} navs={navs} />
  );
};

export default SOLFooter;
