import HeroCarouselContentBlock from 'components/sol-ui/cms/hero-carousel-content-block';
import { DateHelpers } from 'helpers/dateHelpers';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import parser from 'html-react-parser';

const HeroCarouselContentBlockTastic = ({ data }) => {
  const [activeBanners, setActiveBanners] = useState([]);
  const amplienceContent = data?.data?.dataSource?.content?.data || [];
  const cssOverrides = data?.data?.dataSource?.content?.cssoverrides;

  useEffect(() => {
    // Calculate IST offset in milliseconds (5 hours 30 minutes)
    const offset = 5 * 60 * 60 * 1000 + 30 * 60 * 1000;
    const banners = amplienceContent.filter((banner) =>
      DateHelpers.checkDateRange(banner?.startDateAndTime, banner?.endDateAndTime, offset),
    );
    setActiveBanners(banners);
  }, [amplienceContent]);

  return activeBanners?.length > 0 ? (
    <>
      {<Head>{parser(cssOverrides)}</Head>}
      <HeroCarouselContentBlock content={activeBanners} sliderInterval={data?.sliderInterval} />
    </>
  ) : (
    <></>
  );
};

export default HeroCarouselContentBlockTastic;
