const Kount = ({ sessionId, config }) => {
  return (
    <iframe
      width="1"
      height="1"
      frameBorder="0"
      scrolling="no"
      src={`${config?.iframeURL}/logo.htm?m=${config?.merchantId}&s=${sessionId}`}
    >
      <img width="1" height="1" src={`${config?.iframeURL}/logo.gif?m=${config?.merchantId}&s=${sessionId}`} />
    </iframe>
  );
};

export default Kount;
