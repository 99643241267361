import React, { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import router, { useRouter } from 'next/router';
import { titleCase } from 'converting-case';
import BreadcrumbRichText from 'components/seo/breadcrumbsRichText';
import { convertTitleFromCamelCase } from 'helpers/convertToCamelCase';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import DoubleArrow from '../../../icons/sol/double-arrow';
interface BreadcrumbsInterface {
  createBreadCrumbFromUrlFlag: any;
  breadCrumbsData: any;
  isPDP: any;
  productName: any;
  richTextData?: any;
}
const Breadcrumbs = ({
  createBreadCrumbFromUrlFlag,
  breadCrumbsData,
  isPDP,
  productName,
  richTextData,
}: BreadcrumbsInterface) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const router = useRouter();
  if (createBreadCrumbFromUrlFlag && breadcrumbs.length == 0) {
    const pathnames = router?.asPath
      .replace('/categories', '')
      .split('/')
      .filter((x) => x)
      .slice(0, -1);
    breadcrumbs.push({
      title: mapLocaleToMeaningfulFormat(router.locale).home,
      ctaLink: '/',
    });
    pathnames?.forEach((pathName, idx) => {
      const path = `/${pathnames?.slice(0, idx + 1).join('/')}`;
      breadcrumbs.push({
        title: convertTitleFromCamelCase(pathName),
        ctaLink: path,
      });
    });
  }

  useEffect(() => {
    const _breadCrumbsData = breadCrumbsData.reduce((result, item, index) => {
      if (breadCrumbsData.length === 2) {
        if (index === breadCrumbsData.length - 1) {
          result.push({
            title: item?.alternateDisplayName ? item?.alternateDisplayName : titleCase(item?.name),
            ctaLink: item?.overrideURL ? item?.overrideURL : `/categories/${item?.slug}`,
          });
        }
      } else {
        if (index === 0 || index === breadCrumbsData.length - 1) {
          result.push({
            title: item?.alternateDisplayName ? item?.alternateDisplayName : titleCase(item?.name),
            ctaLink: item?.overrideURL ? item?.overrideURL : `/categories/${item?.slug}`,
          });
        }
      }
      return result;
    }, []);
    if (isPDP) {
      if (productName) {
        _breadCrumbsData.push({
          title: titleCase(productName),
          ctaLink: '/',
        });
      }
    }
    setBreadcrumbs(_breadCrumbsData);
  }, [breadCrumbsData]);

  const breadCrumbsDataForRichText = useMemo(() => {
    const data = richTextData ?? breadCrumbsData;
    const _breadCrumbsData = data.reduce((result, item, index) => {
      if (data.length === 2) {
        if (index === data.length - 1) {
          result.push({
            title: item?.alternateDisplayName ? item?.alternateDisplayName : titleCase(item?.name),
            ctaLink: item?.overrideURL ? item?.overrideURL : `/categories/${item?.slug}`,
          });
        }
      } else {
        if (index === 0 || index === data.length - 1) {
          result.push({
            title: item?.alternateDisplayName ? item?.alternateDisplayName : titleCase(item?.name),
            ctaLink: item?.overrideURL ? item?.overrideURL : `/categories/${item?.slug}`,
          });
        }
      }
      return result;
    }, []);
    if (isPDP) {
      if (productName) {
        _breadCrumbsData.push({
          title: titleCase(productName),
          ctaLink: '/',
        });
      }
    }
    return _breadCrumbsData;
  }, []);

  return (
    <>
      <BreadcrumbRichText items={breadCrumbsDataForRichText} />
      <section
        id="pageBreadCrumbs"
        className="mx-auto mt-4 flex max-w-screen-8xl items-center text-xs font-normal text-sol-300"
      >
        <ul className="inline-block gap-y-3 px-4 text-xs font-normal leading-6 text-sol-300">
          <li className="inline">
            <React.Fragment>
              <Link href={routerURL?.home}>
                <a className="text-xs leading-[15px] text-sol-300">{mapLocaleToMeaningfulFormat(router.locale).home}</a>
              </Link>
            </React.Fragment>
            <DoubleArrow className="mx-2 inline" />
          </li>
          <li className="inline">
            {breadcrumbs?.map((breadcrumb, index) => {
              const isLast = index === breadcrumbs?.length - 1;
              const isLastPLP = index === breadcrumbs?.length - 2;
              const isFirst = index === 0;
              return isLast ? (
                <React.Fragment key={index}>
                  {!isFirst && <DoubleArrow className="mx-2 inline" />}
                  <span className="text-xs leading-[15px] text-sol-300">{breadcrumb?.title}</span>
                </React.Fragment>
              ) : isPDP && isLastPLP ? (
                <React.Fragment key={index}>
                  {!isFirst && <DoubleArrow className="mx-2 inline" />}
                  <a
                    className="cursor-pointer text-xs leading-[15px] text-sol-300"
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage?.getItem('plpUrl')
                        ? router?.push(localStorage?.getItem('plpUrl'))
                        : window?.history?.back();
                    }}
                  >
                    {breadcrumb?.title}
                  </a>
                </React.Fragment>
              ) : (
                <React.Fragment key={index}>
                  {!isFirst && <DoubleArrow className="mx-2 inline" />}
                  <Link href={breadcrumb?.ctaLink}>
                    <a className="text-xs leading-[15px] text-sol-300">{breadcrumb?.title}</a>
                  </Link>
                </React.Fragment>
              );
            })}
          </li>
        </ul>
      </section>
    </>
    // <></>
  );
};

export default Breadcrumbs;
