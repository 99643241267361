import React, { Fragment, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Dialog, Listbox, Transition } from '@headlessui/react';
import { Order } from '@Types/cart/Order';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { FEEDBACK_SUBMITTED_SUCCESSFULLY, SERVER_ERROR } from 'helpers/constants/messages';
import { useNotification } from 'helpers/notification/notificationContext';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { mode, theme } from 'tailwind.config';
import { useCart, useConfig } from 'frontastic';
import { OrderDetails } from 'frontastic/actions/cart';
import CloseIcon from '../../../icons/sol/close';
export interface HearAboutUsProps {
  openModal?: boolean;
  order: Order;
  sources: any;
  handleUserClicked: (value: boolean) => void;
  closeModal?: () => void;
}

function HearAboutUs({ openModal, order, sources, handleUserClicked, closeModal }: HearAboutUsProps) {
  const [sourceCodes, setSourceCodes] = useState(sources?.filter((code: any) => code?.type === 'WEB'));

  const [defaultSourceCode, setDefaultSourceCode] = useState(sources?.find((code: any) => code?.type === 'DEFAULT'));
  const [sourceCodeSelected, setSourceCodeSelected] = useState<any>();
  const [txtSourceCode, setTxtSourceCode] = useState('');
  const [filterItemArrow, setFilterItemArrow] = useState(false);
  const { showNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { setSourceCode } = useCart();
  function handleFilterItemDropdown() {
    setFilterItemArrow(!filterItemArrow);
  }
  const onClose = async () => {
    if (defaultSourceCode && !sourceCodeSelected) {
      await update(order, defaultSourceCode?.key);
    }
    closeModal();
  };

  const update = async (order: Order, sourceCode: string) => {
    try {
      const orderDetails: OrderDetails = { orderNumber: order?.orderNumber, sourceCode };
      const response = await setSourceCode(orderDetails);
      if (response?.orderId) {
        if (sourceCodeSelected) {
          showNotification(FEEDBACK_SUBMITTED_SUCCESSFULLY, 'success');
        }
      } else {
        showNotification(SERVER_ERROR, 'error');
      }
    } catch (error) {
      showNotification(SERVER_ERROR, 'error');
    }
    return order;
  };
  const handleSubmit = async () => {
    setLoading(true);
    if (sourceCodeSelected) {
      const sourceCode = txtSourceCode.length > 0 ? txtSourceCode : sourceCodeSelected?.key;
      await update(order, sourceCode);
      setLoading(false);
      handleUserClicked(false);
      setSourceCodeSelected(null);
      setTxtSourceCode('');
      closeModal();
    }
  };

  return (
    <div>
      <Transition.Root show={openModal} as={Fragment}>
        <Dialog className={`${mode} ${theme} default fixed inset-0 z-10 overflow-y-auto`} onClose={onClose}>
          <>
            <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-left sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="absolute inset-0">
                  {/* eslint-disable */}
                  <div className="absolute top-1/2 left-1/2 w-[90%] -translate-x-1/2 -translate-y-1/2 overflow-auto bg-white py-6 px-4 dark:bg-primary-200 sm:px-6 md:w-[500px] lg:px-8">
                    <section className="flex justify-between">
                      <h1 className="mx-auto mb-9 w-max text-center text-2xl font-bold leading-[29px] text-sol-400 after:mx-auto after:mt-4 after:block after:h-[1px] after:w-[150px] after:bg-sol-600 after:content-[''] lg:text-2xl">
                        {mapLocaleToMeaningfulFormat(router.locale).hearAboutUs}
                      </h1>
                      <section className="pt-2">
                        <button type="button" onClick={onClose}>
                          <CloseIcon className="fill-sol-300 focus:outline-none focus:ring-0" />
                        </button>
                      </section>
                    </section>
                    <section className="mb-7">
                      <section className="relative w-full lg:mr-5">
                        <Listbox
                          value={sourceCodeSelected}
                          onChange={(value) => {
                            setSourceCodeSelected(value);
                            handleUserClicked(true);
                          }}
                        >
                          <Listbox.Button className="relative w-full cursor-default rounded border border-black-100/[.20]  bg-white py-[11px] pl-3 pr-10 text-left sm:text-sm">
                            <em className="block truncate not-italic">
                              {sourceCodeSelected?.label ?? mapLocaleToMeaningfulFormat(router.locale).pleaseSelect}
                            </em>
                            <em className="absolute inset-y-0 right-0 flex items-center pr-2 not-italic">
                              {!filterItemArrow ? (
                                <section className="my-auto mr-3">
                                  <MdOutlineKeyboardArrowDown
                                    onClick={handleFilterItemDropdown}
                                    className="text-2xl text-sol-300"
                                  />
                                </section>
                              ) : (
                                <section className="my-auto mr-3">
                                  <MdOutlineKeyboardArrowUp
                                    onClick={handleFilterItemDropdown}
                                    className="text-2xl text-sol-300"
                                  />
                                </section>
                              )}
                            </em>
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="ring-black absolute mt-1 max-h-[6.7rem] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm">
                              {sourceCodes?.length > 0 &&
                                sourceCodes?.map((data) => (
                                  <Listbox.Option
                                    key={data?.key}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                        active && 'bg-gray-200 text-sol-300 '
                                      }`
                                    }
                                    value={data}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <em
                                          className={`block truncate not-italic ${
                                            selected ? 'font-medium' : 'font-normal'
                                          }`}
                                        >
                                          {data?.label}
                                        </em>
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                            </Listbox.Options>
                          </Transition>
                        </Listbox>
                        {sourceCodeSelected && sourceCodeSelected?.custom && (
                          <>
                            <p className="mt-5 text-sm font-semibold text-sol-400">
                              {mapLocaleToMeaningfulFormat(router.locale).enterTheCatalog}
                            </p>
                            <section>
                              <input
                                type="text"
                                onChange={(e) => {
                                  setTxtSourceCode(e.target.value);
                                  handleUserClicked(true);
                                }}
                                value={txtSourceCode}
                                className="input-text-primary mt-4 !w-full"
                              />
                            </section>
                          </>
                        )}
                      </section>
                    </section>
                    <section className="flex flex-col items-center gap-0 md:mb-4 md:flex-row md:gap-6">
                      <button
                        type="submit"
                        id="submitHearAboutUsForm"
                        onClick={!sourceCodeSelected?.key ? null : handleSubmit}
                        className={`${
                          !sourceCodeSelected?.key ? 'cursor-not-allowed' : 'cursor-pointer'
                        } btn-primary-small flex h-[44px] w-full items-center justify-center px-8 md:w-auto`}
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).submit}
                      </button>
                      <button
                        type="button"
                        id="cancelHearAboutUs"
                        onClick={onClose}
                        className={`btn-cancel flex h-[44px] w-full items-center justify-center underline underline-offset-2 md:w-auto`}
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).cancel}
                      </button>
                    </section>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default HearAboutUs;
