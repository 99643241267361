import React, { useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Card, CardRequest } from '@Types/account/AuthorizeNetAccount';
import {
  SERVER_ERROR,
  CREDIT_CARD_UPDATED,
  CREDIT_CARD_DELETED,
  INVALID_CREDIT_CARD_REQUEST,
} from 'helpers/constants/messages';
import { useNotification } from 'helpers/notification/notificationContext';
import { StringHelpers } from 'helpers/stringHelpers';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { useAuthorizeNet, useConfig } from 'frontastic';
import CreditCard from '../modal/credit-card';
import DeleteModal from '../modal/delete-modal';
import SetAsDefaultCreditCard from '../modal/delete-modal';
import CardIcon from './card-icon';

type CardProps = {
  card: Card;
  handleUpdate?: (request: CardRequest) => void;
};
const Card: React.FC<CardProps> = ({ card, handleUpdate }) => {
  const router = useRouter();
  const customerPaymentProfileId = card?.customerPaymentProfileId;
  const { deleteCard, setDefaultCreditCard } = useAuthorizeNet();
  const [loading, setLoading] = useState<boolean>(false);
  const { data: config } = useConfig();
  //create delete modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  //global notification
  const { showNotification } = useNotification();

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  //create default address modal
  const [defaultModalOpen, setDefaultModalOpen] = useState(false);

  const openDefaultModal = () => {
    setDefaultModalOpen(true);
  };

  const closeDefaultModal = () => {
    setDefaultModalOpen(false);
  };

  // create credit card modal
  const [creditCardModalOpen, setCreditCardModalOpen] = useState(false);

  const openCreditCardModal = () => {
    setCreditCardModalOpen(true);
  };

  const closeCreditCardModal = () => {
    setCreditCardModalOpen(false);
  };
  const handleSetAsDefault = async () => {
    try {
      setLoading(true);
      if (customerPaymentProfileId) {
        const response = await setDefaultCreditCard(customerPaymentProfileId);
        if (response?.customerPaymentProfileId) {
          showNotification(CREDIT_CARD_UPDATED, 'success');
        } else {
          showNotification(INVALID_CREDIT_CARD_REQUEST, 'error');
        }
      }
    } catch (error) {
      showNotification(SERVER_ERROR, 'error');
    }
    setLoading(false);
    closeDefaultModal();
  };
  const handleDelete = async () => {
    try {
      setLoading(true);
      if (customerPaymentProfileId) {
        const response = await deleteCard(customerPaymentProfileId);
        if (response?.customerPaymentProfileId) {
          showNotification(CREDIT_CARD_DELETED, 'success');
        } else {
          showNotification(response?.message, 'error');
        }
      }
    } catch (error) {
      showNotification(SERVER_ERROR, 'error');
    }
    setLoading(false);
    closeDeleteModal();
  };
  return (
    <>
      <li className="rounded-md border-2 py-3 px-4">
        <section className="flex items-center gap-2">
          {card?.cardType ? <CardIcon cardType={card?.cardType} /> : <CardIcon cardType={'default'} />}

          <p className="text-sm font-normal text-sol-300">
            {StringHelpers.creditCardNumberFormatter(card?.cardNumber, router)}
          </p>
        </section>
        <section>
          <p className="mt-1 mb-4 text-sm font-normal text-sol-300">
            {StringHelpers.expiryDateFormatter(card?.expirationDate, router)}
          </p>
        </section>
        {card?.isDefault === true ? (
          <p className="font-semibold text-sol-300">{mapLocaleToMeaningfulFormat(router.locale).defaultCreditCard}</p>
        ) : (
          <section className="flex text-sm underline underline-offset-2">
            <button className=" truncate md:text-ellipsis" type="button" onClick={openDefaultModal}>
              {mapLocaleToMeaningfulFormat(router.locale).setAsDefaultCreditCard}
            </button>
            <span className="px-3">|</span>
            <button type="button" className="hidden" onClick={openCreditCardModal}>
              {mapLocaleToMeaningfulFormat(router.locale).edit}
              <span className="px-3">|</span>
            </button>
            <button type="button" onClick={openDeleteModal}>
              {mapLocaleToMeaningfulFormat(router.locale).delete}
            </button>
          </section>
        )}
      </li>
      <SetAsDefaultCreditCard
        open={defaultModalOpen}
        onClose={closeDefaultModal}
        modalHeading={`${mapLocaleToMeaningfulFormat(router.locale).SetDefaultCard}`}
        modalContent={`${mapLocaleToMeaningfulFormat(router.locale).sureWantDefaultCard}`}
        primaryBtnContent={`${mapLocaleToMeaningfulFormat(router.locale).SetDefaultCard}`}
        handleSetAsDefault={handleSetAsDefault}
        action="setAsDefault"
        loading={loading}
      />
      <CreditCard
        open={creditCardModalOpen}
        onClose={closeCreditCardModal}
        modalHeading={`${mapLocaleToMeaningfulFormat(router.locale).editCard}`}
        primaryBtnContent={`${mapLocaleToMeaningfulFormat(router.locale).updateCard}`}
        card={card}
        handleUpdate={handleUpdate}
        loading={loading}
      />
      <DeleteModal
        open={deleteModalOpen}
        onClose={closeDeleteModal}
        modalHeading={`${mapLocaleToMeaningfulFormat(router.locale).deleteConfirmation}`}
        modalContent={`${mapLocaleToMeaningfulFormat(router.locale).sureWantDeleteCard}`}
        primaryBtnContent={`${mapLocaleToMeaningfulFormat(router.locale).delete}`}
        handleDelete={handleDelete}
        loading={loading}
        action="delete"
      />
    </>
  );
};

export default Card;
