import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { SubscriptionList } from '@Types/communication-preference/SubscriptionList';
import Breadcrumb from 'components/sol-ui/cms/Breadcrumb';
import { NOT_YET_SUBSCRIBED } from 'helpers/constants/messages';
import { useNotification } from 'helpers/notification/notificationContext';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { useAccount, useFreshdesk } from 'frontastic';
import SubscriptionPreferences from './subscriptionPreferences';
import UnSubscriptionPreferences from './unSubscriptionpreferences';
const CommunicationPreference = () => {
  const router = useRouter();
  const { account, checkSubscription, loggedIn } = useAccount();

  const [emailId, setEmailId] = useState(loggedIn ? account?.email : '');
  const [confirmEmail, setConfirmEmail] = useState(loggedIn ? account?.email : '');
  const email = (router?.query?.email as string) ?? emailId;
  const [initialLoad, setInitialLoad] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [unSubscribe, setUnSubscribe] = useState(false);
  const [unSubscribeSubmit, setUnSubscribeSubmit] = useState(false);
  const [subscribeSubmit, setSubscribeSubmit] = useState(false);
  const [subscriptionList, setSubscriptionList] = useState<SubscriptionList>(undefined);
  const [breadCrumLevel, setBreadCrumLevel] = useState(true);
  const { showNotification } = useNotification();

  useEffect(() => {
    if (!initialLoad && email) {
      checkSubscription(email).then((response) => {
        setIsSubscribed(response?.isSubscribed);
        setSubscriptionList(response as SubscriptionList);
      });
      setInitialLoad(true);
    }
  }, [initialLoad, email]);

  const handleSubscribe = () => {
    setUnSubscribe(false);
    setUnSubscribeSubmit(false);
    setSubscribeSubmit(false);
    router.push('/pages/communication-preferences');
    setEmailId(loggedIn ? account?.email : '');
    setConfirmEmail('');
  };
  const handleUnSubscribe = () => {
    setUnSubscribe(false);
    setUnSubscribeSubmit(false);
    setEmailId(loggedIn ? account?.email : '');
    setConfirmEmail('');
  };
  useEffect(() => {
    if (unSubscribe) {
      checkSubscription(email).then((response) => {
        if (!response?.isSubscribed) {
          // showNotification(NOT_YET_SUBSCRIBED, 'error');
          setUnSubscribe(false);
        } else {
          setSubscriptionList(response as SubscriptionList);
          setIsSubscribed(response?.isSubscribed);
        }
      });
    }
  }, [unSubscribe]);

  return (
    <>
      {(!unSubscribe && !subscribeSubmit) || (unSubscribe && !unSubscribeSubmit) ? (
        <Breadcrumb
          breadcrumLevelLink={breadCrumLevel}
          activePage={
            unSubscribe && !unSubscribeSubmit
              ? mapLocaleToMeaningfulFormat(router.locale).unsubscribe
              : mapLocaleToMeaningfulFormat(router.locale).subscriptions
          }
        />
      ) : (
        ''
      )}
      <section className="mx-auto max-w-screen-8xl px-4 pb-[7.75rem]">
        {(!unSubscribe && !subscribeSubmit) || (unSubscribe && !unSubscribeSubmit) ? (
          <h1 className="mt-6 text-center text-[1.688rem] font-semibold after:mx-auto after:mt-4 after:block after:h-[0.063rem] after:w-[9.375rem] after:bg-[#B19A6A] after:content-[''] lg:mt-4">
            {unSubscribe && !unSubscribeSubmit
              ? mapLocaleToMeaningfulFormat(router.locale).unsubscribe
              : mapLocaleToMeaningfulFormat(router.locale).communicationPreference}
          </h1>
        ) : (
          ''
        )}
        {!unSubscribe && !subscribeSubmit && (
          <SubscriptionPreferences
            setSubscribeSubmit={setSubscribeSubmit}
            setConfirmEmail={setConfirmEmail}
            confirmEmail={confirmEmail}
            setUnSubscribe={setUnSubscribe}
            isSubscribed={isSubscribed}
            email={emailId}
            setEmail={setEmailId}
            setBreadCrumLevel={setBreadCrumLevel}
            setIsSubscribed={setIsSubscribed}
            setSubscriptionList={setSubscriptionList}
          />
        )}
        {unSubscribe && !unSubscribeSubmit && (
          <UnSubscriptionPreferences
            emailId={emailId}
            isSubscribed={isSubscribed}
            setUnSubscribeSubmit={setUnSubscribeSubmit}
            setIsSubscribed={setIsSubscribed}
            subscriptionList={subscriptionList}
            setSubscriptionList={setSubscriptionList}
          />
        )}

        {subscribeSubmit && (
          <div className="mt-6 text-center">
            <div className="inline-block text-left">
              <p className="mb-2 text-2xl font-bold">{mapLocaleToMeaningfulFormat(router.locale).thankYou}</p>
              <p className="mb-1 text-sm">
                {mapLocaleToMeaningfulFormat(router.locale).successfullyUpdatedYourPreferences}
              </p>
              <p className="mb-1 text-sm">{mapLocaleToMeaningfulFormat(router.locale).changeYourPreferences}</p>
              <button
                type="button"
                id="back"
                className="mb-1 flex h-[44px] w-[80px] items-center justify-center rounded-md bg-[#404040] px-6 text-sm font-bold leading-[17px] text-white focus:outline-offset-2 "
                onClick={handleSubscribe}
              >
                {mapLocaleToMeaningfulFormat(router.locale).back}
              </button>
            </div>
          </div>
        )}
        {unSubscribeSubmit && (
          <div className="mt-6 text-center">
            <div className="inline-block text-left">
              <p className="mb-2 text-2xl font-bold">{mapLocaleToMeaningfulFormat(router.locale).unSubscribed}</p>
              <p className="mb-1 text-sm">{mapLocaleToMeaningfulFormat(router.locale).sorryToSeeYouGo}</p>
              <p className="mb-1 text-sm">{mapLocaleToMeaningfulFormat(router.locale).signInAgain}</p>
              <button
                type="button"
                id="back"
                className="mb-1 flex h-[44px] w-[80px] items-center justify-center rounded-md bg-[#404040] px-6 text-sm font-bold leading-[17px] text-white focus:outline-offset-2 "
                onClick={handleUnSubscribe}
              >
                {mapLocaleToMeaningfulFormat(router.locale).back}
              </button>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default CommunicationPreference;
