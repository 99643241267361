import React, { Fragment, useState } from 'react';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { WISHLIST_SHARED_SUCCESSFULLY, WISHLIST_SHARE_EMAIL_SENT_ERROR } from 'helpers/constants/messages';
import { useNotification } from 'helpers/notification/notificationContext';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { shareWishlistValidationSchema } from 'helpers/utils/validationSchema/list-share';
import { mode, theme } from 'tailwind.config';
import { useWishlist } from 'frontastic';
import CloseIcon from '../../icons/sol/close';
import ReCaptcha from '../recaptcha';

const shareListValidationValue = {
  shareEmail: '',
  message: '',
};

interface ListShareModalProps {
  modelOpen?: boolean;
  onClose?: () => void;
  listHeading: string;
  listShareByLink?: string;
  listShareByLinkSubheading?: string;
  listShareByEmail?: string;
  listShareByEmailSubheading?: string;
  copyURL?: () => void;
  linkCopy: any;
  shareButtonContent?: string;
  cancelButtonContent?: string;
  url?: string;
  btnContent?: string;
}

function ListShare({
  modelOpen,
  onClose,
  listHeading,
  listShareByLink,
  listShareByLinkSubheading,
  listShareByEmail,
  listShareByEmailSubheading,
  shareButtonContent,
  cancelButtonContent,
  copyURL,
  url,
  linkCopy,
  btnContent,
}: ListShareModalProps) {
  const router = useRouter();
  const { shareWishlist, data: wishlist } = useWishlist();
  const { showNotification } = useNotification();
  const [isReCaptchaVerified, setIsReCaptchaVerified] = useState(false);
  const [reCaptchaError, setReCaptchaError] = useState(false);

  const handleShareWishlistEmail = async (values: any) => {
    if (isReCaptchaVerified) {
      const email = values?.shareEmail ?? undefined;
      const message = values?.message ?? undefined;
      const response = await shareWishlist(email, message, url, wishlist);

      if (response?.status) {
        showNotification(WISHLIST_SHARED_SUCCESSFULLY, 'success');
        onClose();
      } else {
        showNotification(WISHLIST_SHARE_EMAIL_SENT_ERROR, 'error');
      }
    } else {
      setReCaptchaError(true);
    }
  };
  const renderError = (message: any) => <p className="text-sm leading-[17px] text-sol-700">{message}</p>;
  return (
    <Transition.Root show={modelOpen} as={Fragment}>
      <Dialog className={`${mode} ${theme} default fixed inset-0 z-10 overflow-y-auto`} onClose={onClose}>
        <>
          <section className="  flex  items-end justify-center px-4 pt-4 pb-20 text-left sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <section className="absolute inset-0" onClick={onClose}>
                {/* eslint-disable */}
                <section
                  className="absolute top-1/2 left-1/2 max-h-[90vh] w-[90%] -translate-x-1/2 -translate-y-1/2 overflow-auto overflow-y-auto bg-white py-6 px-4 dark:bg-primary-200 sm:px-6 md:w-[500px] lg:px-8"
                  onClick={(e) => e.stopPropagation()}
                >
                  <section className="flex justify-between">
                    <h1 className="mx-auto mb-9 w-max text-center text-2xl font-bold leading-5 tracking-wider text-sol-400 after:mx-auto after:mt-4 after:block after:h-[1px] after:w-36 after:bg-[#B19A6A] after:content-['']">
                      {listHeading}
                    </h1>
                    <section className="pt-2">
                      <button type="button" onClick={onClose}>
                        <CloseIcon className="fill-sol-300 " />
                      </button>
                    </section>
                  </section>
                  <section className="mb-6">
                    <p className="text-base font-bold leading-[29px] text-sol-400">{listShareByLink}</p>
                    <p className="text-sm font-normal leading-4 text-sol-400">{listShareByLinkSubheading}</p>
                    <section className="border-black mt-6 flex items-center border-[1px]">
                      <input
                        readOnly
                        type="text"
                        id="shareURL"
                        defaultValue={url}
                        className="text-black block w-full rounded-r-md border-0 bg-gray-50 py-2 text-left text-sm focus:outline-offset-0 focus:ring-0"
                      />
                      <section>
                        <button
                          type="button"
                          id="shareListBtn"
                          className={`w-full px-6 sm:px-4 ${
                            linkCopy
                              ? 'border-l border-sol-500 bg-white'
                              : 'rounded-r-md bg-sol-500 px-2 py-2 text-white focus:outline-offset-2'
                          }`}
                          onClick={() => {
                            navigator.clipboard.writeText(url);
                            copyURL();
                          }}
                        >
                          {linkCopy ? `Copied` : btnContent}
                        </button>
                      </section>
                    </section>
                    <p className="mt-10 text-base font-bold leading-[29px] text-sol-400">{listShareByEmail}</p>
                    <p className="text-sm font-normal leading-4 text-sol-400">{listShareByEmailSubheading}</p>
                  </section>
                  <section>
                    <Formik
                      initialValues={shareListValidationValue}
                      validationSchema={shareWishlistValidationSchema}
                      onSubmit={(values) => handleShareWishlistEmail(values)}
                    >
                      {({ errors, touched, handleBlur }) => (
                        <Form>
                          <label
                            htmlFor="shareEmail"
                            className="requiredLabel text-base font-semibold leading-[17px] text-sol-300"
                          >
                            {mapLocaleToMeaningfulFormat(router.locale).emailAddress}
                          </label>

                          <Field
                            type="text"
                            name="shareEmail"
                            id="shareEmail"
                            onBlur={handleBlur}
                            className={`input-text-primary mt-3 !mb-0 !w-full ${
                              errors?.shareEmail && touched?.shareEmail ? '!border-red-600' : ''
                            }`}
                          />
                          {errors.shareEmail && touched.shareEmail && (
                            <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{errors.shareEmail}</p>
                          )}
                          <section className="mt-3">
                            <label className="text-base font-semibold leading-[17px] text-sol-300">
                              {mapLocaleToMeaningfulFormat(router.locale).message}
                            </label>
                            <Field
                              as="textarea"
                              name="message"
                              id="message"
                              onBlur={handleBlur}
                              rows={3}
                              className="mt-3 w-full resize-none"
                            />
                            {/* <textarea rows={3} className="mt-3 w-full resize-none"></textarea> */}
                          </section>
                          <ReCaptcha
                            setIsReCaptchaVerified={setIsReCaptchaVerified}
                            setReCaptchaError={setReCaptchaError}
                          />
                          {reCaptchaError && (
                            <span className="text-xs font-semibold text-red-500">
                              {mapLocaleToMeaningfulFormat(router.locale).reCaptchaError}
                            </span>
                          )}
                          <section className=" mt-8 flex flex-col items-center gap-4 md:mb-4 md:flex-row md:gap-6">
                            <button
                              type="submit"
                              id="shareListBtn"
                              className={`flex  w-full items-center justify-center rounded-md bg-[#404040] px-16 py-2 text-white focus:outline-offset-2 md:w-auto ${
                                Object.keys(errors).length === 0 ? '' : 'cursor-not-allowed'
                              }`}
                            >
                              {shareButtonContent}
                            </button>
                            <button
                              type="button"
                              id="cancelListShareBtn"
                              onClick={onClose}
                              className="btn-cancel flex w-full items-center justify-center py-2 underline-offset-2 md:w-auto"
                            >
                              {cancelButtonContent}
                            </button>
                          </section>
                        </Form>
                      )}
                    </Formik>
                  </section>
                </section>
              </section>
            </Transition.Child>
          </section>
        </>
      </Dialog>
    </Transition.Root>
  );
}

export default ListShare;
