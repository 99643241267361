import BlogCategoryPage from 'components/sol-ui/cms/blog-category';
import { useEffect, useState } from 'react';

const BlogCategoryTastic = (props) => {
  const [tags, setTags] = useState([]);
  const [category, setCategory] = useState();

  const categories = props?.data?.data?.dataSource?.content;
  const blogs = props?.pageFolder?.dataSourceConfigurations[1]?.preloadedValue?.blogs;

  useEffect(() => {
    setCategory(props?.pageFolder?.dataSourceConfigurations[1]?.preloadedValue?.blogCategory[0]);
  }, [props]);

  const getTags = async () => {
    let tagsresult = [];
    await blogs.map((blog) => {
      if (blog?.tag != undefined) {
        if (tagsresult.hasOwnProperty(blog?.tag?.title)) tagsresult[blog?.tag?.title] += 1;
        else tagsresult[blog?.tag?.title] = 1;
      }
    });
    // let result = await Object.keys(tagsresult).map((key) => [key, tagsresult[key]]);
    // setTags(result);
    setTags(tagsresult);
  };

  useEffect(() => {
    getTags();
  }, [category]);

  return <BlogCategoryPage category={category} categories={categories} blogs={blogs} tags={tags} />;
};

export default BlogCategoryTastic;
