import React from 'react';
import convertToRichResultJSON from 'helpers/utils/json-ld/convertHitsToRichResultsJSON';

const PLPRichText = ({ hits }) => {
  const htmlContent =
    '<script type="application/ld+json">' + JSON.stringify(convertToRichResultJSON(hits)) + '</script>';
  return (
    <>
      <section dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </>
  );
};
export default PLPRichText;
