import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { LINEITEM_ADDED, LINEITEM_ADDED_UNSUCCESSFUL } from 'helpers/constants/messages';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { useNotification } from 'helpers/notification/notificationContext';
import { AMPLIENCE_IMAGE_URL_FORMAT } from 'helpers/utils/constant';
import format from 'helpers/utils/format';
import calculateDynamicImageWidth from 'helpers/utils/getImageSize';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import omitFileExtension from 'helpers/utils/omitFileExtension';
import { routerURL } from 'helpers/utils/routerUrl';
import { desktop } from 'helpers/utils/screensizes';
import { triggerMiniCart } from 'helpers/utils/triggerMiniCart';
import { useCart } from 'frontastic';
export interface Props {
  modelProducts: any;
  selectedVariant: Variant;
  isRugModel: boolean;
  isBulbModel: boolean;
  isQuickView?: boolean;
  onClose?: () => void;
  onAdd?: () => void;
  modelSubheading?: string;
  baseQuantity: number;
}

const BulbSelector = ({
  selectedVariant,
  modelSubheading,
  modelProducts,
  isQuickView,
  isRugModel,
  isBulbModel,
  onClose,
  onAdd,
  baseQuantity,
}: Props) => {
  //next/router
  const router = useRouter();
  const [isDesktopSize] = useMediaQuery(desktop);
  const [products, setProducts] = useState<any[]>([]);
  const baseProductQuantity = selectedVariant?.attributes?.numberOfLights
    ? selectedVariant?.attributes?.numberOfLights * baseQuantity
    : baseQuantity;
  const { showNotification } = useNotification();
  const dynamicWidth = calculateDynamicImageWidth(300, 300, 300);
  const { addItem } = useCart();
  useEffect(() => {
    const mappedProducts = modelProducts?.products?.map((product: any) => {
      return {
        name: product?.name,
        sku: product?.masterVariant?.sku,
        slug: product?.slug,
        image: product?.masterVariant?.images[0]?.url,
        price: product?.masterVariant?.price?.value,
        quantity: baseProductQuantity,
        size: product?.masterVariant?.attributesRaw?.find((attr: any) => attr?.name === 'actualSize'),
      };
    });
    setProducts(mappedProducts);
  }, [modelProducts]);
  const handleQuantity = (e, index) => {
    const count = parseInt(e.target.value);
    const currentItems = [...products];
    if (!isNaN(count)) {
      if (count >= 1 && count <= 999) {
        currentItems[index].quantity = count;
      } else if (count === 0) {
        currentItems[index].quantity = 1;
      }
    } else {
      currentItems[index].quantity = null;
    }
    setProducts(currentItems);
  };

  const handleUpdateQuantity = (index) => {
    const currentItems = [...products];
    if (!currentItems[index].quantity) {
      currentItems[index].quantity = 1;
    }
    setProducts(currentItems);
  };

  const increaseQuantity = (index: number) => {
    const currentItems = [...products];
    if (currentItems[index].quantity < 999) {
      currentItems[index].quantity += 1;
      setProducts(currentItems);
    }
  };

  const decreaseQuantity = (index: number) => {
    const currentItems = [...products];

    if (currentItems[index].quantity > 1) {
      currentItems[index].quantity -= 1;
      setProducts(currentItems);
    }
  };

  const handleAddToCart = async (index: number) => {
    const product = products[index];
    if (product?.sku) {
      await addItem({ sku: product?.sku }, product?.quantity);
      onAdd();
    }
    setTimeout(() => {
      if (isDesktopSize && !isQuickView) {
        triggerMiniCart();
      }
    }, 1000);

    showNotification(LINEITEM_ADDED, 'success');
  };
  const handleSeeMoreSearch = () => {
    let searchQuery = routerURL?.seeMoreBulbs;
    if (isBulbModel) {
      const maxBulbWattage = selectedVariant?.attributes?.maxBulbWattage;
      const bulbBase = selectedVariant?.attributes?.bulbBase?.key;

      if (maxBulbWattage) {
        searchQuery = routerURL?.seeMoreBulbs;
      }

      if (bulbBase) {
        // searchQuery += `&bulbBase="${bulbBase}"`
      }
    }
    if (isRugModel) {
      const height = selectedVariant?.attributes?.height;
      if (height) {
        searchQuery = routerURL?.seeMoreRugs;
      }
    }
    router.push(searchQuery);
  };

  return (
    <>
      <section className="w-full">
        <p className="text-center">{modelSubheading}</p>
        <ul className="mt-8 grid grid-cols-2 gap-x-[22px] gap-y-[30px] md:grid-cols-3">
          {products?.map((product, index) => (
            <li key={index}>
              <section className="h-full w-full bg-sol-100">
                <a href={`/products/${product?.slug}`}>
                  <Image
                    loader={(options) => options.src}
                    width={241}
                    height={253}
                    src={format(AMPLIENCE_IMAGE_URL_FORMAT, [omitFileExtension(product?.image), dynamicWidth])}
                    alt={'#'}
                    sizes="100vw"
                  />
                </a>

                <section className="px-3 py-4 md:px-5">
                  <a href={`/products/${product?.slug}`} title={product?.name}>
                    <h4 className="font-base min-h-[40px] text-center font-semibold leading-5 text-sol-300 line-clamp-2">
                      {product?.name}
                    </h4>
                  </a>
                  {product?.size ? (
                    <p className="font-sm mt-3 text-center font-semibold leading-[17px] text-sol-300">
                      {!isBulbModel && `${mapLocaleToMeaningfulFormat(router.locale).size} : ${product?.size?.value} `}
                    </p>
                  ) : null}
                  <p className="font-sm mt-3 text-center font-semibold leading-[17px] text-sol-300">
                    {CurrencyHelpers.formatForCurrency(product?.price)}
                  </p>
                  <section className="mt-6 flex flex-col items-center gap-x-[10px] gap-y-4 md:flex-row">
                    <section className="relative flex w-[78px] items-center justify-between rounded-lg border">
                      <section>
                        <input
                          type="text"
                          value={product?.quantity}
                          onBlur={() => handleUpdateQuantity(index)}
                          onChange={(e) => handleQuantity(e, index)}
                          className="w-[53px] rounded-l-lg border-none text-center focus:ring-0"
                        />
                      </section>
                      <section className="flex h-[42px] flex-col items-center justify-center">
                        <button type="button" id="incrementBtn" onClick={() => increaseQuantity(index)}>
                          <MdOutlineKeyboardArrowUp
                            className={`h-5 w-5 pt-1 ${
                              product?.quantity === 999 ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer'
                            }`}
                          />
                        </button>
                        <button
                          type="button"
                          id="decrementBtn"
                          onClick={() => decreaseQuantity(index)}
                          disabled={product?.quantity === 1}
                        >
                          <MdOutlineKeyboardArrowDown
                            className={`h-5 w-5 pb-1 ${
                              product?.quantity === 1 ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer'
                            }`}
                          />
                        </button>
                      </section>
                    </section>
                    <button
                      type="button"
                      className="btn-primary-small !mb-0 h-11 w-full"
                      id={product?.sku}
                      onClick={() => handleAddToCart(index)}
                    >
                      {mapLocaleToMeaningfulFormat(router.locale).add}
                    </button>
                  </section>
                </section>
              </section>
            </li>
          ))}
        </ul>
        <section className="mx-auto mt-11 flex w-full flex-col items-center justify-center gap-5 lg:flex-row  lg:gap-10">
          <a
            href={
              isBulbModel
                ? `${routerURL.seeMoreBulbs}?bulbBase%5B%5D=${selectedVariant?.attributes?.bulbBase?.key}&sort=top_sellers`
                : isRugModel
                ? routerURL.seeMoreRugs
                : '#'
            }
            className="btn-primary-small !mb-0 flex h-11 w-full items-center justify-center sm:w-max"
            id="seeMoreBtn"
            onClick={handleSeeMoreSearch}
          >
            {(isBulbModel && mapLocaleToMeaningfulFormat(router.locale).seeMoreCompatibleBulbs) ||
              (isRugModel && mapLocaleToMeaningfulFormat(router.locale).seeMoreCompatibleRugs)}
          </a>
          <button
            type="button"
            className=" h-11 w-full rounded  border border-sol-500 bg-white px-5 py-2 text-center text-sm font-normal leading-[17px] text-sol-300 sm:w-max"
            id="keepShoppingBtn"
            onClick={onClose}
          >
            {mapLocaleToMeaningfulFormat(router.locale).keepShopping}
          </button>
        </section>
      </section>
    </>
  );
};

export default BulbSelector;
