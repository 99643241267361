import Image from 'next/image';
import Link from 'next/link';
import router, { useRouter } from 'next/router';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';

const TrendingNow = ({ content }) => {
  //next/router
  const router = useRouter();
  return (
    <>
      <section className="mx-auto mb-12 max-w-[1440px] px-5 md:px-10">
        <h1 className="relative mb-8 text-center before:absolute before:top-[54%] before:block before:h-[1px] before:w-full before:bg-sol-600 before:content-[''] lg:mb-10">
          <em className="relative bg-white px-3 text-[32px] font-semibold uppercase not-italic leading-[39px] md:px-10">
            {content?.title}
          </em>
        </h1>
        <ul className="grid grid-cols-2 gap-5 lg:grid-cols-4">
          {content?.data?.map((c, index) => (
            <li key={index}>
              <Link href={c?.ctaLink}>
                <a className="flex h-full w-full">
                  <section className="h-full w-full rounded-t-[6px] bg-sol-100">
                    <section className="flex w-full justify-center">
                      <Image
                        loader={(options) => options.src}
                        width={324}
                        height={200}
                        src={c?.image?.url}
                        alt={c?.alt}
                        className="rounded-t-md"
                      />
                    </section>
                    <section className="bg-sol-100 p-4">
                      <button
                        type="button"
                        className="text-center text-base font-semibold leading-5 text-sol-300  lg:text-left"
                      >
                        {c?.title}
                      </button>
                    </section>
                  </section>
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default TrendingNow;
