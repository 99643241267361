import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="20" viewBox="0 0 13 20" fill="none" className={className}>
    <path
      d="M0.000963498 9.565L6.44741 6.37667V0L0 3.18833V9.56582L0.000963498 9.565V15.9417L6.44741 12.7525V19.13L12.8939 15.9417V9.565L6.44741 12.7517L0.000963498 9.565Z"
      fill="#222222"
    />
    <path d="M6.44653 6.37727L12.8939 9.56478V3.18811L6.44653 6.37727Z" fill="#222222" />
    <path d="M6.44653 12.7525L12.8939 9.56495L6.44653 6.37744V12.7525Z" fill="#222222" />
  </svg>
);

export default Icon;
