import { Product } from '@Types/product/Product';
import CTProductListCard from 'components/sol-ui/products/ct-product-list/ct-product-list-card';
import { useEffect, useState } from 'react';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { useRouter } from 'next/router';

function YouMayAlsoNeedTastic({ data }) {
  const router = useRouter();
  const [products, setProducts] = useState([] as Product[]);

  useEffect(() => {
    if (data?.data?.dataSource?.products) {
      setProducts(data?.data?.dataSource?.products);
    }
  }, [data]);

  return (
    <>
      {products && products.length ? (
        <section className="mx-auto mb-5 max-w-[1440px]">
          <strong className="mt-5 mb-5 inline-block w-full text-[28px] font-semibold leading-[34px] text-sol-300">
            {mapLocaleToMeaningfulFormat(router.locale).youMayAlsoNeed}
          </strong>

          <section className="flex h-full w-full">
            <section className="w-full">
              <ul className="grid w-full grid-cols-2 gap-x-5 gap-y-[30px] md:gap-x-[27px] lg:grid-cols-4">
                {products.map((product, index) => (
                  <CTProductListCard data={product} key={index} />
                ))}
              </ul>
            </section>
          </section>
        </section>
      ) : (
        <></>
      )}
    </>
  );
}

export default YouMayAlsoNeedTastic;
