import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';

const InstagramBlogCategory = ({ category }) => {
  const [isDesktop] = useMediaQuery(desktop);

  return (
    <>
      <div className="flex justify-center p-4">
        <section className="max-w-4xl-sm mx-auto -mt-8  mb-8 p-2 md:mt-0 md:px-4">
          <div className="mx-auto w-[357px] md:w-[600px]">
            <Link href="https://www.instagram.com/shadesoflight">
              <a>
                <Image
                  loader={({ src }) => src}
                  src={category?.instagram?.desktopBannerImage?.url + '?fmt=auto&w=auto'}
                  alt={category?.instagram?.alt}
                  width={600}
                  height={160}
                  className="rounded-t-md"
                  sizes="100vw"
                />
              </a>
            </Link>
          </div>
        </section>
      </div>

      <section className="mx-auto -mt-12 mb-12 max-w-4xl px-4 md:mt-0 lg:px-0 ">
        <div>
          <ul className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
            {category?.instagram?.photos?.map((content, index) => (
              <li key={index}>
                <Link href={content?.link}>
                  <a>
                    <Image
                      loader={({ src }) => src}
                      src={content?.photo?.url + '?fmt=auto&w=auto'}
                      alt={content?.alt}
                      width={288}
                      height={288}
                      className="rounded-md"
                      sizes="100vw"
                    />
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
};

export default InstagramBlogCategory;
