import { Product } from 'schema-dts';

const convertToRichResultJSON = (hits) => {
  const items = [];
  const formatPrice = (hit: any) => {
    if (hit?.price && hit?.price > 0) {
      return hit?.price;
    } else if (hit?.['price-USD'] && hit?.['price-USD'] > 0) {
      return hit?.['price-USD'];
    }
    return 0;
  };
  hits.forEach((hit) => {
    items.push({
      '@context': 'https://schema.org',
      '@type': 'Product',
      name: `${hit?.name['en-US']}`,
      description: `${hit?.description['en-US']}`,
      image: hit?.images?.map((image) => {
        return image?.url;
      }),
      url: location.href,
      brand: {
        '@type': 'Brand',
        name: 'Shades of light',
      },
      offers: {
        '@type': 'Offer',
        price: `${formatPrice(hit)}`,
        priceCurrency: 'USD',
      },
    } as Product);
  });
  return items;
};

export default convertToRichResultJSON;
