import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import type { Address as AddressType } from '@Types/account/Address';
import { ADDRESS_CREATED, ADDRESS_NOT_VALID, SERVER_ERROR } from 'helpers/constants/messages';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { useNotification } from 'helpers/notification/notificationContext';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { desktop } from 'helpers/utils/screensizes';
import { useAccount } from 'frontastic';
import AccountNav from '../account/account-nav';
import ContinueShoppingButton from '../account/continueShoppingButton';
import LinksModal from '../account/linksModel';
import AddNewAddressModal from '../account/modal/edit-profile';
import Address from '../account/sections/accountListing/address';
import Breadcrumb from '../cms/Breadcrumb';

export interface AddressReturnProps {
  addressId: string;
}

function AddressBook() {
  const router = useRouter();
  const [isDesktop] = useMediaQuery(desktop);
  //create address modal
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [addresses, setAddresses] = useState([]);
  //account data
  const { loggedIn, account, addAddress, getCustomerAddresses, shippingLocations, validateAddress } = useAccount();
  const [loading, setLoading] = useState(false);
  const openCreateModal = () => {
    setCreateModalOpen(true);
  };

  const setAddressesData = useCallback(() => {
    if (loggedIn) {
      getCustomerAddresses().then((data) => {
        if (data && data?.length > 0) {
          data = data?.sort((address) => (address.isDefaultBillingAddress ? -1 : 1));
          setAddresses(data);
        }
      });
    } else {
      setAddresses([]);
    }
  }, []);

  useEffect(() => {
    setAddressesData();
  }, []);

  useEffect(() => {
    setAddressesData();
  }, [account]);

  const closeCreateModal = () => {
    setCreateModalOpen(false);
  };

  //global notification
  const { showNotification } = useNotification();

  //user addresses

  const handleAddAddress = async (address: AddressType, isDefault: boolean) => {
    setLoading(true);
    const newAddress: AddressType = address;
    newAddress.firstName = newAddress.firstName.trim();
    newAddress.lastName = newAddress.lastName.trim();
    if (addresses?.length === 0) {
      isDefault = true;
    }

    const validate = await validateAddress(newAddress);
    if (!validate.isValid) {
      showNotification(ADDRESS_NOT_VALID, 'error');
      return;
    }

    address.isDefaultBillingAddress = isDefault;
    address.isDefaultShippingAddress = isDefault;
    try {
      const addressResult = await addAddress(newAddress);
      //start to show notification
      if (addressResult?.addresses) {
        showNotification(ADDRESS_CREATED, 'success');
        setAddressesData();
      }
    } catch (error) {
      showNotification(SERVER_ERROR, 'error');
    }
    setLoading(false);
    closeCreateModal();
  };

  return (
    <>
      <Breadcrumb breadcrumLevelLink={false} activePage={mapLocaleToMeaningfulFormat(router.locale).addressBook} />
      <section className="mx-auto max-w-screen-8xl px-4 pb-10">
        <section>{!isDesktop && <AccountNav />}</section>
        <section>
          <h1 className="mt-6 text-center text-[1.688rem] font-semibold after:mx-auto after:mt-4 after:block after:h-[0.063rem] after:w-[9.375rem] after:bg-[#B19A6A] after:content-[''] lg:mt-4">
            {mapLocaleToMeaningfulFormat(router.locale).addressBook}
          </h1>
          <p className="mt-4 text-center">
            {addresses?.length} {mapLocaleToMeaningfulFormat(router.locale).addresses}
          </p>
        </section>
        <section className="relative mt-4 flex w-full justify-end lg:mt-6 ">
          <button type="button" id="addAddressButton" className="btn-primary-small" onClick={openCreateModal}>
            {mapLocaleToMeaningfulFormat(router.locale).addNewAddress}
          </button>
        </section>
        <section className="flex-col gap-5 md:mt-4 lg:mt-2 lg:flex lg:flex-row">
          {isDesktop && <AccountNav />}
          <section className="mt-3 w-full lg:mt-0">
            <section>
              {addresses?.length === 0 && (
                <>
                  <p className="border p-10">{mapLocaleToMeaningfulFormat(router.locale).noSavedAddress}</p>
                </>
              )}
              <ul className="mb-5 grid grid-cols-1 gap-4 text-sm font-normal leading-7 text-sol-300 lg:grid-cols-2">
                {addresses?.map((address, i) => (
                  <li key={i} className="rounded-md border-2 p-5">
                    <Address address={address} />
                    <LinksModal
                      defaultName={mapLocaleToMeaningfulFormat(router.locale).defaultAddress}
                      setAsDefault={mapLocaleToMeaningfulFormat(router.locale).setAsDefaultAddress}
                      address={address}
                    />
                  </li>
                ))}
              </ul>
            </section>
          </section>
          <section>{!isDesktop && <ContinueShoppingButton />}</section>
        </section>
      </section>
      <AddNewAddressModal
        open={createModalOpen}
        onClose={closeCreateModal}
        modalHeading={mapLocaleToMeaningfulFormat(router.locale).addNewAddress}
        primaryBtnContent={mapLocaleToMeaningfulFormat(router.locale).save}
        handleAddAddress={handleAddAddress}
        setAddressesData={setAddressesData}
        loading={loading}
        isBillingAddress={true}
      />
    </>
  );
}

export default AddressBook;
