import { Fragment, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import CloseIcon from 'components/icons/sol/close';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { useDarkMode, useProduct } from 'frontastic';
import BulbSelector from './bulb-selector';

export interface BulbSelectorModalProps {
  openModal?: boolean;
  onClose?: () => void;
  onAdd?: () => void;
  selectedVariant?: Variant;
  productData?: Product;
  modelProducts?: any;
  isRugModel: boolean;
  isQuickView?: boolean;
  isBulbModel: boolean;
  baseQuantity: number;
}

const BulbSelectorModal: React.FC<BulbSelectorModalProps> = ({
  openModal,
  selectedVariant,
  modelProducts,
  productData,
  isRugModel,
  isBulbModel,
  onClose,
  isQuickView,
  onAdd,
  baseQuantity,
}) => {
  //next/router
  const router = useRouter();
  //Darkmode
  const { mode, theme } = useDarkMode();
  const modelheader = `${
    productData?.categories
      ? `${((isBulbModel || isRugModel) && mapLocaleToMeaningfulFormat(router.locale)?.thankYou)?.toUpperCase()}`
      : ''
  }`;

  const modelSubheading = `${
    productData?.categories
      ? `${
          (isBulbModel && `${mapLocaleToMeaningfulFormat(router.locale).bulbModelSubHeading}`) ||
          (isRugModel && `${mapLocaleToMeaningfulFormat(router.locale).rugModelSubHeading}`)
        }`
      : ''
  }`;
  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog className={`${mode} ${theme} default fixed inset-0 z-[200] overflow-y-auto`} onClose={onClose}>
        <>
          <section className="flex items-end justify-center px-4 pt-4 pb-20 text-left sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <section className="absolute inset-0" onClick={onClose}>
                <section
                  className="absolute top-1/2 left-1/2 w-[90%] max-w-[827px] -translate-x-1/2 -translate-y-1/2 overflow-auto bg-white  py-6  dark:bg-primary-200"
                  onClick={(e) => e.stopPropagation()}
                >
                  <section className="mt-2 flex justify-between px-6">
                    <h1 className="mx-auto mb-3 w-max text-center text-2xl font-bold leading-[29px] text-sol-400 after:mx-auto after:mt-4 after:block after:h-[1px] after:w-[150px] after:bg-[#B19A6A] after:content-['']">
                      {modelheader}
                    </h1>
                    <section className="pt-2">
                      <button type="button" onClick={onClose}>
                        <CloseIcon className="fill-sol-300 " />
                      </button>
                    </section>
                  </section>
                  <section className="px-6">
                    <section className="customtablescrollbar max-h-[calc(90vh_-_100px)] overflow-y-auto pr-4">
                      <BulbSelector
                        isQuickView={isQuickView}
                        modelProducts={modelProducts}
                        modelSubheading={modelSubheading}
                        isRugModel={isRugModel}
                        isBulbModel={isBulbModel}
                        selectedVariant={selectedVariant}
                        onClose={onClose}
                        onAdd={onAdd}
                        baseQuantity={baseQuantity}
                      />
                    </section>
                  </section>
                </section>
              </section>
            </Transition.Child>
          </section>
        </>
      </Dialog>
    </Transition.Root>
  );
};

export default BulbSelectorModal;
