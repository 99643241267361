import { useEffect } from 'react';
import Breadcrumb from 'components/sol-ui/cms/Breadcrumb';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
const CcpaForm = () => {
  const [isDesktop] = useMediaQuery(desktop);
  return (
    <>
      {/* TODO: Static the comes from amplience on later*/}
      <Breadcrumb breadcrumLevelLink={true} activePage="dsar" />
      <section>
        <div>
          <h1 className="mt-16 px-4 pb-6 pt-4 text-center text-3xl font-semibold md:mt-4">CCPA Policy</h1>
          <div
            className={`relative mx-auto mb-8 text-center before:absolute before:left-0 before:right-0 before:mx-auto before:block before:h-[2px] before:w-[40%] before:bg-sol-600 before:font-bold before:content-[''] lg:mb-6 lg:w-[15%]`}
          ></div>
        </div>
      </section>
      <section className="mx-auto mb-12 flex max-w-screen-8xl px-2 sm:px-6 lg:px-8">
        <ul className="h-auto w-full">
          <div className="flex items-center justify-center">
            <div className="relative max-w-screen-8xl">
              <div className="relative  px-4 pb-4">
                <p>
                  Shades of Light values our customers&quot; privacy. As stated in our Privacy Policy, under certain
                  states&quot; laws you have rights regarding your personal information. If you wish to make a request
                  related to those rights, please complete and submit the form below. Shades of Light will use the
                  information in this form to respond to your request, usually within 45 days. By providing your request
                  you verify that the information you provide to us is accurate and truthful. Shades of Light will
                  maintain a record of your request.{' '}
                </p>
              </div>
            </div>
          </div>
        </ul>
      </section>
      <iframe
        frameBorder="0"
        id="osno-dsar-form_1687604772336"
        src="https://my.datasubject.com/16COsxTZpgKib4OeQ/25236"
        width="100%"
        height="0"
        style={{ height: '100vh', width: '100vw', border: '0' }}
      ></iframe>
    </>
  );
};

export default CcpaForm;
