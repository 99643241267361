import React, { useContext, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ReCaptcha from 'components/sol-ui/recaptcha';
import { PAYMENT_PROFILE, WISHLIST_DATA } from 'helpers/constants/localStorage';
import { SIGNED_IN, LOGIN_FAILED, SERVER_ERROR } from 'helpers/constants/messages';
import { useFormat } from 'helpers/hooks/useFormat';
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import { useNotification } from 'helpers/notification/notificationContext';
import { PageLoadContext } from 'helpers/page-load-context/pageLoadContext';
import Redirect from 'helpers/redirect';
import { getReferenceTarget } from 'helpers/reference';
import { gtmUserLogin } from 'helpers/utils/gtm-events/gtmUser';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import validationSchema from 'helpers/utils/validationSchema/login';
import { useAccount } from 'frontastic';
import { addToWishlist } from 'frontastic/actions/wishlist';
import Password from '../../form-fields/password';
import Spinner from '../../notification/spinner';
import { LoginProps } from '../index';
type LoginType = {
  email: string;
  password: string;
  remember: boolean;
  loginTitle?: string;
  closeModal?: () => void;
  showRememberButton?: boolean;
  isCheckout?: boolean;
};
const logInInitialValues = {
  email: '',
  password: '',
  remember: false,
};
let loginStatus = '';

const Login: React.FC<LoginProps> = ({
  accountLink,
  loginLink,
  loginTitle,
  isPopOverPanel,
  closeModal,
  showRememberButton,
  setIsErrorModelOpen,
  isCheckout,
}) => {
  //next/router
  const router = useRouter();

  const { showNotification, showInlineNotification } = useNotification();

  const { login } = useAccount();
  //processing...
  const [loading, setLoading] = useState(false);
  const { removeAsGuest, removeItem } = useLocalStorage();
  const { setIsPageLoading } = useContext(PageLoadContext);
  const [isReCaptchaVerified, setIsReCaptchaVerified] = useState(false);
  const [reCaptchaError, setReCaptchaError] = useState(false);
  const [isComponentMounted, setIsComponentMounted] = useState(true);
  const [isLoginFieldsClicked, setIsLoginFieldsClicked] = useState(false);
  useEffect(() => {
    return () => {
      // This function runs when the component unmounts
      setIsComponentMounted(false);
    };
  }, []);

  const handleLogin = async (data: LoginType) => {
    if (isReCaptchaVerified) {
      if (router?.asPath?.includes(routerURL?.login) && !router?.asPath?.includes(routerURL?.checkout)) {
        setIsPageLoading(true);
      }
      setLoading(true);

      try {
        const response = await login(data.email, data.password, data.remember);

        if (isComponentMounted) {
          // Checking that if the component is still mounted before updating the state
          if (!response.accountId) {
            if (response.toString() === LOGIN_FAILED) {
              loginStatus = LOGIN_FAILED;
              setIsPageLoading(false);
              setTimeout(() => {
                loginStatus = '';
              }, 2000);
            } else {
              loginStatus = LOGIN_FAILED;
              setIsPageLoading(false);
              setIsErrorModelOpen(true);
            }
            router.push(getReferenceTarget(loginLink));
          } else {
            setLoading(false);
            removeAsGuest();
            removeItem(PAYMENT_PROFILE);
            if (!router?.asPath?.includes(routerURL?.checkout)) {
              setIsPageLoading(true);
              if (router?.query['redirectURL']) {
                const redirectURL = router?.query['redirectURL'] as string;
                await router.push(redirectURL);
              } else {
                await router.push(getReferenceTarget(accountLink));
              }
              setIsPageLoading(false);
            }
            showNotification(SIGNED_IN, 'success');
            gtmUserLogin(response);
            const wishListData = JSON?.parse(localStorage?.getItem(WISHLIST_DATA));
            if (wishListData && wishListData?.sku) {
              addToWishlist(wishListData.sku, wishListData?.quantity);
            }
            localStorage?.removeItem(WISHLIST_DATA);
          }
        }
      } catch (err) {
      } finally {
        if (isComponentMounted) {
          // Checking if the component is still mounted ?
          setLoading(false);
          setIsPageLoading(false);
        }
      }
    } else {
      setReCaptchaError(true);
    }
  };

  const handleLoginModal = async (data: LoginType) => {
    if (isCheckout && !isReCaptchaVerified) {
      setReCaptchaError(true);
    } else {
      if (router?.asPath?.includes(routerURL?.login) && !router?.asPath?.includes(routerURL?.checkout)) {
        setIsPageLoading(true);
      }
      // Processing starts
      setLoading(true);
      try {
        const response = await login(data.email, data.password, data.remember);

        if (!response.accountId) {
          if (response.toString() === LOGIN_FAILED) {
            setIsPageLoading(false);
            showNotification(response.toString(), 'error');
          } else {
            setIsPageLoading(false);
            showInlineNotification(response.toString(), 'error');
            setIsErrorModelOpen(true);
          }
          closeModal();
          router.push(getReferenceTarget(loginLink));
        } else {
          setLoading(false);
          removeAsGuest();
          if (!router?.asPath?.includes(routerURL?.checkout)) {
            setIsPageLoading(true);
            if (router.query['redirectURL']) {
              const redirectURL = router.query['redirectURL'] as string;
              await router.push(redirectURL);
            } else {
              await router.push(getReferenceTarget(accountLink));
            }
            setIsPageLoading(false);
          }
          showNotification(SIGNED_IN, 'success');
          gtmUserLogin(response);
        }
      } catch (err) {}
      //processing ends
      setLoading(false);
      setIsPageLoading(false);
    }
  };
  const handleFieldClick = (setFieldValue) => {
    if (!isLoginFieldsClicked) {
      setIsLoginFieldsClicked(true);
      setFieldValue('email', '');
      setFieldValue('password', '');
    }
  };
  return (
    <>
      <section className="">
        <Formik
          initialValues={logInInitialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => (isPopOverPanel ? handleLogin(values) : handleLoginModal(values))}
        >
          {({ errors, touched, handleBlur, setFieldValue }) => (
            <Form>
              <section>
                <section>
                  <h1
                    className={`mb-e text-center font-semibold leading-[34px] text-[#1B3144]  ${
                      loginTitle === mapLocaleToMeaningfulFormat(router.locale).logInAccount
                        ? 'text-[24px] lg:mb-4'
                        : 'text-[28px] lg:mb-14'
                    }`}
                  >
                    {loginTitle}
                  </h1>

                  <section>
                    <label htmlFor="loginEmail" className="text-sm font-semibold leading-[17px] text-sol-300">
                      {mapLocaleToMeaningfulFormat(router.locale).email}
                    </label>
                    <Field
                      type="text"
                      id="loginEmail"
                      name="email"
                      onBlur={handleBlur}
                      onClick={() => handleFieldClick(setFieldValue)}
                      className={`input-text-primary mt-3 !w-full text-base
                      ${errors?.email && touched?.email ? '!border-red-600' : ''}`}
                    />
                  </section>
                  {errors.email && touched.email && (
                    <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{errors.email}</p>
                  )}
                  <section className="mt-3">
                    <label htmlFor="loginPassword" className="text-sm font-semibold leading-[17px] text-sol-300">
                      {mapLocaleToMeaningfulFormat(router.locale).password}
                    </label>
                    <div onClick={() => handleFieldClick(setFieldValue)}>
                      <Password
                        name="password"
                        id="loginPassword"
                        onBlur={handleBlur}
                        className={errors?.password && touched?.password ? '!border-red-600' : ''}
                      />
                    </div>
                  </section>
                  {errors.password && touched.password && (
                    <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{errors.password}</p>
                  )}
                  {/* {showRememberButton && (
                    <section className="mt-4 flex items-center gap-4">
                      <Field
                        type="checkbox"
                        name="remember"
                        className="h-4 w-4 appearance-none rounded-[2px] border-none border-black-300 bg-transparent text-white ring-2 ring-sol-300  focus:ring-sol-300"
                        id="loginRememberMe"
                      />
                      <label className="text-sm font-normal leading-[17px] text-sol-300" htmlFor="loginRememberMe">
                        {mapLocaleToMeaningfulFormat(router.locale).rememberMe}
                      </label>
                    </section>
                  )} */}
                  {isPopOverPanel && (
                    <section>
                      <ReCaptcha
                        setIsReCaptchaVerified={setIsReCaptchaVerified}
                        setReCaptchaError={setReCaptchaError}
                      />
                      {reCaptchaError && (
                        <span className="text-xs font-semibold text-red-500">
                          {mapLocaleToMeaningfulFormat(router.locale).reCaptchaError}
                        </span>
                      )}
                    </section>
                  )}

                  {isCheckout && (
                    <section>
                      <ReCaptcha
                        setIsReCaptchaVerified={setIsReCaptchaVerified}
                        setReCaptchaError={setReCaptchaError}
                      />
                      {reCaptchaError && (
                        <span className="text-xs font-semibold text-red-500">
                          {mapLocaleToMeaningfulFormat(router.locale).reCaptchaError}
                        </span>
                      )}
                    </section>
                  )}

                  <section className="mt-5 flex flex-col items-center gap-4 md:flex-row">
                    <button
                      type="submit"
                      id="loginButton"
                      className={`flex h-[44px] w-full items-center justify-center rounded-md bg-[#404040] px-6 text-sm font-bold leading-[17px] text-white focus:outline-offset-2 md:w-auto ${
                        Object.keys(errors).length === 0 ? '' : 'cursor-not-allowed'
                      }`}
                    >
                      {loading ? <Spinner /> : mapLocaleToMeaningfulFormat(router.locale).logIn}
                    </button>
                    <Link href={routerURL?.forgotPassword}>
                      <a
                        className="text-sm font-normal leading-[17px] text-sol-300 hover:underline"
                        onClick={closeModal}
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).forgotPassword}
                      </a>
                    </Link>
                  </section>
                  {isPopOverPanel && loginStatus === LOGIN_FAILED && (
                    <section>
                      <div className="my-5 rounded border-2 border-red-600 bg-red-100 p-3">{loginStatus}</div>
                    </section>
                  )}
                </section>
              </section>
            </Form>
          )}
        </Formik>
      </section>
    </>
  );
};

export default Login;
