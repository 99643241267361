import Image from 'next/image';
import Link from 'next/link';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';

const ThreeColumnCategory = ({ contents }) => {
  const [isDesktop] = useMediaQuery(desktop);

  return (
    <>
      <section className="mx-auto mb-8 max-w-screen-8xl lg:mb-12">
        <ul className="grid grid-cols-1 gap-8 lg:grid-cols-3 lg:gap-5">
          {contents?.map((content, index) => (
            <li key={index}>
              <Link href={content?.clpCtaLink}>
                <a className="flex h-full w-full">
                  <section className="h-full w-full bg-sol-100">
                    <section>
                      <Image
                        loader={(options) => options.src}
                        width={441}
                        height={309}
                        src={content?.image?.url}
                        alt={content?.clpAlt}
                        sizes="100vw"
                        className="rounded-t-md"
                      />
                    </section>
                    <section className="flex justify-between p-4">
                      <section>
                        <h1 className="mb-2 text-2xl font-semibold leading-[29px] text-sol-300">{content?.clpTitle}</h1>
                        <p className="text-sm leading-[17px]  text-sol-300">{content?.clpDescription}</p>
                      </section>
                      <section className="ml-4 mt-1 shrink-0">
                        <button
                          type="button"
                          id={'clpCtaLink' + content?.clpTitle?.trim()?.split(' ')?.join('')}
                          className="btn-primary-small inline-block px-4 py-2 text-sm !font-normal"
                        >
                          {content?.clpCtaLinkText}
                        </button>
                      </section>
                    </section>
                  </section>
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default ThreeColumnCategory;
