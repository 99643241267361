import React, { useEffect, useState } from 'react';
import { useStats } from 'react-instantsearch';
import Accordion from './filter-accordion';

type AccordionProps = {
  isClearItems?: boolean;
  title?: string;
  facetsDisplay?: any;
  setIsFiltersApplied?: any;
  canResetFilters?: boolean;
  setCanResetFilters?: any;
  algoliaConfig?: any;
  display?: any;
  setDisplay?: any;
  selectedFiltersCount?: any;
  setSelectedFiltersCount?: any;
  setShowNoSearchFound?: any;
};

const DynamicAccordion = ({
  title,
  attribute,
  collapsible,
  setIsFiltersApplied,
  canResetFilters,
  setCanResetFilters,
}) => {
  return (
    <Accordion
      title={title}
      isClearItems={true}
      defaultOpen={collapsible}
      attribute={attribute}
      limit={5}
      showMoreLimit={1000}
      showMore={true}
      setIsFiltersApplied={setIsFiltersApplied}
      canResetFilters={canResetFilters}
      setCanResetFilters={setCanResetFilters}
      sortBy={['count']}
    />
  );
};

const ProductFilterNav = ({
  facetsDisplay = null,
  setIsFiltersApplied,
  canResetFilters,
  setCanResetFilters,
  setShowNoSearchFound,
}: AccordionProps) => {
  const { nbHits } = useStats();
  const [isFilterComponentLoaded, setIsFilterComponentLoaded] = useState(false);

  useEffect(() => {
    const filterComponentTimeoutId = setTimeout(() => {
      setIsFilterComponentLoaded(true);
    }, 3000);

    return () => {
      clearTimeout(filterComponentTimeoutId);
    };
  }, [isFilterComponentLoaded]);

  useEffect(() => {
    if (nbHits === 0 && isFilterComponentLoaded) {
      setShowNoSearchFound(true);
    }
  }, [isFilterComponentLoaded]);

  return (
    <>
      <section className="b-black divide-[rgb(27,49,68)]/opacity-20  divide-y border lg:px-[14px]">
        {facetsDisplay &&
          facetsDisplay.length &&
          facetsDisplay?.map((attribute) => (
            <DynamicAccordion
              key={attribute?.facetKey}
              title={`${attribute?.facetName}`}
              attribute={attribute?.facetKey}
              collapsible={attribute?.collapsible}
              setIsFiltersApplied={setIsFiltersApplied}
              canResetFilters={canResetFilters}
              setCanResetFilters={setCanResetFilters}
            />
          ))}
      </section>
    </>
  );
};

export default ProductFilterNav;
