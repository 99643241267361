import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Card } from '@Types/account/AuthorizeNetAccount';
import { Order } from '@Types/cart/Order';
import HearAboutUs from 'components/sol-ui/account/modal/hear-about-us';
import { DateHelpers } from 'helpers/dateHelpers';
import { purchasedObjectIDsAfterSearch } from 'helpers/hooks/algoliaEvents/algoliaEvents';
import { clearAlgoliaQueryIDForPurchase, getAlgoliaQueryIDForPurchase } from 'helpers/hooks/algoliaEvents/algoliaUtils';
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { listrakOrderEvent } from 'helpers/listrakHelper';
import { gtmPurchase } from 'helpers/utils/gtm-events/gtmEcommerce';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { desktop } from 'helpers/utils/screensizes';
import { useAuthorizeNet, useAccount } from 'frontastic';
import CheckoutSummary from '../../order-summary';
import AddressSummary from '../address-summary';
import PaymentMethodSection from '../checkout-payment/payment-method-section';
import OrderSummary from '../order-review';
interface ThankYouProps {
  order: Order;
  modelShowingTime: number;
  sources: any;
}
const ThankYou: React.FC<ThankYouProps> = ({ order, modelShowingTime, sources }) => {
  const router = useRouter();
  const [creditCard, setCreditCard] = useState<Card>(undefined);
  const [hearAboutUsModalOpen, setHearAboutUsModalOpen] = useState(false);
  const { getTransactionDetails } = useAuthorizeNet();
  const { loggedIn, account } = useAccount();
  const [userClicked, setUserClicked] = useState<boolean>(false);
  const [isDesktop] = useMediaQuery(desktop);
  const { getItem } = useLocalStorage();

  const closeHearAboutUsModal = () => {
    setHearAboutUsModalOpen(false);
  };
  const openHearAboutUsModal = () => {
    setHearAboutUsModalOpen(true);
  };

  useEffect(() => {
    gtmPurchase(order, account);
  }, []);

  useEffect(() => {
    if (order) {
      setTimeout(() => {
        listrakOrderEvent(order);
      }, 3000);
    }
  }, [order]);

  useEffect(() => {
    if (!creditCard) {
      getTransactionDetails({ transactionId: order?.paymentMethod?.transactionId }).then((response) => {
        setCreditCard(response?.payment?.creditCard ?? undefined);
      });
    }
    setTimeout(openHearAboutUsModal, modelShowingTime);
  }, []);

  useEffect(() => {
    const algoliaQueryIds = getAlgoliaQueryIDForPurchase() ?? [];
    const objectIDs = order?.lineItems?.map((lineItem) => lineItem?.variant?.sku);
    const objectData = order?.lineItems?.map((lineItem) => {
      const price = (lineItem?.discountedPrice?.centAmount || lineItem?.price?.centAmount) / 100;
      const discount =
        lineItem?.discountedPrice?.centAmount && lineItem?.appliedDiscountPrice?.centAmount
          ? lineItem?.appliedDiscountPrice?.centAmount / 100
          : null;
      const queryID = algoliaQueryIds?.find((obj) => obj?.sku === lineItem?.variant?.sku)?.queryID;
      return {
        price: price,
        quantity: lineItem?.count,
        ...(discount ? { discount } : {}),
        ...(queryID ? { queryID } : {}),
      };
    });

    const value = objectData?.reduce((sum, curr) => sum + curr.price * curr.quantity, 0);

    if (objectIDs?.length && objectData?.length) {
      purchasedObjectIDsAfterSearch({
        index: getItem('currentIndex'),
        eventName: 'Product Purchased After Search',
        objectIDs: objectIDs,
        objectData: objectData,
        value: value,
        currency: 'USD',
      });
    }

    clearAlgoliaQueryIDForPurchase();
  }, [order]);

  const handleUserClicked = (value: boolean) => {
    return setUserClicked(value);
  };

  return (
    <>
      {order ? (
        <section className="mx-auto my-10 max-w-[1180px] px-5">
          <h1 className="text-center text-2xl font-semibold after:mx-auto after:mt-4 after:block after:h-[0.063rem] after:w-[9.375rem] after:bg-[#B19A6A] after:content-[''] md:text-[28px] ">
            {mapLocaleToMeaningfulFormat(router.locale).yourOrderIsConfirmed}
          </h1>
          <section className="mb-3 mt-5 flex flex-col items-center justify-center gap-3 text-sm sm:flex-row sm:gap-0">
            <p>
              <span>
                {mapLocaleToMeaningfulFormat(router.locale).orderHash}
                {mapLocaleToMeaningfulFormat(router.locale).colon}
              </span>{' '}
              <span className="font-semibold">{order?.orderNumber}</span>
            </p>
            <span className="ml-4 mr-3 hidden sm:block">|</span>
            <p>
              <span>
                {mapLocaleToMeaningfulFormat(router.locale).orderDate}
                {mapLocaleToMeaningfulFormat(router.locale).colon}
              </span>{' '}
              <span className="font-semibold">{DateHelpers.solFormatDate(order?.createdAt, router)}</span>
            </p>
          </section>
          <p className="py-5 text-center">{mapLocaleToMeaningfulFormat(router.locale).thankYouForShopping}</p>
          <p className="pb-5 text-center">{mapLocaleToMeaningfulFormat(router.locale).yourOrderConfirmed}</p>
          {loggedIn ? (
            <section className="flex justify-center">
              <Link href={routerURL.account}>
                <a className="btn-primary-large w-[196px] text-center">
                  {mapLocaleToMeaningfulFormat(router.locale).goToAccount}
                </a>
              </Link>
            </section>
          ) : (
            <section className="flex justify-center">
              <Link href={routerURL.login}>
                <a className="btn-primary-large w-[196px] text-center">
                  {mapLocaleToMeaningfulFormat(router.locale).accountLogIn}
                </a>
              </Link>
            </section>
          )}

          <section className="mt-10 flex flex-col justify-between gap-10 md:grid-cols-2 lg:flex-row">
            <section className="flex w-full flex-col gap-5">
              <AddressSummary cart={order} showEditButton={false} />
              <PaymentMethodSection showEditButton={false} creditCard={creditCard} />
              <OrderSummary cart={order} />
            </section>
            <section>
              <HearAboutUs
                sources={sources}
                handleUserClicked={handleUserClicked}
                order={order}
                openModal={hearAboutUsModalOpen}
                closeModal={closeHearAboutUsModal}
              />
              <CheckoutSummary
                checkoutHeading={mapLocaleToMeaningfulFormat(router.locale).cartSummary}
                showCalculateEstimatedTax={true}
                showPromoCode={false}
                showCheckoutButton={false}
                showHelpLinks={false}
                cart={order}
                showShippingPrice={true}
                showPromoRemoveButton={false}
                content={null}
                isCheckout={true}
                isCartValid={true}
                isCheckoutAddress={false}
              />
            </section>
          </section>
        </section>
      ) : (
        <>{mapLocaleToMeaningfulFormat(router.locale).noOrderRequested} </>
      )}
    </>
  );
};

export default ThankYou;
