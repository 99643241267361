export const PHONEMASK = '999 999 9999';
export const EXTENSIONMASK = '9999';
export const DEFAULT_COUNTRY = 'US';
export const DEFAULT_STATE = 'AL';
export const EVENT_LEFT_CLICK = 0;
export const EVENT_RIGHT_CLICK = 2;
export const DEFAULT_REFERRAL = 'notSpecified';
export enum DATA_SOURCE_LINKS {
  PRODUCT_DETAILS = 'frontastic/product',
  EMPTY_DATASOURCE = 'frontastic/empty',
  CATEGORY_LIST = 'commercetools/category-list',
}
export const AMPLIENCE_IMAGE_URL_FORMAT = '{0}?fmt=auto&w={1}';
export const BANNER_IMAGE_SIZE_DESKTOP = 1900;
export const BANNER_IMAGE_SIZE_MOBILE = 780;
export const CATEGORY = 'category';
export const CLEARANCE_CATEGORY_SLUG_PREFIX = 'sale-';
export const CYBER_SALE_CATEGORY_SLUG_PREFIX = 'cyber-sale';
export const CJ_BUSINESS_UNIT = 'Pro';
