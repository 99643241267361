import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none" className={className}>
    <path
      d="M8.61523 0L5.40095 4.5L8.61523 9H7.04023L3.82595 4.5L7.04023 0H8.61523ZM4.79023 0L1.57595 4.5L4.79023 9H3.21523L0.000947952 4.5L3.21523 0H4.79023Z"
      fill="black"
    />
  </svg>
);

export default Icon;
