import React, { useState } from 'react';
import router, { useRouter } from 'next/router';
import { Formik, Form, Field } from 'formik';
import InfoIcon from 'components/icons/sol/info-icon';
import ReCaptcha from 'components/sol-ui/recaptcha';
import { WISHLIST_DATA } from 'helpers/constants/localStorage';
import {
  ACCOUNT_CREATED,
  SERVER_ERROR,
  REGISTER_FAILED,
  ACCOUNT_VERIFIED,
  ACCOUNT_VERIFICATION_FAILED,
} from 'helpers/constants/messages';
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import { useNotification } from 'helpers/notification/notificationContext';
import Redirect from 'helpers/redirect';
import { getReferenceTarget } from 'helpers/reference';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import validationSchema from 'helpers/utils/validationSchema/createAccount';
import { useAccount } from 'frontastic';
import { addToWishlist } from 'frontastic/actions/wishlist';
import Password from '../../form-fields/password';
import Spinner from '../../notification/spinner';
import { LoginProps } from '../index';

type CreateAccountType = {
  email: string;
  password: string;
  confirmPassword?: string;
  checkbox: boolean;
};
const createAccountInitialValues: CreateAccountType = {
  email: '',
  password: '',
  confirmPassword: '',
  checkbox: false,
};

const CreateAccount: React.FC<LoginProps> = ({ accountLink, loginLink, notificationTimeout }) => {
  //next/router
  const router = useRouter();

  //processing...
  const [loading, setLoading] = useState(false);

  //account actions
  const { register, confirm } = useAccount();

  //global notification
  const { showNotification } = useNotification();
  const [isReCaptchaVerified, setIsReCaptchaVerified] = useState(false);
  const [reCaptchaError, setReCaptchaError] = useState(false);
  const { removeAsGuest } = useLocalStorage();
  const [isCreateLoginFieldsClicked, setIsCreateLoginFieldsClicked] = useState(false);

  const clearForm = () => {
    (createAccountInitialValues.email = ''),
      (createAccountInitialValues.password = ''),
      (createAccountInitialValues.confirmPassword = ''),
      (createAccountInitialValues.checkbox = false);
  };

  const handleSubmit = async (values: CreateAccountType) => {
    //processing starts
    if (isReCaptchaVerified) {
      setLoading(true);
      //try registering the user with given credentials
      try {
        const response = await register({ email: values.email, password: values.password });

        if (!response.accountId) {
          showNotification(REGISTER_FAILED, 'error');
        } else {
          const { token } = response?.confirmationToken;
          //TODO - Listrak Email notification send verification token
          const result = await confirm(token);
          if (result.accountId) {
            //start to show notification
            //showNotification(ACCOUNT_VERIFIED,'success');
            if (router.query['redirectURL']) {
              const redirectURL = router.query['redirectURL'] as string;
              await router.push(redirectURL);
            } else {
              await router.push(getReferenceTarget(accountLink));
            }
            removeAsGuest();
            showNotification(ACCOUNT_CREATED, 'success');
            setLoading(false);
            clearForm();
            const wishListData = JSON?.parse(localStorage?.getItem(WISHLIST_DATA));
            if (wishListData && wishListData !== undefined) {
              addToWishlist(wishListData?.sku, wishListData?.quantity);
            }
            localStorage?.removeItem(WISHLIST_DATA);
          } else {
            showNotification(ACCOUNT_VERIFICATION_FAILED, 'error');
          }
        }
      } catch (err) {
        showNotification(SERVER_ERROR, 'error');
      }
      //processing ends
      setLoading(false);
    } else {
      setReCaptchaError(true);
    }
  };

  const handleFieldClick = (setFieldValue) => {
    if (!isCreateLoginFieldsClicked) {
      setIsCreateLoginFieldsClicked(true);
      setFieldValue('email', '');
      setFieldValue('password', '');
    }
  };

  return (
    <>
      <section className="justify-center sm:flex lg:justify-start">
        <section className="pt-7 sm:w-[375px] lg:pl-[50px] lg:pt-0 ">
          <Formik
            initialValues={createAccountInitialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ errors, touched, handleBlur, setFieldValue }) => (
              <>
                <Form>
                  <section className="w-full">
                    <h2 className="mb-5 text-center text-[28px] font-semibold leading-[34px] text-[#1B3144] lg:mb-11">
                      {' '}
                      {mapLocaleToMeaningfulFormat(router.locale).createAccount}
                    </h2>
                    <section className="mt-[54px]">
                      <label htmlFor="registerEmail" className="text-sm font-semibold leading-[17px] text-sol-300">
                        {mapLocaleToMeaningfulFormat(router.locale).email}
                      </label>
                      <Field
                        type="text"
                        name="email"
                        id="registerEmail"
                        onBlur={handleBlur}
                        onClick={() => handleFieldClick(setFieldValue)}
                        className={`input-text-primary mt-3 !w-full ${
                          errors?.email && touched?.email ? '!border-red-600' : ''
                        }`}
                      />
                    </section>
                    {errors.email && touched.email && (
                      <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{errors.email}</p>
                    )}
                    <section className="mt-4">
                      <section className="flex items-center gap-3">
                        <label className="text-sm font-semibold leading-[17px] text-sol-300" htmlFor="registerPassword">
                          {mapLocaleToMeaningfulFormat(router.locale).password}
                        </label>

                        <section className="dropdown group relative">
                          <section className="cursor-pointer py-1 pr-5">
                            <InfoIcon className="fill-sol-300" />
                          </section>

                          <div className="dropdown-menu absolute -left-[5rem] z-[1] hidden h-auto w-[270px] rounded-[6px] border border-gray-300 bg-[#F5F5F5] p-4 group-hover:block lg:left-0 lg:w-[347px]">
                            <h4 className="mb-3 text-xs font-semibold leading-[15px] text-sol-500">
                              {mapLocaleToMeaningfulFormat(router.locale).passwordsMustMeet}
                            </h4>
                            <section className="top-0 w-full">
                              <ul className="list-disc pl-4 ">
                                <li className="py-1 text-sol-300">
                                  <p className="text-xs font-normal leading-[18px] text-sol-500">
                                    {mapLocaleToMeaningfulFormat(router.locale).passwordlength}
                                  </p>
                                </li>
                                <li className="my-2">
                                  <p className="text-xs font-normal leading-[18px] text-sol-500">
                                    {mapLocaleToMeaningfulFormat(router.locale).passwordDifferentFromUserName}
                                  </p>
                                </li>
                                <li className="my-2">
                                  <p className="text-xs font-normal leading-[18px] text-sol-500">
                                    {mapLocaleToMeaningfulFormat(router.locale).passwordNotSpace}
                                  </p>
                                </li>
                                <li className="my-2">
                                  <p className="text-xs font-normal leading-[18px] text-sol-500">
                                    {mapLocaleToMeaningfulFormat(router.locale).passwordOneUpperCase}
                                  </p>
                                </li>
                                <li className="my-2">
                                  <p className="text-xs font-normal leading-[18px] text-sol-500">
                                    {mapLocaleToMeaningfulFormat(router.locale).passwordOneDigit}
                                  </p>
                                </li>
                                <li className="my-2">
                                  <p className="text-xs font-normal leading-[18px] text-sol-500">
                                    {mapLocaleToMeaningfulFormat(router.locale).passwordMustContain}
                                  </p>
                                </li>
                              </ul>
                            </section>
                          </div>
                        </section>
                      </section>
                      <div onClick={() => handleFieldClick(setFieldValue)}>
                        <Password
                          name="password"
                          id="registerPassword"
                          onBlur={handleBlur}
                          className={errors?.password && touched?.password ? '!border-red-600' : ''}
                        />
                      </div>
                    </section>
                    {errors.password && touched.password && (
                      <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{errors.password}</p>
                    )}
                    <section className="mt-3">
                      <label
                        className="text-sm font-semibold leading-[17px] text-sol-300"
                        htmlFor="registerConfirmPassword"
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).confirmPassword}
                      </label>
                      <Password
                        name="confirmPassword"
                        id="registerConfirmPassword"
                        onBlur={handleBlur}
                        className={errors?.confirmPassword && touched?.confirmPassword ? '!border-red-600' : ''}
                      />
                    </section>
                    {errors.confirmPassword && touched.confirmPassword && (
                      <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{errors.confirmPassword}</p>
                    )}
                    <section className="mt-4 flex items-center gap-4">
                      <Field
                        type="checkbox"
                        name="checkbox"
                        id="registerCheckbox"
                        onBlur={handleBlur}
                        className="h-4 w-4 appearance-none rounded-[2px] border-none border-black-300 bg-transparent text-white ring-2 ring-sol-300  focus:ring-sol-300"
                      />
                      <label
                        htmlFor="registerCheckbox"
                        className="text-sm leading-[17px] text-sol-300 outline-none focus:ring-0"
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).signUpEmailList}
                      </label>
                    </section>
                    <ReCaptcha setIsReCaptchaVerified={setIsReCaptchaVerified} setReCaptchaError={setReCaptchaError} />
                    {reCaptchaError && (
                      <span className="text-xs font-semibold text-red-500">
                        {mapLocaleToMeaningfulFormat(router.locale).reCaptchaError}
                      </span>
                    )}
                    <section className="mt-6 flex flex-col items-center gap-4 md:flex-row">
                      <button
                        type="submit"
                        id="createAccountBtn"
                        className={`flex h-[44px] w-full  items-center justify-center rounded-md bg-[#404040] px-6 text-sm font-bold leading-[17px] text-white focus:outline-offset-2 md:w-auto ${
                          Object.keys(errors).length === 0 ? '' : 'cursor-not-allowed'
                        }`}
                      >
                        {loading ? (
                          <section className="flex justify-center gap-3">
                            <Spinner />
                            <p className="mt-1">{mapLocaleToMeaningfulFormat(router.locale).processing}</p>
                          </section>
                        ) : (
                          mapLocaleToMeaningfulFormat(router.locale).createAccount
                        )}
                      </button>
                    </section>
                    <div>
                      {/* {notification && success && <SuccessMessage message={success} />}
                      {notification && error && <SuccessMessage message={error} />} */}
                    </div>
                  </section>
                </Form>
              </>
            )}
          </Formik>
        </section>
      </section>
    </>
  );
};

export default CreateAccount;
