import CatalogLandingPage from 'components/sol-ui/pages/catalog';
import React from 'react';

const CatalogLandingPageTastic = ({ data }) => {
  const content = data?.data?.dataSource?.content ?? undefined;
  if (!content || content?.length === 0) {
    return <></>;
  }
  return <CatalogLandingPage content={content[0]} />;
};

export default CatalogLandingPageTastic;
