import React, { useState } from 'react';
import Link from 'next/link';
import router, { useRouter } from 'next/router';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import Submenu from '../subNav.json';

function AccountSubMenuMobile() {
  const [subMenuArrowDown, setSubMenuArrowDown] = useState(false);
  function handleArrow() {
    setSubMenuArrowDown(!subMenuArrowDown);
  }
  const router = useRouter();
  return (
    <section className="mt-6 rounded border lg:mt-8">
      <section className="flex cursor-pointer justify-between bg-sol-900" onClick={handleArrow}>
        <h3 className="p-3 text-sm text-sol-300">{mapLocaleToMeaningfulFormat(router.locale).account}</h3>
        {!subMenuArrowDown ? (
          <section className="my-auto mr-3">
            <MdOutlineKeyboardArrowDown onClick={handleArrow} className="text-2xl text-sol-300" />
          </section>
        ) : (
          <section className="my-auto mr-3">
            <MdOutlineKeyboardArrowUp onClick={handleArrow} className="text-2xl text-sol-300" />
          </section>
        )}
      </section>
      {subMenuArrowDown && (
        <ul className="pl-3 text-left">
          {Submenu?.subNav?.map((item, i) => (
            <li className="mr-1 text-sm" key={i}>
              <Link href={item?.url}>
                <a>{item?.name}</a>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
}
export default AccountSubMenuMobile;
