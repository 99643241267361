import { StringHelpers } from './stringHelpers';

export class ArrayHelpers {
  static chunk = <Element>(input: Element[], size: number): Element[][] => {
    return input.reduce((array, item, index) => {
      return index % size === 0 ? [...array, [item]] : [...array.slice(0, -1), [...array.slice(-1)[0], item]];
    }, []);
  };

  static findObjectWithKey = (array, key: string) => {
    return array?.find((obj) => obj?.hasOwnProperty(key));
  };
  static findObjectMatchesSku = (array, str) => {
    return array?.find((obj) => StringHelpers?.removeWhiteSpace(obj?.sku)?.toLowerCase() === str);
  };
  static removeDuplicatesById = (array) => {
    const uniqueIds = new Set();
    return array?.filter((item) => !uniqueIds?.has(item?.productId) && uniqueIds?.add(item?.productId));
  };
}
