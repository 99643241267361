import React, { useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import HeaderLogo from 'components/icons/sol/header-logo';
import MobileLogo from 'components/icons/sol/mobile-logo';
import Notification from 'components/sol-ui/notification';
import InlineNotification from 'components/sol-ui/notification/inline-notification';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { useNotification } from 'helpers/notification/notificationContext';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { desktop } from 'helpers/utils/screensizes';
function CheckoutHeader() {
  const router = useRouter();
  const [isDesktop] = useMediaQuery(desktop);
  const { hideNotification } = useNotification();
  useEffect(() => {
    hideNotification();
  }, [router.asPath]);

  return (
    <section
      className={`shadow-[0px_2px_4px_rgba(177, 154, 106, 0.3)] w-full bg-sol-200 py-5 text-xs font-bold ${
        isDesktop ? '' : 'mb-10'
      }`}
    >
      <Notification timeoutInMs={5000} />
      <InlineNotification timeoutInMs={5000} />
      <section className="mx-auto flex max-w-screen-8xl items-center justify-center px-2 pb-0 ">
        {isDesktop ? (
          <Link href={routerURL?.home}>
            <a aria-label={mapLocaleToMeaningfulFormat(router.locale).shadesOfLightLogo}>
              <HeaderLogo className="h-[98px] w-[347px]" />
            </a>
          </Link>
        ) : (
          <section className="shadow-[0px_2px_4px_rgba(177, 154, 106, 0.3)] fixed z-10 flex h-[140px] w-full items-center justify-center bg-sol-200 pt-12">
            <Link href={routerURL?.home}>
              <a aria-label={mapLocaleToMeaningfulFormat(router.locale).shadesOfLightLogo}>
                <MobileLogo className="h-[54px] w-[191px]" />
              </a>
            </Link>
          </section>
        )}
      </section>
    </section>
  );
}

export default CheckoutHeader;
