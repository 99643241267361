import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { AMPLIENCE_IMAGE_URL_FORMAT } from 'helpers/utils/constant';
import format from 'helpers/utils/format';
import calculateDynamicImageWidth from 'helpers/utils/getImageSize';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { amplienceImageLoader } from 'helpers/utils/amplienceImageLoader';

const ThreeColumnContentBlock = ({ content, showShadesOfLightHeading }) => {
  //next/router
  const router = useRouter();
  const dynamicWidth = calculateDynamicImageWidth(400, 500, 520);
  return (
    <>
      <section className="mx-auto mb-8 max-w-[1440px] md:px-10 lg:mb-12">
        {(content?.title_h2 || content?.title_h1) && (
          <div className="mb-8 text-center before:absolute before:top-[54%] before:block before:h-[1px] before:w-full before:content-[''] md:relative md:before:bg-sol-600 lg:mb-10">
            <em className="relative bg-white text-[32px] font-semibold uppercase not-italic leading-[39px] md:px-10">
              {content?.title_h2 && <h2 className="inline-block">{content?.title_h2}</h2>}
              {content?.title_h1 && <h1 className="inline-block pl-3">{content?.title_h1}</h1>}
            </em>
          </div>
        )}
        <ul className="grid grid-cols-1 gap-8 lg:grid-cols-3 lg:gap-5">
          {content?.data?.map((c, index) => (
            <li key={index}>
              <Link href={c?.ctaLink}>
                <a className="flex h-full w-full">
                  <section className="h-full w-full rounded-t-[6px] bg-sol-100">
                    <section className="w-full justify-center">
                      <Image
                        loader={amplienceImageLoader}
                        width={441}
                        height={309}
                        src={c?.image?.url}
                        sizes="(max-width: 1023px) 100vw, 30vw"
                        alt={c?.alt}
                        className="rounded-t-md"
                      />
                    </section>
                    <section className="flex justify-between p-4">
                      <section>
                        <h3 className="mb-2 text-2xl font-semibold leading-[29px] text-sol-300">{c?.title}</h3>
                        <p className="text-sm leading-[17px]  text-sol-300">{c?.description}</p>
                      </section>
                      <section className="ml-4 mt-1 shrink-0">
                        <button
                          type="button"
                          id={'threeColCtaLink' + c?.title?.trim()?.split(' ')?.join('')}
                          className="btn-primary-small inline-block px-4 py-2 text-sm !font-normal"
                        >
                          {c?.ctaLinkText}
                        </button>
                      </section>
                    </section>
                  </section>
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default ThreeColumnContentBlock;
