export const handleSwipe = (touchStartX, touchEndX, imageGallery, selectedImage, setSelectedImage) => {
  const threshold = 100; // Minimum swipe distance in pixels to consider as a valid swipe
  if (touchEndX - touchStartX > threshold) {
    // Swiped right, go to the previous image
    const currentIndex = imageGallery.findIndex((item) => item.src === selectedImage.src);
    if (currentIndex > 0) {
      setSelectedImage(imageGallery[currentIndex - 1]);
    }
  } else if (touchStartX - touchEndX > threshold) {
    // Swiped left, go to the next image
    const currentIndex = imageGallery.findIndex((item) => item.src === selectedImage.src);
    if (currentIndex < imageGallery.length - 1) {
      setSelectedImage(imageGallery[currentIndex + 1]);
    }
  }
};

export const handleSwipeHeroCarousel = (touchStartX, touchEndX, currentIndex, content, setCurrentIndex) => {
  const threshold = 100; // Minimum swipe distance in pixels to consider as a valid swipe

  if (touchEndX - touchStartX > threshold) {
    // Swiped right, go to the previous image
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  } else if (touchStartX - touchEndX > threshold) {
    // Swiped left, go to the next image
    if (currentIndex < content.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  }
};
