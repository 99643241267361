import { JsonLd } from 'react-schemaorg';
import { BlogPosting } from 'schema-dts';

const BlogPostRichText = ({ data, url }) => {
  return (
    <>
      <JsonLd<BlogPosting>
        item={{
          '@context': 'https://schema.org',
          '@type': 'BlogPosting',
          name: data?.title,
          publisher: {
            '@type': 'Organization',
            name: data?.author,
          },
          author: {
            '@type': 'Organization',
            name: data?.author,
          },
          url: `https://www.shadesoflight.com/${url}`,
          datePublished: data?.publishedAt,
          dateModified: data?.publishedAt,
          articleBody: data?.longDescription,
        }}
      />
    </>
  );
};

export default BlogPostRichText;
