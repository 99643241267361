import React from 'react';
import { useRouter } from 'next/router';
import { Card } from '@Types/account/AuthorizeNetAccount';
import CardIcon from 'components/sol-ui/account/credit-card/card-icon';
import { StringHelpers } from 'helpers/stringHelpers';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
type PaymentMethodSectionProps = {
  showEditButton: boolean;
  creditCard: Card;
};
function PaymentMethodSection({ showEditButton, creditCard }: PaymentMethodSectionProps) {
  const router = useRouter();
  const handleEdit = (e: React.FormEvent) => {
    e.preventDefault();
    router.push(routerURL.checkoutPayment);
  };
  return (
    <section className="mt-5 max-w-screen-8xl rounded border p-5">
      {creditCard && (
        <>
          <section className="mb-5 flex justify-between">
            <p className="text-lg font-semibold text-sol-300">
              {mapLocaleToMeaningfulFormat(router.locale).paymentMethod}
            </p>
            {showEditButton && (
              <button type="button" id="edit-payment" className="text-sm font-semibold underline" onClick={handleEdit}>
                {mapLocaleToMeaningfulFormat(router.locale).edit}
              </button>
            )}
          </section>
          <label className="text-sm font-semibold">{mapLocaleToMeaningfulFormat(router.locale).creditCard}</label>
          <section className="ml-2 mt-4 flex items-center gap-2">
            <CardIcon cardType={creditCard?.cardType || 'default'} />
            <p>{StringHelpers.creditCardNumberFormatter(creditCard?.cardNumber, router)}</p>
          </section>
        </>
      )}
    </section>
  );
}

export default PaymentMethodSection;
