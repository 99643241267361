import { useRouter } from 'next/router';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';

const BlogPopularTags = ({ data, tags }) => {
  const router = useRouter();
  const popularTags = data?.map((item) => item.title);

  return (
    <>
      <section className="mt-5 px-5">
        <section className="mx-auto max-w-screen-8xl border-t-[1px] border-sol-600 pt-10 lg:border-none">
          <h3 className="text-3xl font-semibold text-gray-700">
            {mapLocaleToMeaningfulFormat(router.locale).popularTags}
          </h3>
          <section className="my-8 flex">
            <ul>
              {tags &&
                Object.entries(tags).map(([key, value], index) => {
                  // Check if the key exists in the popularTags array
                  if (popularTags?.includes(key)) {
                    return (
                      <li key={index} className="py-2 leading-5 ">
                        {key} ({value})
                      </li>
                    );
                  }
                  return null;
                })}
            </ul>
          </section>
        </section>
      </section>
    </>
  );
};

export default BlogPopularTags;
