import React, { useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { LineItem } from '@Types/cart/LineItem';
import { HiChevronDoubleRight } from 'react-icons/hi';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { desktop } from 'helpers/utils/screensizes';
import { useAccount, useWishlist, useCart, useAuthorizeNet } from 'frontastic';
import Breadcrumb from '../cms/Breadcrumb';
import AccountNav from './account-nav';
import ContinueShoppingButton from './continueShoppingButton';
import {
  AccountSubMenuDesktop,
  AccountSubMenuMobile,
  Profile,
  Address,
  Card,
  MyList,
  RecentOrder,
  TradeAccount,
  CommunicationPreference,
} from './sections/accountListing';

function Account() {
  const [isDesktop] = useMediaQuery(desktop);
  const router = useRouter();
  const [addresses, setAddresses] = useState([]);
  //account data
  const { account, addAddress, getCustomerAddresses, loggedIn } = useAccount();
  const [isRecentOrder, setIsRecentOrder] = useState(true);

  const setAddressesData = useCallback(() => {
    if (loggedIn) {
      getCustomerAddresses().then((data) => {
        if (data && data?.length > 0) {
          data = data?.sort((address) => (address.isDefaultBillingAddress ? -1 : 1));
          setAddresses(data);
        }
      });
    } else {
      setAddresses([]);
    }
  }, []);

  useEffect(() => {
    setAddressesData();
  }, []);
  useEffect(() => {
    router.replace(router.asPath);
  }, [router.asPath]);

  const defaultAddress = addresses?.filter((address) => address?.isDefaultBillingAddress)[0];

  const { data: wishlist } = useWishlist();

  const { recentOrder } = useCart();

  const count = wishlist?.lineItems?.length || 0;

  const { data: creditCards } = useAuthorizeNet();

  const images = wishlist?.lineItems?.map((lineItem: LineItem) => lineItem?.variant?.images[0]?.url);

  const defaultCreditCard = creditCards && creditCards.length && creditCards?.find((card) => card.isDefault);

  return (
    <>
      <Breadcrumb breadcrumLevelLink={true} activePage={mapLocaleToMeaningfulFormat(router.locale).account} />
      <section className="mx-auto max-w-screen-8xl px-4 pb-10">
        {isDesktop && (
          <h2 className="py-6 text-center text-[27px] font-semibold text-sol-300">
            {mapLocaleToMeaningfulFormat(router.locale).account}
          </h2>
        )}
        <section className="gap-4 lg:flex lg:gap-5">
          {isDesktop ? (
            <AccountNav />
          ) : (
            <>
              <AccountNav />
              <h2 className="py-4 text-center text-[27px] font-semibold text-sol-300">
                {mapLocaleToMeaningfulFormat(router.locale).account}
              </h2>
            </>
          )}
          <section className="md:w-full">
            <section className="grid grid-cols-1 gap-4 lg:gap-5 xl:grid-cols-2">
              <section className="grid grid-cols-1">
                <Profile account={account} />
              </section>
              <section className="rounded-md border border-slate-300 p-4">
                <section>
                  <section className="flex justify-between">
                    <h3 className="text-sm font-semibold text-sol-300">
                      {mapLocaleToMeaningfulFormat(router.locale).defaultAddress}
                    </h3>
                    {isDesktop && (
                      <Link href={routerURL?.address}>
                        <a className="btn-primary-small cursor-pointer">
                          {mapLocaleToMeaningfulFormat(router.locale).addressBook}
                        </a>
                      </Link>
                    )}
                  </section>
                  {defaultAddress ? (
                    <Address address={defaultAddress} />
                  ) : (
                    <p>{mapLocaleToMeaningfulFormat(router.locale).addressEmptyMessage}</p>
                  )}
                </section>
                {!isDesktop && (
                  <section className="mt-5 shrink-0 lg:mt-0">
                    <Link href={routerURL?.address}>
                      <a className="btn-primary-small cursor-pointer text-center">
                        {mapLocaleToMeaningfulFormat(router.locale).addressBook}
                      </a>
                    </Link>
                  </section>
                )}
              </section>
              <section className="rounded-md border border-slate-300 p-4">
                <section className="flex justify-between">
                  <h3 className="text-sm font-semibold text-sol-300">
                    {mapLocaleToMeaningfulFormat(router.locale).defaultCreditCard}
                  </h3>
                  {isDesktop && (
                    <section className="mt-5 shrink-0 lg:mt-0">
                      <Link href={routerURL?.creditCard}>
                        <a className="btn-primary-small cursor-pointer lg:px-2">
                          {mapLocaleToMeaningfulFormat(router.locale).manageCard}
                        </a>
                      </Link>
                    </section>
                  )}
                </section>
                {}
                <Card card={defaultCreditCard} />
                {defaultCreditCard && defaultAddress ? <Address address={defaultAddress} /> : <></>}
                {!isDesktop && (
                  <section className="mt-5 shrink-0 lg:mt-0">
                    <Link href={routerURL?.creditCard}>
                      <a className="btn-primary-small cursor-pointer text-center lg:px-2">
                        {mapLocaleToMeaningfulFormat(router.locale).manageCard}
                      </a>
                    </Link>
                  </section>
                )}
              </section>
              <MyList images={images} count={count} />
              <section className="grid grid-cols-1 xl:col-span-2">
                <section className="rounded-md border border-slate-300 p-4">
                  <section>
                    <section className={`flex justify-between ${isDesktop ? 'mb-5' : ' '}`}>
                      <h3 className="text-sm font-semibold text-sol-300">
                        {mapLocaleToMeaningfulFormat(router.locale).recentOrder}
                      </h3>
                      {isDesktop && (
                        <section className="mt-5 shrink-0 lg:mt-0">
                          <Link href={routerURL?.orderHistory}>
                            <a className="btn-primary-small cursor-pointer lg:px-7">
                              {mapLocaleToMeaningfulFormat(router.locale).orderHistory}
                            </a>
                          </Link>
                        </section>
                      )}
                    </section>
                    {recentOrder && (
                      <RecentOrder
                        isRecentOrder={isRecentOrder}
                        order={recentOrder?.data || undefined}
                        emptyOrder={true}
                      />
                    )}
                  </section>
                  {!isDesktop && (
                    <section className="mt-5 shrink-0 lg:mt-0">
                      <Link href={routerURL?.orderHistory}>
                        <a className="btn-primary-small cursor-pointer text-center lg:px-7">
                          {mapLocaleToMeaningfulFormat(router.locale).orderHistory}
                        </a>
                      </Link>
                    </section>
                  )}
                </section>
              </section>
              <TradeAccount />
              <CommunicationPreference />
              {!isDesktop && <ContinueShoppingButton />}
            </section>
          </section>
        </section>
      </section>
    </>
  );
}

export default Account;
