export const isImage = (media) => {
  if (media?.__typename == 'ImageNode') return true;
  else return false;
};

export const isVideo = (media) => {
  if (media?.__typename == 'VideoNode') return true;
  else return false;
};

export const getAlignment = (alignment) => {
  if (alignment === 'center') {
    return 'items-center justify-center';
  } else if (alignment === 'topLeft') {
    return 'items-start justify-start';
  } else if (alignment === 'topRight') {
    return 'items-start justify-end';
  } else if (alignment === 'bottomLeft') {
    return 'items-end justify-start';
  } else if (alignment === 'bottomRight') {
    return 'items-start justify-end';
  } else {
    return 'items-center justify-center';
  }
};

export const getBannerAlignment = (alignment) => {
  if (alignment === 'left') {
    return 'flex justify-start';
  } else if (alignment === 'center') {
    return 'flex justify-center';
  } else if (alignment === 'right') {
    return 'flex justify-end';
  } else {
    return 'items-center justify-center';
  }
};

export const getMobileAlignment = (alignment) => {
  if (alignment === 'top') {
    return 'flex items-start justify-center';
  } else if (alignment === 'center') {
    return 'flex items-center justify-center';
  } else if (alignment === 'bottom') {
    return 'flex items-end justify-center';
  } else {
    return 'items-center justify-center';
  }
};

export const formatPosterUrl = (media) => {
  return `https://${media?.defaultHost}/v/${media?.endpoint}/${media?.name}`;
};

export const formatVideoUrl = (media) => {
  return `https://${media?.defaultHost}/v/${media?.endpoint}/${media?.name}/mp4_720p`;
};
