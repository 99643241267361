import React from 'react';

const DesignCatalogRequestSubmit = () => {
  return (
    <>
      {/* TODO: Static the comes from amplience on later  */}
      <section className="mx-auto mb-12 flex max-w-screen-8xl px-2 sm:px-6 lg:px-8">
        <ul className="h-auto w-full">
          <div className="flex items-center justify-center">
            <div className="relative max-w-screen-8xl">
              <h1 className="relative  px-4 pb-4 text-3xl font-bold">DESIGN INSPIRATION & IDEAS</h1>
              <div className="relative  px-4 pb-4">
                <p>
                  At Shades of Light, we know it can be difficult to know where to start with your lighting and design
                  choices. Even when you have distinguished style and great taste, coordinating all the pieces in a
                  room, or all the rooms in a living space may seem overwhelming. Whether you&quot;re designing a space
                  from scratch or &quot; just interested in refreshing your existing lighting and decor, &quot; put the
                  best of our industry knowledge, tips and tricks together in one place for you! Use our curated style
                  collections to learn which pieces contribute to a cohesive theme, whether it be industrial,
                  traditional, rustic, contemporary or nautical style. For hand-picked lighting and interiors choices
                  from leading design professionals in the industry, check out our curated designer collections,
                  including popular designers like Joanna Gaines, Young House Love, Colonial Metalcrafts and more! For
                  advice on frequently asked design questions, like how to measure a lampshade, as well as to browse
                  Shades of &quot; latest seasonal catalogs, visit our Inspired Spaces Blog.{' '}
                </p>
              </div>
            </div>
          </div>
        </ul>
      </section>
    </>
  );
};

export default DesignCatalogRequestSubmit;
