import * as Yup from 'yup';

const validationSchema = Yup.object({
  shareEmail: Yup.string().email('Email must be valid').required('Please enter a valid email address.'),
  recipientEmail: Yup.string().email('Email must be valid').required('Please enter a valid email address.'),
});
export default validationSchema;

export const shareWishlistValidationSchema = Yup.object({
  shareEmail: Yup.string().email('Email must be valid').required('Please enter a valid email address.'),
  //recipientEmail: Yup.string().email('Email must be valid').required('Please enter a valid email address.'),
});
