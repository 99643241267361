import React from 'react';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Breadcrumb from 'components/sol-ui/cms/Breadcrumb';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';

const CatalogLandingPage = ({ content }) => {
  const router = useRouter();
  return (
    <>
      <Head>
        <script
          data-cfasync={content?.datacfasync}
          data-height={content?.dataHeight}
          data-publication={content?.dataPublication}
          data-publication-aspect-ratio={content?.dataPublicationAspectRatio}
          data-responsive={content?.dataResponsive}
          data-width={content?.dataWidth}
          data-wrapper-id={content?.currentCatalogScriptID}
          publitas-embed
          src={content?.currentCatalogSrc}
          type={content?.currentCatalogType}
          async
        ></script>
      </Head>
      <Breadcrumb
        breadcrumLevelLink={true}
        activePage={mapLocaleToMeaningfulFormat(router.locale).catalogLandingPage}
      />
      <section className="mx-auto mt-7 w-full px-4 pb-[5rem] md:w-[70%]">
        <h1 className="relative mb-8 text-center before:absolute before:top-[54%] before:block before:h-[1px] before:w-full before:bg-sol-600 before:content-[''] lg:mb-10">
          <em className="relative bg-white px-3 text-2xl font-semibold uppercase not-italic leading-[39px] md:px-10 md:text-[1.688rem]">
            {mapLocaleToMeaningfulFormat(router.locale).currentCatalog}
          </em>
        </h1>

        <p className="mx-auto mt-4 text-center  text-sm">
          {content?.longDescription ?? ''}{' '}
          <Link href={content?.longDescriptionCtaLink ?? '#'}>
            <span className="cursor-pointer font-medium">{content?.longDescriptionCtaText ?? ''}</span>
          </Link>
        </p>
        <p className="mx-auto mt-8 text-center text-lg uppercase">{content?.title ?? ''}</p>
        <p className="mx-auto mt-4 text-center text-sm">
          {content?.shortDescription ?? ''}{' '}
          <Link href={content?.shortDescriptionCtaLink ?? '#'}>
            <span className="cursor-pointer font-medium">{content?.shortDescriptionCtaText ?? ''}.</span>
          </Link>
        </p>
      </section>
      <div id={content?.currentCatalogScriptID}></div>
      <p className="mx-auto mt-12 text-center  text-sm">{content?.eventText ?? ''}</p>
      <section className="md: mx-auto mb-12 px-5 md:w-[70%] md:px-10 md:py-[3rem]">
        <section className="py-10">
          <h1 className="relative mb-8 text-center before:absolute before:top-[54%] before:block before:h-[1px] before:w-full before:bg-sol-600 before:content-[''] lg:mb-10">
            <em className="relative bg-white px-3 text-2xl font-semibold uppercase not-italic leading-[39px] md:px-10 md:text-[1.688rem]">
              {mapLocaleToMeaningfulFormat(router.locale).previousCatalogs}
            </em>
          </h1>
        </section>
        <ul className="mx-auto grid grid-cols-2 gap-4 md:w-[60vw] lg:grid-cols-4">
          {content?.previousCatalogs?.map((content, index) => (
            <li key={index}>
              <Link href={content?.ctaLink}>
                {/*TODO: Need to replace it with dynamic images url*/}
                <a className="flex h-full w-full">
                  <section className="h-full w-full bg-sol-100">
                    <section>
                      <Image
                        loader={(options) => options.src}
                        width={285}
                        height={363}
                        src={content?.image?.url}
                        alt={content?.alt}
                        sizes="100vw"
                        className="rounded-md"
                      />
                    </section>
                    <section className="rounded-b-[8px] bg-sol-100 p-4">
                      <button type="button" className="w-full text-center text-base leading-5 text-sol-300 ">
                        {content?.title}
                      </button>
                    </section>
                  </section>
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default CatalogLandingPage;
