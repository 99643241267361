import React, { useContext } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CurrentPathContext } from 'helpers/continueShoppingContext/continueShoppingContext';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';

function ContinueShoppingButton() {
  const { currentPath } = useContext(CurrentPathContext);
  const router = useRouter();
  return (
    <div className="my-5">
      <a
        href={currentPath ? currentPath : routerURL?.home}
        id="continueShopping"
        className="mt-0 h-11 w-full rounded bg-gray-200 py-3 px-4 text-sm font-bold text-sol-300 focus:outline-offset-2"
      >
        {mapLocaleToMeaningfulFormat(router.locale).continueShopping}
      </a>
    </div>
  );
}
export default ContinueShoppingButton;
