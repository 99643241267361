//Account
export const SIGNED_IN = 'You have successfully logged in to your account.';
export const SIGNED_OUT = 'You have successfully signed-out of your account.';
export const ACCOUNT_CREATED = 'You have successfully created an account.';
export const LOGIN_FAILED =
  'The email and password combination cannot be found. Please try again, select “Forgot your password” or create a new account.';
export const REGISTER_FAILED = 'An account with this email already exists.';
export const ACCOUNT_VERIFIED = 'Your account has been verified.';
export const ACCOUNT_VERIFICATION_FAILED = 'Failed to verify account';
export const RESET_PASSWORD_EMAIL_SUCCESS = 'Reset password email successfully sent to your email address';
export const RESET_PASSWORD_EMAIL_FAIL = 'You have entered an unrecognized e-mail. Please try again or click';
export const CREATE_ACCOUNT = 'to create a new account.  If you are a Trade Customer, please click';
export const TRADE_ACCOUNT = ' to setup your trade account.';
export const HERE = 'here';
export const RESET_PASSWORD_SUCCESS = 'Password reset success. Redirecting to your account. ';

export const SUCCESSFULLY_SUBSCRIBED_NEWSLETTER = 'You have successfully subscribed to our emails';

//Address book
export const ADDRESS_CREATED = 'Address has been created';
export const ADDRESS_UPDATED = 'Address has been updated';
export const ADDRESS_DELETED = 'Address has been removed';
export const ADDRESS_NOT_VALID = 'Please enter a valid address';
export const SHIPPING_ADDRESS_NOT_VALID = 'Please enter a valid shipping address';
export const BILLING_ADDRESS_NOT_VALID = 'Please enter a valid billing address';

//Server
export const SERVER_ERROR = 'Sorry. Something went wrong...';
export const WRONG_CREDIT_CARD = 'Please enter a valid credit card';
export const TRANSACTION_DECLINED = 'There was a problem processing your payment. This transaction has been declined.';

export const BU_SUCCESS = 'Trade account request is submitted';
//cart lineitem
export const LINEITEM_REMOVED = 'Item has been removed';
export const LINEITEM_UPDATED = 'Item has been updated';
export const LINEITEM_ADDED = 'Item added';
export const LINEITEM_ADDED_UNSUCCESSFUL = 'Unable to add the item to My List';
// edit profile
export const PROFILE_UPDATED = 'Profile has been updated';
export const INCORRECT_OLD_PASSWORD = 'Old password does not match.';

//cart
export const CART_ADDRESS_UPDATED = 'Address has been updated in cart';
export const CART_PAYMENT_UPDATED = 'Payment updated in cart';
export const CART_PAYMENT_SUCCESS = 'Payment success';
export const CART_UPDATE_ERROR = 'Sales tax cannot be calculated. Zip code is not valid for this state.';
export const CART_ITEM_MAX_QTY_REACHED = 'Item discontinued. You may only order a maximum quantity of {0}';
export const CART_ITEM_EMPTY = 'Your cart is empty';
// credit card
export const CREDIT_CARD_CREATED = 'Credit card has been created';
export const CREDIT_CARD_UPDATED = 'Credit card has been updated';
export const CREDIT_CARD_DELETED = 'Credit card has been deleted';
export const INVALID_CREDIT_CARD_REQUEST = 'Invalid credit card request';
export const INVALID_CREDIT_CARD = 'This card number is not valid';

//Subcribe and Unsubscribe
export const UNSUBSCRIBED_SUCCESSFULLY = 'You have successfully subscribed';
export const CATALOG_UNSUBSCRIBED_SUCCESSFULLY = 'You have successfully catalog subscribed ';
export const SUBSCRIBED_SUCCESSFULLY = 'You have successfully subscribed ';
export const NOT_YET_SUBSCRIBED = 'You have not yet subscribed';

//share wishlist Email
export const WISHLIST_SHARED_SUCCESSFULLY = 'Email sent successfully';
export const WISHLIST_SHARE_EMAIL_SENT_ERROR = 'Email failed to send. Please contact your email administrator';
//How did you hear about us
export const FEEDBACK_SUBMITTED_SUCCESSFULLY = 'Thanks for your feedback.';
