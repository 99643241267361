import { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import Breadcrumb from 'components/sol-ui/cms/Breadcrumb';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { desktop } from 'helpers/utils/screensizes';
import BlogBreadcrumbs from '../BlogBreadcrumbs';
import BlogCategoryComponent from './blogCategoryComponent';
import InstagramBlogCategory from './instagramBlogCategory';

const BlogCategory = ({ category, categories, blogs, tags }) => {
  const [isDesktop] = useMediaQuery(desktop);
  const router = useRouter();
  const slug = router.asPath.split('/')[2];

  const dropdownOptions = [
    ...categories?.map((category) => {
      return { title: category?.title, slug: category?.slug };
    }),
  ];

  const [selectedCategory, setSelectedCategory] = useState({ title: '', slug: '' });
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  useEffect(() => {
    const currentCategory = dropdownOptions?.find((option) => option?.slug == slug);
    setSelectedCategory(currentCategory);
  }, []);

  const handleTabList = (category) => {
    setSelectedCategory(category);
    setSubMenuOpen(false);
  };

  const handleDropDown = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const dropdownMenuItems = dropdownOptions.filter((option) => option?.slug !== selectedCategory?.slug);

  return (
    <>
      <BlogBreadcrumbs
        breadcrumLevelLink={true}
        activePages={[
          { title: 'Blogs', ctaLink: '/blogs' },
          { title: category?.title, ctaLink: '#' },
        ]}
      />

      <section>
        <div>
          <h1 className="mx-auto w-max px-4 pb-6 pt-4 text-center text-3xl font-semibold after:mx-auto after:mt-4 after:block after:h-[0.063rem] after:w-[80%] after:bg-[#B19A6A] after:content-['']">
            {mapLocaleToMeaningfulFormat(router.locale).inspiredSpacesBlog}
          </h1>
        </div>
      </section>

      {isDesktop ? (
        <section className="relative w-full px-6 pb-4">
          <div className="flex flex-col items-center justify-center pb-4 md:flex-row">
            {dropdownOptions.map(
              (option, index) =>
                option?.title && (
                  <div key={index} className="mb-4 flex items-center md:mb-0 md:mr-12">
                    <Link href={`/blogs/${option?.slug}`}>
                      <a
                        onClick={() => handleTabList(option)}
                        className={`${
                          selectedCategory?.slug == option?.slug
                            ? 'after:content font-semibold after:mx-auto after:block after:h-[2px] after:w-full after:bg-[#B19A6A]'
                            : ''
                        }`}
                      >
                        {option?.title}
                      </a>
                    </Link>
                  </div>
                ),
            )}
          </div>
        </section>
      ) : (
        <section className="relative w-full px-6 pb-4">
          <div className="relative pb-4">
            <div className="flex cursor-pointer justify-between bg-sol-900" onClick={handleDropDown}>
              <h3 className="p-3 text-sm text-sol-300">{selectedCategory?.title}</h3>
              {subMenuOpen ? (
                <MdOutlineKeyboardArrowUp className="my-auto mr-3 text-2xl text-sol-300" />
              ) : (
                <MdOutlineKeyboardArrowDown className="my-auto mr-3 text-2xl text-sol-300" />
              )}
            </div>
            {subMenuOpen && (
              <ul className="pl-3 text-left">
                {dropdownMenuItems.map((option, index) => (
                  <li key={index} className="mr-1 text-sm leading-[2.5rem]">
                    <Link href={`/blogs/${option?.slug}`}>
                      <a onClick={() => handleTabList(option)}>{option?.title}</a>
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </section>
      )}

      {slug == 'instagram' ? (
        <InstagramBlogCategory category={category} />
      ) : (
        <BlogCategoryComponent category={category} blogs={blogs} tags={tags} />
      )}
    </>
  );
};

export default BlogCategory;
