import React, { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { useAccount } from 'frontastic';
import RequestTradeAccount from '../../modal/request-trade-account';

function TradeAccount() {
  // TODO :To be replaced by data from CMS
  const [tradeData, setTradeData] = useState({
    para: 'Shades of Lights Designer Trade Program is focused on serving Residential Interior Designers, Home Builders Contractors and Architects working on Residential Projects',
    learnMore: 'Learn More',
  });
  const router = useRouter();
  const [requestTradeModalOpen, setRequestTradeModalOpen] = useState(false);
  const { account } = useAccount();

  const openRequestTradeModal = () => {
    setRequestTradeModalOpen(true);
  };

  const closeRequestTradeModal = () => {
    setRequestTradeModalOpen(false);
  };
  return (
    <section className="rounded-md border border-slate-300 p-4">
      <h3 className="mb-1 text-sm font-semibold text-sol-300">
        {mapLocaleToMeaningfulFormat(router?.locale)?.tradeAccount}
      </h3>
      {/* TODO: This page URL will change later on and data will comes from CMS */}
      {account && account?.isB2BAccount ? (
        <>
          <p className="mt-2 text-sm font-normal leading-6 text-sol-300">
            {' '}
            {mapLocaleToMeaningfulFormat(router?.locale)?.tradeAccountStatus}:{' '}
            {account?.businessUnit?.status && account?.isB2BApproved ? 'Active' : 'Inactive'}
          </p>
          <p className="mt-2 text-sm font-normal leading-6 text-sol-300">
            {' '}
            {mapLocaleToMeaningfulFormat(router?.locale)?.tradeAccountCompanyName}: {account?.businessUnit?.name}
          </p>

          {account?.momCustomerId && (
            <p className="mt-2 text-sm font-normal leading-6 text-sol-300">
              {' '}
              {mapLocaleToMeaningfulFormat(router?.locale)?.tradeAccountId}: {account?.momCustomerId}
            </p>
          )}
        </>
      ) : (
        <>
          <p className="mt-4 text-sm font-normal leading-6 text-sol-300">
            {tradeData?.para}
            <Link href={routerURL?.designerTradeProgram}>
              <a className="ml-2 text-sol-300 underline underline-offset-2">{tradeData?.learnMore}</a>
            </Link>
          </p>
          <section className="mt-5 shrink-0 lg:mt-0">
            <button
              type="button"
              id="requestTradeAccountbutton"
              onClick={openRequestTradeModal}
              className="btn-primary-small mt-2 w-full py-2 text-center lg:mt-4 lg:w-64"
            >
              {mapLocaleToMeaningfulFormat(router?.locale)?.openTradeAccount}
            </button>
          </section>
          <RequestTradeAccount
            modalOpen={requestTradeModalOpen}
            modalClose={closeRequestTradeModal}
            modalHeading={`${mapLocaleToMeaningfulFormat(router?.locale)?.requestTradeAccount}`}
            primaryBtnContent={`${mapLocaleToMeaningfulFormat(router?.locale)?.submit}`}
          />
        </>
      )}
    </section>
  );
}

export default TradeAccount;
