import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M19.6828 2.0028C18.5328 0.852514 17.0082 0.153153 15.3862 0.0319014C13.7642 -0.0893506 12.1526 0.37556 10.8444 1.34209C9.46625 0.317044 7.75092 -0.147759 6.04382 0.0412773C4.33671 0.230313 2.76464 1.05915 1.64417 2.36087C0.523698 3.66259 -0.0619375 5.34051 0.00519409 7.05674C0.0723257 8.77296 0.787238 10.4 2.00597 11.6102L10.0753 19.6796C10.176 19.7811 10.2958 19.8617 10.4278 19.9167C10.5598 19.9717 10.7014 20 10.8444 20C10.9874 20 11.1289 19.9717 11.2609 19.9167C11.3929 19.8617 11.5127 19.7811 11.6134 19.6796L19.6828 11.6102C20.3139 10.9795 20.8145 10.2307 21.1561 9.40644C21.4976 8.5822 21.6735 7.69873 21.6735 6.80652C21.6735 5.9143 21.4976 5.03083 21.1561 4.20659C20.8145 3.38235 20.3139 2.63349 19.6828 2.0028V2.0028ZM18.1556 10.083L10.8444 17.3834L3.53319 10.083C2.88891 9.43605 2.45002 8.61324 2.27158 7.7178C2.09314 6.82237 2.18309 5.89417 2.53015 5.04966C2.87721 4.20515 3.46592 3.48193 4.22244 2.97072C4.97896 2.45951 5.86959 2.18308 6.7826 2.1761C8.00234 2.17909 9.17103 2.66604 10.032 3.53002C10.1327 3.63155 10.2525 3.71212 10.3845 3.76711C10.5165 3.8221 10.6581 3.85041 10.801 3.85041C10.944 3.85041 11.0856 3.8221 11.2176 3.76711C11.3496 3.71212 11.4694 3.63155 11.5701 3.53002C12.4564 2.76195 13.6015 2.35931 14.7735 2.40359C15.9455 2.44787 17.0569 2.93576 17.8828 3.76852C18.7087 4.60128 19.1873 5.7167 19.2219 6.88904C19.2564 8.06138 18.8443 9.20306 18.0689 10.083H18.1556Z"
      fill="#222222"
    />
  </svg>
);

export default Icon;
