import React from 'react';

const SampleTastic = ({ data }) => {
  const banner = data?.data?.dataSource?.response;

  return (
    <div className="m-auto w-full">
      <div
        style={{
          backgroundImage: `url('https://cdn.media.amplience.net/i/changecx/${banner?.backgroundImage?.name}')`,
          height: '40vh',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          justifyContent: 'center',
          color: 'white',
          padding: '50px',
          marginTop: '100px',
          boxShadow: '3px 7px 30px rgb(0, 0, 0, 0.1)',
        }}
      >
        <div className="w-max p-8 backdrop-blur-sm" style={{ backgroundColor: 'rgb(155, 155, 155, 0.5)' }}>
          <h2 className="text-5xl font-extrabold">{banner?.title}</h2>
          <p>{banner?.subTitle}</p>
        </div>
      </div>
    </div>
  );
};

export default SampleTastic;
