import React from 'react';
import { useRouter } from 'next/router';
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { LoginProps } from '../login';
import Login from '../login/account-login';
import GuestCheckout from './guest-checkout';
const Checkout: React.FC<LoginProps> = ({ accountLink, notificationTimeout }) => {
  const router = useRouter();
  const { setAsGuest } = useLocalStorage();
  const handleGuestCheckout = () => {
    setAsGuest();
    window.location.href = routerURL.checkoutAddress;
  };

  return (
    <div>
      <section className="mx-auto max-w-screen-8xl px-4">
        <section className="mt-20 flex flex-col items-center justify-center gap-4 lg:flex-row lg:items-start">
          <GuestCheckout handleGuestCheckout={handleGuestCheckout} />
          <section className="mb-5 w-full border-t border-[#B19A6A] pt-8 sm:w-[373px] lg:border-t-0 lg:border-l lg:pl-[50px] lg:pt-0">
            <Login
              accountLink={accountLink}
              notificationTimeout={notificationTimeout}
              loginTitle={mapLocaleToMeaningfulFormat(router.locale).logIn}
              showRememberButton={true}
              isCheckout={true}
            />
          </section>
        </section>
      </section>
    </div>
  );
};

export default Checkout;
