import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M7.2 10.4H8.8V12H7.2V10.4ZM7.2 4H8.8V8.8H7.2V4ZM8 0C3.576 0 0 3.6 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0ZM8 14.4C6.30261 14.4 4.67475 13.7257 3.47452 12.5255C2.27428 11.3253 1.6 9.69739 1.6 8C1.6 6.30261 2.27428 4.67475 3.47452 3.47452C4.67475 2.27428 6.30261 1.6 8 1.6C9.69739 1.6 11.3253 2.27428 12.5255 3.47452C13.7257 4.67475 14.4 6.30261 14.4 8C14.4 9.69739 13.7257 11.3253 12.5255 12.5255C11.3253 13.7257 9.69739 14.4 8 14.4Z"
      fill="#DA0F0F"
    />
  </svg>
);

export default Icon;
