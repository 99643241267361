import React from 'react';
import { useRouter } from 'next/router';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';

const SearchTips = ({ solInfo }) => {
  //next/router
  const router = useRouter();

  return (
    <>
      <section className="mt-10">
        <h4 className="font-[18px] mb-3 font-semibold leading-[22px] text-sol-300">
          {mapLocaleToMeaningfulFormat(router.locale).searchTips}
        </h4>
        <ul className="grid list-disc gap-y-3  pl-6">
          <li className="font-sm font-normal leading-[17px] text-sol-300">
            {mapLocaleToMeaningfulFormat(router.locale).makeSpelledCorrectly}
          </li>
          <li className="font-sm font-normal leading-[17px] text-sol-300">
            {mapLocaleToMeaningfulFormat(router.locale).tryDifferentKeywords}
          </li>
          <li className="font-sm font-normal leading-[17px] text-sol-300">
            {mapLocaleToMeaningfulFormat(router.locale).tryGeneralKeywords}
          </li>
          <li className="font-sm font-normal leading-[17px] text-sol-300">
            <span>{mapLocaleToMeaningfulFormat(router.locale).needHelp}</span>
            <a href={`tel:${solInfo?.solCustomerCareNumber}`} className="underline underline-offset-4">
              {solInfo?.solCustomerCareNumber}
            </a>
            <span className="px-1">{mapLocaleToMeaningfulFormat(router.locale).emailUs}</span>
            <a href={`mailto:${solInfo?.solCustomerCareEmail}`} className="underline underline-offset-4">
              {solInfo?.solCustomerCareEmail}
            </a>
          </li>
        </ul>
      </section>
    </>
  );
};

export default SearchTips;
