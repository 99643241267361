import React, { useEffect } from 'react';
import SOLHeader from 'components/sol-ui/layout/header';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { useAccount } from 'frontastic/provider';
import { algoliaUser } from 'helpers/hooks/algoliaEvents/algoliaEvents';
import { useIsMounted } from 'helpers/hooks/useIsMounted';

const SOLHeaderTastic = ({ data }) => {
  const [isDesktop] = useMediaQuery(1024);
  const { account } = useAccount();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted) {
      algoliaUser(account);
    }
  }, [account, isMounted]);

  return isDesktop ? (
    <section className="min-h-[191px]">
      <SOLHeader data={data} />
    </section>
  ) : (
    <section className="min-h-[86px] lg:hidden">
      <SOLHeader data={data} />
    </section>
  );
};

export default SOLHeaderTastic;
