import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { CardRequest } from '@Types/account/AuthorizeNetAccount';
import { SERVER_ERROR, CREDIT_CARD_CREATED, INVALID_CREDIT_CARD_REQUEST } from 'helpers/constants/messages';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { useNotification } from 'helpers/notification/notificationContext';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { desktop } from 'helpers/utils/screensizes';
import { useAuthorizeNet } from 'frontastic';
import Breadcrumb from '../../cms/Breadcrumb';
import AccountNav from '../account-nav';
import ContinueShoppingButton from '../continueShoppingButton';
import CreditCard from '../modal/credit-card';
import Card from './credit-card';

function CreditCards() {
  const router = useRouter();
  const [isDesktop] = useMediaQuery(desktop);
  const { data: cards, addCard, updateCard, setDefaultCreditCard } = useAuthorizeNet();
  // create credit card modal
  const [creditCardModalOpen, setcreditCardModalOpen] = useState(false);
  //global notification
  const { showNotification } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);

  const openCreditCardModal = () => {
    setcreditCardModalOpen(true);
  };

  const closeCreditCardModal = () => {
    setcreditCardModalOpen(false);
  };
  const handleAdd = async (request: CardRequest) => {
    setLoading(true);
    if (cards?.length === 0) {
      request.isDefault = true;
    }
    const response = await addCard(request);
    if (response?.customerPaymentProfileId) {
      if (request.isDefault) {
        await setDefaultCreditCard(response?.customerPaymentProfileId);
      }
      showNotification(response?.message, 'success');
    } else {
      showNotification(response?.message, 'error');
    }
    setLoading(false);
  };

  const handleUpdate = async (request: CardRequest) => {
    setLoading(true);
    const response = await updateCard(request);
    if (request.isDefault) {
      await setDefaultCreditCard(response?.customerPaymentProfileId);
      showNotification(response?.message, 'success');
    } else {
      showNotification(response?.message, 'error');
    }
    setLoading(false);
  };

  return (
    <>
      <Breadcrumb breadcrumLevelLink={false} activePage={mapLocaleToMeaningfulFormat(router.locale).creditCard} />
      <section className="mx-auto max-w-screen-8xl px-4 pb-[14.188rem]">
        <section className="mt-2 lg:mt-0 lg:flex lg:items-center lg:justify-center"></section>
        <section>{!isDesktop && <AccountNav />}</section>
        <section>
          <h1 className="mt-6 text-center text-[1.688rem] font-semibold after:mx-auto after:mt-4 after:block after:h-[0.063rem] after:w-[9.375rem] after:bg-[#B19A6A] after:content-[''] lg:mt-2">
            {mapLocaleToMeaningfulFormat(router.locale).creditCards}
          </h1>
          <p className="mt-4 text-center text-sm">
            {cards?.length || 0} {mapLocaleToMeaningfulFormat(router.locale).creditCards}
          </p>
        </section>
        <section className="relative mt-6 flex w-full justify-end ">
          <button type="button" id="addCard" className="btn-primary-small" onClick={openCreditCardModal}>
            {mapLocaleToMeaningfulFormat(router.locale).addCreditCard}
          </button>
        </section>
        <section className="flex-col gap-5 md:mt-4 lg:mt-2 lg:flex lg:flex-row">
          {isDesktop && <AccountNav />}
          <section className="mt-3 w-full text-sm font-normal leading-7 text-sol-300 lg:mt-0">
            {cards?.length === 0 && (
              <p className="rounded-md border-2 p-5">{mapLocaleToMeaningfulFormat(router.locale).noSavedCreditCard}</p>
            )}
            <ul className="mb-5 grid grid-cols-1 gap-4 lg:grid-cols-2">
              {cards?.length > 0 &&
                cards
                  ?.sort((card) => (card.isDefault ? -1 : 1))
                  .map((card, i) => <Card handleUpdate={handleUpdate} key={i} card={card} />)}
            </ul>
          </section>
        </section>
        <section>{!isDesktop && <ContinueShoppingButton />}</section>
      </section>
      <CreditCard
        open={creditCardModalOpen}
        onClose={closeCreditCardModal}
        modalHeading={`${mapLocaleToMeaningfulFormat(router.locale).addCreditCard}`}
        primaryBtnContent={`${mapLocaleToMeaningfulFormat(router.locale).save}`}
        handleAdd={handleAdd}
        loading={loading}
      />
    </>
  );
}

export default CreditCards;
