import { Product } from '@Types/product/Product';
import CTProductListCard from 'components/sol-ui/products/ct-product-list/ct-product-list-card';
import { useEffect, useState } from 'react';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { useRouter } from 'next/router';
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import { useProduct } from 'frontastic/provider';

function ProductRecommendationsTastic({ data }) {
  const router = useRouter();

  const [recommendationProducts, setRecommendationProducts] = useState([] as Product[]);
  const [youMayAlsoNeedProducts, setYouMayAlsoNeedProducts] = useState([] as Product[]);
  const [initialRecommendationProducts, setInitialRecommendationProducts] = useState([] as Product[]);
  const [groupedProducts, setGroupedProducts] = useState([] as Product[]);
  const { getProductRecommendationProducts } = useProduct();
  const { getItem } = useLocalStorage();
  const recommendationBlockLimit = data?.numberOfProducts;
  const isSplitColumn = youMayAlsoNeedProducts?.length + recommendationProducts?.length <= recommendationBlockLimit;
  useEffect(() => {
    if (data?.data?.dataSource?.recommendationProducts) {
      const recommendationProducts = data?.data?.dataSource?.recommendationProducts ?? [];
      const youMayAlsoNeedProducts = data?.data?.dataSource?.youMayAlsoNeedProducts ?? [];
      setInitialRecommendationProducts(recommendationProducts);
      setYouMayAlsoNeedProducts(youMayAlsoNeedProducts);
    }
  }, [data]);

  const removeDuplicates = (youMayAlsoNeedProducts, recommendationProducts, setRecommendationProducts) => {
    const youMayAlsoNeedProductsIdArray = [];
    youMayAlsoNeedProducts?.map((product) => youMayAlsoNeedProductsIdArray?.push(product?.productId));
    const tempRecommendationProducts = recommendationProducts?.filter(
      (product) => !youMayAlsoNeedProductsIdArray?.includes(product?.productId),
    );
    let tmpRecommendationProducts = tempRecommendationProducts;
    if (tempRecommendationProducts?.length > recommendationBlockLimit) {
      tmpRecommendationProducts = tempRecommendationProducts?.slice(0, recommendationBlockLimit);
    }
    setRecommendationProducts(tmpRecommendationProducts);
  };
  useEffect(() => {
    if (youMayAlsoNeedProducts && initialRecommendationProducts) {
      removeDuplicates(youMayAlsoNeedProducts, initialRecommendationProducts, setRecommendationProducts);
    }
  }, [youMayAlsoNeedProducts, initialRecommendationProducts]);
  // useEffect(() => {
  //   if (getItem('pdpRoutePath') !== router?.asPath)
  //     getProductRecommendationProducts(router.asPath).then((response: any) => {
  //       if (response) {
  //         setProducts(response);
  //       }
  //     });
  // }, [router.asPath]);
  return (
    <>
      {isSplitColumn ? (
        <div>
          {
            <section className="mx-auto mt-5 mb-5 grid max-w-screen-8xl grid-cols-1 gap-7 px-5 md:grid-cols-4">
              {youMayAlsoNeedProducts && youMayAlsoNeedProducts?.length > 0 && (
                <section className={`col-span-${youMayAlsoNeedProducts?.length}`}>
                  <strong className="mt-5 mb-5 inline-block text-[28px] font-semibold leading-[34px] text-sol-300">
                    {mapLocaleToMeaningfulFormat(router.locale).youMayAlsoNeed}
                  </strong>
                  <ul
                    className={`grid w-full grid-cols-${youMayAlsoNeedProducts?.length} gap-x-5 gap-y-[30px] md:gap-x-[27px] lg:grid-cols-${youMayAlsoNeedProducts?.length}`}
                  >
                    {youMayAlsoNeedProducts.map((product, index) => (
                      <CTProductListCard data={product} key={index} />
                    ))}
                  </ul>
                </section>
              )}
              {recommendationProducts && recommendationProducts?.length > 0 && (
                <section className={`col-span-${recommendationProducts?.length}`}>
                  <strong className="mt-5 mb-5 inline-block text-[28px] font-semibold leading-[34px] text-sol-300">
                    {mapLocaleToMeaningfulFormat(router.locale).youMayAlsoLike}
                  </strong>
                  <ul
                    className={`grid w-full grid-cols-${recommendationProducts?.length} gap-x-5 gap-y-[30px] md:gap-x-[27px] lg:grid-cols-${recommendationProducts?.length}`}
                  >
                    {recommendationProducts.map((product, index) => (
                      <CTProductListCard data={product} key={index} />
                    ))}
                  </ul>
                </section>
              )}
            </section>
          }
        </div>
      ) : (
        <div>
          {youMayAlsoNeedProducts && youMayAlsoNeedProducts.length ? (
            <section className="mx-auto mb-5 max-w-[1440px]">
              <section className="flex h-full w-full">
                <section className="w-full">
                  <section>
                    <strong className="mt-5 mb-5 inline-block text-[28px] font-semibold leading-[34px] text-sol-300">
                      {mapLocaleToMeaningfulFormat(router.locale).youMayAlsoNeed}
                    </strong>
                    <ul className="grid w-full grid-cols-2 gap-x-5 gap-y-[30px] md:gap-x-[27px] lg:grid-cols-4">
                      {youMayAlsoNeedProducts.map((product, index) => (
                        <CTProductListCard data={product} key={index} />
                      ))}
                    </ul>
                  </section>
                </section>
              </section>
            </section>
          ) : (
            <></>
          )}
          {recommendationProducts && recommendationProducts.length ? (
            <section className="mx-auto mb-5 max-w-[1440px]">
              <strong className="mt-5 mb-5 inline-block text-[28px] font-semibold leading-[34px] text-sol-300">
                {mapLocaleToMeaningfulFormat(router.locale).youMayAlsoLike}
              </strong>

              <section className="flex h-full w-full">
                <section className="w-full">
                  <ul className="grid w-full grid-cols-2 gap-x-5 gap-y-[30px] md:gap-x-[27px] lg:grid-cols-4">
                    {recommendationProducts.map((product, index) => (
                      <CTProductListCard data={product} key={index} />
                    ))}
                  </ul>
                </section>
              </section>
            </section>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
}

export default ProductRecommendationsTastic;
