import { memo } from 'react';
import { JsonLd } from 'react-schemaorg';
import { Product } from 'schema-dts';
import { CurrencyHelpers } from 'helpers/currencyHelpers';

const ProductRichText = ({ product, variant, url }) => {
  if (!product) return <></>;

  return (
    <>
      <JsonLd<Product>
        item={{
          '@context': 'https://schema.org',
          '@type': 'Product',
          name: `${product?.name}`,
          description: `${product?.description}`,
          image: variant?.images?.map((image) => {
            return image?.url;
          }),
          url: `https://www.shadesoflight.com${url}`,
          brand: {
            '@type': 'Brand',
            name: 'Shades of light',
          },
          offers: {
            '@type': 'Offer',
            price: CurrencyHelpers.formatAmount(variant?.price),
            priceCurrency: 'USD',
          },
        }}
      />
    </>
  );
};

export default memo(ProductRichText);
