import React from 'react';
import ErrorIcon from 'components/icons/sol/alert';
import CloseIcon from 'components/icons/sol/close';
import SuccessIcon from 'components/icons/sol/right';
import { useNotification } from 'helpers/notification/notificationContext';

interface NotificationProps {
  timeoutInMs: number;
}

const MiniCartNotification: React.FC<NotificationProps> = ({ timeoutInMs }) => {
  const { notification, hideMiniCartNotification } = useNotification();

  if (!notification) {
    return null;
  } else {
    setTimeout(hideMiniCartNotification, timeoutInMs || 5000);
  }

  const { message, type } = notification;

  return (
    <>
      <section className="absolute left-1/2 right-0 top-[45px] z-[999] flex w-full -translate-x-2/4 items-center justify-center px-5">
        <section
          className={`${
            type === 'success' ? 'border-[#B19A6A] bg-[#f1ebde]' : 'border-red-500 bg-red-100'
          }  flex w-max justify-between rounded-md border py-2 lg:items-center`}
        >
          <section className="flex pl-3">
            <section>
              {type === 'success' ? <SuccessIcon className="ml-2 h-5 w-4" /> : <ErrorIcon className="ml-2 h-5 w-4" />}
            </section>
            <section>
              <p className="mx-2 text-sm font-semibold">{message}</p>
            </section>
          </section>
          <section>
            <button type="button" className="ml-3 lg:mt-1" onClick={hideMiniCartNotification}>
              <CloseIcon className="mr-6 h-5 w-[0.625rem] fill-sol-300" />
            </button>
          </section>
        </section>
      </section>
    </>
  );
};

export default MiniCartNotification;
