import React, { useState } from 'react';
import { useRouter } from 'next/router';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { desktop } from 'helpers/utils/screensizes';

const PlpContent = ({ content }) => {
  const [isDesktop] = useMediaQuery(desktop);
  const [isTruncate, setIsTruncate] = useState(true);
  const router = useRouter();

  return (
    <section className={`${isDesktop && 'items-end justify-end'} mx-auto  max-w-screen-8xl px-3 md:flex`}>
      {content != undefined ? (
        <section className={isDesktop && 'w-[calc(100%-251px)] '}>
          <div className={` p-6`}>
            <h1 className="mb-6 text-[28px] font-semibold text-sol-300">{content?.contentTitle}</h1>
            <div>
              <span
                className={`${
                  isTruncate
                    ? 'inline-block w-full overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-[21px] text-sol-300'
                    : 'w-full'
                }`}
              >
                {content?.contentDescription}
              </span>
              <span
                onClick={() => setIsTruncate(!isTruncate)}
                className="cursor-pointer text-sm  leading-[21px] text-sol-300 underline"
              >
                {isTruncate
                  ? mapLocaleToMeaningfulFormat(router?.locale).readMore
                  : mapLocaleToMeaningfulFormat(router?.locale).readLess}
              </span>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
    </section>
  );
};

export default PlpContent;
