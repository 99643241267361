import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="347"
    height="98"
    viewBox="0 0 347 98"
    fill="none"
    className={className}
  >
    <path
      d="M0 94.3625L2.90039 90.8834C4.91206 92.5437 7.01328 93.5909 9.56921 93.5909C11.5809 93.5909 12.7934 92.7917 12.7934 91.4827V91.4207C12.7934 90.1738 12.0287 89.54 8.29469 88.5823C3.79599 87.4318 0.895606 86.1918 0.895606 81.7551V81.7C0.895606 77.6491 4.14735 74.9691 8.70805 74.9691C11.9598 74.9691 14.7362 75.9888 17.0027 77.8075L14.4537 81.5071C12.4765 80.1361 10.5268 79.3094 8.64604 79.3094C6.76527 79.3094 5.77322 80.1705 5.77322 81.2522V81.3142C5.77322 82.7816 6.73082 83.2569 10.5888 84.249C15.122 85.4271 17.671 87.0529 17.671 90.9454V91.0074C17.671 95.4441 14.2884 97.9311 9.47276 97.9311C6.09012 97.9311 2.67993 96.753 0 94.3625Z"
      fill="#B19A6A"
    />
    <path
      d="M25.1389 75.286H30.051V84.125H39.1104V75.286H44.0224V97.6142H39.1104V88.6512H30.051V97.6142H25.1389V75.286Z"
      fill="#B19A6A"
    />
    <path
      d="M60.2329 75.1276H64.7592L74.3284 97.6142H69.189L67.1497 92.6057H57.7114L55.6722 97.6142H50.6637L60.2329 75.1276ZM65.3999 88.2654L62.4306 81.0248L59.4613 88.2654H65.3999Z"
      fill="#B19A6A"
    />
    <path
      d="M80.9697 75.286H89.6778C96.698 75.286 101.541 80.1016 101.541 86.3847V86.4467C101.541 92.7297 96.6911 97.6142 89.6778 97.6142H80.9697V75.286ZM85.8818 79.7158V93.1775H89.6778C93.6942 93.1775 96.4086 90.47 96.4086 86.5087V86.4467C96.4086 82.4922 93.7011 79.7158 89.6778 79.7158H85.8818Z"
      fill="#B19A6A"
    />
    <path
      d="M109.112 75.286H125.957V79.6538H113.997V84.1801H124.524V88.5479H113.997V93.2395H126.122V97.6073H109.119V75.286H109.112Z"
      fill="#B19A6A"
    />
    <path
      d="M132.143 94.3625L135.044 90.8834C137.055 92.5437 139.157 93.5909 141.713 93.5909C143.724 93.5909 144.937 92.7917 144.937 91.4827V91.4207C144.937 90.1738 144.172 89.54 140.438 88.5823C135.939 87.4318 133.039 86.1918 133.039 81.7551V81.7C133.039 77.6491 136.291 74.9691 140.851 74.9691C144.103 74.9691 146.879 75.9888 149.146 77.8075L146.597 81.5071C144.62 80.1361 142.67 79.3094 140.789 79.3094C138.909 79.3094 137.917 80.1705 137.917 81.2522V81.3142C137.917 82.7816 138.874 83.2569 142.732 84.249C147.265 85.4271 149.814 87.0529 149.814 90.9454V91.0074C149.814 95.4441 146.432 97.9311 141.616 97.9311C138.24 97.9311 134.823 96.753 132.143 94.3625Z"
      fill="#B19A6A"
    />
    <path
      d="M169.056 86.5156V86.4536C169.056 80.1085 174.065 74.9071 180.954 74.9071C187.843 74.9071 192.79 80.0465 192.79 86.3916V86.4536C192.79 92.7986 187.781 98 180.892 98C174.003 97.9931 169.056 92.8606 169.056 86.5156ZM187.65 86.5156V86.4536C187.65 82.6231 184.846 79.4334 180.885 79.4334C176.93 79.4334 174.189 82.5611 174.189 86.3847V86.4467C174.189 90.2771 176.992 93.4668 180.954 93.4668C184.915 93.4668 187.65 90.3391 187.65 86.5156Z"
      fill="#B19A6A"
    />
    <path
      d="M200.354 75.286H217.357V79.7503H205.266V84.5039H215.917V88.9681H205.266V97.6142H200.354V75.286Z"
      fill="#B19A6A"
    />
    <path d="M237.239 75.286H242.151V93.1499H253.284V97.6142H237.239V75.286Z" fill="#B19A6A" />
    <path d="M260.401 75.286H265.313V97.6142H260.401V75.286Z" fill="#B19A6A" />
    <path
      d="M273.098 86.5156V86.4536C273.098 80.1085 278.044 74.9071 284.803 74.9071C288.826 74.9071 291.244 75.9887 293.58 77.966L290.486 81.7C288.764 80.267 287.235 79.4334 284.651 79.4334C281.083 79.4334 278.237 82.5887 278.237 86.3847V86.4467C278.237 90.532 281.041 93.5289 285.003 93.5289C286.787 93.5289 288.385 93.0811 289.632 92.1923V89.0026H286.725V84.7588H294.386V94.452C292.085 96.3948 288.929 97.9931 284.844 97.9931C277.879 97.9931 273.098 93.1155 273.098 86.5156Z"
      fill="#B19A6A"
    />
    <path
      d="M302.288 75.286H307.2V84.125H316.259V75.286H321.171V97.6142H316.259V88.6512H307.2V97.6142H302.288V75.286Z"
      fill="#B19A6A"
    />
    <path d="M334.922 79.8123H328.129V75.2792H346.627V79.8123H339.834V97.6142H334.922V79.8123Z" fill="#B19A6A" />
    <path d="M188.284 30.7951L173.314 0L158.343 30.7951L173.314 45.4623L188.284 30.7951Z" fill="#B19A6A" />
    <path d="M135.815 8.71494V52.9579H147.568L158.343 30.7951L135.815 8.71494Z" fill="#1B3144" />
    <path d="M188.284 30.7951L199.066 52.9579H210.819V8.71494L188.284 30.7951Z" fill="#FFA892" />
    <path d="M173.314 45.4623L180.961 52.9579H199.066L188.284 30.7951L173.314 45.4623Z" fill="#AB6339" />
    <path d="M173.314 45.4623L158.343 30.7951L147.568 52.9579H165.666H180.961L173.314 45.4623Z" fill="black" />
  </svg>
);

export default Icon;
