import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.83078 5.2458L0.290784 1.7058C0.104533 1.51844 -8.58307e-06 1.26498 -8.58307e-06 1.0008C-8.58307e-06 0.736612 0.104533 0.483161 0.290784 0.295798C0.383747 0.20207 0.494348 0.127675 0.616207 0.0769067C0.738066 0.026138 0.868772 0 1.00078 0C1.1328 0 1.2635 0.026138 1.38536 0.0769067C1.50722 0.127675 1.61782 0.20207 1.71078 0.295798L5.95078 4.5358C6.04451 4.62876 6.11891 4.73936 6.16967 4.86122C6.22044 4.98308 6.24658 5.11379 6.24658 5.2458C6.24658 5.37781 6.22044 5.50852 6.16967 5.63037C6.11891 5.75223 6.04451 5.86283 5.95078 5.9558L1.71078 10.2458C1.61734 10.3385 1.50653 10.4118 1.38469 10.4616C1.26285 10.5113 1.13239 10.5366 1.00078 10.5358C0.869177 10.5366 0.738715 10.5113 0.616878 10.4616C0.49504 10.4118 0.384224 10.3385 0.290784 10.2458C0.104533 10.0584 -8.58307e-06 9.80498 -8.58307e-06 9.5408C-8.58307e-06 9.27661 0.104533 9.02316 0.290784 8.8358L3.83078 5.2458Z"
      fill="#222222"
    />
  </svg>
);

export default Icon;
