import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { desktop } from 'helpers/utils/screensizes';

export const RelatedCategoryBlock = ({ contents }) => {
  const router = useRouter();
  const [isDesktop] = useMediaQuery(desktop);

  return (
    <section className="mx-auto mb-12 max-w-screen-8xl">
      <h1 className="relative mb-8 text-center before:absolute before:top-[54%] before:block before:h-[1px] before:w-full before:bg-sol-600 before:content-[''] lg:mb-10">
        <em className="relative bg-white px-2 text-[28px] font-semibold uppercase not-italic leading-[39px] md:px-10 md:text-[32px]">
          {mapLocaleToMeaningfulFormat(router.locale).relatedCategories}
        </em>
      </h1>
      <ul className="mx-auto grid grid-cols-2 gap-5 lg:grid-cols-4">
        {contents?.map((content, index) => (
          <li key={index}>
            <Link href={content?.clpCtaLink}>
              <a className="flex h-full w-full">
                <section className="h-full w-full bg-sol-100">
                  <section>
                    <Image
                      loader={(options) => options.src}
                      width={324}
                      height={200}
                      src={content?.image?.url}
                      alt={content?.clpAlt}
                      sizes="100vw"
                      className="rounded-t-md"
                    />
                  </section>
                  <section className="bg-sol-100 p-4">
                    <button type="button" className="w-full text-center text-base font-semibold leading-5 text-sol-300">
                      {content?.clpTitle}
                    </button>
                  </section>
                </section>
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};
