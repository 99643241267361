import React from 'react';
import CreditCardIcon from 'components/icons/sol/credit-card-icon';
import DiscoverIcon from 'components/icons/sol/discover';
import AmexIcon from '../../../icons/sol/amex';
import MasterCardIcon from '../../../icons/sol/mastercard';
import VisaIcon from '../../../icons/sol/visa';

function cardIcon({ cardType }) {
  return (
    <section>
      {(() => {
        switch (cardType) {
          case 'Visa':
            return <VisaIcon />;
          case 'MasterCard':
            return <MasterCardIcon />;
          case 'AmericanExpress':
            return <AmexIcon />;
          case 'Discover':
            return <DiscoverIcon />;
          default:
            return <CreditCardIcon />;
        }
      })()}
      {/* {cardType === 'Visa' && <VisaIcon />}
      {cardType === 'MasterCard' && <MasterCardIcon />}
      {cardType === 'AmericanExpress' && <AmexIcon />}
      {cardType === 'Discover' && <DiscoverIcon />}
      {cardType === 'default' && <CreditCardIcon />} */}
    </section>
  );
}

export default cardIcon;
