import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7" fill="none" className={className}>
    <path
      d="M2.134 5.95163C2.2236 6.04791 2.3302 6.12432 2.44766 6.17647C2.56511 6.22862 2.69108 6.25547 2.81832 6.25547C2.94556 6.25547 3.07154 6.22862 3.18899 6.17647C3.30644 6.12432 3.41304 6.04791 3.50264 5.95163L7.43506 1.7607C7.61655 1.56727 7.71851 1.30493 7.71851 1.03139C7.71851 0.757849 7.61655 0.495511 7.43506 0.302087C7.25356 0.108664 7.00741 0 6.75074 0C6.49407 0 6.24791 0.108664 6.06642 0.302087L2.81832 3.77399L1.65209 2.52082C1.4706 2.32739 1.22444 2.21873 0.967771 2.21873C0.711102 2.21873 0.464946 2.32739 0.283454 2.52082C0.101961 2.71424 0 2.97658 0 3.25012C0 3.52366 0.101961 3.786 0.283454 3.97943L2.134 5.95163Z"
      fill="#222222"
    ></path>
  </svg>
);

export default Icon;
