import React, { useState, useEffect, useRef } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Formik, Field } from 'formik';
import { MdCheckCircleOutline } from 'react-icons/md';
import ForwardIcon from 'components/icons/sol/forward';
import { SUCCESSFULLY_SUBSCRIBED_NEWSLETTER } from 'helpers/constants/messages';
import { useNotification } from 'helpers/notification/notificationContext';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import validationSchema from 'helpers/utils/validationSchema/footer';
import { useAccount } from 'frontastic';
// import Image from 'frontastic/lib/image';

export const SOLFooterMobile = ({ navs, content }) => {
  const router = useRouter();
  const [navIndex, setNavIndex] = useState(-1);
  const formikRef = useRef(null);
  const {
    socialGroupTitle,
    SocialGroups,
    integrityGroups,
    newsletterDescription,
    newsletterTitle,
    footerBannerTitle,
    footerBannerCTALink,
    copyrightText,
    year,
  } = content;
  const {
    customerServiceColumn,
    customerServiceColumnLinks,
    b2bProgramsColumn,
    b2bProgramsColumnLinks,
    ourCompanyColumn,
    ourCompanyColumnLinks,
    shippingResourcesColumn,
    shippingResourcesColumnLinks,
  } = navs;
  const { showNotification } = useNotification();
  const { signupNewsLetter } = useAccount();
  const [isSubmitted, setSubmitted] = useState(false);
  const handleSubmit = async (values, { setSubmitting }) => {
    await signupNewsLetter(values.email).then(() => {
      formikRef.current.resetForm();
      setSubmitted(true);
    });
    setSubmitting(false);
  };

  if (isSubmitted) {
    setTimeout(() => {
      setSubmitted(false);
    }, 5000);
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && window?.location?.href) {
      formikRef.current.resetForm();
    }
  }, [typeof window !== 'undefined' ? window?.location?.href : undefined]);
  return (
    <section className="w-full flex-col text-sm">
      <section className="flex flex-wrap items-center justify-center gap-5 bg-[rgb(34,34,34)]/10 py-10 px-[15px]">
        <section className="flex flex-wrap items-center justify-center gap-9">
          <section className={'flex flex-col gap-3 text-center'}>
            <p className="font-semibold leading-[17px] text-[#222222]">{newsletterTitle || ''}</p>
            <p className="leading-[17px] text-[#222222]">{newsletterDescription || ''}</p>
          </section>
          <section className={' flex w-full items-center gap-0 pb-2'}>
            <Formik
              innerRef={formikRef}
              initialValues={{ email: '' }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formikProps) => (
                <form onSubmit={formikProps.handleSubmit} className="flex w-full items-center gap-0">
                  <div className=" w-full items-center gap-0">
                    <Field
                      type="text"
                      name="email"
                      className={`order-transparent bottom-0 h-10 w-full rounded-l-md border-white leading-[17px] text-[#666666] placeholder-[#666666] focus:border-transparent focus:ring-0 ${
                        formikProps.touched.email && formikProps.errors.email ? '!border-red-600' : ''
                      }`}
                      placeholder="Enter email address"
                    />

                    {formikProps.touched.email && formikProps.errors.email ? (
                      <div className="absolute  inset-x-0  px-4">
                        <p className="whitespace-nowrap pt-1 text-sm font-normal leading-[17px] text-[#DA0F0F]">
                          {formikProps.errors.email}
                        </p>
                      </div>
                    ) : formikProps.values.email !== '' ? null : isSubmitted ? (
                      <div className="absolute inset-x-0 px-4">
                        <p className="flex items-center whitespace-nowrap py-1 text-sm font-normal leading-[17px] text-sand-200">
                          <MdCheckCircleOutline className="mr-1" />
                          {mapLocaleToMeaningfulFormat(router.locale).successfulSubscription}
                        </p>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <button className="h-10 rounded-r-md bg-[#404040] px-5 text-sm font-bold leading-[17px] text-white">
                    {mapLocaleToMeaningfulFormat(router.locale).join}
                  </button>
                </form>
              )}
            </Formik>
          </section>
        </section>
        <section className={'g-5 flex flex-col items-center gap-7'}>
          <p className="text-sm font-semibold leading-[17px] text-[#222222]">{socialGroupTitle || ''}</p>
          <ul className="flex items-center gap-10">
            {SocialGroups?.data?.map((content, key) => (
              <li key={key}>
                <a
                  href={content?.link || '#'}
                  target="_blank"
                  rel="noreferrer"
                  className="relative flex h-5 w-5 items-center justify-center"
                >
                  <Image
                    loader={({ src }) => src}
                    src={content?.icon?.url ? `${content?.icon?.url}?fmt=auto` : '#'}
                    alt={content?.alt}
                    priority={true}
                    layout="fill"
                    objectFit="contain"
                  />
                </a>
              </li>
            ))}
          </ul>
        </section>
      </section>

      <section className="flex flex-col bg-[#F4F4F4]">
        <section>
          {footerBannerTitle && (
            <p className="py-5 text-center font-semibold leading-[17px] after:mx-auto after:mt-4 after:block after:h-[1px] after:w-[80px] after:bg-[#B19A6A] after:content-['']">
              <a href={footerBannerCTALink} rel="noreferrer">
                {footerBannerTitle || ''}
              </a>
            </p>
          )}
          {customerServiceColumn && (
            <section data-index={0} className="my-6 flex flex-col gap-3 px-[15px]">
              <section
                key={customerServiceColumn || ''}
                className="flex items-center justify-between"
                onClick={() => (navIndex === 0 ? setNavIndex(-1) : setNavIndex(0))}
              >
                <p className="font-semibold leading-[17px]">{customerServiceColumn}</p>
                <section className={navIndex === 0 ? 'rotate-90' : ''}>
                  <ForwardIcon />
                </section>
              </section>
              <ul>
                {navIndex === 0 &&
                  customerServiceColumnLinks.map((item, index) => (
                    <li key={index} className="py-2">
                      <a href={item?.reference?.link || '/'} key={index} className="leading-[17px]">
                        {item?.name}
                      </a>
                    </li>
                  ))}
              </ul>
            </section>
          )}
          {b2bProgramsColumn && (
            <section data-index={1} className="my-6 flex flex-col gap-3 px-[15px]">
              <section
                key={b2bProgramsColumn || ''}
                className="flex items-center justify-between"
                onClick={() => (navIndex === 1 ? setNavIndex(-1) : setNavIndex(1))}
              >
                <p className="font-semibold leading-[17px]">{b2bProgramsColumn}</p>
                <section className={navIndex === 1 ? 'rotate-90' : ''}>
                  <ForwardIcon />
                </section>
              </section>
              <ul>
                {navIndex === 1 &&
                  b2bProgramsColumnLinks.map((item, index) => (
                    <li key={index} className="py-2">
                      <a href={item?.reference?.link || '/'} key={index} className="leading-[17px]">
                        {item?.name}
                      </a>
                    </li>
                  ))}
              </ul>
            </section>
          )}
          {ourCompanyColumn && (
            <section data-index={2} className="my-6 flex flex-col gap-3 px-[15px]">
              <section
                key={ourCompanyColumn || ''}
                className="flex items-center justify-between"
                onClick={() => (navIndex === 2 ? setNavIndex(-1) : setNavIndex(2))}
              >
                <p className="font-semibold leading-[17px]">{ourCompanyColumn}</p>
                <section className={navIndex === 2 ? 'rotate-90' : ''}>
                  <ForwardIcon />
                </section>
              </section>
              <ul>
                {navIndex === 2 &&
                  ourCompanyColumnLinks.map((item, index) => (
                    <li key={index} className="py-2">
                      <a href={item?.reference?.link || '/'} key={index} className="leading-[17px]">
                        {item?.name}
                      </a>
                    </li>
                  ))}
              </ul>
            </section>
          )}
          {shippingResourcesColumn && (
            <section data-index={3} className="my-6 flex flex-col gap-3 px-[15px]">
              <section
                key={shippingResourcesColumn || ''}
                className="flex items-center justify-between"
                onClick={() => (navIndex === 3 ? setNavIndex(-1) : setNavIndex(3))}
              >
                <p className="font-semibold leading-[17px]">{shippingResourcesColumn}</p>
                <section className={navIndex === 3 ? 'rotate-90' : ''}>
                  <ForwardIcon />
                </section>
              </section>
              <ul>
                {navIndex === 3 &&
                  shippingResourcesColumnLinks.map((item, index) => (
                    <li key={index} className="py-2">
                      <a href={item?.reference?.link || '/'} key={index} className="leading-[17px]">
                        {item?.name}
                      </a>
                    </li>
                  ))}
              </ul>
            </section>
          )}
        </section>

        <section className="mt-0 grid justify-center gap-5 border-t border-[rgb(34,34,34)]/10 py-5 px-[15px] text-xs leading-[15px] text-[#222222]">
          <ul className="flex flex-wrap justify-center gap-x-2 gap-y-4 ">
            {integrityGroups?.data?.map((content, key) => (
              <li key={key} className="shrink-0 hover:underline">
                {key !== 0 ? <span className="pr-2">|</span> : <></>}
                {content.link === routerURL?.hash ? (
                  <button
                    className="hover:underline"
                    type="button"
                    onClick={() =>
                      window !== undefined && window['Osano']?.cm?.showDrawer('osano-cm-dom-info-dialog-open')
                    }
                  >{`${content?.title || ''} `}</button>
                ) : (
                  <a href={content?.link || '/'}>{`${content?.title || ''} `}</a>
                )}
              </li>
            ))}
          </ul>
          <p className="text-center">{`${mapLocaleToMeaningfulFormat(router.locale).copyrightSymbol} ${year || ''} ${
            copyrightText || ''
          }`}</p>
        </section>
      </section>
    </section>
  );
};
