import * as Yup from 'yup';

const validationSchema = Yup.object({
  creditCardNumber: Yup.string()
    .test('no-spaces', 'Spaces are not allowed inside the Credit card Number.', (value) => {
      return value === undefined || !/\s/.test(value);
    })
    .required('Please enter a valid Credit card Number.')
    .min(11, 'Must be exactly between 11-19 digits')
    .max(19, 'Must be exactly between 11-19 digits'),
  expirationDate: Yup.string()
    .required('Please enter correct Card Expiration Date.')
    .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, 'Invalid expiration date'),
  cvv: Yup.string()
    .required('Please enter correct CVV.')
    .matches(/^[0-9]{3,4}$/, 'Must be exactly between 3-4 digits number.'),
});
export default validationSchema;
