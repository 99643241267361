import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';

export const Error404Page = ({ content }) => {
  const [content404, setContent404] = useState(null);
  const [isDesktopSize] = useMediaQuery(desktop);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setContent404(localStorage.getItem('product404') ? JSON.parse(localStorage.getItem('product404')) : null);
    }
  }, []);

  return (
    <section className="mx-auto mt-10 flex max-w-screen-8xl flex-col items-center justify-between p-5">
      <section className="flex w-full flex-wrap-reverse items-center justify-evenly gap-10 text-xl">
        <div className="flex flex-[1] flex-col gap-2 space-x-1">
          <h1 className="text-7xl font-[500] text-[#c5a97e]">{content?.title}</h1>
          <p className="mt-5 lg:max-w-[450px]">{content?.description}</p>
          <span className="mt-10 font-[500] text-[#c5a97e]">{content?.subTitle}</span>
          <span className="font-[500]">{content?.helpSubtext}</span>
          <span className="font-[500]">{content?.phone}</span>
          <span className="break-all font-[500]">{content?.emailCtaLinkText}</span>
        </div>
        <img className="lg:max-w-[500px]" src={content?.image?.url} alt="" />
      </section>
      {content404 && (
        <section className="my-10 w-full cursor-pointer">
          <Link href={content404?.ctaLink || ''}>
            <img src={!isDesktopSize ? content404?.mobileImage?.url : content404?.desktopImage?.url} alt="" />
          </Link>
        </section>
      )}
    </section>
  );
};
