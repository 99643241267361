import * as Yup from 'yup';

const validationSchema = (sendCatalog) =>
  Yup.object({
    email: Yup.string().email('Email must be valid').required('Please enter a valid email address.'),
    firstName: Yup.string()
      .required('Please enter a valid First name.')
      .matches(/^[aA-zZ-\s]+$/, 'Please enter a valid First name.'),
    lastName: Yup.string()
      .required('Please enter a valid Last name.')
      .matches(/^[aA-zZ-\s]+$/, 'Please enter a valid Last name.'),
    streetName: sendCatalog && Yup.string().required('Please enter your Street name.'),
    city:
      sendCatalog &&
      Yup.string()
        .required('Please enter your city.')
        .matches(/^[aA-zZ\s]+$/, 'Please enter a valid city.'),
    state:
      sendCatalog &&
      Yup.string()
        .required('Please enter your State/Region.')
        .matches(/^[aA-zZ\s]+$/, 'Please enter a valid state'),
    postalCode: sendCatalog && Yup.string().required('Please enter your valid Zip Code.'),
    phone: sendCatalog && Yup.string().required('Number is required'),
  });
export default validationSchema;
