import { useState } from 'react';
import Image from 'next/image';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';

const BannerCatalogRequestSubmit = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDesktop] = useMediaQuery(desktop);

  return (
    <>
      {/* TODO: Static the comes from amplience on later  */}
      <section className="relative mx-auto mb-20 mt-6 flex h-[auto] max-w-screen-8xl items-center justify-center lg:mt-0">
        <div className="mx-auto max-w-screen-8xl">
          <ul className="flex h-[600px] w-full justify-center">
            <li className="flex h-[600px] w-full items-center">
              <Image
                loader={({ src }) => src}
                src="https://cdn.shadesoflight.com/media/W1siZiIsIjIwMjAvMDgvMDYvMTAvNDMvMTAvODYvRGVjb3JfY2F0LmpwZyJdLFsicCIsIm9wdGltIl1d/Decor_cat.jpg?sha=a0fa15519fc8bbb2"
                width="0"
                height="0"
                layout="fill"
                className="object-cover"
                alt=""
              />
            </li>
          </ul>
        </div>
        <div
          className={`absolute flex h-[600px] w-full ${isDesktop ? 'justify-center ' : 'items-center justify-center'} `}
        >
          <div className="m-12 flex flex-col items-center justify-center gap-3 bg-[rgb(0,0,0,0.4)] p-10 text-white">
            <p className="text-3xl">Thank You For Your Request!</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default BannerCatalogRequestSubmit;
