import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Cart } from '@Types/cart/Cart';
import { Order } from '@Types/cart/Order';
import OrderReviewSummary from '../../line-Item/index';
interface OrderReviewProps {
  cart: Cart | Order;
}
const OrderReview: React.FC<OrderReviewProps> = ({ cart }) => {
  const [componentIdentifier, setComponentIdentifier] = useState(null);
  const router = useRouter();
  useEffect(() => {
    if (router?.asPath?.includes('/checkout')) {
      setComponentIdentifier('checkout');
    }
    if (router?.asPath?.includes('/thank-you')) {
      setComponentIdentifier('thankyouPage');
    }
  }, []);
  return (
    <section>
      <ul>
        {cart?.lineItems?.map((lineItem, index) => (
          <li
            key={index}
            className="mb-5 mt-3 w-full rounded border px-5 py-6 pb-5 text-sm font-normal text-sol-300 lg:mt-0 lg:py-2"
          >
            {/* TODO: Static will change or uncomment it after BE integration */}

            {/* {cart?.lineItems?.isLineItemRestricted && (
                  <p className='text-red-600 font-medium mt-4 text-center lg:pr-4 lg:pb-8'>This product is no longer available.</p>
                  )} */}
            <OrderReviewSummary
              componentIdentifier={componentIdentifier}
              lineItem={lineItem}
              showQuantityInput={false}
              showAddToCartButton={false}
              showInventory={false}
              isFromOrderDetails={true}
              index={index}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default OrderReview;
