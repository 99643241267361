import { useEffect, useState } from 'react';
import Image from 'next/image';
import router, { useRouter } from 'next/router';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { Reference, getReferenceTarget } from 'helpers/reference';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { desktop } from 'helpers/utils/screensizes';
import { useAccount } from 'frontastic';
import Breadcrumb from '../cms/Breadcrumb';
import CreateAccount from './account-create';
import Login from './account-login';
export interface LoginProps {
  accountLink?: Reference;
  loginLink?: Reference;
  notificationTimeout?: number;
  loginTitle?: string;
  closeModal?: () => void;
  showRememberButton?: boolean;
  setIsErrorModelOpen?: any;
  isPopOverPanel?: boolean;
  isCheckout?: boolean;
}

const AccountLogin = ({ data }) => {
  const { accountLink, loginLink, notificationTimeout, maximumLoginAttemptTimeout } = data;
  const [isDesktop] = useMediaQuery(desktop);

  //account actions
  const { register, confirm, loggedIn } = useAccount();
  const [isErrorModelOpen, setIsErrorModelOpen] = useState(false);
  //next/router
  const router = useRouter();

  if (loggedIn) {
    router.push(getReferenceTarget(accountLink));
  }
  useEffect(() => {
    let currentTimeOutStatus;

    if (isErrorModelOpen) {
      currentTimeOutStatus = setTimeout(() => {
        setIsErrorModelOpen(false);
      }, maximumLoginAttemptTimeout);
    }

    return () => {
      if (currentTimeOutStatus) {
        clearTimeout(currentTimeOutStatus);
      }
    };
  }, [isErrorModelOpen, maximumLoginAttemptTimeout]);

  return (
    <section>
      <Breadcrumb breadcrumLevelLink={true} activePage={mapLocaleToMeaningfulFormat(router.locale).logIn} />
      <section
        className={`mx-auto my-10 max-w-[1440px] px-5 ${
          isErrorModelOpen ? (isDesktop ? 'mt-20 mb-20' : 'mt-[16rem]') : isDesktop ? '' : 'mt-[4rem]'
        }`}
      >
        <section className="grid grid-cols-1 px-3 md:px-6 lg:mt-8 lg:grid-cols-2">
          <section className="w-full justify-center sm:flex lg:justify-end">
            <section className="sm:w-[373px]">
              <section className="!w-full border-b border-[#B19A6A] pb-8 lg:border-b-0 lg:border-r lg:pr-[50px] lg:pb-0">
                <Login
                  setIsErrorModelOpen={setIsErrorModelOpen}
                  accountLink={accountLink}
                  loginLink={loginLink}
                  notificationTimeout={notificationTimeout}
                  loginTitle={`${mapLocaleToMeaningfulFormat(router.locale).logIn}`}
                  showRememberButton={true}
                  isPopOverPanel={true}
                  isCheckout={false}
                />
              </section>
            </section>
          </section>
          <CreateAccount accountLink={accountLink} loginLink={loginLink} notificationTimeout={notificationTimeout} />
        </section>
      </section>
    </section>
  );
};

export default AccountLogin;
