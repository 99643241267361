import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useConfig } from 'frontastic';

const ReCaptcha = ({ setIsReCaptchaVerified, setReCaptchaError }) => {
  const { data: config } = useConfig();

  if (config?.reCaptcha?.enabled !== undefined && !config?.reCaptcha?.enabled) {
    setIsReCaptchaVerified(true);
    setReCaptchaError(false);
    return <></>;
  }

  const handleChange = (res) => {
    if (res) {
      setIsReCaptchaVerified(true);
      setReCaptchaError(false);
    }
  };

  return (
    <div className="mt-4">
      {config?.reCaptcha?.siteKey && (
        <ReCAPTCHA
          onChange={(res) => handleChange(res)}
          onExpired={() => setIsReCaptchaVerified(false)}
          size="normal"
          sitekey={config?.reCaptcha?.siteKey}
        />
      )}
    </div>
  );
};

export default ReCaptcha;
