import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { SubscriptionList } from '@Types/communication-preference/SubscriptionList';
import { Formik, Form, Field } from 'formik';
import Spinner from 'components/sol-ui/notification/spinner';
import ReCaptcha from 'components/sol-ui/recaptcha';
import { UNSUBSCRIBED_SUCCESSFULLY } from 'helpers/constants/messages';
import { useNotification } from 'helpers/notification/notificationContext';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { useAccount, useFreshdesk } from 'frontastic';
interface Props {
  isSubscribed: boolean;
  setIsSubscribed: any;
  subscriptionList: SubscriptionList;
  setSubscriptionList: any;
  emailId: string;
  setUnSubscribeSubmit?: any;
}
const UnSubscriptionPreferences = ({
  isSubscribed,
  setIsSubscribed,
  subscriptionList,
  setSubscriptionList,
  setUnSubscribeSubmit,
  emailId,
}: Props) => {
  const router = useRouter();

  const initialOption = {
    checkAll: subscriptionList?.all ?? false,
    trending_looks_styles: subscriptionList?.trending_looks_styles ?? false,
    new_catalog_previews: subscriptionList?.new_catalog_previews ?? false,
    new_item_introductions: subscriptionList?.new_item_introductions ?? false,
    decoration_tips_ideas: subscriptionList?.decoration_tips_ideas ?? false,
    sales_specials: subscriptionList?.sales_specials ?? false,
  };

  const { loggedIn, account, checkSubscription, updateSubscription } = useAccount();
  const userEmail = (router?.query?.email as string) ?? emailId;

  const [reCaptchaError, setReCaptchaError] = useState(false);
  const { showNotification } = useNotification();
  const [isReCaptchaVerified, setIsReCaptchaVerified] = useState(false);

  const [options, setoptions] = useState(initialOption);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(userEmail);
  const [confirmEmail, setConfirmEmail] = useState(userEmail);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isValidForm, setIsValidForm] = useState(false);

  const onOptionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'checkAll') {
      setoptions((prev: any) => {
        const res = Object.keys(prev).reduce((a: any, b: any) => {
          a[b] = event.target.checked;
          return a;
        }, {});
        return res;
      });
    } else {
      setoptions((prev: any) => {
        const res = { ...prev, [event.target.name]: event.target.checked };
        const { checkAll, ...data } = res;
        return { ...data, checkAll: Object.values(data).every((item) => item === true) };
      });
    }
  };
  useEffect(() => {
    if (Object.entries(subscriptionList).length > 0) {
      const copyOptions: SubscriptionList = { ...options };
      Object.entries(copyOptions).forEach(([key, value]) => {
        if (!subscriptionList[key]) {
          delete copyOptions[key];
        }
      });
      const isValid = Object.values(copyOptions).some((value) => value === true);

      if (isValid) {
        setIsValidForm(true);
      } else {
        setIsValidForm(false);
      }
    }
  }, [options]);
  useEffect(() => {
    if (userEmail && !initialLoad) {
      setEmail(userEmail);
      setConfirmEmail(userEmail);
      setInitialLoad(true);
    } else if (email === '') {
      setInitialLoad(true);
    } else {
      setEmail(userEmail);
      setConfirmEmail(userEmail);
    }
  }, [userEmail]);

  const emailPreferenceInitialValues = options;
  const handleSubmit = async (values, { resetForm }) => {
    const payload: SubscriptionList = {
      email: values?.email ?? email,
      decoration_tips_ideas: values.decoration_tips_ideas ?? false,
      new_catalog_previews: values.new_catalog_previews ?? false,
      new_item_introductions: values.new_item_introductions ?? false,
      sales_specials: values.sales_specials ?? false,
      trending_looks_styles: values.trending_looks_styles ?? false,
    };
    Object.entries(payload).forEach(([key, value]) => {
      if (payload[key] && payload[key] != 'email' && subscriptionList[key]) {
        payload[key] = false;
      } else if (!payload[key] && payload[key] != 'email' && subscriptionList[key]) {
        payload[key] = true;
      } else {
        payload[key] = value;
      }
    });

    setLoading(true);
    setReCaptchaError(false);
    if (isReCaptchaVerified) {
      const result = await updateSubscription(payload);
      setLoading(false);
      setUnSubscribeSubmit(true);
      setIsSubscribed(result?.isSubscribed);

      setSubscriptionList(result as SubscriptionList);
    } else {
      setReCaptchaError(true);
    }
    setLoading(false);
  };
  const setFieldValue = (val: any, val2: any) => {
    //console.log('setFieldValue', val, val2);
  };
  return (
    <>
      <Formik
        initialValues={emailPreferenceInitialValues}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, { resetForm });
        }}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form>
            <section className="mx-auto mt-[1.875rem] w-full rounded border md:w-[41.875rem]">
              <h1 className="w-full p-5 text-base font-semibold">
                {mapLocaleToMeaningfulFormat(router.locale).emailPreferences}
              </h1>
              <section className="mt-4 flex items-center gap-4 px-5">
                <Field
                  type="checkbox"
                  name="checkAll"
                  id="checkAll"
                  disabled={!subscriptionList?.all ?? options.checkAll}
                  checked={options.checkAll}
                  onChange={onOptionChanged}
                  className={`${
                    !subscriptionList?.all ? 'cursor-not-allowed' : 'cursor-pointer'
                  } h-4 w-4 appearance-none rounded-[2px] border-none border-sol-300 bg-transparent text-white ring-2 ring-gray-300  focus:ring-sol-300`}
                />
                <label
                  htmlFor="selectAll"
                  className={`${!subscriptionList?.all ? 'opacity-50' : ''} text-sm leading-[20px] text-sol-300`}
                >
                  {mapLocaleToMeaningfulFormat(router.locale).unsubscribeEmail}
                </label>
              </section>
              <h1 className="w-full px-5 pt-6 pb-3 text-base font-semibold">
                {mapLocaleToMeaningfulFormat(router.locale).keepMeUpdate}
              </h1>
              <section className="mt-4 flex items-center gap-4 px-5 pb-5">
                <Field
                  type="checkbox"
                  name="new_catalog_previews"
                  id="new_catalog_previews"
                  onBlur={formik?.handleBlur}
                  onPress={() => setFieldValue('check', true)}
                  checked={options.new_catalog_previews}
                  onChange={onOptionChanged}
                  disabled={!subscriptionList?.new_catalog_previews ?? options.new_catalog_previews}
                  className={`${
                    !subscriptionList?.new_catalog_previews ? 'cursor-not-allowed' : 'cursor-pointer'
                  } h-4 w-4 appearance-none rounded-[2px] border-none border-sol-300 bg-transparent text-white ring-2 ring-gray-300  focus:ring-sol-300`}
                />
                <label
                  htmlFor="new_catalog_previews"
                  className={`${
                    !subscriptionList?.new_catalog_previews ? 'opacity-50' : ''
                  } text-sm leading-[17px] text-sol-300`}
                >
                  {mapLocaleToMeaningfulFormat(router.locale).newCatalogPreview}
                </label>
              </section>
              <section className="mt-4 flex items-center gap-4 px-5 pb-5">
                <Field
                  type="checkbox"
                  name="trending_looks_styles"
                  id="trending_looks_styles"
                  onBlur={formik?.handleBlur}
                  checked={options?.trending_looks_styles}
                  onChange={onOptionChanged}
                  disabled={!subscriptionList?.trending_looks_styles ?? options.trending_looks_styles}
                  className={`${
                    !subscriptionList?.trending_looks_styles ? 'cursor-not-allowed' : 'cursor-pointer'
                  } h-4 w-4 appearance-none rounded-[2px] border-none border-sol-300 bg-transparent text-white ring-2 ring-gray-300  focus:ring-sol-300`}
                />
                <label
                  htmlFor="trending_looks_styles"
                  className={`${
                    !subscriptionList?.trending_looks_styles ? 'opacity-50' : ''
                  } text-sm leading-[17px] text-sol-300`}
                >
                  {mapLocaleToMeaningfulFormat(router.locale).looksAndStyles}
                </label>
              </section>
              <section className="mt-4 flex items-center gap-4 px-5 pb-5">
                <Field
                  type="checkbox"
                  name="new_item_introductions"
                  id="new_item_introductions"
                  onBlur={formik?.handleBlur}
                  checked={options?.new_item_introductions}
                  onChange={onOptionChanged}
                  disabled={!subscriptionList?.new_item_introductions ?? options.new_item_introductions}
                  className={`${
                    !subscriptionList?.new_item_introductions ? 'cursor-not-allowed' : 'cursor-pointer'
                  } h-4 w-4 appearance-none rounded-[2px] border-none border-sol-300 bg-transparent text-white ring-2 ring-gray-300  focus:ring-sol-300`}
                />
                <label
                  htmlFor="new_item_introductions"
                  className={`${
                    !subscriptionList?.new_item_introductions ? 'opacity-50' : ''
                  } text-sm leading-[17px] text-sol-300`}
                >
                  {mapLocaleToMeaningfulFormat(router.locale).newItemIntro}
                </label>
              </section>
              <section className="mt-4 flex items-center gap-4 px-5 pb-5">
                <Field
                  type="checkbox"
                  name="decoration_tips_ideas"
                  id="decoration_tips_ideas"
                  onBlur={formik?.handleBlur}
                  checked={options?.decoration_tips_ideas}
                  onChange={onOptionChanged}
                  disabled={!subscriptionList?.decoration_tips_ideas ?? options.decoration_tips_ideas}
                  className={`${
                    !subscriptionList?.decoration_tips_ideas ? 'cursor-not-allowed' : 'cursor-pointer'
                  } h-4 w-4 appearance-none rounded-[2px] border-none border-sol-300 bg-transparent text-white ring-2 ring-gray-300  focus:ring-sol-300`}
                />
                <label
                  htmlFor="decoration_tips_ideas"
                  className={`${
                    !subscriptionList?.decoration_tips_ideas ? 'opacity-50' : ''
                  } text-sm leading-[17px] text-sol-300`}
                >
                  {mapLocaleToMeaningfulFormat(router.locale).decoratingTips}
                </label>
              </section>
              <section className="mt-4 flex items-center gap-4 px-5 pb-8">
                <Field
                  type="checkbox"
                  name="sales_specials"
                  id="sales_specials"
                  onBlur={formik?.handleBlur}
                  checked={options?.sales_specials}
                  onChange={onOptionChanged}
                  disabled={!subscriptionList?.sales_specials ?? options.sales_specials}
                  className={`${
                    !subscriptionList?.sales_specials ? 'cursor-not-allowed' : 'cursor-pointer'
                  } h-4 w-4 appearance-none rounded-[2px] border-none border-sol-300 bg-transparent text-white ring-2 ring-gray-300  focus:ring-sol-300`}
                />
                <label
                  htmlFor="sales_specials"
                  className={`${
                    !subscriptionList?.sales_specials ? 'opacity-50' : ''
                  } text-sm leading-[17px] text-sol-300`}
                >
                  {mapLocaleToMeaningfulFormat(router.locale).saleAndSpecials}
                </label>
              </section>
            </section>
            <section className="mx-auto mt-4 w-full md:w-[41.875rem]">
              <div>
                <ReCaptcha setIsReCaptchaVerified={setIsReCaptchaVerified} setReCaptchaError={setReCaptchaError} />
                {reCaptchaError && (
                  <span className="text-xs font-semibold text-red-500">
                    {mapLocaleToMeaningfulFormat(router.locale).reCaptchaError}
                  </span>
                )}
              </div>
            </section>
            <section className="mx-auto mt-4 w-full md:w-[41.875rem]">
              {isValidForm ? (
                <button type="submit" className="btn-primary-large mt-6 w-full md:w-[294px]">
                  {loading ? (
                    <section className="flex justify-center gap-3">
                      {' '}
                      <Spinner />
                      <p>{mapLocaleToMeaningfulFormat(router.locale).processing}</p>
                    </section>
                  ) : (
                    mapLocaleToMeaningfulFormat(router.locale).unsubscribe
                  )}
                </button>
              ) : (
                <button
                  disabled={!isValidForm}
                  className="btn-primary-large mt-6 w-full cursor-not-allowed opacity-50 md:w-[294px]"
                >
                  {mapLocaleToMeaningfulFormat(router.locale).unsubscribe}
                </button>
              )}
            </section>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default UnSubscriptionPreferences;
