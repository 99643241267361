function calculateDynamicImageWidth(maxWidthMobile, maxWidthTablet, maxWidthDesktop) {
  if (typeof window !== 'undefined') {
    const viewportWidth = window.innerWidth;
    const mobileBreakpoint = 768;
    const tabletBreakpoint = 1024;

    let calculatedWidth;

    if (viewportWidth < mobileBreakpoint) {
      calculatedWidth = Math.min(viewportWidth, maxWidthMobile);
    } else if (viewportWidth < tabletBreakpoint) {
      calculatedWidth = Math.min(viewportWidth, maxWidthTablet);
    } else {
      calculatedWidth = Math.min(viewportWidth, maxWidthDesktop);
    }

    return calculatedWidth;
  }

  return 0;
}

export default calculateDynamicImageWidth;
