import BlogPopularTags from 'components/sol-ui/cms/blogPopularTags';
import React from 'react';

const BlogPopularTagsTastic = ({ data }) => {
  const content = data?.data?.dataSource?.content?.popularTags;
  const tags = data?.data?.dataSource?.content?.tags;
  return <BlogPopularTags data={content} tags={tags} />;
};

export default BlogPopularTagsTastic;
