import RecentlyViewed from 'components/sol-ui/products/details/recently-viewed';
import { CURRENTLY_SELECTED_VARIANT, RECENTLY_VIEWED } from 'helpers/constants/localStorage';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const RecentlyViewedTastic = () => {
  const [currentProductId, setCurrentProductId] = useState('');
  const [canShowRecentlyViewed, setCanShowRecentlyViewed] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setCurrentProductId(localStorage?.getItem(CURRENTLY_SELECTED_VARIANT) ?? '');
  }, [router?.asPath]);

  useEffect(() => {
    const recentlyViewedProductsSkus = JSON.parse(localStorage?.getItem(RECENTLY_VIEWED));
    if (recentlyViewedProductsSkus?.length > 1) {
      setCanShowRecentlyViewed(true);
    } else {
      setCanShowRecentlyViewed(false);
    }
  }, [currentProductId]);
  return (
    <>
      {
        <section className={canShowRecentlyViewed ? 'w-full px-4' : 'hidden'}>
          <section className="mx-auto mb-10 mt-6 max-w-[1440px] lg:mt-1 lg:px-2">
            <section>
              <strong className="mb-11 inline-block w-full text-[28px] font-semibold leading-[34px] text-sol-300">
                {mapLocaleToMeaningfulFormat(router.locale)?.recentlyViewed}
              </strong>
              <section>
                <RecentlyViewed currentProductId={currentProductId} />
              </section>
            </section>
          </section>
        </section>
      }
    </>
  );
};
export default RecentlyViewedTastic;
