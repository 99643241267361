import React from 'react';
import BlogBreadcrumbs from '../cms/BlogBreadcrumbs';
import { ClpBanner } from './ClpBanner';
import { FourColumnCategory } from './FourColumnCategory';
import { RelatedCategoryBlock } from './RelatedCategory';
import ThreeColumnCategory from './ThreeColumnCategory';

const Categories = ({ content }) => {
  return (
    <>
      <section className="mx-auto max-w-[1440px]">
        <BlogBreadcrumbs breadcrumLevelLink={true} activePages={[{ title: content?.title, ctaLink: '#' }]} />
      </section>
      <section className="mx-auto max-w-screen-8xl px-4">
        <h1 className="m-6 text-center text-2xl font-bold">{content?.clpTitle}</h1>

        <ClpBanner content={content} />

        <ThreeColumnCategory contents={content?.threeColumnCategoryBlock} />

        <FourColumnCategory contents={content?.fourColumnCategoryBlock} />

        <RelatedCategoryBlock contents={content?.relatedColumnCategoryBlock} />
      </section>
    </>
  );
};

export default Categories;
