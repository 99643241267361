import { Cart as CartType } from '@Types/cart/Cart';
import { LineItem as WishlistLineItem } from '@Types/wishlist/LineItem';
import { Wishlist } from '@Types/wishlist/Wishlist';
import { LineItem } from '@Types/cart/LineItem';
export const isCartIsValid = (cart: CartType) => {
  return cart?.lineItems?.find((lineItem) => !lineItem?.isAvailable) ? false : true;
};

export const isWishlistIsValid = (wishlist: Wishlist) => {
  return wishlist?.lineItems?.find((lineItem) => !lineItem?.isAvailable) ? false : true;
};

export const validateWishlistItemsQty = (wishlist: Wishlist, cart: CartType) => {
  let value: boolean = true;
  const lineItems = wishlist?.lineItems || [];
  for (const wishlistItem of lineItems) {
    if (value) {
      value = checkItemExistinCart(wishlistItem, cart, '');
      if (!value) {
        break;
      }
    }
  }
  return value;
};

export const validateWishlistItemQty = (
  lineItem: LineItem | WishlistLineItem,
  cart: CartType,
  componentIdentifier: string,
) => {
  return checkItemExistinCart(lineItem, cart, componentIdentifier);
};

const checkItemExistinCart = (lineItem: LineItem | WishlistLineItem, cart: CartType, componentIdentifier: string) => {
  const cartLineItem = cart?.lineItems?.find((cartItem) => cartItem?.variant?.sku === lineItem?.variant?.sku);
  if (cartLineItem) {
    const validIdentifiers: string[] = ['cart', 'checkout', 'thankyouPage', 'orderDetail'];
    const count = validIdentifiers.includes(componentIdentifier)
      ? cartLineItem?.count
      : lineItem?.count + cartLineItem?.count;
    if (count > cartLineItem?.maxQuantityPerVariant) {
      return false;
    }
  }
  return true;
};

export const isAccessoryLinkDisabled = (lineItem, disabledAccessories) => {
  const accessoryKey = lineItem?.variant?.attributes?.accessoryCategory?.key?.toUpperCase().trim().replace(/\s/g, '_');
  const isAccessoryDisabled =
    accessoryKey && disabledAccessories?.[`IS_${accessoryKey}_DISABLED`] != undefined
      ? disabledAccessories[`IS_${accessoryKey}_DISABLED`]
      : false;

  return isAccessoryDisabled;
};
