import React, { useEffect, useState } from 'react';
import { Variant } from '@Types/product/Variant';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';

type UpdateQuantitynProps = {
  accessory?: any;
  checkedOptionAccessories?: (sku: any) => boolean;
  selectedAccessories?: Array<{ variant: Variant; quantity: number }>;
  setSelectedAccessories?: React.Dispatch<React.SetStateAction<Array<{ variant: Variant; quantity: number }>>>;
};
const UpdateQuantity = ({
  accessory,
  checkedOptionAccessories,
  selectedAccessories,
  setSelectedAccessories,
}: UpdateQuantitynProps) => {
  const [isDesktopSize] = useMediaQuery(desktop);
  const [quantity, setQuantity] = useState(1);
  const accessorieIndex = selectedAccessories.findIndex(
    (accessorie) => accessorie?.variant?.sku === accessory?.obj?.masterData?.current?.masterVariant?.sku,
  );
  const updatedAccessories = [...selectedAccessories];
  useEffect(() => {
    setQuantity(1);
  }, [accessory]);
  if (accessorieIndex >= 0) {
    if (updatedAccessories[accessorieIndex].quantity < quantity) {
      updatedAccessories[accessorieIndex].quantity = quantity;
    }
  }

  const increaseQuantity = () => {
    if (quantity < 999) {
      if (accessorieIndex >= 0) {
        updatedAccessories[accessorieIndex].quantity += 1;
        setQuantity(updatedAccessories[accessorieIndex].quantity);
        setSelectedAccessories([...updatedAccessories]);
      } else {
        setQuantity(quantity + 1);
      }
    }
  };
  const decreaseQuantity = () => {
    if (quantity > 1) {
      if (accessorieIndex >= 0) {
        updatedAccessories[accessorieIndex].quantity -= 1;
        setQuantity(updatedAccessories[accessorieIndex].quantity);
        setSelectedAccessories([...updatedAccessories]);
      } else {
        setQuantity(quantity - 1);
      }
    }
  };
  const handleQuantity = (e) => {
    const count = parseInt(e.target.value);
    if (!isNaN(count)) {
      if (count >= 1 && count <= 999) {
        setQuantity(count);
        if (accessorieIndex >= 0) {
          updatedAccessories[accessorieIndex].quantity = quantity;
        }
      } else if (count === 0) {
        setQuantity(1);
        if (accessorieIndex >= 0) {
          updatedAccessories[accessorieIndex].quantity = quantity;
        }
      }
      setSelectedAccessories([...updatedAccessories]);
    } else {
      setQuantity(null);
    }
  };

  const handleUpdateQuantity = () => {
    if (!quantity) {
      setQuantity(1);
      if (accessorieIndex >= 0) {
        updatedAccessories[accessorieIndex].quantity = quantity;
        setSelectedAccessories([...updatedAccessories]);
      }
    }
  };

  return (
    <div className="relative flex items-center">
      <section
        className={`${
          isDesktopSize ? 'w-[80px]' : 'w-[6.25rem]'
        } relative mr-2 flex h-11 items-center justify-between rounded-lg border`}
      >
        <section>
          <input
            type="text"
            value={quantity}
            onBlur={handleUpdateQuantity}
            onChange={handleQuantity}
            className="w-[53px] rounded-l-lg border-none text-center focus:ring-0"
          />
        </section>
        <section className="absolute right-2 flex h-[42px] flex-col items-center justify-center">
          <button type="button" aria-label="increment" id="incrementBtn" onClick={increaseQuantity}>
            <MdOutlineKeyboardArrowUp
              className={`${quantity >= 999 ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'} h-5 w-5 pt-1`}
            />
          </button>
          <button type="button" aria-label="decrement" id="decrementBtn" onClick={decreaseQuantity}>
            <MdOutlineKeyboardArrowDown
              className={`${quantity > 1 ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'} h-5 w-5 pb-1`}
            />
          </button>
        </section>
      </section>
      <span className="text-base font-semibold leading-5 text-sol-300">+</span>
      <p
        className={`text-sm leading-5 ${
          checkedOptionAccessories(accessory?.obj?.masterData?.current?.masterVariant?.sku)
            ? 'font-bold text-sol-300'
            : 'text-sol-300'
        }`}
      >
        {CurrencyHelpers.formatForCurrency(accessory?.obj?.masterData?.current?.masterVariant?.prices[0]?.value)}
      </p>
    </div>
  );
};

export default UpdateQuantity;
