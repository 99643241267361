import { Category } from '@Types/product/Category';
import { DYNAMIC_CATEGORY_PATH } from 'helpers/constants/route';
import { NextRouter } from 'next/router';
import { mapLocaleToMeaningfulFormat } from './utils/i18n';
import { Address } from '@Types/account/Address';
import format from './utils/format';
import crypto from 'crypto';
export class StringHelpers {
  /**
   * isNumeric tests a string and returns true when it's a decimal value
   */
  static isNumeric = (val: string) => /^\d+$/.test(val);

  /**
   * capitaliseFirstLetter capitalises only the very first character of a string, leaving the
   * rest unedited
   */

  static capitaliseFirstLetter = (val: string) => val?.charAt(0)?.toUpperCase() + val?.slice(1);

  static titleCaseFormatter = (val: string) =>
    val
      ?.toLowerCase()
      ?.split(' ')
      ?.map((word) => {
        return word?.charAt(0)?.toUpperCase() + word?.slice(1) + ' ';
      });

  static categoryRouteFormatter = (category: any) =>
    category?.overrideURL ??
    `${DYNAMIC_CATEGORY_PATH}${category?.slug?.startsWith('/') ? category?.slug : `/${category?.slug}` || '#'}` ??
    '#';

  static creditCardNumberFormatter = (value: string, router: NextRouter) => {
    if (!value) {
      return '';
    }
    return `${mapLocaleToMeaningfulFormat(router.locale).ending} ${value?.substring(value?.length - 4)}`;
  };
  static expiryDateFormatter = (value: string, router: NextRouter) => {
    let arr = value?.split('-');
    if (!arr) {
      return '';
    }
    return `${mapLocaleToMeaningfulFormat(router.locale).expiry} ${arr[1]} / ${arr[0].substring(arr[0].length - 2)}`;
  };

  static phoneExtensionMerger = (address: Address) => {
    let phone = '';
    const addressPhone = address?.phone;
    if (address?.extension) {
      phone = address?.extension.replace(/ /g, '');
    }
    if (phone != '') {
      phone += ` ${addressPhone.replace(/ /g, '')}`;
    } else {
      phone = `${addressPhone.replace(/ /g, '')}`;
    }

    return { ...address, phone };
  };

  static phoneExtensionSplitter = (address: Address) => {
    if (!address && !address?.phone) {
      return address;
    }
    let strArray = address?.phone?.split(' ');
    if (strArray?.length === 2) {
      address.extension = strArray[0];
      address.phone = strArray[1];
    } else if (strArray?.length === 1) {
      address.phone = strArray[0];
    }
    return address;
  };

  static phoneDisplayFormat = (input: string): string => {
    const match =
      input.match(/(\d+)\s(\d{3})(\d{3})(\d{4})/) ||
      input.match(/\+(\d{1})\s(\d{3})(\d{3})(\d{4})/) ||
      input.match(/(\d{1})\s(\d{3})(\d{3})(\d{4})/);
    const matchWithoutExt = input.match(/(\d{3})(\d{3})(\d{4})/);
    if (match) {
      const [, countryCode, areaCode, firstPart, secondPart] = match;
      return `(${areaCode}) ${firstPart}-${secondPart} ext ${countryCode}`;
    } else if (matchWithoutExt) {
      const [, areaCode, firstPart, secondPart] = matchWithoutExt;
      return `(${areaCode}) ${firstPart}-${secondPart}`;
    }

    return input; // Return original input if the format doesn't match
  };

  static checkValidCreditCard(cardNumber: string): boolean {
    const cardTypes: [string, string][] = [
      ['Visa', '^4'],
      ['MasterCard', '^(5[1-5]|2[2-7])'],
      ['American Express', '^3[47]'],
      ['Discover', '^(6011|65|64[4-9]|622)'],
      // Add more card types and their IIN/BIN ranges as needed
    ];

    for (const [cardType, pattern] of cardTypes) {
      if (new RegExp(pattern).test(cardNumber)) {
        return true;
      }
    }

    return false;
  }

  static removeWhiteSpace(inputString): string {
    const inputAfterWhitespaceRemoved = inputString?.replace(/\s/g, '');
    return inputAfterWhitespaceRemoved;
  }
  static formatDiscountText(discount: any, router: NextRouter): string {
    let discountText: string = mapLocaleToMeaningfulFormat(router.locale).discountTextFormat;
    if (discount?.discountValue && discount?.discountCode && discount?.name && discount?.endDate) {
      let args: any[] = [discount?.discountValue, discount?.discountCode, discount?.name, discount?.endDate];
      return format(discountText, args);
    }
    return '';
  }

  static convertToSHA256Hash(input: string) {
    return crypto.createHash('sha256').update(input).digest('hex');
  }
}
