import PlpContent from 'components/sol-ui/products/plpContent';
import { DATA_SOURCE_LINKS } from 'helpers/utils/constant';
import { DataSourceExtractorHelpers } from 'helpers/utils/dataSourceExtractor';
import React from 'react';

const PlpContentTastic = (data) => {
  const plpContentTasticConfiguration = DataSourceExtractorHelpers.getPreloadedValue(
    DATA_SOURCE_LINKS.EMPTY_DATASOURCE,
    data?.pageFolder?.dataSourceConfigurations,
  );
  const isCategory = plpContentTasticConfiguration?.route == 'category';
  const content = isCategory
    ? data?.data?.data?.dataSource?.content
      ? data.data.data.dataSource.content[0]
      : null
    : null;
  return content ? <PlpContent content={content} /> : <></>;
};

export default PlpContentTastic;
