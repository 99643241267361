import BlogPopularTags from 'components/sol-ui/cms/blogPopularTags';
import FeaturedBlogs from 'components/sol-ui/cms/featuredBlogs';
import React from 'react';

const FeaturedBlogsAndPopularTagsTastic = ({ data }) => {
  const featuredBlogs = data?.data?.dataSource?.content?.featuredBlogs;
  const popularTags = data?.data?.dataSource?.content?.popularTags;
  const tags = data?.data?.dataSource?.content?.tags;

  return (
    <section className="mx-auto mt-24 mb-12 grid max-w-screen-8xl grid-cols-1 gap-7 px-5 md:grid-cols-4">
      <section className="col-span-3">
        <FeaturedBlogs data={featuredBlogs} />
      </section>
      <section className="flex justify-end">
        <BlogPopularTags data={popularTags} tags={tags} />
      </section>
    </section>
  );
};

export default FeaturedBlogsAndPopularTagsTastic;
