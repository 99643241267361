import React from 'react';
import { Category } from '@Types/product/Category';
import { useState } from 'react';
import getCacheCategoriesData from 'helpers/cache';
import { CACHE_CATEGORIES } from 'helpers/constants/cache';
import Link from 'next/link';
import { StringHelpers } from 'helpers/stringHelpers';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { useRouter } from 'next/router';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import Breadcrumb from 'components/sol-ui/cms/Breadcrumb';
const SOLSitemap = ({ data }) => {
  const [categories, setCategory] = useState<Category[]>([]);
  const router = useRouter();
  const [isDesktop] = useMediaQuery(desktop);
  const {
    subMenuLevel2Count,
    accountLink,
    notificationTimeout,
    loginLink,
    searchContentCount,
    searchRelatedProdutsCount,
    searchSuggestedSearchesCount,
    maximumLoginAttemptTimeout,
  } = data;
  const getCategories = async () => {
    const { cacheStdTTLTime, cacheCheckPeriodTime } = data;
    await getCacheCategoriesData({
      cacheKey: CACHE_CATEGORIES,
      cacheStdTTLTime,
      cacheCheckPeriodTime,
    }).then((response: Category[]) => {
      setCategory(response);
    });
  };

  if (categories.length === 0) {
    getCategories();
  }

  return (
    <>
      <Breadcrumb breadcrumLevelLink={true} activePage={mapLocaleToMeaningfulFormat(router.locale).sitemap} />
      <section className="relative mx-auto mt-6 mb-10 w-full max-w-[1440px] px-5 md:mt-10 md:px-6">
        <h1 className="mt-6 text-center text-[1.688rem] font-semibold after:mx-auto after:mt-4 after:block after:h-[0.063rem] after:w-[9.375rem] after:bg-[#B19A6A] after:content-[''] lg:mt-2">
          {mapLocaleToMeaningfulFormat(router.locale).sitemap}
        </h1>
        <section className="mt-5">
          {categories?.map((categoryLvl1: Category, index: number) => (
            <section key={index} className="mt-3">
              <h6 className="mb-2">
                <Link href={StringHelpers.categoryRouteFormatter(categoryLvl1)}>
                  <a className="catLevel1 flex w-full items-center text-center text-xs uppercase text-[#222222] hover:underline">
                    {' '}
                    {StringHelpers.titleCaseFormatter(categoryLvl1?.name)}
                  </a>
                </Link>
              </h6>
              {categoryLvl1?.children?.map(
                (categoryLvl2: Category, index) =>
                  categoryLvl2?.name !== '' && (
                    <section className="my-2" key={index}>
                      <ul className="mb-3">
                        <li className="mb-1">
                          <a
                            href={StringHelpers.categoryRouteFormatter(categoryLvl2)}
                            className="catLevel2 inline-block text-xs font-semibold uppercase text-[#222222] hover:underline"
                          >
                            {StringHelpers.titleCaseFormatter(categoryLvl2?.name)}
                          </a>
                        </li>
                        <li>
                          <ul className="ml-5 mb-4 list-disc">
                            {categoryLvl2?.children.slice(0, subMenuLevel2Count)?.map((categoryLvl3, index) => (
                              <li key={index} className="">
                                <a
                                  href={StringHelpers.categoryRouteFormatter(categoryLvl3)}
                                  className="catLevel3 inline-block text-xs font-normal leading-[19px] text-[#222222] hover:underline"
                                >
                                  {StringHelpers.titleCaseFormatter(categoryLvl3?.name)}
                                </a>
                              </li>
                            ))}
                            {categoryLvl2?.children?.length > subMenuLevel2Count ? (
                              <li key={index} className="list-disc">
                                <a
                                  href={StringHelpers.categoryRouteFormatter(categoryLvl2)}
                                  className="inline-block text-xs font-normal leading-[19px] text-[#222222] hover:underline"
                                >
                                  {mapLocaleToMeaningfulFormat(router.locale).exploreAll}
                                </a>
                              </li>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </li>
                      </ul>
                    </section>
                  ),
              )}
            </section>
          ))}
        </section>
      </section>
    </>
  );
};
export default SOLSitemap;
