import React, { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ArrayHelpers } from 'helpers/arrayHelpers';
import { PREVIOUS_PATH } from 'helpers/constants/localStorage';
import { clickedObjectIDsAfterSearch } from 'helpers/hooks/algoliaEvents/algoliaEvents';
import { getAlgoliaQueryID } from 'helpers/hooks/algoliaEvents/algoliaUtils';
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import { StringHelpers } from 'helpers/stringHelpers';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import SearchContent from './search-content-product';
import SearchRelatedProduct from './search-related-product';
import SearchSuggestedProduct from './search-suggested-product';

interface AutoCompleteData {
  name?: { [key: string]: string };
  slug?: { [key: string]: string };
  images?: { url: string }[];
  title?: string;
  image?: string;
  ctaLink?: string;
}

interface SOLHeaderDesktopSearchProps {
  productsAutoComplete: AutoCompleteData[];
  blogsAutoComplete: any[];
  categoriesAutoComplete: AutoCompleteData[];
  handleSearchPopupClose?: () => void;
  data: any;
  categories: any;
  setProductRedirectionLink: any;
}

const SOLHeaderDesktopSearch: React.FC<SOLHeaderDesktopSearchProps> = ({
  productsAutoComplete,
  blogsAutoComplete,
  categoriesAutoComplete,
  handleSearchPopupClose,
  data,
  categories,
  setProductRedirectionLink,
}) => {
  const router = useRouter();
  const [categoriesArray, setCategoriesArray] = useState([]);
  const selectedLocale = router.query.locale ? router.query.locale.toString() : 'en-US';
  const { getItem } = useLocalStorage();
  // Handle Locale Later
  const suggestedSearches = useMemo(
    () =>
      // Handle Links after the final url structure is defined
      categoriesAutoComplete?.map((data, i) => {
        return (
          <li
            key={i}
            className="cursor-pointer"
            onClick={(e) =>
              handleOnClick(
                e,
                categoriesArray?.includes(data.name['en-US'].toLowerCase())
                  ? `/pages/${data.slug['en-US']}`
                  : `/categories/${data.slug['en-US']}`,
              )
            }
          >
            <SearchSuggestedProduct
              suggestedSearchData={data.name['en-US']}
              dataLink={
                categoriesArray?.includes(data.name['en-US'].toLowerCase())
                  ? `/pages/${data.slug['en-US']}`
                  : `/categories/${data.slug['en-US']}`
              }
            />
          </li>
        );
      }),
    [categoriesAutoComplete],
  );

  const content = useMemo(
    () =>
      // Handle Links after the final url structure is defined
      blogsAutoComplete?.map((contentData, i) => (
        <li key={i} className="cursor-pointer" onClick={(e) => handleOnClick(e, contentData?.ctaLink)}>
          <SearchContent contentData={contentData?.title} link={contentData?.ctaLink} />
        </li>
      )),
    [blogsAutoComplete],
  );
  const handleOnClick = (e, link, plpItem = null, position = null) => {
    e.preventDefault();

    if (plpItem && position) {
      const algoliaQueryId = getAlgoliaQueryID();
      clickedObjectIDsAfterSearch({
        index: getItem('currentIndex'),
        eventName: 'Product Clicked After Search',
        objectIDs: [plpItem?.objectID],
        queryID: algoliaQueryId,
        positions: [position],
      });
    }

    window.location.href = link;
    handleSearchPopupClose();
    localStorage.setItem(PREVIOUS_PATH, 'null');
  };
  const relatedProduct = useMemo(
    () =>
      // Handle Links after the final url structure is defined
      productsAutoComplete?.map((relatedData: any, i) => {
        const exactVariant = ArrayHelpers?.findObjectMatchesSku(
          relatedData?.all_variants,
          StringHelpers?.removeWhiteSpace(data)?.toLowerCase(),
        );
        let masterVariant;
        if (!exactVariant) {
          masterVariant = ArrayHelpers?.findObjectWithKey(
            relatedData?.all_variants,
            mapLocaleToMeaningfulFormat(router.locale)?.masterVariantKey,
          );
        }
        return (
          <li
            key={i}
            className="flex cursor-pointer flex-col gap-4"
            onClick={(e) =>
              handleOnClick(
                e,
                `/products/${relatedData?.slug['en-US']}?color=${exactVariant?.all_Attributes?.actualColor}${
                  exactVariant?.all_Attributes?.size ? '&size=' + exactVariant?.all_Attributes?.size : ''
                }`,
                relatedData,
                i + 1,
              )
            }
          >
            <SearchRelatedProduct
              productName={relatedData?.name['en-US']}
              imageSrc={
                exactVariant ? exactVariant?.images[0]?.url : masterVariant ? masterVariant?.images[0]?.url : ''
              }
              dataLink={`/products/${relatedData?.slug['en-US']}`}
            />
          </li>
        );
      }),
    [productsAutoComplete],
  );

  useEffect(() => {
    const temp_Array = [];
    categories?.map((val, idx) => {
      temp_Array.push(StringHelpers.titleCaseFormatter(val?.name).join('').trim().toLowerCase());
      val?.children?.map((subValue) => {
        temp_Array.push(StringHelpers.titleCaseFormatter(subValue?.name).join('').trim().toLowerCase());
      });
    });
    setCategoriesArray([].concat(temp_Array));
  }, [categories]);

  useEffect(() => {
    if (productsAutoComplete.length === 1) {
      const currentResult: any = productsAutoComplete[0];

      const exactVariant = ArrayHelpers?.findObjectMatchesSku(
        currentResult?.all_variants,
        StringHelpers?.removeWhiteSpace(data)?.toLowerCase(),
      );
      const selectedVariantColor = exactVariant?.all_Attributes?.actualColor;
      const selectedVariantSize = exactVariant?.all_Attributes?.size;
      setProductRedirectionLink(
        `/products/${productsAutoComplete[0]?.slug['en-US']}?color=${selectedVariantColor ?? ''}${
          selectedVariantSize ? '&size=' + selectedVariantSize : ''
        }`,
      );
    }
  }, [productsAutoComplete]);
  return (
    <section className="shadow-[0px_2px_4px_rgba(177, 154, 106, 0.3] flex w-[506px] gap-10 rounded-md bg-sand-400 p-4 ">
      <section className="flex w-56 flex-col gap-3">
        <section className="flex flex-col gap-3">
          <p className="mt-5 text-sm font-semibold">{mapLocaleToMeaningfulFormat(router.locale).suggestedSearches}</p>
          {suggestedSearches.length === 0 ? (
            <p className="text-sm font-normal text-sol-300">{mapLocaleToMeaningfulFormat(router.locale).noResult}</p>
          ) : (
            <ul className="grid gap-3">{suggestedSearches}</ul>
          )}
        </section>
        <section className="mt-1 h-[1px] w-full bg-sol-600"></section>
        <section className="flex flex-col gap-3">
          <p className="mt-1 text-sm font-semibold">{mapLocaleToMeaningfulFormat(router.locale).content}</p>
          {content.length === 0 ? (
            <p className="text-sm font-normal text-sol-300">{mapLocaleToMeaningfulFormat(router.locale).noResult}</p>
          ) : (
            <ul className="grid gap-3">{content}</ul>
          )}
        </section>
      </section>
      <section className="flex w-72 flex-col gap-5">
        <p className="mt-5 text-sm font-semibold">{mapLocaleToMeaningfulFormat(router.locale).relatedProduct}</p>
        {relatedProduct.length === 0 ? (
          <p className="text-sm font-normal text-sol-300">{mapLocaleToMeaningfulFormat(router.locale).noResult}</p>
        ) : (
          <ul className="grid grid-cols-2 gap-5 pr-[10px] ">{relatedProduct}</ul>
        )}
        {relatedProduct?.length > 3 && (
          <Link href={`${routerURL?.search}${data}`}>
            <a
              className="mt-6 cursor-pointer pb-2 text-center text-sm font-normal text-sol-300 underline underline-offset-2"
              onClick={handleSearchPopupClose}
            >
              {mapLocaleToMeaningfulFormat(router.locale).viewMoreResult}
            </a>
          </Link>
        )}
      </section>
    </section>
  );
};

export default SOLHeaderDesktopSearch;
