import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Breadcrumb from 'components/sol-ui/cms/Breadcrumb';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { formatPosterUrl, formatVideoUrl, getAlignment, isImage, isVideo } from 'helpers/utils/banner';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { desktop } from 'helpers/utils/screensizes';
import { amplienceImageLoader } from 'helpers/utils/amplienceImageLoader';

const BlogBanner = ({ data }) => {
  const [isDesktop] = useMediaQuery(desktop);
  const router = useRouter();

  return (
    <>
      <Breadcrumb breadcrumLevelLink={true} activePage={mapLocaleToMeaningfulFormat(router.locale).blog} />
      <section className="mx-auto max-w-screen-8xl px-5">
        <section>
          <h1 className="mt-4 pb-4 text-center text-[28px] font-semibold">
            {mapLocaleToMeaningfulFormat(router.locale).inspiredSpacesBlog}
          </h1>
          <section
            className={`relative mb-8 text-center before:absolute before:left-0 before:right-0 before:mx-auto before:block before:h-[1px] before:w-40 before:bg-sol-600 before:font-bold before:content-[''] lg:mb-6 ${
              isDesktop ? 'lg:mb-10' : ''
            }`}
          ></section>
        </section>

        <section className="relative mt-6 flex items-center justify-center lg:mb-16 lg:mt-10">
          <ul>
            <Link href={data?.link}>
              <li className="h-[605px] w-full">
                {isImage(data?.desktopMedia) && (
                  <Image
                    loader={amplienceImageLoader}
                    src={isDesktop ? data?.desktopMedia?.url : data?.mobileMedia?.url}
                    width="0"
                    height="0"
                    layout="fill"
                    className="rounded-t-md object-cover lg:rounded-md"
                    alt={data?.alt}
                    sizes="100vw"
                  />
                )}
                {isVideo(data?.desktopMedia) && (
                  <video
                    className="h-[450px] w-full content-center object-cover"
                    autoPlay
                    loop
                    poster={
                      isDesktop ? formatPosterUrl(data?.desktopMedia) : formatPosterUrl(data?.clpBannerMobileMedia)
                    }
                    src={isDesktop ? formatVideoUrl(data?.desktopMedia) : formatVideoUrl(data?.mobileMedia)}
                  ></video>
                )}
              </li>
            </Link>
          </ul>
          {isDesktop && (
            <>
              {data?.title && (
                <section
                  className={`absolute flex h-[450px] w-[495px] ${
                    isDesktop ? getAlignment(data?.alignment) : 'items-center justify-center'
                  } `}
                >
                  <section className="rounded-md bg-[rgb(0,0,0,0.4)] p-9 text-white">
                    <h2 className="mb-5 text-center text-2xl font-semibold leading-10 tracking-normal text-white lg:text-[32px]">
                      {data?.title}
                    </h2>
                    <p className="text-center text-base leading-5 text-white">{data?.subTitle}</p>
                  </section>
                </section>
              )}
            </>
          )}
        </section>
        {!isDesktop && (
          <>
            {data?.title && (
              <section
                className={`flex w-full ${isDesktop ? getAlignment(data?.alignment) : 'items-center justify-center'} `}
              >
                <section className="rounded-b-md bg-[rgb(0,0,0,0.4)] p-6 text-white">
                  <h2 className="mb-5 px-6 text-center text-2xl font-semibold leading-10 tracking-normal text-white lg:text-[32px]">
                    {data?.title}
                  </h2>
                  <p className="text-center text-base leading-5 text-white">{data?.subTitle}</p>
                </section>
              </section>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default BlogBanner;
