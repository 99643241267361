import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M8.51 3.32003H10.39V0.14003C9.47975 0.045377 8.56516 -0.00135428 7.65 2.98641e-05C4.93 2.98641e-05 3.07 1.66003 3.07 4.70003V7.32003H0V10.88H3.07V20H6.75V10.88H9.81L10.27 7.32003H6.75V5.05003C6.75 4.00003 7.03 3.32003 8.51 3.32003Z"
      fill="#222222"
    />
  </svg>
);

export default Icon;
