import React from 'react';
import { useRouter } from 'next/router';
import { Cart } from '@Types/cart/Cart';
import { Order } from '@Types/cart/Order';
import { StringHelpers } from 'helpers/stringHelpers';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';

export interface AddressSummaryProps {
  showEditButton?: boolean;
  cart: Cart | Order;
}

function AddressSummary({ showEditButton, cart }: AddressSummaryProps) {
  const router = useRouter();
  const handleEdit = () => {
    router.push(routerURL.checkoutAddress);
  };
  const shippingPhone =
    cart?.shippingAddress?.phone?.length > 10 && !cart?.shippingAddress?.phone?.includes(' ')
      ? `${cart?.shippingAddress?.phone?.slice(0, -10)} ${cart?.shippingAddress?.phone?.slice(-10)}`
      : cart?.shippingAddress?.phone;

  const billingPhone =
    cart?.billingAddress?.phone?.length > 10 && !cart?.billingAddress?.phone?.includes(' ')
      ? `${cart?.billingAddress?.phone?.slice(0, -10)} ${cart?.billingAddress?.phone?.slice(-10)}`
      : cart?.billingAddress?.phone;

  return (
    <section className="rounded-md border p-5">
      <section className="flex justify-between">
        <h1 className="text-lg font-semibold text-sol-300">{mapLocaleToMeaningfulFormat(router.locale).addresses}</h1>
        <button
          type="button"
          id="p-edit-address"
          onClick={handleEdit}
          className="text-sm font-semibold text-sol-300 underline "
        >
          {showEditButton && `${mapLocaleToMeaningfulFormat(router.locale).edit}`}
        </button>
      </section>
      <section className="mt-5 grid grid-cols-1 lg:grid-cols-2">
        <section>
          <h2 className="text-sm font-semibold">{mapLocaleToMeaningfulFormat(router.locale).shippingAddress}</h2>
          <ul className="mt-2">
            <li>
              {cart?.shippingAddress && Object.keys(cart?.shippingAddress).length !== 0 ? (
                <>
                  <p>{`${cart?.shippingAddress?.firstName || ''} ${cart?.shippingAddress?.lastName || ''}`}</p>
                  <p>{`${cart?.shippingAddress?.company || ''}`} </p>
                  <p> {`${cart?.shippingAddress?.streetName || ''} ${cart?.shippingAddress?.streetNumber || ''}`}</p>
                  <p>{`${cart?.shippingAddress?.city || ''}, ${cart?.shippingAddress?.state || ''} ${
                    cart?.shippingAddress?.postalCode || ''
                  }`}</p>
                  <p>{cart?.shippingAddress?.country || ''}</p>
                  <p>
                    {StringHelpers.phoneDisplayFormat(
                      cart?.shippingAddress?.extension
                        ? StringHelpers.phoneExtensionMerger(cart?.shippingAddress)?.phone
                        : shippingPhone,
                    ) || ''}
                  </p>
                </>
              ) : (
                <>
                  <p>{mapLocaleToMeaningfulFormat(router.locale).noSavedAddress}</p>
                </>
              )}
            </li>
          </ul>
        </section>
        <section className="mt-5 lg:mt-0">
          <h2 className="text-sm font-semibold">{mapLocaleToMeaningfulFormat(router.locale).billingAddress}</h2>
          <ul className="mt-2">
            <li>
              {cart?.billingAddress && Object.keys(cart?.billingAddress).length !== 0 ? (
                <>
                  <p>{`${cart?.billingAddress?.firstName || ''} ${cart?.billingAddress?.lastName || ''}`}</p>
                  <p>{`${cart?.billingAddress?.company || ''}`} </p>
                  <p> {`${cart?.billingAddress?.streetName || ''} ${cart?.billingAddress?.streetNumber || ''}`}</p>
                  <p>{`${cart?.billingAddress?.city || ''}, ${cart?.billingAddress?.state || ''} ${
                    cart?.billingAddress?.postalCode || ''
                  }`}</p>
                  <p>{cart?.billingAddress?.country || ''}</p>
                  <p>
                    {StringHelpers.phoneDisplayFormat(
                      cart?.billingAddress?.extension
                        ? StringHelpers.phoneExtensionMerger(cart?.billingAddress)?.phone
                        : billingPhone,
                    ) || ''}
                  </p>
                </>
              ) : (
                <>
                  <p>{mapLocaleToMeaningfulFormat(router.locale).noSavedAddress}</p>
                </>
              )}
            </li>
          </ul>
        </section>
      </section>
      <h2 className="mt-5 text-sm font-semibold">{mapLocaleToMeaningfulFormat(router.locale).email}</h2>
      <p>{cart?.email || ''}</p>
    </section>
  );
}

export default AddressSummary;
