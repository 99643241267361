import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { Variant } from '@Types/product/Variant';

export const validateCartItemQty = (
  cart: Cart,
  selectedVariant: Variant,
  availableQuantity: number,
  quantity: number,
) => {
  const lineItem = cart?.lineItems?.find((item: LineItem) => item?.variant?.sku === selectedVariant?.sku);
  if (!lineItem) {
    return true;
  }

  if (lineItem) {
    const count = quantity + lineItem?.count;
    if (count <= availableQuantity) {
      return true;
    }
  }
  return false;
};
