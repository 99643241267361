import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="7" viewBox="0 0 13 7" fill="none" className={className}>
    <path
      d="M7.1149 6.6176L12.1876 1.53597C12.2717 1.45251 12.3385 1.35321 12.3841 1.2438C12.4297 1.1344 12.4531 1.01705 12.4531 0.898526C12.4531 0.780003 12.4297 0.662654 12.3841 0.553247C12.3385 0.44384 12.2717 0.344541 12.1876 0.261078C12.0193 0.0938588 11.7918 0 11.5546 0C11.3174 0 11.0899 0.0938588 10.9216 0.261078L6.43256 4.70526L1.98839 0.261078C1.82017 0.0938588 1.59262 0 1.35543 0C1.11824 0 0.890685 0.0938588 0.722468 0.261078C0.637635 0.344228 0.570147 0.443387 0.523912 0.552809C0.477677 0.66223 0.453618 0.779738 0.453125 0.898526C0.453618 1.01731 0.477677 1.13482 0.523912 1.24424C0.570147 1.35366 0.637635 1.45282 0.722468 1.53597L5.79512 6.6176C5.87919 6.70873 5.98123 6.78146 6.0948 6.83121C6.20837 6.88095 6.33102 6.90664 6.45501 6.90664C6.579 6.90664 6.70165 6.88095 6.81522 6.83121C6.92879 6.78146 7.03083 6.70873 7.1149 6.6176Z"
      fill="#1B3144"
      fillOpacity="0.9"
    />
  </svg>
);

export default Icon;
