import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import { useCurrentRefinements, useInfiniteHits } from 'react-instantsearch';
import { connectInfiniteHits, connectStateResults } from 'react-instantsearch-dom';
import { Product } from 'schema-dts';
import CheckedIcon from 'components/icons/sol/checked-icon';
import PLPRichText from 'components/seo/plpRichText';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import convertToRichResultJSON from 'helpers/utils/json-ld/convertHitsToRichResultsJSON';
import DownIcon from '../../../icons/chevron-down';
import CloseIcon from '../../../icons/sol/close';
import Spinner from '../../notification/spinner';
import ProductListCard from './product-list-card';
import QuickViewModal from './quick-view-modal';

const processFilterAppliedData = (data) => {
  const result = {};
  data.forEach((item) => {
    if (!result[item.attribute]) {
      result[item.attribute] = [];
    }
    item.refinements.forEach((refinement) => {
      result[item.attribute].push(refinement.value);
    });
  });
  return result;
};

const Hits = (props) => {
  //   const sessionStorageCache = createInfiniteHitsSessionStorageCache();
  // const propsWithCache = {
  //     ...props,
  //     cache: sessionStorageCache
  // };
  const { hits, currentPageHits, results, isFirstPage, isLastPage, showMore, sendEvent } = useInfiniteHits(props);
  const { items } = useCurrentRefinements();
  const { currentCat, downrodsContent, isClearanceCategory, setCurrentPageNo, isSearchComponent } = props;
  const router = useRouter();
  const filterApplied = processFilterAppliedData(items);

  const changePageNo = () => {
    const currentPageNo = localStorage.getItem('pageNo');
    if (!currentPageNo) {
      localStorage.setItem('pageNo', '1');
    } else {
      localStorage.setItem('pageNo', (parseInt(currentPageNo) + 1).toString());
    }
  };

  return (
    <>
      <PLPRichText hits={hits} />
      {/* <div className="text-center">
        {!isFirstPage ? (
          <button
            className="mb-4 h-10 rounded bg-[#404040] px-5 text-sm font-bold leading-[17px] text-white"
            onClick={() => {
              // showPrevious();
            }}
            disabled={isLastPage}
          >
            {mapLocaleToMeaningfulFormat(router.locale).showPrevious}
          </button>
        ) : null}
      </div> */}
      {/* <CustomStateResults setCurrentFilters={setCurrentFilters} /> */}
      <ul className="grid grid-cols-2 gap-x-4 gap-y-[30px] sm:gap-x-5 md:gap-x-[27px] lg:grid-cols-3">
        {hits?.map((hit, index) => (
          <ProductListCard
            downrodsContent={downrodsContent}
            data={hit}
            key={index}
            positions={index}
            currentCat={currentCat}
            currentFilters={filterApplied}
            isClearanceCategory={isClearanceCategory}
            isSearchComponent={isSearchComponent}
          />
        ))}
      </ul>
      <div className="text-center">
        {!isLastPage ? (
          <button
            className="mt-4 h-10 rounded bg-[#404040] px-5 text-sm font-bold leading-[17px] text-white"
            onClick={() => {
              setCurrentPageNo((pre) => pre + 1);
              changePageNo();
            }}
            disabled={isLastPage}
          >
            {mapLocaleToMeaningfulFormat(router.locale).loadMore}
          </button>
        ) : null}
      </div>
    </>
  );
};

const ProductListItem = ({ currentCat, downrodsContent, isClearanceCategory, isSearchComponent, setCurrentPageNo }) => {
  return (
    <>
      <Hits
        downrodsContent={downrodsContent}
        currentCat={currentCat}
        isClearanceCategory={isClearanceCategory}
        isSearchComponent={isSearchComponent}
        setCurrentPageNo={setCurrentPageNo}
      />{' '}
    </>
  );
};

export default React.memo(ProductListItem);
