import React, { useEffect, useState } from 'react';

const GiftRegistry = ({}) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (!scriptLoaded) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src =
        'https://www.myregistry.com/ScriptPlatform/other/LandingPage.js?siteKey=n-561uQLqrmGDdzl9BVjpA2&version=' +
        new Date().getTime();
      document.body.appendChild(script);

      script.onload = () => {
        setScriptLoaded(true);
      };

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [scriptLoaded]);

  return (
    <>
      <div id="myRegistryPnPLandingPage"></div>
    </>
  );
};

export default GiftRegistry;
