import React, { Fragment, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Listbox, Transition } from '@headlessui/react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import Breadcrumb from 'components/sol-ui/cms/Breadcrumb';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { desktop } from 'helpers/utils/screensizes';
import { useCart } from 'frontastic';
import AccountNav from '../account/account-nav';
import ContinueShoppingButton from '../account/continueShoppingButton';
import RecentOrder from '../account/sections/accountListing/recentOrder';

function OrderHistory({ data }) {
  const filterDropDownData = data?.filters;

  const router = useRouter();
  const [isDesktop] = useMediaQuery(desktop);
  const [showItemFilterDropdown, setShowItemFilterDropdown] = useState(filterDropDownData[0]?.name);
  const [filterArrow, setFilterArrow] = useState(false);
  const { orderHistory, ordersByMonths } = useCart();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const getOrders = async () => {
      const response = await orderHistory();
      const tempOrders = response?.slice()?.sort((a, b: any) => {
        return b?.createdAt?.localeCompare(a?.createdAt);
      });
      setOrders([]?.concat(...tempOrders));
    };
    getOrders();
  }, [showItemFilterDropdown]);

  useEffect(() => {
    const initializeOrders = async () => {
      await getOrdersByMonths(0);
    };

    initializeOrders();
  }, []);

  function handleShareListArrow() {
    setFilterArrow(!filterArrow);
  }

  const getOrdersByMonths = async (index) => {
    setShowItemFilterDropdown(filterDropDownData[index]?.name);

    const response = await ordersByMonths(filterDropDownData[index]?.value);
    setOrders(response);
  };

  return (
    <>
      <Breadcrumb breadcrumLevelLink={false} activePage={mapLocaleToMeaningfulFormat(router.locale).orderHistory} />
      <section className="mx-auto max-w-screen-8xl px-4 pb-10">
        <section>{!isDesktop && <AccountNav />}</section>
        <section>
          <h1 className="mt-6 text-center text-[1.688rem] font-semibold after:mx-auto after:mt-4 after:block after:h-[0.063rem] after:w-[9.375rem] after:bg-[#B19A6A] after:content-[''] lg:mt-4">
            {mapLocaleToMeaningfulFormat(router.locale).orderHistory}
          </h1>
        </section>
        <Listbox value={showItemFilterDropdown} onChange={setShowItemFilterDropdown}>
          <section className="relative mt-8 flex w-full justify-end lg:mt-6 ">
            <Listbox.Button className="relative w-full cursor-default rounded border bg-white py-2 pl-3 pr-10 text-left md:w-[12.25rem]">
              <em className="block truncate text-sm not-italic text-sol-300">{showItemFilterDropdown}</em>
              <em className="absolute inset-y-0 right-0 flex items-center not-italic">
                {!filterArrow ? (
                  <section className="my-auto mr-3">
                    <MdOutlineKeyboardArrowDown onClick={handleShareListArrow} className="text-2xl text-sol-300" />
                  </section>
                ) : (
                  <section className="my-auto mr-3">
                    <MdOutlineKeyboardArrowUp onClick={handleShareListArrow} className="text-2xl text-sol-300" />
                  </section>
                )}
              </em>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="md:ring-black absolute mt-11 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base text-sol-300 shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:mt-10 sm:text-sm md:w-[12.25rem]">
                {filterDropDownData.map((dropDownList, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4  ${active && 'bg-gray-200 text-sol-300'}`
                    }
                    value={dropDownList?.name}
                  >
                    {({ selected }) => (
                      <>
                        <em
                          className={`block truncate not-italic hover:font-semibold ${
                            selected ? 'text-sm font-semibold text-sol-300' : 'text-sm font-normal text-sol-300'
                          }`}
                          onClick={() => getOrdersByMonths(index)}
                        >
                          {dropDownList.name}
                        </em>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </section>
        </Listbox>

        <section className="flex-col gap-5 md:mt-4 lg:mt-4 lg:flex lg:flex-row">
          {isDesktop && <AccountNav />}
          <>
            {orders?.length === 0 && (
              <section className="mt-3 w-full border py-4 px-5 lg:mt-0">
                <ul className="mt-6 text-sm font-normal leading-7 text-sol-300 lg:mt-0">
                  <li>
                    <p className="mb-4">{mapLocaleToMeaningfulFormat(router.locale).noOrderFound}</p>
                    <p className="mt-5">
                      <Link href={routerURL?.continueShopping}>
                        <a className="w-full rounded-md border border-sol-300 border-opacity-90 bg-white py-2 px-4 font-semibold text-sol-300 lg:w-[180px]">
                          {mapLocaleToMeaningfulFormat(router.locale).continueShopping}
                        </a>
                      </Link>
                    </p>
                  </li>
                </ul>
              </section>
            )}
            {!isDesktop && <ContinueShoppingButton />}
          </>
          {orders?.length !== 0 && (
            <section className="mt-3 w-full lg:mt-0">
              <ul>
                {orders?.length > 0 &&
                  orders?.map((order, i) => (
                    <li key={i} className=" mb-5 rounded border p-4">
                      <RecentOrder order={order} emptyOrder={false} />
                    </li>
                  ))}
              </ul>
            </section>
          )}
        </section>
      </section>
    </>
  );
}

export default OrderHistory;
