import { Order } from '@Types/cart/Order';
import { CurrencyHelpers } from './currencyHelpers';
import { Cart } from '@Types/cart/Cart';
import { Account } from '@Types/account/Account';

declare const _ltk: any;

export const listrakOrderEvent = (order: Order) => {
  if (typeof _ltk !== 'undefined') {
    _ltk.Order.SetCustomer(order.email, order.shippingAddress.firstName, order.shippingAddress.lastName);
    _ltk.Order.OrderNumber = order.orderNumber;
    _ltk.Order.UID = order.orderId;
    _ltk.Order.Currency = order.subTotal.currencyCode;
    _ltk.Order.ItemTotal = CurrencyHelpers.formatAmount(order?.subTotal);
    _ltk.Order.ShippingTotal = CurrencyHelpers.formatAmount(order?.shippingAmount);
    _ltk.Order.TaxTotal = CurrencyHelpers.formatAmount(order?.totalTaxedPrice);
    _ltk.Order.OrderTotal = CurrencyHelpers.formatAmount(order?.total);

    order.lineItems.forEach((lineItem) => {
      _ltk.Order.AddItem(lineItem.variant.sku, lineItem.count, CurrencyHelpers.formatAmount(lineItem?.price));
    });

    _ltk.Order.Submit();
  }
};

export const listrakSCAEvent = (cart: Cart, account: Account) => {
  if (typeof _ltk !== 'undefined' && (account?.email || cart?.email)) {
    _ltk.SCA.Update('email', account?.email ? account?.email : cart?.email);
    _ltk.SCA.Total = CurrencyHelpers.formatAmount(cart?.total);
    _ltk.SCA.Items = [];
    if (cart?.lineItems?.length === 0) {
      _ltk.SCA.ClearCart();
    } else {
      cart?.lineItems?.forEach((lineItem) => {
        _ltk.SCA.AddItemWithLinks(
          lineItem?.variant?.sku,
          lineItem?.count,
          CurrencyHelpers.formatAmount(lineItem?.price),
          lineItem?.name,
          lineItem?.variant?.images?.[0]?.url,
          lineItem?._url,
        );
      });
      _ltk.SCA.Submit();
    }
  }
};
