import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import BackIcon from 'components/icons/sol/backIcon';
import CustomLoader from 'components/icons/sol/custom-loader';
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import { gtmViewCart } from 'helpers/utils/gtm-events/gtmEcommerce';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { isCartIsValid } from 'helpers/utils/validateCart';
import { useCart, useAccount } from 'frontastic';
import CheckoutSummary from '../../order-summary';
import ProgressIndicator from '../progress-indicator';
import AuthAddressLayout from './auth-address';
import ComponentSwitcher from './component-switcher';
import GuestAddressLayout from './guest-address';
function CheckoutAddresses({ data }) {
  const { isGuestUser } = useLocalStorage();
  const { loggedIn, account, addAddress } = useAccount();
  const router = useRouter();
  const [activeStep, setActiveStep] = useState(1);
  const { data: cart, redeemDiscountCode, removeDiscountCode } = useCart();
  const isCartValid = isCartIsValid(cart);
  const [isLoaded, setIsLoaded] = useState(true);
  useEffect(() => {
    if (!isGuestUser && loggedIn) {
      setIsLoaded(false);
    }
    if (isGuestUser && !loggedIn) {
      setIsLoaded(false);
    }
  }, [isGuestUser, loggedIn]);

  //   useEffect(() => {
  //     if (!isGuestUser && !loggedIn) {
  //       router.push(routerURL.checkout);
  //     }
  //   }, [cart, isGuestUser, loggedIn]);

  return !isLoaded ? (
    <>
      <section>
        <>
          <section className="mx-auto my-10 max-w-[1180px] px-5">
            <section className="mb-4 mt-2 flex items-center justify-center gap-1">
              <BackIcon className="fill-textsol-300" />
              <Link href={routerURL?.cart}>
                <a
                  className="text-sm font-medium leading-[17px] text-sol-300 underline underline-offset-2"
                  onClick={() => {
                    gtmViewCart(cart, account);
                  }}
                >
                  {mapLocaleToMeaningfulFormat(router.locale).returnToCart}
                </a>
              </Link>
            </section>
            <h1
              className={
                isGuestUser
                  ? 'mt-6 text-center text-[1.688rem] font-semibold  lg:mt-2'
                  : "mt-6 text-center text-[1.688rem] font-semibold after:mx-auto after:mt-4 after:block after:h-[0.063rem] after:w-[9.375rem] after:bg-[#B19A6A] after:content-[''] lg:mt-2"
              }
            >
              {isGuestUser
                ? 'Guest ' + mapLocaleToMeaningfulFormat(router.locale).checkout
                : mapLocaleToMeaningfulFormat(router.locale).checkout}
            </h1>
            {isGuestUser && (
              <p className="text-center  text-sm font-normal leading-[17px]">
                {mapLocaleToMeaningfulFormat(router.locale)?.emailAlreadyExists}{' '}
                <a href={routerURL?.login} className="font-bold underline">
                  {mapLocaleToMeaningfulFormat(router.locale)?.login_here}
                </a>
              </p>
            )}
            <section className="mt-4">
              <ProgressIndicator activeStep={activeStep} setActiveStep={setActiveStep} />
            </section>
            <section className=" mt-6 flex flex-col justify-between gap-5 md:grid-cols-2 lg:mt-20 lg:flex-row lg:gap-10 ">
              <section className="flex w-full flex-col">
                <ComponentSwitcher
                  booleanCheck={isGuestUser}
                  componentTrue={<GuestAddressLayout />}
                  componentFalse={
                    <AuthAddressLayout
                      account={account}
                      addAddress={addAddress}
                      setIsLoaded={setIsLoaded}
                      isLoaded={isLoaded}
                    />
                  }
                />
              </section>
              <section>
                <CheckoutSummary
                  checkoutHeading={mapLocaleToMeaningfulFormat(router.locale).orderSummary}
                  showCalculateEstimatedTax={loggedIn ? true : false}
                  showPromoCode={true}
                  cart={cart}
                  showCheckoutButton={true}
                  showHelpLinks={true}
                  redeemDiscountCode={redeemDiscountCode}
                  removeDiscountCode={removeDiscountCode}
                  showPromoRemoveButton={true}
                  showShippingPrice={loggedIn ? true : false}
                  isCheckout={false}
                  isCartValid={isCartValid}
                  isCheckoutAddress={true}
                />
              </section>
            </section>
          </section>
        </>
      </section>
    </>
  ) : (
    <CustomLoader />
  );
}
export default CheckoutAddresses;
