import { Fragment, useState } from 'react';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import ReactPlayer from 'react-player';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { useDarkMode } from 'frontastic';
import CloseIcon from '../../../icons/sol/close';

type actions = 'setAsDefault' | 'delete' | 'removeLineItem';

export interface QuickViewModalProps {
  open?: boolean;
  videoUrl?: string;
  onClose?: () => void;
}

const QuickViewModal: React.FC<QuickViewModalProps> = ({ open, videoUrl, onClose }) => {
  //next/router
  const router = useRouter();
  //Darkmode
  const { mode, theme } = useDarkMode();
  const [currentIndex, setCurrentIndex] = useState(0);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className={`${mode} ${theme} default fixed inset-0 z-[100] overflow-y-auto`} onClose={onClose}>
        <>
          <section className="flex items-end justify-center px-4 pt-4 pb-20 text-left sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <section className="absolute inset-0" onClick={onClose}>
                {/* eslint-disable */}
                <section
                  className="absolute top-1/2 left-1/2 w-[90%] -translate-x-1/2 -translate-y-1/2 overflow-auto bg-white py-6 px-6 dark:bg-primary-200  md:w-[700px] lg:w-[940px] 2xl:w-[1100px]"
                  onClick={(e) => e.stopPropagation()}
                >
                  <section className="mb-3 flex justify-end">
                    <section>
                      <button type="button" onClick={onClose} id="modalClose">
                        <CloseIcon className="fill-sol-300 " />
                      </button>
                    </section>
                  </section>
                  <section className="aspect-video">
                    <section style={{ position: 'relative', paddingTop: '56.25%' }}>
                      {/* <ReactPlayer
                        key={1}
                        playing={true}
                        style={{ position: 'absolute', top: 0, left: 0 }}
                        url={videoUrl}
                        controls={true}
                        width="100%"
                        height="100%"
                      /> */}
                      {/* {mapLocaleToMeaningfulFormat(router.locale).browserNotSupportVideo} */}
                      <iframe
                        src={`https://player.vimeo.com/video/${videoUrl}?autoplay=1`}
                        style={{ position: 'absolute', top: 0, left: 0 }}
                        allowFullScreen={true}
                        width="100%"
                        height="100%"
                        allow={'autoplay'}
                      ></iframe>
                    </section>
                    {/* <video autoPlay loop muted className="aspect-video">
                      <source src={videoUrl} type="video/mp4" />
                      {mapLocaleToMeaningfulFormat(router.locale).browserNotSupportVideo}
                    </video> */}
                  </section>
                </section>
              </section>
            </Transition.Child>
          </section>
        </>
      </Dialog>
    </Transition.Root>
  );
};

export default QuickViewModal;
