import * as Yup from 'yup';

const validationSchema = Yup.object({
  email: Yup.string().email('Email must be valid').required('Please enter a valid email address.'),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref('email')], 'Email and email confirmation fields do not match.')
    .required('Confirm Email Address is required'),
});

const catalogValidationSchemaGuestUser = Yup.object({
  email: Yup.string().email('Email should be valid').required('Please enter a valid email address.'),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref('email')], 'Email and email confirmation fields do not match.')
    .required('Confirm Email Address is required'),
  firstName: Yup.string()
    .required('Please enter a valid First name.')
    .matches(/^[aA-zZ-\s]+$/, 'Please enter a valid First name.'),
  lastName: Yup.string()
    .required('Please enter a valid Last name.')
    .matches(/^[aA-zZ-\s]+$/, 'Please enter a valid Last name.'),
  streetName: Yup.string().required('Please enter your Street name.'),
  city: Yup.string()
    .required('Please enter your city.')
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid city.'),
  state: Yup.string()
    .required('Please enter your State/Region.')
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid state'),
  postalCode: Yup.string().required('Please enter your valid Zip Code.'),
});

const catalogValidationSchemaLoginUser = Yup.object({
  firstName: Yup.string()
    .required('Please enter a valid First name.')
    .matches(/^[aA-zZ-\s]+$/, 'Please enter a valid First name.'),
  lastName: Yup.string()
    .required('Please enter a valid Last name.')
    .matches(/^[aA-zZ-\s]+$/, 'Please enter a valid Last name.'),
  streetName: Yup.string().required('Please enter your Street name.'),
  city: Yup.string()
    .required('Please enter your city.')
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid city.'),
  state: Yup.string()
    .required('Please enter your State/Region.')
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid state'),
  postalCode: Yup.string().required('Please enter your valid Zip Code.'),
});
export { validationSchema, catalogValidationSchemaGuestUser, catalogValidationSchemaLoginUser };
