import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';

const SaleBanner = ({ content, isSales, isBannerExclusion, data }) => {
  const [isDesktopSize] = useMediaQuery(desktop);
  const [showBanner, setShowBanner] = useState(false);
  useEffect(() => {
    const currentDate = new Date();
    const fromDate = new Date(data?.from);
    const toDate = new Date(data?.to);
    const isCurrentDateInRange = currentDate >= fromDate && currentDate <= toDate;
    setShowBanner(data?.isSaleBannerVisible && isCurrentDateInRange);
  }, [data?.from, data?.to, data?.isSaleBannerVisible]);

  if (!showBanner || isBannerExclusion) {
    return <></>;
  }

  if (isSales) {
    const trueIndex = isSales?.indexOf(true);
    const banner = content?.saleBanner[trueIndex];
    if (banner) {
      const imageUrl = isDesktopSize ? banner?.desktopImageBanner?.url : banner?.mobileImageBanner?.url;
      const width = isDesktopSize ? 1400 : 760;
      const height = isDesktopSize ? 120 : 180;

      return (
        <section className="sale-banner mx-auto mt-10 mb-8 grid max-w-[1440px] justify-center px-5 lg:mb-12">
          <a href={banner?.imageClickUrl || '#'}>
            <Image
              className="mx-auto"
              src={`${imageUrl}?fmt=auto&w=${width}`}
              alt={banner?.imageAltText}
              width={width}
              height={height}
              loader={({ src }) => src}
            />
          </a>
        </section>
      );
    }
  }

  const imageUrl = isDesktopSize
    ? content?.siteWideImageBanner?.desktopImageBanner?.url
    : content?.siteWideImageBanner?.mobileImageBanner?.url;
  const width = isDesktopSize ? 1400 : 760;
  const height = isDesktopSize ? 120 : 180;
  if (!imageUrl) {
    return <></>;
  }

  return (
    <section className="sale-banner mx-auto mt-10 mb-8 grid max-w-[1440px] justify-center px-5 lg:mb-12">
      <a href={content?.siteWideImageBanner?.imageClickUrl || '#'}>
        <Image
          className="mx-auto"
          src={`${imageUrl}?fmt=auto&w=${width}`}
          alt={content?.siteWideImageBanner?.imageAltText}
          width={width}
          height={height}
          loader={({ src }) => src}
        />
      </a>
    </section>
  );
};

export default SaleBanner;
