import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import router, { useRouter } from 'next/router';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import DoubleArrow from 'components/icons/sol/double-arrow';
import ResetIcon from 'components/icons/sol/reset-success-icon';
import {
  RESET_PASSWORD_EMAIL_SUCCESS,
  RESET_PASSWORD_EMAIL_FAIL,
  HERE,
  LOGIN_FAILED,
  TRADE_ACCOUNT,
  CREATE_ACCOUNT,
} from 'helpers/constants/messages';
import { useNotification } from 'helpers/notification/notificationContext';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import validationSchema from 'helpers/utils/validationSchema/forgetPassword';
import { useAccount } from 'frontastic';
import Breadcrumb from '../cms/Breadcrumb';

const forgetPasswordInitialValues = {
  email: '',
};

enum MessageResponseTypeEnum {
  SUCCESS = 'success',
  FAIL = 'fail',
}

const ForgetPassword = () => {
  const { showNotification } = useNotification();

  const { requestPasswordReset } = useAccount();
  //next/router
  const router = useRouter();
  const renderError = (message: any) => <p className="text-sm leading-[17px] text-[#DA0F0F]">{message}</p>;
  const [error, setError] = useState('');

  const handleForgetPassword = async (values: any, resetForm) => {
    const resetPasswordRequest = await requestPasswordReset(values.email);
    if (resetPasswordRequest && resetPasswordRequest.status === MessageResponseTypeEnum.SUCCESS) {
      showNotification(RESET_PASSWORD_EMAIL_SUCCESS, 'success');
      setError(RESET_PASSWORD_EMAIL_SUCCESS);
      resetForm();
    } else {
      setError(
        `${RESET_PASSWORD_EMAIL_FAIL} <a href=${routerURL?.login} class='underline'>${HERE}</a> ${CREATE_ACCOUNT} <a href=${routerURL?.designerTradeProgram} class='underline'>${HERE}</a> ${TRADE_ACCOUNT}`,
      );
    }
  };
  return (
    <>
      <Breadcrumb
        breadcrumLevelLink={true}
        activePage={mapLocaleToMeaningfulFormat(router.locale).forgotPasswordBreadcrumb}
      />
      <section className="mx-auto max-w-screen-8xl pb-[50px] lg:pb-0">
        <section className="mt-10 mb-[149px] w-full justify-center sm:flex">
          <Formik
            initialValues={forgetPasswordInitialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              handleForgetPassword(values, resetForm);
            }}
          >
            {({ errors, touched, handleBlur }) => (
              <Form>
                <section className="px-4 sm:px-14">
                  <h1 className="mx-auto mb-4 w-max text-center text-[28px] font-semibold leading-[34px] text-[#1B3144] after:mx-auto after:mt-6 after:block after:h-[1px] after:w-[280px] after:bg-[#B19A6A] after:content-['']">
                    {mapLocaleToMeaningfulFormat(router.locale).forgotYourPassword}
                  </h1>
                  <p className="text-center text-sm font-normal leading-[17px] text-sol-300">
                    {mapLocaleToMeaningfulFormat(router.locale).emailLinkResetPassword}
                  </p>
                  <section className="mx-auto mt-12 sm:w-[430px]">
                    <section className="mb-3 flex flex-col justify-center gap-3 md:flex-row">
                      <section className="w-full">
                        <section className="w-full">
                          <label htmlFor="forgetPassword" className="text-sm font-semibold leading-[17px] text-sol-300">
                            {mapLocaleToMeaningfulFormat(router.locale).email}
                          </label>
                          <Field
                            type="text"
                            name="email"
                            id="forgetPassword"
                            onBlur={handleBlur}
                            className={`input-text-primary mt-3 mb-3 !w-full ${
                              errors?.email && touched?.email ? '!border-red-600' : ''
                            }`}
                          />
                          {errors.email && touched.email && (
                            <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{errors.email}</p>
                          )}
                        </section>
                      </section>
                      <button
                        className={`mt-3 flex h-[44px] w-full items-center justify-center rounded-md bg-[#404040] px-6 text-white md:mt-9 md:w-auto ${
                          Object.keys(errors).length === 0 ? '' : 'cursor-not-allowed'
                        }`}
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).send}
                      </button>
                    </section>
                    <section>
                      {error !== RESET_PASSWORD_EMAIL_SUCCESS && error !== '' && (
                        <div
                          className="rounded border-2 border-red-600 bg-red-100 p-3"
                          dangerouslySetInnerHTML={{ __html: error }}
                        />
                      )}
                    </section>
                  </section>
                </section>
              </Form>
            )}
          </Formik>
        </section>
      </section>
    </>
  );
};

export default ForgetPassword;
