import * as Yup from 'yup';

const validationSchema = Yup.object({
  firstName: Yup.string()
    .required('Please enter a valid First name.')
    .matches(/^[A-Za-z-\s]+$/, 'Please enter a valid First name.'),
  lastName: Yup.string()
    .required('Please enter a valid Last name.')
    .matches(/^[A-Za-z-\s]+$/, 'Please enter a valid Last name.'),
  streetName: Yup.string().required('Please enter your Street name.'),
  city: Yup.string()
    .required('Please enter your city.')
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid city.'),
  // state: Yup.string()
  //   .required('Please enter your State/Region.')
  //   .matches(/^[aA-zZ\s]+$/, 'Please enter a valid state'),
  postalCode: Yup.string().required('Please enter your valid Zip Code.'),
  phone: Yup.string().required('Number is required'),
});
export default validationSchema;
