import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none" className={className}>
    <path
      d="M5.79135 0.835768L0.718697 5.9174C0.634546 6.00086 0.567754 6.10016 0.522173 6.20957C0.476592 6.31897 0.453125 6.43632 0.453125 6.55484C0.453125 6.67337 0.476592 6.79072 0.522173 6.90012C0.567754 7.00953 0.634546 7.10883 0.718697 7.19229C0.886914 7.35951 1.11447 7.45337 1.35166 7.45337C1.58885 7.45337 1.8164 7.35951 1.98461 7.19229L6.47369 2.74811L10.9179 7.19229C11.0861 7.35951 11.3136 7.45337 11.5508 7.45337C11.788 7.45337 12.0156 7.35951 12.1838 7.19229C12.2686 7.10914 12.3361 7.00998 12.3823 6.90056C12.4286 6.79114 12.4526 6.67363 12.4531 6.55484C12.4526 6.43606 12.4286 6.31855 12.3823 6.20913C12.3361 6.09971 12.2686 6.00055 12.1838 5.9174L7.11113 0.835768C7.02706 0.744636 6.92502 0.671905 6.81145 0.622159C6.69788 0.572414 6.57523 0.546732 6.45124 0.546732C6.32725 0.546732 6.2046 0.572414 6.09103 0.622159C5.97746 0.671905 5.87542 0.744636 5.79135 0.835768Z"
      fill="#1B3144"
      fillOpacity="0.9"
    />
  </svg>
);

export default Icon;
