import React from 'react';
import Head from 'next/head';
import { useConfig } from 'frontastic';

const AuthorizeNetScript = () => {
  const { data: config } = useConfig();
  return (
    <Head>
      <script id="authSiteTag" src={config?.authorizeNet?.authClientUrl} defer></script>
    </Head>
  );
};

export default AuthorizeNetScript;
