import { CurrencyHelpers } from './currencyHelpers';

export const totalPriceCalculation = (amount, quantity) => {
  const amountInCents = amount?.centAmount * quantity;
  const totalAmount = CurrencyHelpers.formatForCurrency({
    fractionDigits: amount?.fractionDigits,
    centAmount: amountInCents,
    currencyCode: amount?.currencyCode,
  });
  return totalAmount;
};
