import { Fragment } from 'react';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import { Cart } from '@Types/cart/Cart';
import CustomSpinner from 'components/icons/sol/custom-spinner';
import { listrakSCAEvent } from 'helpers/listrakHelper';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { useAccount, useDarkMode } from 'frontastic';
import CloseIcon from '../../../icons/sol/close';
type actions = 'setAsDefault' | 'delete' | 'removeLineItem';

export interface DeleteModalProps {
  open?: boolean;
  onClose?: () => void;
  addressId?: string;
  lineItemId?: string;
  modalHeading?: string;
  modalContent?: string;
  primaryBtnContent?: string;
  handleSetAsDefault?: ({ addressId }) => void;
  handleDelete?: ({ addressId }) => void;
  handleRemoveLineitem?: ({ lineItemId }) => void;
  action: actions;
  loading?: boolean;
  cart?: Cart;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  onClose,
  modalHeading,
  modalContent,
  primaryBtnContent,
  handleSetAsDefault,
  addressId,
  lineItemId,
  handleDelete,
  handleRemoveLineitem,
  action,
  loading,
  cart,
}) => {
  //next/router
  const router = useRouter();
  const { account } = useAccount();
  //Darkmode
  const { mode, theme } = useDarkMode();
  const handleSave = () => {
    switch (action) {
      case 'delete':
        handleDelete({ addressId });
        break;
      case 'setAsDefault':
        handleSetAsDefault({ addressId });
        break;
      case 'removeLineItem':
        handleRemoveLineitem({ lineItemId });
        break;
    }
    listrakSCAEvent(cart, account);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className={`${mode} ${theme} default fixed inset-0 z-10 overflow-y-auto`} onClose={onClose}>
        <>
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-left sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="absolute inset-0">
                {/* eslint-disable */}
                <div className="absolute top-1/2 left-1/2 w-[90%] -translate-x-1/2 -translate-y-1/2 overflow-auto bg-white py-6 px-4 dark:bg-primary-200 sm:px-6 md:w-[500px] lg:px-8">
                  <section className="flex justify-between">
                    <h1 className="mx-auto mb-9 w-max text-center text-xl font-bold leading-[29px] text-sol-400 after:mx-auto after:mt-4 after:block after:h-[1px] after:w-[150px] after:bg-sol-600 after:content-[''] lg:text-2xl">
                      {modalHeading}
                    </h1>
                    <section className="pt-2">
                      <button type="button" onClick={onClose}>
                        <CloseIcon className="fill-sol-300 " />
                      </button>
                    </section>
                  </section>
                  <section className="mb-10">
                    <p className="text-sm font-normal leading-[17px] text-sol-300">{modalContent}</p>
                  </section>
                  <section className="flex flex-col items-center gap-0 md:mb-4 md:flex-row md:gap-6">
                    <button
                      type="submit"
                      id="deleteModal"
                      className={`btn-primary-small flex h-[44px] w-full items-center justify-center px-8 md:w-auto`}
                      onClick={handleSave}
                    >
                      {loading ? (
                        <CustomSpinner text={mapLocaleToMeaningfulFormat(router.locale).processing} />
                      ) : (
                        primaryBtnContent
                      )}
                    </button>
                    <button
                      type="button"
                      id="deleteModal"
                      onClick={onClose}
                      className={`btn-cancel flex h-[44px] w-full items-center justify-center underline underline-offset-2 md:w-auto`}
                    >
                      {mapLocaleToMeaningfulFormat(router.locale).cancel}
                    </button>
                  </section>
                </div>
              </div>
            </Transition.Child>
          </div>
        </>
      </Dialog>
    </Transition.Root>
  );
};

export default DeleteModal;
