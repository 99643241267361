import React, { useState } from 'react';
import Link from 'next/link';
import router, { useRouter } from 'next/router';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { desktop } from 'helpers/utils/screensizes';

function CommunicationPreference() {
  const [isDesktop] = useMediaQuery(desktop);
  const router = useRouter();
  return (
    <section className="rounded-md border border-slate-300 p-4">
      <section className="flex flex-col justify-between gap-5 lg:flex-row">
        <section>
          <h3 className="text-sm font-semibold text-sol-300">
            {mapLocaleToMeaningfulFormat(router.locale).communicationPreference}
          </h3>
          <ul className="mt-6 ml-4 list-disc  space-y-3 pt-4 text-sm marker:text-[10px] lg:py-0">
            <li className="flex items-center gap-3">
              <span className="h-[3px] w-[3px] rounded-lg bg-black-100"></span>
              <p>{mapLocaleToMeaningfulFormat(router.locale).allEmail}</p>
            </li>
            <li className="flex items-center gap-3">
              <span className="h-[3px] w-[3px] rounded-lg bg-black-100"></span>
              <p>{mapLocaleToMeaningfulFormat(router.locale).catalog}</p>
            </li>
          </ul>
        </section>
        <section>
          <Link href={routerURL?.communicationPreferences}>
            <a className="btn-primary-small mt-5 w-full shrink-0 py-2 text-sm">
              {mapLocaleToMeaningfulFormat(router.locale).update}
            </a>
          </Link>
        </section>
      </section>
    </section>
  );
}

export default CommunicationPreference;
