import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import YouMayLike from './you-may-like-item';

const YouMayLikeCard = ({ algoliaConfig }) => {
  //next/router
  const router = useRouter();
  const [isData, setIsData] = useState(true);

  return (
    <>
      <section className={isData && 'mt-11 border-t border-[#B19A6A] pt-[50px]'}>
        {isData && (
          <strong className="mb-11 block text-[28px] font-semibold leading-[34px] text-sol-300 ">
            {mapLocaleToMeaningfulFormat(router.locale).youMayAlsoLike}
          </strong>
        )}
        <YouMayLike setIsData={setIsData} variantName={''} searchTerm={''} algoliaConfig={algoliaConfig} />
      </section>
    </>
  );
};

export default YouMayLikeCard;
