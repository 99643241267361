import * as Yup from 'yup';

const validationSchema = (password: string) =>
  Yup.object({
    password: Yup.string()
      .test('no-spaces', 'Must not contain spaces', (value) => !/\s/.test(value))
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .notOneOf([password], 'New password must be different')
      .min(8, 'Minimum length would be 8 chars')
      .max(40, 'Maximum length would be 40 chars')
      .matches(/[a-z]+/, 'Must contain 1 lowercase character (a-z)')
      .matches(/[A-Z]+/, 'Must contain 1 uppercase character (A-Z)')
      .matches(/\d+/, 'Must contain 1 digit from 0-9')
      .matches(/[@#$%^&*()_+=\[\]{}|:;"'<>,.?\/\\-]+/, 'Must contain 1 special character "^[]’@#$%&*()_+=|}{;:<,.>/?-')
      .required('Please enter a valid password.'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Password and Confirm Password fields do not match.')
      .required('Confirm Password is required'),
  });
export default validationSchema;
