import Image from 'next/image';
import Link from 'next/link';

const TwoCatalogRequestSubmit = () => {
  const data = [
    {
      id: 1,
      imageUrl:
        'https://cdn.shadesoflight.com/media/W1siZiIsIjIwMjAvMTAvMDYvMDkvMzIvMzgvOTUzL0JTMTgzMDlfbGFyZ2UuanBnIl0sWyJwIiwib3B0aW0iXV0/BS18309_large.jpg?sha=fe378563895ba2a7',
      title: 'Retro, Modern, Rustic & more',
      button: 'Shop Bath Lights',
    },
    {
      id: 2,
      imageUrl:
        'https://cdn.shadesoflight.com/media/W1siZiIsIjIwMTkvMDUvMDgvMTEvMzkvMjMvMzYzL0NIMTgxMDQuanBnIl0sWyJwIiwib3B0aW0iXV0/CH18104.jpg?sha=0520dddeb159b505',
      title: 'Rustic Chandeliers',
      button: 'Shop the Look',
    },
  ];

  return (
    <>
      {/* TODO: Static the comes from amplience on later  */}
      <section className="mx-auto mb-8 max-w-[1440px] px-5 md:px-10 lg:mb-20">
        <ul className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-5">
          {data.map((item) => (
            <li key={item.id}>
              <Link href="">
                <a className="flex h-full w-full">
                  <section className="h-full w-full rounded-t-[6px] bg-sol-100">
                    <section>
                      <Image
                        loader={({ src }) => src}
                        src={item?.imageUrl}
                        alt={item?.title}
                        width={670}
                        height={464}
                        layout="responsive"
                        className="rounded-t-md"
                      />
                    </section>
                    <section className="flex justify-between p-4">
                      <h1 className="text-2xl font-semibold leading-[29px] text-sol-300">{item.title}</h1>
                      <section className="ml-4 mt-1 shrink-0">
                        <button
                          type="button"
                          id={'twoColCatalogBtn' + item.title?.trim()?.split(' ')?.join('')}
                          className="btn-primary-small inline-block px-4 py-2 text-sm !font-normal"
                        >
                          {item.button}
                        </button>
                      </section>
                    </section>
                  </section>
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default TwoCatalogRequestSubmit;
