import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import BreadcrumbRichText from 'components/seo/breadcrumbsRichText';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import DoubleArrow from '../../../icons/sol/double-arrow';

const BlogBreadcrumbs = ({ breadcrumLevelLink, activePages }) => {
  const router = useRouter();
  return (
    <>
      <BreadcrumbRichText items={activePages} />
      <section className="mx-auto mt-4 flex max-w-screen-8xl items-center text-xs font-normal text-sol-300">
        <ul className="inline-block px-4 text-xs font-normal leading-6 text-sol-300">
          {breadcrumLevelLink ? (
            <>
              <li className="mr-2 inline ">
                <Link href={routerURL?.home}>
                  <a className="text-xs leading-[15px] text-sol-300">
                    {mapLocaleToMeaningfulFormat(router.locale).home}
                  </a>
                </Link>
              </li>
              {activePages?.map((activePage, index) => (
                <li key={index} className="mr-2 inline ">
                  <DoubleArrow className="mr-2 inline" />
                  <Link href={activePage?.ctaLink}>
                    <span className="cursor-pointer text-xs leading-[15px] text-sol-300">
                      {activePage?.title &&
                        activePage?.title
                          .split(' ')
                          .map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase())
                          .join(' ')}
                    </span>
                  </Link>
                </li>
              ))}
            </>
          ) : (
            <>
              <li className="mr-2 inline ">
                <Link href={routerURL?.home}>
                  <a className="text-xs leading-[15px] text-sol-300">
                    {mapLocaleToMeaningfulFormat(router.locale).home}
                  </a>
                </Link>
              </li>
              <li className="mr-2 inline">
                <DoubleArrow className="mr-2 inline" />
                <Link href={activePages?.ctaLink}>
                  <span className="cursor-pointer text-xs leading-[15px] text-sol-300">{activePages?.title}</span>
                </Link>
              </li>
            </>
          )}
        </ul>
      </section>
    </>
  );
};

export default BlogBreadcrumbs;
