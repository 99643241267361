import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { SubscriptionList } from '@Types/communication-preference/SubscriptionList';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Spinner from 'components/sol-ui/notification/spinner';
import ReCaptcha from 'components/sol-ui/recaptcha';
import { NOT_YET_SUBSCRIBED } from 'helpers/constants/messages';
import { useNotification } from 'helpers/notification/notificationContext';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { validationSchema } from 'helpers/utils/validationSchema/communicationPreference';
import { useAccount } from 'frontastic';

interface Subscribe {
  setUnSubscribe?: React.Dispatch<React.SetStateAction<boolean>>;
  setSubscribeSubmit?: React.Dispatch<React.SetStateAction<boolean>>;
  email?: string;
  setEmail?: React.Dispatch<React.SetStateAction<string>>;
  confirmEmail?: string;
  setConfirmEmail?: React.Dispatch<React.SetStateAction<string>>;
  isSubscribed?: boolean;
  setIsSubscribed?: any;
  setSubscriptionList?: any;
  setBreadCrumLevel?: React.Dispatch<React.SetStateAction<boolean>>;
}
const SubscriptionPreferences = ({
  setSubscribeSubmit,
  setUnSubscribe,
  email,
  setEmail,
  confirmEmail,
  setConfirmEmail,
  isSubscribed,
  setIsSubscribed,
  setSubscriptionList,
  setBreadCrumLevel,
}: Subscribe) => {
  const initialOption = {
    checkAll: true,
    trending_looks_styles: true,
    new_catalog_previews: true,
    new_item_introductions: true,
    decoration_tips_ideas: true,
    sales_specials: true,
  };

  const router = useRouter();
  const { shippingLocations, loggedIn, account, checkSubscription, updateSubscription } = useAccount();
  const userEmail = (router?.query?.email as string) ?? email;

  const [isReCaptchaVerified, setIsReCaptchaVerified] = useState(false);
  const [reCaptchaError, setReCaptchaError] = useState(false);
  const [options, setoptions] = useState(initialOption);

  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const [emailSubscribed, setEmailSubscribed] = useState<boolean>(true);
  const [emailSubscribedMessage, setEmailSubscribedMessage] = useState<string>('');
  const { showNotification } = useNotification();

  const onOptionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'checkAll') {
      setoptions((prev: any) => {
        const res = Object.keys(prev).reduce((a: any, b: any) => {
          a[b] = event.target.checked;
          return a;
        }, {});
        return res;
      });
    } else {
      setoptions((prev: any) => {
        const res = { ...prev, [event.target.name]: event.target.checked };
        const { checkAll, ...data } = res;
        return { ...data, checkAll: Object.values(data).every((item) => item === true) };
      });
    }
  };
  useEffect(() => {
    if (Object.entries(options).length > 0) {
      const copyOptions: SubscriptionList = { ...options };
      Object.entries(copyOptions).forEach(([key, value]) => {
        if (!options[key]) {
          delete copyOptions[key];
        }
      });
      const isValid = Object.values(copyOptions).some((value) => value === true);

      if (isValid) {
        setIsValidForm(true);
      } else {
        setIsValidForm(false);
      }
    }
  }, [options]);

  useEffect(() => {
    if (userEmail && !initialLoad) {
      setEmail(userEmail);
      setConfirmEmail(userEmail);
      setInitialLoad(true);
    } else if (email === '') {
      setInitialLoad(true);
    }
  }, [userEmail]);

  useEffect(() => {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) && email != '') {
      setIsValidForm(true);
    } else {
      setIsValidForm(false);
    }
    validateEmail(email);
    if (loggedIn) {
      setBreadCrumLevel(false);
    } else {
      setBreadCrumLevel(true);
    }
  }, [email]);
  useEffect(() => {
    setConfirmEmail('');
    setEmail(loggedIn ? email : '');
    validateEmail(email);
  }, [router.asPath]);
  const emailPreferenceInitialValues = {
    email: email,
    confirmEmail: confirmEmail,
    checkAll: options.checkAll,
    trending_looks_styles: options.trending_looks_styles,
    new_catalog_previews: options.new_catalog_previews,
    new_item_introductions: options.new_item_introductions,
    decoration_tips_ideas: options.decoration_tips_ideas,
    sales_specials: options.sales_specials,
  };

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    setReCaptchaError(false);
    if (isReCaptchaVerified) {
      const result = await updateSubscription({
        ...values,
        email: values?.email || userEmail,
        requestFrom: 'subscribe',
      });
      if (result?.isSubscribed) {
        setSubscribeSubmit(true);
        setLoading(false);
        setEmailSubscribed(true);
        setEmailSubscribedMessage('');
        router.push(`/pages/communication-preferences?email=${email}`);
      }
    } else {
      setReCaptchaError(true);
    }
    setLoading(false);
  };

  const onUnSubscribeClick = async () => {
    if (
      (emailSubscribed && email === confirmEmail && email && confirmEmail) ||
      (emailSubscribed && loggedIn && email === confirmEmail && email && confirmEmail)
    ) {
      setUnSubscribe(true);
    }
  };

  const validateEmail = async (value: string) => {
    let error = '';
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) && value != '') {
      await checkSubscription(value).then((response) => {
        if (response?.isSubscribed) {
          setSubscriptionList(response as SubscriptionList);
          setEmailSubscribed(true);
          setEmailSubscribedMessage('');
        } else {
          error = mapLocaleToMeaningfulFormat(router.locale).noEmailSubscription;
          setEmailSubscribed(false);
          setEmailSubscribedMessage(error);
        }
        setIsSubscribed(response?.isSubscribed);
      });
    }

    return error;
  };

  return (
    <>
      <Formik
        initialValues={{ ...emailPreferenceInitialValues }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, { resetForm });
        }}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form>
            <section className="mt-14 flex flex-col justify-center gap-5 md:flex-row">
              <section className="flex flex-col">
                <label
                  htmlFor="emailAddress"
                  className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                >
                  {mapLocaleToMeaningfulFormat(router.locale).emailAddress}
                </label>
                <Field
                  className={`mt-2 h-9 w-[20.3rem] appearance-none rounded border border-gray-300 py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none ${
                    formik.errors?.email && formik.touched?.email ? '!border-red-600' : ''
                  }`}
                  id="emailAddress"
                  name="email"
                  type="text"
                  value={email}
                  onBlur={formik?.handleBlur}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    validateEmail(e.target.value);
                  }}
                />
                {formik?.errors?.email && formik?.touched?.email ? (
                  <p className="pt-2 text-sm font-normal leading-[17px] text-[#DA0F0F]">{formik?.errors?.email}</p>
                ) : (
                  !emailSubscribed && (
                    <p className="pt-2 text-sm font-normal leading-[17px] text-[#DA0F0F]">{emailSubscribedMessage}</p>
                  )
                )}
              </section>

              <section className="flex flex-col">
                <label
                  htmlFor="confirmEmailAddress"
                  className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                >
                  {mapLocaleToMeaningfulFormat(router.locale).confirmEmailAddress}
                </label>
                <Field
                  className={`mt-2 h-9 w-[20.3rem] appearance-none rounded border border-gray-300 py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none ${
                    formik.errors?.confirmEmail && formik.touched?.confirmEmail ? '!border-red-600' : ''
                  }`}
                  id="confirmEmailAddress"
                  name="confirmEmail"
                  type="text"
                  value={confirmEmail}
                  onBlur={formik?.handleBlur}
                  onChange={(e) => setConfirmEmail(e.target.value)}
                />
                {formik?.errors?.confirmEmail && formik?.touched?.confirmEmail && (
                  <p className="w-[20.3rem] pt-2 text-sm font-normal leading-[17px] text-[#DA0F0F]">
                    {formik.errors.confirmEmail}
                  </p>
                )}
              </section>
            </section>
            <section className="mx-auto mt-[1.875rem] w-full rounded border md:w-[41.875rem]">
              <h1 className="w-full p-5 text-base font-semibold">
                {mapLocaleToMeaningfulFormat(router.locale).emailPreferences}
              </h1>
              <section className="mt-4 flex items-center gap-4 px-5">
                <Field
                  type="checkbox"
                  name="checkAll"
                  id="checkAll"
                  checked={options.checkAll}
                  onChange={onOptionChanged}
                  className="h-4 w-4 appearance-none rounded-[2px] border-none border-sol-300 bg-transparent text-white ring-2 ring-gray-300  focus:ring-sol-300"
                />
                <label htmlFor="checkAll" className="text-sm leading-[20px] text-sol-300">
                  {mapLocaleToMeaningfulFormat(router.locale).emailUpdate}
                </label>
              </section>
              <h1 className="w-full px-5 pt-6 pb-3 text-base font-semibold">
                {mapLocaleToMeaningfulFormat(router.locale).keepMeUpdate}
              </h1>
              <section className="mt-4 flex items-center gap-4 px-5 pb-5">
                <Field
                  type="checkbox"
                  name="new_catalog_previews"
                  id="new_catalog_previews"
                  onBlur={formik?.handleBlur}
                  checked={options.new_catalog_previews}
                  onChange={onOptionChanged}
                  className="h-4 w-4 appearance-none rounded-[2px] border-none border-sol-300 bg-transparent text-white ring-2 ring-gray-300  focus:ring-sol-300"
                />
                <label htmlFor="new_catalog_previews" className="text-sm leading-[17px] text-sol-300">
                  {mapLocaleToMeaningfulFormat(router.locale).newCatalogPreview}
                </label>
              </section>
              <section className="mt-4 flex items-center gap-4 px-5 pb-5">
                <Field
                  type="checkbox"
                  name="trending_looks_styles"
                  id="trending_looks_styles"
                  onBlur={formik?.handleBlur}
                  checked={options.trending_looks_styles}
                  onChange={onOptionChanged}
                  className="h-4 w-4 appearance-none rounded-[2px] border-none border-sol-300 bg-transparent text-white ring-2 ring-gray-300  focus:ring-sol-300"
                />
                <label htmlFor="trending_looks_styles" className="text-sm leading-[17px] text-sol-300">
                  {mapLocaleToMeaningfulFormat(router.locale).looksAndStyles}
                </label>
              </section>
              <section className="mt-4 flex items-center gap-4 px-5 pb-5">
                <Field
                  type="checkbox"
                  name="new_item_introductions"
                  id="new_item_introductions"
                  onBlur={formik?.handleBlur}
                  checked={options.new_item_introductions}
                  onChange={onOptionChanged}
                  className="h-4 w-4 appearance-none rounded-[2px] border-none border-sol-300 bg-transparent text-white ring-2 ring-gray-300  focus:ring-sol-300"
                />
                <label htmlFor="new_item_introductions" className="text-sm leading-[17px] text-sol-300">
                  {mapLocaleToMeaningfulFormat(router.locale).newItemIntro}
                </label>
              </section>
              <section className="mt-4 flex items-center gap-4 px-5 pb-5">
                <Field
                  type="checkbox"
                  name="decoration_tips_ideas"
                  id="decoration_tips_ideas"
                  onBlur={formik?.handleBlur}
                  checked={options.decoration_tips_ideas}
                  onChange={onOptionChanged}
                  className="h-4 w-4 appearance-none rounded-[2px] border-none border-sol-300 bg-transparent text-white ring-2 ring-gray-300  focus:ring-sol-300"
                />
                <label htmlFor="decoration_tips_ideas" className="text-sm leading-[17px] text-sol-300">
                  {mapLocaleToMeaningfulFormat(router.locale).decoratingTips}
                </label>
              </section>
              <section className="mt-4 flex items-center gap-4 px-5 pb-8">
                <Field
                  type="checkbox"
                  name="sales_specials"
                  id="sales_specials"
                  onBlur={formik?.handleBlur}
                  checked={options.sales_specials}
                  onChange={onOptionChanged}
                  className="h-4 w-4 appearance-none rounded-[2px] border-none border-sol-300 bg-transparent text-white ring-2 ring-gray-300  focus:ring-sol-300"
                />
                <label htmlFor="sales_specials" className="text-sm leading-[17px] text-sol-300">
                  {mapLocaleToMeaningfulFormat(router.locale).saleAndSpecials}
                </label>
              </section>
            </section>
            <section className="mx-auto mt-4 w-full md:w-[41.875rem]">
              <div>
                <ReCaptcha setIsReCaptchaVerified={setIsReCaptchaVerified} setReCaptchaError={setReCaptchaError} />
                {reCaptchaError && (
                  <span className="text-xs font-semibold text-red-500">
                    {mapLocaleToMeaningfulFormat(router.locale).reCaptchaError}
                  </span>
                )}
              </div>
            </section>
            <section className="mx-auto mt-4 w-full md:w-[41.875rem]">
              {isValidForm ? (
                <button type="submit" className="btn-primary-large mt-6 w-full md:w-[294px]">
                  {loading ? (
                    <section className="flex justify-center gap-3">
                      {' '}
                      <Spinner />
                      <p>{mapLocaleToMeaningfulFormat(router.locale).processing}</p>
                    </section>
                  ) : (
                    mapLocaleToMeaningfulFormat(router.locale).submit
                  )}
                </button>
              ) : (
                <button
                  disabled={!isValidForm}
                  className="btn-primary-large mt-6 w-full cursor-not-allowed opacity-50 md:w-[294px]"
                >
                  {mapLocaleToMeaningfulFormat(router.locale).submit}
                </button>
              )}
              {/*  */}
            </section>
          </Form>
        )}
      </Formik>
      <section className="flex flex-col justify-center gap-5 md:flex-row">
        <section className="mt-6 ml-8 text-center">
          <div className="inline-block text-left">
            <p className="mb-2 text-sm">{mapLocaleToMeaningfulFormat(router.locale).emailSubscription}</p>
            <p className="mb-1 text-sm font-bold">{mapLocaleToMeaningfulFormat(router.locale).needToUnsubscribe}</p>
            <p className="text-sm">
              {mapLocaleToMeaningfulFormat(router.locale).unsubscribeFromOurEmail}{' '}
              <button type="submit">
                {' '}
                <a
                  className={`${
                    (emailSubscribed && email === confirmEmail && email && confirmEmail) ||
                    (emailSubscribed && loggedIn && email === confirmEmail && email && confirmEmail)
                      ? 'cursor-pointer '
                      : 'cursor-not-allowed'
                  } underline`}
                  onClick={onUnSubscribeClick}
                >
                  {mapLocaleToMeaningfulFormat(router.locale).clickHere}
                </a>
              </button>
            </p>
          </div>
        </section>
      </section>
    </>
  );
};
export default SubscriptionPreferences;
