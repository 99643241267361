import { JsonLd } from 'react-schemaorg';
import { BreadcrumbList } from 'schema-dts';

const BreadcrumbRichText = ({ items }) => {
  const itemList = [];
  items?.forEach((item, idx) => {
    itemList.push({
      '@type': 'ListItem',
      item: {
        '@id': `https://www.shadesoflight.com${item?.ctaLink?.startsWith('/') ? item?.ctaLink : `/${item?.ctaLink}`}`,
        name: item?.title,
      },
      position: idx + 1,
    });
  });
  return (
    <>
      <JsonLd<BreadcrumbList>
        item={{
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: itemList,
        }}
      />
    </>
  );
};

export default BreadcrumbRichText;
