import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Cart } from '@Types/cart/Cart';
import MiniCartItem from 'components/sol-ui/line-Item/mini-cart-item';
import MiniCartNotification from 'components/sol-ui/notification/mincart-notification';
import { cartQuantityTotal } from 'helpers/cartQuantityTotal';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { gtmViewCart } from 'helpers/utils/gtm-events/gtmEcommerce';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { desktop } from 'helpers/utils/screensizes';
import { isCartIsValid } from 'helpers/utils/validateCart';
import { useCart, useAccount } from 'frontastic';
import CloseIcon from '../../../icons/sol/close';

interface MiniCartProps {
  cart?: Cart;
  mobileModalClose?: () => void;
  desktopModalClose?: () => void;
  openMiniCartModal?: boolean;
  notificationTimeoutInMs?: number;
}

export const MiniCart = ({
  cart,
  mobileModalClose,
  desktopModalClose,
  openMiniCartModal,
  notificationTimeoutInMs,
}: MiniCartProps) => {
  const [isDesktop] = useMediaQuery(desktop);
  const router = useRouter();
  const { removeItem, updateItem } = useCart();
  const { account } = useAccount();
  const editItemQuantity = (lineItemId: string, newQuantity: number) => updateItem(lineItemId, newQuantity);
  const isEmpty = cart?.lineItems?.length === 0;
  const isValidCart = isCartIsValid(cart);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [removeButtonDisabled, setRemoveButtonDisabled] = useState(false);
  const [lineItemQuantityDisabled, setLineItemQuantityDisabled] = useState(false);

  const handleScroll = (isMiniCartOpen) => {
    document.body.style.overflow = isMiniCartOpen ? 'hidden' : 'auto';
  };

  // useEffect(() => {
  //   handleScroll(true);
  //   return () => handleScroll(false);
  // }, []);

  return (
    <>
      {openMiniCartModal && <MiniCartNotification timeoutInMs={notificationTimeoutInMs} />}
      <section className="flex justify-between">
        <p className="my-4 px-5 text-left text-lg font-semibold lg:mt-0">
          {mapLocaleToMeaningfulFormat(router.locale).cart}{' '}
          <span>
            (
            {`${cart?.lineItems?.length > 0 ? cartQuantityTotal(cart?.lineItems) : 0} ${
              mapLocaleToMeaningfulFormat(router.locale).items
            }`}
            )
          </span>
        </p>
        {isDesktop && (
          <button type="button" id="closeModal" onClick={desktopModalClose}>
            <CloseIcon className="mr-5 h-4 w-4 fill-black-600" />
          </button>
        )}
      </section>
      <section className="mt-[1.25rem] h-[calc(100%_-_250px)] w-full bg-[#fcfcfa] lg:h-auto">
        <ul className="customtablescrollbar max-h-full divide-y divide-sol-600 overflow-y-auto px-5 lg:max-h-[420px]">
          {isEmpty && (
            <>
              {' '}
              <li className="py-10">{mapLocaleToMeaningfulFormat(router.locale).yourCartIsEmpty}</li>{' '}
            </>
          )}
          {cart?.lineItems?.map((cartLineItem, index) => (
            <li key={index} className="mt-3 w-full py-6 pb-5 text-sm font-normal text-sol-300 lg:mt-0 lg:py-2">
              {!cartLineItem?.isAvailable && (
                <p className="mt-4 text-left font-medium text-red-600 lg:pr-4 lg:pb-4">
                  {mapLocaleToMeaningfulFormat(router.locale).thisProductNoLongerAvailable}
                </p>
              )}
              <MiniCartItem
                lineItemIndex={index}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                lineItem={cartLineItem}
                removeLineItem={removeItem}
                updateLineItem={editItemQuantity}
                desktopModalClose={desktopModalClose}
                mobileModalClose={mobileModalClose}
                removeButtonDisabled={removeButtonDisabled}
                setRemoveButtonDisabled={setRemoveButtonDisabled}
                lineItemQuantityDisabled={lineItemQuantityDisabled}
                setLineItemQuantityDisabled={setLineItemQuantityDisabled}
              />
            </li>
          ))}
        </ul>
      </section>
      <section className="absolute bottom-0 w-full bg-[#dfdfdf] p-5 lg:static">
        {isEmpty ? null : (
          <section className="mb-5 flex justify-end gap-10">
            <p className="pr-7 text-sm font-semibold">
              {mapLocaleToMeaningfulFormat(router.locale).subTotal} {mapLocaleToMeaningfulFormat(router.locale).colon}
            </p>
            <p className="text-sm font-semibold">{CurrencyHelpers.formatForCurrency(cart?.subTotal)}</p>
          </section>
        )}
        <section className="gap-5 lg:mx-0  lg:flex lg:justify-center">
          {isDesktop ? (
            <>
              {isEmpty ? (
                <section>
                  <Link href={routerURL?.cart}>
                    <a>
                      <button
                        type="button"
                        id="viewCart"
                        disabled
                        onClick={() => {
                          gtmViewCart(cart, account);
                          desktopModalClose();
                        }}
                        className="h-11 w-full cursor-not-allowed rounded bg-sol-500 py-3 px-4 text-sm font-bold text-white opacity-40 focus:outline-offset-2"
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).viewCartAndCheckout}
                      </button>
                    </a>
                  </Link>
                </section>
              ) : (
                <section>
                  {/* {isValidCart ? ( */}
                  {!isEmpty ? (
                    <Link href={routerURL?.cart}>
                      <a>
                        <button
                          type="button"
                          id="viewCart"
                          onClick={() => {
                            gtmViewCart(cart, account);
                            desktopModalClose();
                          }}
                          className="h-11 w-full rounded bg-sol-500 py-3 px-4 text-sm font-bold text-white focus:outline-offset-2"
                        >
                          {mapLocaleToMeaningfulFormat(router.locale).viewCartAndCheckout}
                        </button>
                      </a>
                    </Link>
                  ) : (
                    <button
                      type="button"
                      id="viewCart"
                      className="h-11 w-full cursor-not-allowed rounded bg-sol-500 py-3 px-4 text-sm font-bold text-white opacity-50 focus:outline-offset-2"
                    >
                      {mapLocaleToMeaningfulFormat(router.locale).viewCartAndCheckout}
                    </button>
                  )}
                </section>
              )}
              <section>
                <button
                  type="button"
                  id="continueShopping"
                  onClick={desktopModalClose}
                  className="mt-0 h-11 w-full rounded border border-sol-500  py-3 px-4 text-sm font-bold text-sol-300 focus:outline-offset-2"
                >
                  {mapLocaleToMeaningfulFormat(router.locale).continueShopping}
                </button>
              </section>
            </>
          ) : (
            <>
              {isEmpty ? (
                <section>
                  <Link href={routerURL?.cart}>
                    <a>
                      <button
                        type="button"
                        id="viewCart"
                        disabled
                        onClick={() => {
                          gtmViewCart(cart, account);
                          desktopModalClose();
                        }}
                        className="h-11 w-full cursor-not-allowed rounded bg-sol-500 py-3 px-4 text-sm font-bold text-white opacity-40 focus:outline-offset-2"
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).viewCartAndCheckout}
                      </button>
                    </a>
                  </Link>
                </section>
              ) : (
                <section>
                  <Link href={routerURL?.cart}>
                    <a>
                      <button
                        type="button"
                        id="viewCart"
                        onClick={() => {
                          gtmViewCart(cart, account);
                          mobileModalClose();
                        }}
                        className="h-11 w-full rounded bg-sol-500 px-5 py-2 text-sm font-bold text-white focus:outline-offset-2 "
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).viewCartAndCheckout}
                      </button>
                    </a>
                  </Link>
                </section>
              )}

              <section>
                <Link href={routerURL?.home}>
                  <a>
                    <button
                      type="button"
                      id="continueShopping"
                      onClick={mobileModalClose}
                      className="mt-4 h-11 w-full rounded border border-sol-500 bg-white px-5 py-2 text-sm font-bold text-sol-300 focus:outline-offset-2"
                    >
                      {mapLocaleToMeaningfulFormat(router.locale).continueShopping}
                    </button>
                  </a>
                </Link>
              </section>
            </>
          )}
        </section>
      </section>
    </>
  );
};
