import { useAccount } from 'frontastic/provider';
import React, { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import Redirect from 'helpers/redirect';

const ImpersonationTastic = () => {
  const { impersonateUser } = useAccount();
  const router = useRouter();
  const tmpImpersonateUser = useCallback(async () => {
    await impersonateUser(router.query['login'] as string);
  }, [impersonateUser]);

  useEffect(() => {
    tmpImpersonateUser();
  }, [tmpImpersonateUser]);
  return <Redirect target="/account" />;
};

export default ImpersonationTastic;
