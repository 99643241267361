import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Card } from '@Types/account/AuthorizeNetAccount';
import { StringHelpers } from 'helpers/stringHelpers';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import CardIcon from '../../credit-card/card-icon';
interface CardProps {
  card: Card;
}
function Card({ card }: CardProps) {
  const router = useRouter();
  return (
    <>
      {' '}
      {card ? (
        <>
          <div className="mt-5 flex lg:mt-0">
            <div>
              {' '}
              <CardIcon cardType={card?.cardType || 'default'} />
            </div>
            <div className="ml-5">
              {' '}
              <section>
                <p className="text-sm font-normal text-sol-300">
                  {StringHelpers.creditCardNumberFormatter(card?.cardNumber, router)}
                </p>
              </section>
              <section>
                <p className="mt-1 mb-4 text-sm font-normal text-sol-300">
                  {StringHelpers.expiryDateFormatter(card?.expirationDate, router)}
                </p>
              </section>
            </div>
          </div>
        </>
      ) : (
        <p className="text-sm text-sol-300">{mapLocaleToMeaningfulFormat(router.locale).noSavedCreditCard}</p>
      )}
    </>
  );
}

export default Card;
