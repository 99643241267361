import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { routerURL } from 'helpers/utils/routerUrl';

interface relatedProductProps {
  productName?: string;
  altText?: string;
  imageSrc?: string;
  dataLink?: string;
}

function RelatedProduct({ productName, altText, imageSrc, dataLink }: relatedProductProps) {
  return (
    <>
      <section>
        <a href={imageSrc}>
          <Image
            className="w-full rounded-md object-cover"
            loader={(options) => options.src}
            width={120}
            height={120}
            src={imageSrc}
            alt={altText}
            sizes="100vw"
          />
        </a>

        <section className="mt-[6px]">
          <Link href={dataLink}>
            <a tabIndex={0} className="mt-4 block pr-4 text-sm font-normal text-sol-300">
              {productName}
            </a>
          </Link>
        </section>
      </section>
    </>
  );
}

export default RelatedProduct;
