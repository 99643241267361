import React, { useEffect } from 'react';
declare global {
  interface Window {
    Trustpilot: any;
  }
}
const TrustBox = ({ id }) => {
  // Hardcoded Couldn't find any product on stage, added for testing
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // When it is, it will automatically load the TrustBox.
    if (window && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [id]); // adding this dependency to update the response for each variantselection

  if (!id) {
    return <></>;
  }
  return (
    // will make it dynamic coming from backend
    <div
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      className="trustpilot-widget" // Renamed this to className.
      data-locale="en-US"
      data-template-id="544a426205dc0a09088833c6"
      data-businessunit-id="5b65a26a4a7a1a00011daaa0"
      data-style-height="450"
      data-style-width="100%"
      data-theme="light"
      data-sku={id}
      data-review-languages="en"
      data-no-reviews="show"
      data-fullwidth="true"
    >
      <a href="https://www.trustpilot.com/review/www.shadesoflight.com" target="_blank" rel="noopener noreferrer">
        {' '}
        Trustpilot
      </a>
    </div>
  );
};
export default TrustBox;
