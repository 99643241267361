import { Fragment, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { useDarkMode } from 'frontastic';
import CloseIcon from '../../../icons/sol/close';
import { amplienceImageLoader } from 'helpers/utils/amplienceImageLoader';
type actions = 'setAsDefault' | 'delete' | 'removeLineItem';

export interface ImageModalProps {
  open?: boolean;
  onClose?: () => void;
  alt?: string;
  src?: string;
}

const ImageModal: React.FC<ImageModalProps> = ({ open, onClose, alt, src }) => {
  //next/router
  const router = useRouter();
  //Darkmode
  const { mode, theme } = useDarkMode();
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className={`${mode} ${theme} default fixed inset-0 z-[100] overflow-y-auto`} onClose={onClose}>
        <>
          <section className="flex items-end justify-center px-4 pt-4 pb-20 text-left sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <section className="absolute inset-0" onClick={onClose}>
                {/* eslint-disable */}
                <section
                  className="customtablescrollbar absolute top-1/2 left-1/2 max-h-[100vh] w-[848px] -translate-x-1/2 -translate-y-1/2 overflow-auto overflow-y-auto bg-white py-6 px-6 dark:bg-primary-200"
                  onClick={(e) => e.stopPropagation()}
                >
                  <section className="mb-3 flex justify-end">
                    <section>
                      <button type="button" onClick={onClose} id="modalClose">
                        <CloseIcon className="fill-sol-300 " />
                      </button>
                    </section>
                  </section>
                  <section className="aspect-video">
                    <Image
                      loader={amplienceImageLoader}
                      width={800}
                      height={800}
                      src={src}
                      alt={alt}
                      className="w-full rounded-[6px] object-center"
                      sizes="100vw"
                      priority={true}
                    />
                  </section>
                </section>
              </section>
            </Transition.Child>
          </section>
        </>
      </Dialog>
    </Transition.Root>
  );
};

export default ImageModal;
