import React from 'react';
import { LineItem } from '@Types/wishlist/LineItem';
import { useCart, useWishlist } from 'frontastic/provider';
import { Variant } from '@Types/wishlist/Variant';
import MyList from 'components/sol-ui/myList';
const WishlistTastic = ({ data }) => {
  const { data: wishlist, removeLineItem } = useWishlist();
  const { addItem } = useCart();

  const handleAddToCart = (variant: Variant): Promise<void> => {
    return addItem(variant, 1);
  };

  const removeLineItems = async (item: LineItem) => {
    await removeLineItem(item.lineItemId);
  };

  return <MyList />;
};

export default WishlistTastic;
