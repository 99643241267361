import React, { useState } from 'react';
import { useRouter } from 'next/router';
import CheckoutTick from 'components/icons/checkout-tick';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { desktop } from 'helpers/utils/screensizes';
function ProgressIndicator({ activeStep, setActiveStep }) {
  const [isDesktop] = useMediaQuery(desktop);
  const router = useRouter();
  const handleStepChange = (step) => {
    setActiveStep(step?.stepNumber);
    router.push(step?.path);
  };

  const steps = [
    {
      stepNumber: 1,
      text: `${mapLocaleToMeaningfulFormat(router.locale).addresses}`,
      path: routerURL?.checkoutAddress,
    },
    {
      stepNumber: 2,
      text: `${mapLocaleToMeaningfulFormat(router.locale).payment}`,
      path: routerURL?.checkoutPayment,
    },
    {
      stepNumber: 3,
      text: `${mapLocaleToMeaningfulFormat(router.locale).reviewAndOrder}`,
      path: routerURL?.checkoutReview,
    },
  ];

  return (
    <section>
      {isDesktop ? (
        <ul
          data-te-stepper-init
          className="checkoutProgressBar relative m-0 mx-auto mt-5 flex w-[556px] list-none items-center justify-between overflow-visible p-0 pl-14 text-center transition-[height] duration-200 ease-in-out"
        >
          {steps.map((step, stepNumber) => (
            <li
              key={stepNumber}
              data-te-stepper-step-ref
              data-te-stepper-step-active={activeStep === step?.stepNumber}
              className={`w-1/3 flex-auto  ${activeStep === step?.stepNumber ? 'text-black-500' : 'text-gray-400'}`}
              onClick={() => (activeStep > step.stepNumber ? handleStepChange(step) : undefined)}
            >
              <section
                data-te-stepper-head-ref
                className={`progressBarSection  flex cursor-pointer items-center justify-center leading-[1.3rem] no-underline ${
                  stepNumber < steps?.length - 1
                    ? 'after:h-px after:w-full after:flex-1 after:bg-sol-600 after:content-[""]'
                    : ''
                } focus:outline-none dark:after:bg-sol-600`}
              >
                <section className="relative">
                  <p
                    data-te-stepper-head-icon-ref
                    className="my-2 flex h-[1.938rem] w-[1.938rem] items-center justify-center rounded-full border border-sol-600 text-sm font-medium text-sol-300"
                  >
                    {activeStep <= step?.stepNumber ? step?.stepNumber : <CheckoutTick />}
                  </p>
                  <button
                    type="button"
                    id={step?.text}
                    data-te-stepper-head-text-ref
                    tabIndex={activeStep > step?.stepNumber ? 0 : -1}
                    className="trans progressBarWidth absolute top-12 bottom-0 left-1/2 -translate-x-1/2 -translate-y-2 cursor-pointer whitespace-nowrap text-left font-medium after:flex after:text-[0.8rem] after:content-[data-content]"
                  >
                    {step?.text}
                  </button>
                </section>
              </section>
            </li>
          ))}
        </ul>
      ) : (
        <section className="lg:hidden">
          <section className="flex items-center justify-center">
            <p className="text-sm font-semibold text-sol-600">
              {`${mapLocaleToMeaningfulFormat(router.locale).step} ${activeStep}/${steps?.length}`}
            </p>
          </section>
          <section className="mt-2 text-center text-sm font-semibold text-sol-300">
            {steps[activeStep - 1].text}
          </section>
        </section>
      )}
    </section>
  );
}
export default ProgressIndicator;
