import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="191"
    height="54"
    viewBox="0 0 191 54"
    fill="none"
    className={className}
  >
    <path
      d="M0 51.9956L1.59817 50.0786C2.70664 50.9935 3.86446 51.5705 5.27283 51.5705C6.3813 51.5705 7.04942 51.1301 7.04942 50.4089V50.3747C7.04942 49.6876 6.62805 49.3383 4.57055 48.8107C2.09167 48.1767 0.493497 47.4934 0.493497 45.0487V45.0183C0.493497 42.7862 2.28527 41.3095 4.79831 41.3095C6.59009 41.3095 8.11993 41.8714 9.36886 42.8735L7.96429 44.9121C6.8748 44.1566 5.80049 43.7011 4.76415 43.7011C3.7278 43.7011 3.18116 44.1756 3.18116 44.7716V44.8058C3.18116 45.6143 3.70882 45.8763 5.83466 46.4229C8.33251 47.0721 9.73708 47.968 9.73708 50.1128V50.1469C9.73708 52.5916 7.87318 53.962 5.21968 53.962C3.35578 53.962 1.4767 53.3129 0 51.9956Z"
      fill="#B19A6A"
    />
    <path
      d="M13.8521 41.4841H16.5587V46.3546H21.5506V41.4841H24.2573V53.7874H21.5506V48.8486H16.5587V53.7874H13.8521V41.4841Z"
      fill="#B19A6A"
    />
    <path
      d="M33.1895 41.3969H35.6835L40.9563 53.7874H38.1244L37.0008 51.0276H31.8001L30.6764 53.7874H27.9166L33.1895 41.3969ZM36.0366 48.6361L34.4004 44.6463L32.7643 48.6361H36.0366Z"
      fill="#B19A6A"
    />
    <path
      d="M44.616 41.4841H49.4143C53.2825 41.4841 55.9512 44.1376 55.9512 47.5997V47.6339C55.9512 51.0959 53.2787 53.7874 49.4143 53.7874H44.616V41.4841ZM47.3226 43.925V51.3427H49.4143C51.6274 51.3427 53.1231 49.8508 53.1231 47.668V47.6339C53.1231 45.4549 51.6312 43.925 49.4143 43.925H47.3226Z"
      fill="#B19A6A"
    />
    <path
      d="M60.1234 41.4841H69.405V43.8909H62.8149V46.3849H68.6154V48.7917H62.8149V51.3769H69.4961V53.7836H60.1272V41.4841H60.1234Z"
      fill="#B19A6A"
    />
    <path
      d="M72.8134 51.9956L74.4115 50.0786C75.52 50.9935 76.6778 51.5705 78.0862 51.5705C79.1947 51.5705 79.8628 51.1301 79.8628 50.4089V50.3747C79.8628 49.6876 79.4414 49.3383 77.3839 48.8107C74.905 48.1767 73.3069 47.4934 73.3069 45.0487V45.0183C73.3069 42.7862 75.0986 41.3095 77.6117 41.3095C79.4034 41.3095 80.9333 41.8714 82.1822 42.8735L80.7776 44.9121C79.6882 44.1566 78.6138 43.7011 77.5775 43.7011C76.5412 43.7011 75.9945 44.1756 75.9945 44.7716V44.8058C75.9945 45.6143 76.5222 45.8763 78.648 46.4229C81.1459 47.0721 82.5504 47.968 82.5504 50.1128V50.1469C82.5504 52.5916 80.6865 53.962 78.033 53.962C76.1729 53.962 74.2901 53.3129 72.8134 51.9956Z"
      fill="#B19A6A"
    />
    <path
      d="M93.153 47.6718V47.6377C93.153 44.1414 95.9127 41.2754 99.7089 41.2754C103.505 41.2754 106.231 44.1073 106.231 47.6035V47.6377C106.231 51.1339 103.471 54 99.6747 54C95.8786 53.9962 93.153 51.1681 93.153 47.6718ZM103.399 47.6718V47.6377C103.399 45.527 101.854 43.7694 99.6709 43.7694C97.4919 43.7694 95.9811 45.4929 95.9811 47.5997V47.6339C95.9811 49.7445 97.5261 51.5021 99.7089 51.5021C101.892 51.5021 103.399 49.7787 103.399 47.6718Z"
      fill="#B19A6A"
    />
    <path
      d="M110.399 41.4841H119.768V43.944H113.106V46.5634H118.975V49.0232H113.106V53.7874H110.399V41.4841Z"
      fill="#B19A6A"
    />
    <path d="M130.724 41.4841H133.43V51.3275H139.565V53.7874H130.724V41.4841Z" fill="#B19A6A" />
    <path d="M143.486 41.4841H146.193V53.7874H143.486V41.4841Z" fill="#B19A6A" />
    <path
      d="M150.483 47.6718V47.6377C150.483 44.1414 153.208 41.2754 156.932 41.2754C159.149 41.2754 160.482 41.8713 161.769 42.9608L160.064 45.0183C159.115 44.2288 158.272 43.7694 156.849 43.7694C154.882 43.7694 153.315 45.508 153.315 47.5997V47.6339C153.315 49.885 154.86 51.5363 157.042 51.5363C158.026 51.5363 158.906 51.2896 159.593 50.7999V49.0423H157.991V46.7038H162.213V52.045C160.945 53.1155 159.206 53.9962 156.955 53.9962C153.117 53.9962 150.483 51.3085 150.483 47.6718Z"
      fill="#B19A6A"
    />
    <path
      d="M166.567 41.4841H169.273V46.3546H174.265V41.4841H176.972V53.7874H174.265V48.8486H169.273V53.7874H166.567V41.4841Z"
      fill="#B19A6A"
    />
    <path d="M184.549 43.9782H180.806V41.4803H190.999V43.9782H187.256V53.7874H184.549V43.9782Z" fill="#B19A6A" />
    <path d="M103.748 16.9687L95.4992 0L87.2502 16.9687L95.4992 25.0507L103.748 16.9687Z" fill="#B19A6A" />
    <path d="M74.8369 4.80209V29.1809H81.3131L87.2503 16.9687L74.8369 4.80209Z" fill="#1B3144" />
    <path d="M103.749 16.9687L109.689 29.1809H116.166V4.80209L103.749 16.9687Z" fill="#FFA892" />
    <path d="M95.4993 25.0507L99.713 29.1809H109.689L103.748 16.9687L95.4993 25.0507Z" fill="#AB6339" />
    <path d="M95.499 25.0507L87.25 16.9687L81.3129 29.1809H91.2853H99.7127L95.499 25.0507Z" fill="black" />
  </svg>
);

export default Icon;
