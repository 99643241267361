import React, { useEffect } from 'react';
import Categories from 'components/sol-ui/categories';
import StaticPage from 'components/sol-ui/pages/static-page';
import { useRouter } from 'next/router';

const SOLCategoriesTastic = (prop) => {
  const router = useRouter();
  let categoryContent;
  let staticPageContent;
  let categoryList;

  if (prop?.pageFolder?.dataSourceConfigurations) {
    prop?.pageFolder?.dataSourceConfigurations.forEach((dataSourceConfiguration) => {
      if (dataSourceConfiguration?.type === 'frontastic/empty') {
        if (dataSourceConfiguration?.preloadedValue) {
          const preloadedValue = Array.isArray(dataSourceConfiguration?.preloadedValue)
            ? dataSourceConfiguration?.preloadedValue[0]
            : dataSourceConfiguration?.preloadedValue;
          categoryContent = preloadedValue?.content?.allCategorySchema?.edges;
          staticPageContent = preloadedValue?.content?.allStaticPageSchema?.edges;
          categoryList = dataSourceConfiguration?.preloadedValue[1];
        }
      }
    });
  }
  useEffect(() => {
    if (categoryContent?.length == 0 && staticPageContent?.length == 0) {
      router.push('/not-found');
    }
  }, []);

  return (
    <>
      {categoryContent?.length !== 0 && <Categories content={categoryContent[0]?.node} />}
      {staticPageContent?.length !== 0 && (
        <StaticPage content={staticPageContent[0]?.node} categoryList={categoryList} />
      )}
    </>
  );
};

export default SOLCategoriesTastic;
