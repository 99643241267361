import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none" className={className}>
    <path
      d="M1.75 14C1.26875 14 0.856771 13.8286 0.514063 13.4859C0.171354 13.1432 0 12.7312 0 12.25V1.75C0 1.26875 0.171354 0.856771 0.514063 0.514063C0.856771 0.171354 1.26875 0 1.75 0H15.75C16.2313 0 16.6432 0.171354 16.9859 0.514063C17.3286 0.856771 17.5 1.26875 17.5 1.75V12.25C17.5 12.7312 17.3286 13.1432 16.9859 13.4859C16.6432 13.8286 16.2313 14 15.75 14H1.75ZM8.75 7.875L1.75 3.5V12.25H15.75V3.5L8.75 7.875ZM8.75 6.125L15.75 1.75H1.75L8.75 6.125ZM1.75 3.5V1.75V12.25V3.5Z"
      fill="#1B3144"
    />
  </svg>
);

export default Icon;
