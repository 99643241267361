import React, { memo, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useClearRefinements, useCurrentRefinements } from 'react-instantsearch';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import CloseIcon from '../../../icons/sol/close';

const RefinementListItems = memo(function RefinementListItems(props: any) {
  const { title, attributeValue, refinement, removeRefinement } = props;

  return (
    <li className="mx-1 shrink-0" key={title}>
      <button
        onClick={() => removeRefinement(refinement)}
        type="button"
        className="flex h-6 items-center justify-between rounded-[20px] bg-sol-100 px-[10px] text-sm font-normal leading-[17px] text-sol-300"
      >
        {attributeValue}
        <CloseIcon className="mt-1 ml-3 h-2 w-2 shrink-0 fill-sol-300" />
      </button>
    </li>
  );
});

const CustomClearRefinements = memo(function CustomClearRefinements(props: any) {
  const { canRefine, removeAllRefinements } = props;
  const router = useRouter();

  return (
    <>
      <li className="clearAllBtn mx-1 flex h-6 shrink-0 items-center justify-between px-[10px] text-sm font-normal leading-[17px] text-sol-300 underline">
        <button
          disabled={!canRefine}
          onClick={() => {
            removeAllRefinements();
          }}
          type="button"
        >
          {mapLocaleToMeaningfulFormat(router.locale).clearAll}
        </button>
      </li>
    </>
  );
});

const TrailDisplayer = memo(function TrailDisplayer({ title, attribute, refinement, removeRefinement }: any) {
  return (
    <RefinementListItems
      title={title}
      attributeValue={attribute}
      refinement={refinement}
      removeRefinement={removeRefinement}
    />
  );
});

const FilterTrail = memo(function FilterTrail({ title, facetsDisplay }: any) {
  const router = useRouter();
  const faceDisplayTitle = (defaultTitle, key) => {
    const facetData = facetsDisplay && facetsDisplay?.find((item) => item?.facetKey == key);
    if (facetData) {
      return facetData?.facetName?.includes('${title}')
        ? facetData?.facetName?.replace('${title}', title)
        : facetData?.facetName;
    }
    return defaultTitle;
  };

  const { items, refine } = useCurrentRefinements();
  const { refine: clearAllRefinements, canRefine } = useClearRefinements();

  const removeRefinement = useCallback(
    (item) => {
      refine(item);
    },
    [refine],
  );

  const removeAllRefinements = useCallback(() => {
    clearAllRefinements();
  }, [clearAllRefinements]);

  return (
    <>
      {items?.length ? (
        <section className="pb-10 lg:pb-0">
          <ul className="mt-3 flex flex-wrap gap-x-3 gap-y-4 md:gap-x-5">
            {
              <li className="shrink-0">
                <p>{mapLocaleToMeaningfulFormat(router.locale).filters}</p>
              </li>
            }
            {items.map((item) =>
              item.refinements.map((filter) => (
                <TrailDisplayer
                  key={filter?.value}
                  title={`${faceDisplayTitle(item?.label, filter?.attribute)}`}
                  attribute={filter?.value}
                  refinement={filter}
                  removeRefinement={removeRefinement}
                />
              )),
            )}

            <CustomClearRefinements removeAllRefinements={removeAllRefinements} canRefine={canRefine} />
          </ul>
        </section>
      ) : (
        <></>
      )}
    </>
  );
});

export default FilterTrail;
