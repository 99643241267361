import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Order } from '@Types/cart/Order';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { DateHelpers } from 'helpers/dateHelpers';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { AMPLIENCE_IMAGE_URL_FORMAT } from 'helpers/utils/constant';
import format from 'helpers/utils/format';
import calculateDynamicImageWidth from 'helpers/utils/getImageSize';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { itemCount } from 'helpers/utils/itemCount';
import omitFileExtension from 'helpers/utils/omitFileExtension';
import { routerURL } from 'helpers/utils/routerUrl';
import { desktop } from 'helpers/utils/screensizes';
import { orderHistory } from 'frontastic/actions/cart';
import Image from 'frontastic/lib/image';
interface RecentOrderProps {
  order?: Order;
  emptyOrder: boolean;
  isRecentOrder?: boolean;
}

function RecentOrder({ order, isRecentOrder = false }: RecentOrderProps) {
  const router = useRouter();
  const [isDesktop] = useMediaQuery(desktop);
  const dynamicWidth = calculateDynamicImageWidth(100, 100, 100);
  const [lastOrder, setlastOrder] = useState<any | null>(null);

  useEffect(() => {
    const getOrders = async () => {
      const response = await orderHistory();
      const tempOrders = response?.slice()?.sort((a, b) => b?.createdAt?.localeCompare(a?.createdAt));
      const firstSortedOrder = tempOrders?.[0];
      if (isRecentOrder) {
        setlastOrder(firstSortedOrder);
      } else {
        setlastOrder(order);
      }
    };
    getOrders();
  }, [RecentOrder]);
  return (
    <>
      {lastOrder?.orderNumber && lastOrder?.lineItems?.length > 0 ? (
        <section>
          <section className={`${isDesktop ? '' : 'pt-4'} text-sm text-sol-300 md:flex`}>
            <section className="flex flex-row  flex-wrap gap-y-2">
              {`${
                isDesktop
                  ? mapLocaleToMeaningfulFormat(router.locale).orderNumber
                  : mapLocaleToMeaningfulFormat(router.locale).orderNo
              }`}
              {':'}
              <Link href={`${routerURL?.orderDetails}/${lastOrder?.orderNumber}`}>
                <a className="cursor-pointer pr-1 pl-2 uppercase underline underline-offset-2 sm:pr-4">{`${
                  lastOrder?.orderNumber || '#'
                }`}</a>
              </Link>

              <em className="pr-1 not-italic sm:pr-4">&#124;</em>
              <p className="lg:pr-4">{`${
                mapLocaleToMeaningfulFormat(router.locale).orderDate
              }${':'} ${''}${DateHelpers.solFormatDate(lastOrder?.createdAt, router)}`}</p>
              {isDesktop && <em className="pr-4 not-italic">&#124;</em>}
            </section>
            <section className="mt-3 flex flex-row flex-wrap gap-y-2 md:mt-0 md:pl-4 lg:pl-4 xl:pl-0">
              <p className="pr-1 sm:pr-4">{`${mapLocaleToMeaningfulFormat(router.locale).status}${':'} ${''} ${
                lastOrder?.orderState
              }`}</p>

              <em className="pr-1 not-italic sm:pr-4">&#124;</em>

              <p className="lg:pr-4">{`${
                mapLocaleToMeaningfulFormat(router.locale).orderTotal
              }${':'} ${''} ${CurrencyHelpers.formatForCurrency(lastOrder?.total)}`}</p>
            </section>
          </section>
          <ul className="mt-4 flex flex-wrap gap-2">
            {lastOrder?.lineItems?.slice(0, isDesktop ? 4 : 3)?.map((lineItem, index) => (
              <li key={index} className="w-[70px] shrink-0 sm:w-[75px] md:w-[80px]">
                <Link href={`${routerURL?.orderDetails}/${lastOrder?.orderNumber}`}>
                  <a>
                    <Image
                      src={
                        format(AMPLIENCE_IMAGE_URL_FORMAT, [
                          omitFileExtension(lineItem?.variant?.images[0]?.url),
                          dynamicWidth,
                        ]) || '#'
                      }
                      alt={lineItem?.name}
                      height={80}
                      width={80}
                      sizes="100vw"
                      className="rounded-md"
                    />
                  </a>
                </Link>
              </li>
            ))}
            {lastOrder?.lineItems?.length > 4 && (
              <li className="h-[70px] w-[70px]  shrink-0 rounded-md bg-gray-200 sm:h-[75px] sm:w-[75px] md:h-[80px] md:w-[80px]">
                <Link href={`${routerURL?.orderDetails}/${lastOrder?.orderNumber}`}>
                  <a className="flex h-full w-full cursor-pointer items-center justify-center font-normal">
                    {itemCount(isDesktop, lastOrder?.lineItems?.length)}{' '}
                    {mapLocaleToMeaningfulFormat(router.locale).more}
                  </a>
                </Link>
              </li>
            )}
          </ul>
        </section>
      ) : (
        <p className="text-sm text-sol-300">{mapLocaleToMeaningfulFormat(router.locale).recentOrderEmptyMessage}</p>
      )}
    </>
  );
}

export default RecentOrder;
