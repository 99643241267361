import { IS_GUEST_USER, PAYMENT_PROFILE, PAYMENT_TOKEN } from 'helpers/constants/localStorage';

const useLocalStorage = () => {
  const isGuestCheck = () => {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem(IS_GUEST_USER) === '1';
    }
    return false;
  };
  const setAsGuest = () => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(IS_GUEST_USER, '1');
    }
  };
  const removeAsGuest = () => {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(IS_GUEST_USER);
      localStorage.removeItem(PAYMENT_TOKEN);
      localStorage.removeItem(PAYMENT_PROFILE);
    }
  };
  const setItem = (key, value) => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(key, value);
    }
  };
  const getItem = (key) => {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem(key);
    }
  };
  const removeItem = (key) => {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(key);
    }
  };
  const clearForm = (key) => {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(PAYMENT_PROFILE);
      localStorage.removeItem(PAYMENT_TOKEN);
    }
  };
  return {
    isGuestUser: isGuestCheck(),
    setAsGuest,
    removeAsGuest,
    setItem,
    getItem,
    removeItem,
  };
};

export default useLocalStorage;
