import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';

export const FourColumnCategory = ({ contents }) => {
  const [isDesktop] = useMediaQuery(desktop);

  return (
    <section className="mx-auto mb-12 max-w-screen-8xl">
      <ul className="grid grid-cols-2 gap-5 lg:grid-cols-4">
        {contents?.map((content, index) => (
          <li key={index}>
            <Link href={content?.clpCtaLink}>
              <a className="flex h-full w-full">
                <section className="h-full w-full bg-sol-100">
                  <section>
                    <Image
                      loader={(options) => options.src}
                      width={324}
                      height={200}
                      src={content?.image?.url}
                      alt={content?.clpAlt}
                      sizes="100vw"
                      className="rounded-t-[6px]"
                    />
                  </section>
                  <section className="bg-sol-100 p-4">
                    <button type="button" className="w-full text-center text-base font-semibold leading-5 text-sol-300">
                      {content?.clpTitle}
                    </button>
                  </section>
                </section>
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};
