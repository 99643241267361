import { CATEGORY } from './constant';

export const buildLinkProductVariant = (basePath, productSlug, color) => {
  return basePath + '/products/' + productSlug + '?color=' + encodeURIComponent(color);
};

export const buildLinkProductVariantWithSize = (basePath, productSlug, color, size) => {
  return basePath + '/products/' + productSlug + '?color=' + encodeURIComponent(color) + '&size=' + size;
};

export const buildPLPFacets = (currentRoute: string, urlCategory: string, isClearanceCategory: boolean) => {
  let facets = 'shownOnSite:true ';
  if (currentRoute === CATEGORY && urlCategory) {
    facets += `AND categories.en-US.slug:${urlCategory} `;
  }
  if (isClearanceCategory) {
    facets += 'AND originalPrice > 0 ';
  }
  return facets;
};
