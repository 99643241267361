import React, { Fragment, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Listbox, Transition } from '@headlessui/react';
import { Account } from '@Types/account/Account';
import { Address as AddressType } from '@Types/account/Address';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { ADDRESS_CREATED, ADDRESS_NOT_VALID, SERVER_ERROR } from 'helpers/constants/messages';
import { useNotification } from 'helpers/notification/notificationContext';
import { AddressHelpers } from 'helpers/utils/addressHelpers';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { useAccount } from 'frontastic';
import AddNewAddressModal from '../../account/modal/edit-profile';
interface AddressSelectProps {
  addresses: AddressType[];
  setSelectedAddress: (address: AddressType) => void;
  isShippingChanged: boolean;
  setIsShippingChanged: (bool: boolean) => void;
  selectedAddress: AddressType;
  addAddress: (address: AddressType) => Promise<Account>;
  getCustomerAddresses?: any;
  isAddressesLoading: any;
  isBillingAddress: boolean;
}

const AddressSelect: React.FC<AddressSelectProps> = ({
  addresses,
  selectedAddress,
  setSelectedAddress,
  isShippingChanged,
  setIsShippingChanged,
  addAddress,
  getCustomerAddresses,
  isAddressesLoading,
  isBillingAddress,
}) => {
  const router = useRouter();
  const { validateAddress } = useAccount();

  //global notification
  const { showNotification } = useNotification();
  const [filterArrow, setFilterArrow] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  useEffect(() => {
    setCreateModalOpen(false);
  }, []);

  useEffect(() => {
    setCreateModalOpen(addresses.length === 0 && !isAddressesLoading);
  }, [addresses, isAddressesLoading]);

  useEffect(() => {
    if (!createModalOpen) {
      setTimeout(() => {
        document?.getElementsByTagName('html')[0]?.style?.removeProperty('overflow');
      }, 500);
    }
  }, [createModalOpen]);

  const openCreateModal = () => {
    setCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalOpen(false);
  };

  const handleShareListArrow = () => {
    setFilterArrow(!filterArrow);
  };

  const handleAddAddress = async (address: AddressType, isDefault: boolean) => {
    const newAddress: AddressType = address;
    newAddress.firstName = newAddress.firstName.trim();
    newAddress.lastName = newAddress.lastName.trim();
    if (addresses?.length === 0) {
      isDefault = true;
    }
    const validate = await validateAddress(newAddress);
    if (!validate.isValid) {
      showNotification(ADDRESS_NOT_VALID, 'error');
      return;
    }
    newAddress.isDefaultBillingAddress = isDefault;
    newAddress.isDefaultShippingAddress = isDefault;
    try {
      const addressResult = await addAddress(newAddress);

      //start to show notification
      if (addressResult.addresses) {
        showNotification(ADDRESS_CREATED, 'success');
        getCustomerAddresses();
      }
    } catch (error) {
      showNotification(SERVER_ERROR, 'error');
    }
    closeCreateModal();
  };
  const filteredAddresses = addresses?.filter((address) => address?.country !== 'CA' || isBillingAddress);
  return (
    <section className="mt-5">
      {addresses?.length > 0 && (
        <>
          {
            <>
              <Listbox value={selectedAddress} onChange={setSelectedAddress}>
                <section className="relative">
                  <Listbox.Button className="relative w-full cursor-default rounded border bg-white py-[10px] pl-3 pr-24 text-left md:w-[400px]">
                    <em className="block truncate text-sm not-italic text-sol-300">
                      {selectedAddress
                        ? AddressHelpers.formatAddress(selectedAddress, false)
                        : mapLocaleToMeaningfulFormat(router.locale).select}
                    </em>
                    <em className="absolute inset-y-0 right-0 flex items-center not-italic">
                      {!filterArrow ? (
                        <section className="my-auto mr-3">
                          <MdOutlineKeyboardArrowDown
                            onClick={handleShareListArrow}
                            className="text-2xl text-sol-300"
                          />
                        </section>
                      ) : (
                        <section className="my-auto mr-3">
                          <MdOutlineKeyboardArrowUp onClick={handleShareListArrow} className="text-2xl text-sol-300" />
                        </section>
                      )}
                    </em>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="md:ring-black absolute max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base text-sol-300 shadow-lg ring-1 ring-opacity-5 focus:outline-none  sm:text-sm md:w-[400px]">
                      {filteredAddresses.map((address, key) => (
                        <Listbox.Option
                          key={key}
                          className={({ active }) =>
                            `relative cursor-default select-none  ${active && 'bg-gray-200 text-sol-300'}`
                          }
                          value={address}
                        >
                          {({ active, selected }) => (
                            <section
                              className={`${
                                active ? 'text-base text-sol-300 sm:text-sm' : 'text-gray-900'
                              } relative cursor-default select-none py-2 pl-3 pr-9`}
                            >
                              <em
                                className={`${
                                  selected ? 'font-medium not-italic' : 'font-normal not-italic'
                                } block truncate not-italic`}
                              >
                                <section
                                  onClick={() => setIsShippingChanged(!isShippingChanged)}
                                  dangerouslySetInnerHTML={{
                                    __html: `${AddressHelpers.formatAddress(address, false)}`,
                                  }}
                                ></section>
                              </em>
                            </section>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </section>
              </Listbox>
              <section className="mt-4 text-sm text-sol-300">
                <section
                  dangerouslySetInnerHTML={{
                    __html: `${AddressHelpers.formatAddress(selectedAddress, true)}`,
                  }}
                ></section>
              </section>
              <p className="mt-3">{mapLocaleToMeaningfulFormat(router.locale).or}</p>
            </>
          }
        </>
      )}

      <button
        type="button"
        id="addAddress"
        onClick={openCreateModal}
        className="my-2 text-sm underline underline-offset-1"
      >
        {mapLocaleToMeaningfulFormat(router.locale).addNewAddress}
      </button>
      <AddNewAddressModal
        open={createModalOpen}
        onClose={closeCreateModal}
        modalHeading={mapLocaleToMeaningfulFormat(router.locale).addNewAddress}
        primaryBtnContent={mapLocaleToMeaningfulFormat(router.locale).save}
        handleAddAddress={handleAddAddress}
        loading={false}
        isBillingAddress={isBillingAddress}
      />
    </section>
  );
};

export default AddressSelect;
