import React, { useEffect, useState } from 'react';
import { connectHits } from 'react-instantsearch-dom';
import PLPRichText from 'components/seo/plpRichText';
import ProductListCard from '../../product-list/product-list-card';
type Props = {
  variantName: any;
  setIsData: any;
  setIsShowViewAll?: any;
};
const ProductListItemBlock = ({ variantName, setIsData, setIsShowViewAll }: Props) => {
  const Hits = ({ hits }) => {
    const [data, setdata] = useState(hits?.filter((hit: any) => hit?.name['en-US'] !== variantName) || []);

    useEffect(() => {
      if (data && data?.length < 4 && setIsShowViewAll) {
        setIsShowViewAll(false);
      } else if (setIsShowViewAll) {
        setIsShowViewAll(true);
      }
      if (data.length == 5) {
        setdata(data.slice(0, 4));
      }
      if (hits.length != 0) {
        setIsData(true);
      } else {
        setIsData(false);
      }
    }, [hits, data]);

    return (
      <>
        <section className="w-full">
          <PLPRichText hits={hits} />
          <ul className="grid w-full grid-cols-2 gap-x-5 gap-y-[30px] md:gap-x-[27px] lg:grid-cols-4">
            {data.map((hit, index) => (
              <ProductListCard data={hit} key={index} />
            ))}
          </ul>
        </section>
      </>
    );
  };

  const CustomHits = connectHits(Hits);

  return (
    <>
      <CustomHits />
    </>
  );
};

export default ProductListItemBlock;
