import React from 'react';
import ThankYou from 'components/sol-ui/checkout/thank-you';

const ThankYouTastic = ({ data }) => {
  const order = data?.orderData?.dataSource?.order ?? undefined;
  const sourceCodes = data?.orderData?.dataSource?.sourceCodes ?? [];
  const { modelShowingTime } = data;

  return <ThankYou order={order} modelShowingTime={modelShowingTime} sources={JSON.parse(sourceCodes)} />;
};

export default ThankYouTastic;
