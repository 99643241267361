import React, { useState } from 'react';
import { TransactionRequest } from '@Types/payment';
import Accept from 'helpers/utils/authorize-net';
import { useAuthorizeNet, useCart } from 'frontastic';

const Checkout = () => {
  const [state, setState] = useState({
    cardCode: '',
    cardNumber: '',
    expDate: '',
  });

  //const { makePayment } = useAuthorizeNet();
  // todo - when creditcard inplementation
  //const { clientKey, apiLoginKey } = useConfig()?.data?.authorizeNet;

  const { data: cart } = useCart();
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // todo - when creditcard inplementation
    //const authData = { apiLoginID: apiLoginKey, clientKey };

    // const [month, year] = state.expDate.split('/');

    // const result = await Accept.dispatchData({
    //   authData: {
    //     apiLoginID: apiLoginKey,
    //     clientKey,
    //   },
    //   cardData: {
    //     cardCode: state.cardCode,
    //     cardNumber: state.cardNumber.replace(/\s/g, ''),
    //     month,
    //     year,
    //   },
    // });
    // const { dataValue } = result?.opaqueData;

    // const transactionRequest: TransactionRequest = {
    //   dataValue,
    // };

    // const response = await makePayment(transactionRequest);
    // console.log(response, 'GuestpaymentRequest');

    // Encrypt the credit card data
  };

  return (
    <section
      id="dropin-container"
      aria-labelledby="cart-heading"
      className="bg-white md:rounded md:shadow-md lg:col-span-7"
    >
      <div>
        <form onSubmit={handleSubmit}>
          <div className="form-control">
            <label>cardNumber</label>
            <input type="text" name="cardNumber" value={state.cardNumber} onChange={handleInputChange} />
          </div>
          <div className="form-control">
            <label>cardCode</label>
            <input type="text" name="cardCode" value={state.cardCode} onChange={handleInputChange} />
          </div>
          <div className="form-control">
            <label>expDate</label>
            <input type="text" name="expDate" value={state.expDate} onChange={handleInputChange} />
          </div>
          <div className="form-control">
            <label></label>
            {/* TODO-make it configurable */}
            <button type="submit">Pay</button>
            {/* ************* */}
          </div>
        </form>
      </div>
    </section>
  );
};

export default Checkout;
