import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { AMPLIENCE_IMAGE_URL_FORMAT } from 'helpers/utils/constant';
import format from 'helpers/utils/format';
import calculateDynamicImageWidth from 'helpers/utils/getImageSize';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { desktop } from 'helpers/utils/screensizes';
const FeaturedBlogs = ({ data }) => {
  const [isDesktop] = useMediaQuery(desktop);
  const router = useRouter();
  const dynamicWidth = calculateDynamicImageWidth(300, 400, 500);
  return (
    <>
      <section className="mx-auto mt-24 mb-12 flex max-w-screen-8xl px-5 md:px-0">
        <div className="lg:px-auto flex flex-col leading-snug md:flex-row">
          <div className="wrapper mb-4 leading-snug">
            <div className="relative w-full">
              <h3 className="mb-8 mt-[-50px] flex flex-wrap text-3xl font-semibold leading-6 text-gray-700">
                {mapLocaleToMeaningfulFormat(router.locale).featured}
              </h3>
            </div>
            <div>
              {data?.map((content, index) =>
                content?.ctaLink ? (
                  <Link href={content?.ctaLink}>
                    <div key={index}>
                      <a className={`relative mb-8 flex cursor-pointer pb-8`}>
                        <div className="w-[120px] shrink-0 md:w-[220px]">
                          <Image
                            loader={(options) => options.src}
                            width={220}
                            height={159}
                            src={format(AMPLIENCE_IMAGE_URL_FORMAT, [content?.image?.url, dynamicWidth])}
                            alt={content?.image?.alt}
                            className="rounded-md"
                          />
                        </div>
                        <div
                          className={`p-auto flex flex-col justify-between rounded-b bg-white pl-4 leading-normal lg:rounded-b-none lg:rounded-r lg:border-l-0 lg:border-gray-400 `}
                        >
                          <div className="relative mb-10">
                            <p className="font-montserrat absolute rounded-sm bg-gray-200 px-2 text-sm font-medium leading-5">
                              {content?.category?.title}
                            </p>
                          </div>
                          <div className="mb-2 text-xl font-bold text-gray-900">{content?.title}</div>
                          <p className="text-base leading-snug text-gray-700">{content?.shortDescription}</p>
                          <div className="mt-4 flex items-center">
                            <div className="text-sm leading-6 text-gray-900">
                              <Link href={`/blogs/${content?.category?.slug}/tagged/${content?.tag?.title}`}>
                                <p>
                                  {mapLocaleToMeaningfulFormat(router.locale).tags}{' '}
                                  <span className="underline">{content?.tag?.title}</span>
                                </p>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </a>
                      {index !== data.length - 1 && <hr className="my-8 h-px border-0 bg-[#B19A6A]" />}
                    </div>
                  </Link>
                ) : (
                  <div key={index} className={`relative mb-8 flex pb-8`}>
                    <div className="w-[120px] shrink-0 md:w-[220px]">
                      <Image
                        loader={(options) => options.src}
                        width={220}
                        height={159}
                        src={format(AMPLIENCE_IMAGE_URL_FORMAT, [content?.image?.url, dynamicWidth])}
                        alt={content?.image?.alt}
                        sizes="100vw"
                        className="rounded-md"
                      />
                    </div>
                    <div
                      className={`p-auto flex flex-col justify-between rounded-b bg-white pl-4 leading-normal lg:rounded-b-none lg:rounded-r lg:border-l-0 lg:border-gray-400`}
                    >
                      <div className="relative mb-10">
                        <p className="font-montserrat absolute rounded-sm bg-gray-200 px-2 text-sm font-medium leading-5">
                          {content?.category?.title}
                        </p>
                      </div>
                      <div className="mb-2 text-xl font-bold text-gray-900">{content?.title}</div>
                      <p className="text-base leading-snug text-gray-700">{content?.shortDescription}</p>
                      <div className="mt-4 flex items-center">
                        <div className="text-sm leading-6 text-gray-900">
                          <Link href={`/blogs/${content?.category?.slug}/tagged/${content?.tag?.title}`}>
                            <p>
                              {mapLocaleToMeaningfulFormat(router.locale).tags}{' '}
                              <span className="underline">{content?.tag?.title}</span>
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturedBlogs;
