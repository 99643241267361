import React from 'react';

type Props = {
  className?: string;
  color?: string;
};

const Icon: React.FC<Props> = ({ className, color }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width="16" height="12" viewBox="0 0 16 12" fill="none">
    <path
      d="M4.26801 11.4171C4.44721 11.6018 4.66041 11.7484 4.89531 11.8485C5.13021 11.9485 5.38217 12 5.63664 12C5.89112 12 6.14307 11.9485 6.37797 11.8485C6.61288 11.7484 6.82608 11.6018 7.00528 11.4171L14.8701 3.37758C15.2331 3.00653 15.437 2.50328 15.437 1.97854C15.437 1.4538 15.2331 0.950549 14.8701 0.5795C14.5071 0.208452 14.0148 0 13.5015 0C12.9881 0 12.4958 0.208452 12.1328 0.5795L5.63664 7.23972L3.30418 4.83573C2.94119 4.46469 2.44888 4.25623 1.93554 4.25623C1.4222 4.25623 0.929892 4.46469 0.566908 4.83573C0.203923 5.20678 0 5.71003 0 6.23477C0 6.75952 0.203923 7.26277 0.566908 7.63382L4.26801 11.4171Z"
      fill={color ? color : '#222222'}
    />
  </svg>
);

export default Icon;
