import React from 'react';
import Image from 'next/image';
import router from 'next/router';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';

const CyberSaleBanner = ({ data }) => {
  const content = data?.data?.dataSource?.content;
  const isCyberSale = data?.data?.dataSource?.isCyberSale;
  const [isDesktopSize] = useMediaQuery(desktop);
  return isCyberSale ? (
    content?.image !== null && (
      <section className="mx-auto mt-10 mb-8 grid max-w-[1440px] justify-center px-5 lg:mb-12">
        <a href={content?.image?.imageUrl || '#'}>
          {isDesktopSize ? (
            <Image
              className="mx-auto"
              src={content?.image?.desktopImageBanner?.url + '?fmt=auto&w=1400'}
              alt={content?.altImage}
              width={1400}
              height={120}
              loader={({ src }) => src}
            />
          ) : (
            <Image
              className="mx-auto"
              src={content?.image?.mobileImageBanner?.url + '?fmt=auto&w=760'}
              alt={content?.altImage}
              width={760}
              height={180}
              loader={({ src }) => src}
            />
          )}
        </a>
      </section>
    )
  ) : (
    <section className="mx-auto mt-10 mb-8 grid max-w-[1440px] justify-center px-5 lg:mb-12">
      <a href={content?.imagePlp?.imageUrl || '#'}>
        {isDesktopSize ? (
          <Image
            className="mx-auto"
            src={content?.imagePlp?.desktopImageBanner?.url + '?fmt=auto&w=1400'}
            alt={content?.altImage}
            width={1400}
            height={120}
            loader={({ src }) => src}
          />
        ) : (
          <Image
            className="mx-auto"
            src={content?.imagePlp?.mobileImageBanner?.url + '?fmt=auto&w=760'}
            alt={content?.altImage}
            width={760}
            height={180}
            loader={({ src }) => src}
          />
        )}
      </a>
    </section>
  );
};

export default CyberSaleBanner;
