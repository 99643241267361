import React from 'react';
import OrderDetailLineItem from 'components/sol-ui/line-Item';

const OrderDetailsListItem = ({ lineItem, index }) => {
  return (
    <>
      <li>
        <OrderDetailLineItem
          componentIdentifier={`orderDetail`}
          lineItem={lineItem}
          showQuantityInput={false}
          showAddToCartButton={false}
          showRemoveButton={false}
          showInventory={false}
          isFromOrderDetails={true}
          index={index}
        />
      </li>
    </>
  );
};

export default OrderDetailsListItem;
