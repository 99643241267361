import React from 'react';
import parse from 'html-react-parser';
import ErrorIcon from 'components/icons/sol/alert';
import CloseIcon from 'components/icons/sol/close';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { useNotification } from 'helpers/notification/notificationContext';
import { desktop } from 'helpers/utils/screensizes';
interface NotificationProps {
  timeoutInMs?: number;
}
const InlineNotification: React.FC<NotificationProps> = ({ timeoutInMs }) => {
  const { inlineNotification, showInlineNotification, hideInlineNotification } = useNotification();
  const [isDesktop] = useMediaQuery(desktop);
  if (!inlineNotification) {
    return null;
  } else {
    setTimeout(hideInlineNotification, timeoutInMs);
  }

  const { message, type } = inlineNotification;
  return (
    <div>
      <section
        className={`${
          isDesktop ? 'top-[210px] w-[70%]' : 'top-[125px] w-[92.5%] '
        } absolute left-1/2 right-0 z-[999] flex -translate-x-2/4 items-center justify-center px-5`}
      >
        <section className="flex w-max justify-between rounded-md border border-red-500 bg-red-100 py-3 lg:items-center">
          <section className="flex pl-3">
            <section>
              <ErrorIcon className="ml-2 h-5 w-4" />
            </section>
            <section>
              <p className="mx-2 text-sm font-semibold">{parse(message)}</p>
            </section>
            <section>
              <button type="button" className="ml-3 lg:mt-1" onClick={hideInlineNotification}>
                <CloseIcon className="mr-6 h-5 w-[0.625rem] fill-sol-300" />
              </button>
            </section>
          </section>
        </section>
      </section>
    </div>
  );
};

export default InlineNotification;
