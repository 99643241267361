const dispatchData = async (payload: {
  // @ts-ignore
  authData: Accept.AuthData;
  // @ts-ignore
  cardData: Accept.CardData;
  //@ts-ignore
}): Promise<Accept.Response> => {
  return new Promise((resolve, reject) =>
    // @ts-ignore
    Accept.dispatchData(payload, (response) => {
      switch (response.messages.resultCode) {
        case 'Ok':
          resolve(response);
          break;

        case 'Error':
          reject(response);
          break;
      }
    }),
  );
};

export default { dispatchData };
