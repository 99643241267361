import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Breadcrumb from 'components/sol-ui/cms/Breadcrumb';

const StaticPage = ({ content, categoryList }) => {
  const htmlPage: string = content?.content;
  const [parentCategory, setParentCategory] = useState('');
  const [parentOverrideUrl, setParentOverrideUrl] = useState('');
  const router = useRouter();
  useEffect(() => {
    let flag = true;
    categoryList?.map((categoryItem) => {
      categoryItem?.children?.map((childCategoryItem) => {
        if (childCategoryItem?.overrideURL?.split('pages/')[1] === content?.slug) {
          setParentCategory(categoryItem?.name);
          setParentOverrideUrl(categoryItem?.overrideURL);
          flag = false;
        }
      });
    });
    if (flag) {
      setParentCategory('');
      setParentOverrideUrl('');
    }
  }, [router.asPath]);

  useEffect(() => {
    return () => {
      setParentCategory('');
      setParentOverrideUrl('');
    };
  }, []);

  return (
    <>
      {!content?.removeBreadcrumb && (
        <Breadcrumb
          breadcrumLevelLink={true}
          activePage={content?.title}
          parentCategory={parentCategory}
          parentOverrideUrl={parentOverrideUrl}
        />
      )}
      <section className="mx-auto max-w-screen-8xl px-4 pb-16">
        {content?.title && (
          <h1 className="mx-auto mt-6 text-center text-[1.688rem] font-semibold after:mx-auto after:mt-4 after:block after:h-[0.063rem] after:w-[200px] after:bg-[#B19A6A] after:content-[''] lg:mt-4">
            {content?.title}
          </h1>
        )}
        <section dangerouslySetInnerHTML={{ __html: htmlPage }} />
      </section>
    </>
  );
};
export default StaticPage;
