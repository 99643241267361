import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import calculateDynamicImageWidth from 'helpers/utils/getImageSize';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { desktop } from 'helpers/utils/screensizes';
import { amplienceImageLoader } from 'helpers/utils/amplienceImageLoader';
const BlogCategory = ({ data }) => {
  const [isDesktop] = useMediaQuery(desktop);
  const router = useRouter();
  const dynamicWidth = calculateDynamicImageWidth(300, 400, 500);
  return (
    <>
      <section className="mx-auto max-w-screen-8xl px-5">
        <section
          className={`relative my-8 text-center before:absolute before:top-[54%] before:block before:h-[1px] before:w-full before:bg-sol-600 before:content-[''] lg:mb-10`}
        >
          <em className="relative bg-white px-6 text-[32px] font-semibold uppercase not-italic leading-[39px] md:px-10">
            {mapLocaleToMeaningfulFormat(router.locale).categories}
          </em>
        </section>
        <ul className={`grid grid-cols-1 gap-x-5 gap-y-7 sm:grid-cols-2 lg:grid-cols-4 `}>
          {data?.map((content, index) =>
            content?.ctaLink ? (
              <li key={index} className="rounded-t-[6px] bg-sol-100">
                <Link key={index} href={content?.ctaLink}>
                  <a className="flex h-full w-full">
                    <section className="w-full">
                      <section className="place-content-center">
                        <Image
                          loader={amplienceImageLoader}
                          src={content?.image?.url}
                          alt={content?.alt}
                          width={isDesktop ? 350 : 300}
                          height={isDesktop ? 350 : 300}
                          className="rounded-t-[6px]"
                          sizes="(max-width: 639px) 100vw, (max-width: 767px) 50vw, 25vw"
                        />
                      </section>
                      <section className="bg-sol-100 p-4">
                        <h2 className="text-left text-2xl font-semibold leading-10 text-sol-300">{content.title}</h2>
                        <p className="text-left text-sm leading-5 text-sol-400 lg:text-left">{content.description}</p>
                      </section>
                    </section>
                  </a>
                </Link>
              </li>
            ) : (
              <li key={index} className="rounded-t-[6px] bg-sol-100">
                <a className="h-full w-full">
                  <section className="w-full">
                    <section>
                      <Image
                        loader={amplienceImageLoader}
                        src={content?.image?.url}
                        alt={content?.alt}
                        width={isDesktop ? 325 : 400}
                        height={isDesktop ? 325 : 286}
                        className="rounded-t-[6px]"
                        sizes="(max-width: 639px) 100vw, (max-width: 767px) 50vw, 25vw"
                      />
                    </section>
                    <section className="bg-sol-100 p-4">
                      <h2 className="text-left text-2xl font-semibold leading-10 text-sol-300">{content.title}</h2>
                      <p className="text-left text-sm leading-5 text-sol-400 lg:text-left">{content.description}</p>
                    </section>
                  </section>
                </a>
              </li>
            ),
          )}
        </ul>
      </section>
    </>
  );
};

export default BlogCategory;
