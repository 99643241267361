import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import parse from 'html-react-parser';
import Breadcrumb from 'components/sol-ui/cms/Breadcrumb';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { getAlignment, getMobileAlignment } from 'helpers/utils/banner';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { desktop } from 'helpers/utils/screensizes';

const TradeAccountLandingPage = ({ content }) => {
  const router = useRouter();
  const [isDesktop] = useMediaQuery(desktop);

  const contents = [
    { url: 'https://cdn.media.amplience.net/i/shadesoflight/Rectangle%2054', altText: 'product image' },
    { url: 'https://cdn.media.amplience.net/i/shadesoflight/Rectangle%2054', altText: 'product image' },
    { url: 'https://cdn.media.amplience.net/i/shadesoflight/Rectangle%2054', altText: 'product image' },
    { url: 'https://cdn.media.amplience.net/i/shadesoflight/Rectangle%2054', altText: 'product image' },
  ]; //TODO: Need to replace it with dynamic images url

  return (
    <>
      <Breadcrumb
        breadcrumLevelLink={true}
        activePage={mapLocaleToMeaningfulFormat(router.locale).tradeAccountProgram}
      />

      <section className="relative mx-auto max-w-screen-8xl lg:px-5">
        <div>
          {content?.banner?.desktopMedia?.__typename == 'ImageNode' ? (
            <img
              src={isDesktop ? content?.banner?.desktopMedia?.url : content?.banner?.mobileMedia?.url}
              className="mx-auto my-9 w-full max-w-full rounded-md md:block"
              alt={content?.banner?.alt}
            />
          ) : (
            <video
              className="aspect-video mx-auto  my-9 w-full max-w-full rounded-md md:block"
              autoPlay
              loop
              poster={
                isDesktop
                  ? `https://${content?.banner?.desktopMedia?.defaultHost}/v/${content?.banner?.desktopMedia?.endpoint}/${content?.banner?.desktopMedia?.name}`
                  : `https://${content?.banner?.mobileMedia?.defaultHost}/v/${content?.banner?.mobileMedia?.endpoint}/${content?.banner?.mobileMedia?.name}`
              }
              src={
                isDesktop
                  ? `https://${content?.banner?.desktopMedia?.defaultHost}/v/${content?.banner?.desktopMedia?.endpoint}/${content?.banner?.desktopMedia?.name}/mp4_720p`
                  : `https://${content?.banner?.mobileMedia?.defaultHost}/v/${content?.banner?.mobileMedia?.endpoint}/${content?.banner?.mobileMedia?.name}/mp4_720p`
              }
            ></video>
          )}
        </div>
        {content?.banner?.title && (
          <div
            className={`absolute top-0 flex h-[29rem] w-[88rem] ${
              isDesktop
                ? getAlignment(content?.banner?.alignment)
                : getMobileAlignment(content?.banner?.mobileAlignment)
            } `}
          >
            <div className="m-12 flex flex-col items-center justify-center gap-3 bg-[rgb(0,0,0,0.4)] p-10 text-white">
              <p className="text-3xl">{content?.banner?.title}</p>

              <p className="font-bold">{content?.banner?.subTitle}</p>
            </div>
          </div>
        )}
      </section>
      <section>
        <section className="mx-auto max-w-5xl px-5 lg:px-28">{parse(content?.content)}</section>
        <section className="mx-auto max-w-5xl px-5 lg:px-28">
          <section className="py-10">
            <h1 className="relative mb-6 px-3 text-center font-normal">
              <span className="relative z-[1] inline-block bg-white px-2 text-[1.688rem] font-semibold uppercase md:px-5">
                <a href="https://www.instagram.com/shadesoflight/" className="hover:underline">
                  {mapLocaleToMeaningfulFormat(router.locale).followOnInstagram}
                </a>
              </span>
              <span className="z-1 absolute top-1/2 left-1/2 inline-block h-[1px] w-full -translate-y-1/2 -translate-x-1/2 bg-[#B19A6A]"></span>
            </h1>
            <p className="mt-4 text-center text-sm">{parse(content?.instagram?.subHeading)}</p>
          </section>
          <section className="md: mx-auto mb-12 px-5 md:px-10 md:pb-[10rem]">
            <ul className="grid grid-cols-2 gap-5 lg:grid-cols-4">
              {content?.instagram?.photos?.map((photo, index) => (
                <li key={index}>
                  <Link href={photo?.link || ''}>
                    <a className="flex h-full w-full">
                      <section className="h-full w-full bg-sol-100">
                        <section>
                          <Image
                            loader={(options) => options.src}
                            width={210}
                            height={200}
                            alt={photo?.photo?.alt}
                            src={photo?.photo?.url}
                            sizes="100vw"
                            className="rounded-md"
                          />
                        </section>
                      </section>
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </section>
        </section>
      </section>
    </>
  );
};

export default TradeAccountLandingPage;
