import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import { Reference } from 'helpers/reference';
import { routerURL } from 'helpers/utils/routerUrl';
import { useAccount } from 'frontastic';
import CheckoutAuthentication from '../checkout-authentication';
interface CheckoutProps {
  accountLink?: Reference;
  loginLink?: Reference;
  notificationTimeout?: number;
}
function Checkout({ accountLink, loginLink, notificationTimeout }: CheckoutProps) {
  const { isGuestUser } = useLocalStorage();
  const { loggedIn } = useAccount();
  const router = useRouter();

  useEffect(() => {
    if (isGuestUser || loggedIn) {
      router.push(routerURL.checkoutAddress);
    }
  }, [isGuestUser, loggedIn]);

  return (
    <>
      {!isGuestUser && (
        <section>
          <CheckoutAuthentication
            accountLink={accountLink}
            loginLink={loginLink}
            notificationTimeout={notificationTimeout}
          />
        </section>
      )}
    </>
  );
}

export default Checkout;
