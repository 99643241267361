import BlogDetail from 'components/sol-ui/cms/blog-detail';

const BlogDetailTastic = (props) => {
  const blogCategories = props?.data?.data?.dataSource?.content;
  const blog = props?.pageFolder?.dataSourceConfigurations[1]?.preloadedValue[0];

  return <BlogDetail blogCategories={blogCategories} blog={blog} />;
};

export default BlogDetailTastic;
