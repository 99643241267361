import ThreeColumnContentBlock from 'components/sol-ui/cms/three-column-content-block';

const ThreeColumnContentBlockTastic = ({ data }) => {
  const amplienceContent = data?.data?.dataSource?.content || {};

  return (
    <ThreeColumnContentBlock content={amplienceContent} showShadesOfLightHeading={data?.showShadesOfLightHeading} />
  );
};

export default ThreeColumnContentBlockTastic;
