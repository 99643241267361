import React from 'react';
import { useAccount } from 'frontastic';
import Redirect from 'helpers/redirect';
import Head from 'next/head';
import { useFormat } from 'helpers/hooks/useFormat';
import AccountDetails from 'components/sol-ui/account/index';
export interface Props {
  data: any; //Any keyword will be changed by BE later on.
}

const AccountDetailsTastic: React.FC<Props> = ({ data }) => {
  //I18n messages
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  //account data
  const { loggedIn, account } = useAccount();

  //user not logged in
  // if (!loggedIn) return <Redirect target={data.loginLink} />;  // this will also handle by BE later on.

  return (
    <>
      <AccountDetails />
    </>
  );
};

export default AccountDetailsTastic;
