import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Wishlist } from '@Types/wishlist/Wishlist';
import WishlistLineItem from 'components/sol-ui/line-Item';
import { cartQuantityTotal } from 'helpers/cartQuantityTotal';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { triggerMiniCart } from 'helpers/utils/triggerMiniCart';
import { isWishlistIsValid, validateWishlistItemsQty } from 'helpers/utils/validateCart';
import { useCart, useWishlist } from 'frontastic';
interface Props {
  wishlist: Wishlist;
  hasOwnList: boolean;
}
const ShareMyListComponent: React.FC<Props> = ({ wishlist, hasOwnList }) => {
  const router = useRouter();
  const { updateLineItem, removeLineItem, data: myList } = useWishlist();
  const [sharedList, setSharedList] = useState<Wishlist>(null);
  const { data: cart, addShoppingList } = useCart();
  const isWishlistValid = isWishlistIsValid(wishlist);
  const [lineItemQuantityDisabled, setLineItemQuantityDisabled] = useState(false);
  const isWishlistAllItemsValid = validateWishlistItemsQty(wishlist, cart);
  const addShoppingListToCart = async () => {
    await addShoppingList(wishlist?.wishlistId);
    triggerMiniCart();
  };
  useEffect(() => {
    wishlist?.wishlistId === myList?.wishlistId ? setSharedList(myList) : setSharedList(wishlist);
  }, [myList]);
  return (
    <>
      <section className="mx-auto max-w-screen-8xl px-4 pb-10">
        <section>
          <h1 className="mt-6 text-center text-[1.688rem] font-semibold after:mx-auto after:mt-4 after:block after:h-[0.063rem] after:w-[9.375rem] after:bg-[#B19A6A] after:content-[''] lg:mt-2">
            {mapLocaleToMeaningfulFormat(router.locale).sharedMyList}
          </h1>
          <p className="mt-4 text-center text-sm capitalize">
            {sharedList?.lineItems?.length > 0 ? cartQuantityTotal(sharedList?.lineItems) : 0}{' '}
            {mapLocaleToMeaningfulFormat(router.locale).items}
          </p>
        </section>
        {!isWishlistValid && (
          <p className="mt-4 text-center font-medium text-red-600 lg:pr-4 lg:pb-8">
            {' '}
            {mapLocaleToMeaningfulFormat(router.locale).invalidWishlist}
          </p>
        )}
        {wishlist?.lineItems?.length === 0 ? (
          ''
        ) : (
          <section className="mt-6 lg:flex lg:justify-end">
            <section className="flex flex-col justify-between lg:w-[calc(100%_-_233px)] lg:flex-row">
              <section className="shrink-0 lg:mt-4"></section>
              <section className="w-full flex-col justify-end lg:flex lg:flex-row">
                <section className="relative w-full lg:mr-5 lg:w-[196px]"></section>
                <button
                  type="button"
                  id="addItemToList"
                  className={`btn-primary-small mt-4 lg:mt-0 ${
                    !isWishlistValid || !isWishlistAllItemsValid ? 'cursor-not-allowed opacity-50' : ''
                  }`}
                  onClick={() => (isWishlistValid && isWishlistAllItemsValid ? addShoppingListToCart() : null)}
                >
                  {mapLocaleToMeaningfulFormat(router.locale).addAllToCart}
                </button>
              </section>
            </section>
          </section>
        )}
        <section className="mt-5 flex-col gap-5 md:mt-4 lg:flex lg:flex-row">
          <section className="w-full">
            <ul>
              {sharedList?.lineItems?.length === 0 && (
                <p className="w-full rounded border px-5 py-6 text-sm font-normal text-sol-300">
                  {mapLocaleToMeaningfulFormat(router.locale).yourListIsEmpty}
                </p>
              )}
              {sharedList?.lineItems?.map((wishlistItem, index) => (
                <li
                  key={index}
                  className="mb-5 mt-3 w-full rounded border px-5 py-6 pb-5 text-sm font-normal text-sol-300 lg:mt-0 lg:py-2"
                >
                  {!wishlistItem?.isAvailable && (
                    <p className="mt-4 text-left font-medium text-red-600 lg:pr-4 lg:pb-4">
                      {mapLocaleToMeaningfulFormat(router.locale).thisProductNoLongerAvailable}
                    </p>
                  )}

                  <WishlistLineItem
                    componentIdentifier={`myList`}
                    lineItem={wishlistItem}
                    showQuantityInput={hasOwnList}
                    showAddToCartButton={true}
                    showRemoveButton={hasOwnList}
                    updateLineItem={updateLineItem}
                    removeLineItem={removeLineItem}
                    lineItemQuantityDisabled={lineItemQuantityDisabled}
                    setLineItemQuantityDisabled={setLineItemQuantityDisabled}
                    showInventory={true}
                    wishlist={wishlist}
                    isFromOrderDetails={false}
                    index={index}
                  />
                </li>
              ))}
            </ul>
          </section>
        </section>
      </section>
    </>
  );
};

export default ShareMyListComponent;
