import FeaturedBlogs from 'components/sol-ui/cms/featuredBlogs';
import React from 'react';

const FeaturedBlogsTastic = ({ data }) => {
  const content = data?.data?.dataSource?.content;

  return <FeaturedBlogs data={content} />;
};

export default FeaturedBlogsTastic;
