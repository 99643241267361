import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { DateHelpers } from 'helpers/dateHelpers';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { getBannerAlignment } from 'helpers/utils/banner';
import { tablet } from 'helpers/utils/screensizes';

export const PromotionalBanner = ({ data }) => {
  const [showBanner, setShowBanner] = useState(false);
  const [isMobile] = useMediaQuery(tablet);

  useEffect(() => {
    DateHelpers.checkDateRange(data?.from, data?.to) ? setShowBanner(true) : setShowBanner(false);
  }, []);

  return (
    <section id="promotional-banner" className={`${!isMobile ? 'relative top-0 left-0 z-[9]' : null} h-auto w-full`}>
      {showBanner && (
        <div
          style={{ backgroundColor: data?.bgColor }}
          className={`text-[${data?.fontSize}px] w-full p-2 px-5 text-center font-semibold`}
        >
          <div className={getBannerAlignment(data?.alignment)}>
            <p className="mr-1 text-sm font-medium">
              {data?.title}{' '}
              <span className="underline-offset-2 hover:underline">
                {data?.isLinkVisible && <Link href={data?.linkUrl}>{data?.linkText}</Link>}
              </span>
            </p>
          </div>
        </div>
      )}
    </section>
  );
};
