import { CurrencyHelpers } from 'helpers/currencyHelpers';

const getVariantPrice = (selectedVariant) => {
  return CurrencyHelpers.formatForCurrency(selectedVariant?.price);
};

const addToGiftRegistry = (productData, selectedVariant, quantity, isQuickView, pdpNavigationLink) => {
  const gift = {
    Title: productData?.name,
    ImageUrl: selectedVariant?.images?.[0]?.url || '#',
    URL: pdpNavigationLink,
    Price: getVariantPrice(selectedVariant),
    Quantity: quantity,
    HideSize: true,
    HideColor: true,
    Color: selectedVariant?.attributes?.actualColor,
    Size: selectedVariant?.attributes?.actualSize,
    SKU: selectedVariant?.sku,
    Options: [],
  };
  //@ts-ignore
  const myregistrysdk = new MyRegistrySDK(gift);
  myregistrysdk.on('gift_value_changed', function (e) {
    console.log(e);
  });
  myregistrysdk.on('popup_showing', function (e) {
    console.log(e);
  });
  myregistrysdk.on('popup_showed', function (e) {
    console.log(e);
  });
  myregistrysdk.on('gift_option_bound', function (e) {
    console.log(e);
  });
  if (myregistrysdk) myregistrysdk.showPopup();
};

export default { addToGiftRegistry };
