import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import router, { useRouter } from 'next/router';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import DoubleArrow from 'components/icons/sol/double-arrow';
import InfoIcon from 'components/icons/sol/info-icon';
import ResetIcon from 'components/icons/sol/reset-success-icon';
import { RESET_PASSWORD_SUCCESS } from 'helpers/constants/messages';
import { useNotification } from 'helpers/notification/notificationContext';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import validationSchema from 'helpers/utils/validationSchema/resetPassword';
import { useAccount } from 'frontastic';
import Breadcrumb from '../cms/Breadcrumb';
import Password from '../form-fields/password';
const resetPasswordinitialValues = {
  password: '',
  confirmPassword: '',
};

const ResetPassword = () => {
  const { resetPassword } = useAccount();
  const { showNotification } = useNotification();
  //next/router
  const router = useRouter();
  const renderError = (message: any) => <p className="text-sm leading-[17px] text-[#DA0F0F]">{message}</p>;

  const handleResetPassword = async (values: any) => {
    const { query } = router;
    if (!query.token) {
      router.push('/login');
      return null;
    }

    await resetPassword(query.token.toString(), values.password).then(() => {
      showNotification(RESET_PASSWORD_SUCCESS, 'success');
      router.push('/account');
    });
  };

  return (
    <>
      <Breadcrumb
        breadcrumLevelLink={true}
        activePage={mapLocaleToMeaningfulFormat(router.locale).resetPasswordBreadcrumb}
      />
      <section className="mx-auto max-w-screen-8xl">
        <section className="my-10 w-full justify-center sm:flex">
          <Formik
            initialValues={resetPasswordinitialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleResetPassword(values)}
          >
            {({ errors, touched, handleBlur }) => (
              <Form>
                <section className="px-4 sm:px-14">
                  <section className="sm:w-[373px]">
                    <section className="!w-full pb-8 lg:pb-0">
                      <h1 className="mx-auto mb-4 w-max text-center text-[28px] font-semibold leading-[34px] text-[#1B3144] after:mx-auto after:mt-6 after:block after:h-[1px] after:w-[299px] after:bg-[#B19A6A] after:content-['']">
                        {mapLocaleToMeaningfulFormat(router.locale).resetPassword}
                      </h1>
                      <section className="mb-3 mt-6">
                        <section className="flex items-center gap-3">
                          <label
                            className="text-sm font-semibold leading-[17px] text-sol-300"
                            htmlFor="registerPassword"
                          >
                            {mapLocaleToMeaningfulFormat(router.locale).password}
                          </label>

                          <section className="dropdown group relative">
                            <section className="cursor-pointer py-1 pr-5">
                              <InfoIcon className="fill-sol-300" />
                            </section>

                            <div className="dropdown-menu absolute z-[1] hidden h-auto w-[270px] rounded-[6px] border border-gray-300 bg-[#F5F5F5] p-4 group-hover:block lg:w-[347px]">
                              <h4 className="mb-3 text-xs font-semibold leading-[15px] text-[#191919]">
                                {mapLocaleToMeaningfulFormat(router.locale).passwordsMustMeet}
                              </h4>
                              <section className="top-0 w-full">
                                <ul className="list-disc pl-4 ">
                                  <li className="py-1 text-sol-300">
                                    <p className="text-xs font-normal leading-[18px] text-[#191919]">
                                      {mapLocaleToMeaningfulFormat(router.locale).passwordlength}
                                    </p>
                                  </li>
                                  <li className="my-2">
                                    <p className="text-xs font-normal leading-[18px] text-[#191919]">
                                      {mapLocaleToMeaningfulFormat(router.locale).passwordDifferentFromUserName}
                                    </p>
                                  </li>
                                  <li className="my-2">
                                    <p className="text-xs font-normal leading-[18px] text-[#191919]">
                                      {mapLocaleToMeaningfulFormat(router.locale).passwordNotSpace}
                                    </p>
                                  </li>
                                  <li className="my-2">
                                    <p className="text-xs font-normal leading-[18px] text-[#191919]">
                                      {mapLocaleToMeaningfulFormat(router.locale).passwordOneUpperCase}
                                    </p>
                                  </li>
                                  <li className="my-2">
                                    <p className="text-xs font-normal leading-[18px] text-[#191919]">
                                      {mapLocaleToMeaningfulFormat(router.locale).passwordOneDigit}
                                    </p>
                                  </li>
                                  <li className="my-2">
                                    <p className="text-xs font-normal leading-[18px] text-[#191919]">
                                      {mapLocaleToMeaningfulFormat(router.locale).passwordMustContain}
                                    </p>
                                  </li>
                                </ul>
                              </section>
                            </div>
                          </section>
                        </section>
                        <Password
                          name="password"
                          id="registerPassword"
                          onBlur={handleBlur}
                          className={errors?.password && touched?.password ? '!border-red-600' : ''}
                        />
                      </section>
                      {errors.password && touched.password && (
                        <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{errors.password}</p>
                      )}
                      <section className="mt-6 mb-3">
                        <label
                          htmlFor="resetConfirmPassword"
                          className="text-sm font-semibold leading-[17px] text-sol-300"
                        >
                          {mapLocaleToMeaningfulFormat(router.locale).confirmPassword}
                        </label>
                        <Password
                          name="confirmPassword"
                          id="resetConfirmPassword"
                          onBlur={handleBlur}
                          className={errors?.confirmPassword && touched?.confirmPassword ? '!border-red-600' : ''}
                        />
                      </section>
                      {errors.confirmPassword && touched.confirmPassword && (
                        <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">{errors.confirmPassword}</p>
                      )}
                      <section className="mt-9 flex flex-col items-center gap-4 md:flex-row">
                        <button
                          type="submit"
                          className={`flex h-[44px] w-full items-center justify-center rounded-md bg-[#404040] px-6 text-white md:w-auto ${
                            Object.keys(errors).length === 0 ? '' : 'cursor-not-allowed'
                          }`}
                        >
                          {mapLocaleToMeaningfulFormat(router.locale).resetPassword}
                        </button>
                      </section>
                    </section>
                  </section>
                </section>
              </Form>
            )}
          </Formik>
        </section>
      </section>
    </>
  );
};

export default ResetPassword;
